const activeDepotKey = 'activeDepot'

function getActiveDepot() {
  return localStorage.getItem(activeDepotKey)
}

function setActiveDepot(depot: string) {
  localStorage.setItem(activeDepotKey, depot)
}

const activeDepotUtils = {
  getActiveDepot,
  setActiveDepot,
}

export default activeDepotUtils

function debounce(func: Function, timeout = 300) {
  let timer: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

const callbackUtils = {
  debounce,
}

export default callbackUtils

import { listSliceBuilder, createSliceBuilder } from 'store/helper'
import { combineReducers } from '@reduxjs/toolkit'
import { AdminUserResource, UserTokenResource } from 'api/main'
import {
  usersListRequestAsync,
  usersInviteRequestAsync,
  userRegisterRequestAsync,
  userUpdateRequestAsync,
} from './actions'

export const usersReducers = combineReducers({
  list: listSliceBuilder<AdminUserResource>('list/userListsRequestAsync', usersListRequestAsync).reducer,
  invite: createSliceBuilder<void>('invite/inviteUser', usersInviteRequestAsync).reducer,
  register: createSliceBuilder<UserTokenResource>('register/userRegister', userRegisterRequestAsync).reducer,
  update: createSliceBuilder<AdminUserResource>('update/userUpdate', userUpdateRequestAsync).reducer,
})

import { Row, Col, Typography } from 'antd'

const { Text } = Typography

interface Props {
    title: string
    text?: string | null
    span?: number
}

export default function EditTableCell({ title, text, span = 5 }: Props) {
    return (
        <Col span={span}>
            <Text strong>{title}</Text>
            <Row className='mb-2' />
            <Text>{text}</Text>
        </Col>
    )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminContainerInfoService, ContainerTypeCollection } from 'api/main'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const containerTypeListRequestAsync = createAsyncThunk<
  ContainerTypeCollection,
  GeneralListRequest,
  { rejectValue: string }
>('list/containerTypeListRequestAsync', async ({ page, perPage, query }, { rejectWithValue }) => {
  try {
    return await AdminContainerInfoService.adminContainerType(perPage, page, query)
  } catch (e: any) {
   return handleApiCallError(e, rejectWithValue)
  }
})

import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType, UserUpdateRequestWithEmail } from 'models'



export const userFields: GeneralFormFieldType<UserUpdateRequestWithEmail> = {
  role_id: {
    label: 'User role',
    placeholder: 'User role',
    type: InputType.SELECT
  },
  first_name: {
    label: 'First name',
    placeholder: 'First name',
  },
  last_name: {
    label: 'Last name',
    placeholder: 'Last name',
  },
  email: {
    label: 'Email',
    placeholder: 'Email',
    disabled: true
  },
  address: {
    label: 'Address',
    placeholder: 'Address',
  },
  phone_number: {
    label: 'Phone',
    placeholder: 'Phone',
  },
  date_of_birth: {
    label: 'Date of Birth',
    placeholder: 'example',
    hideInForm: true
  },
  depot_ids: {
    label: 'Depot name',
    placeholder: 'Depot name',
    type: InputType.MULTISELECT
  },
}

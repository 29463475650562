import { combineReducers } from '@reduxjs/toolkit'
import {
  createCompanyRequestAsync,
  companiesListRequestAsync,
  updateCompanyRequestAsync,
  deleteCompanyRequestAsync,
} from 'store/companies/actions'
import { createSliceBuilder, listSliceBuilder } from 'store/helper'
import { CompanyResource } from 'api/main'

export const companyReducers = combineReducers({
  list: listSliceBuilder<CompanyResource>('listCompany', companiesListRequestAsync).reducer,
  create: createSliceBuilder<CompanyResource>('createCompany', createCompanyRequestAsync).reducer,
  update: createSliceBuilder<CompanyResource>('updateCompany', updateCompanyRequestAsync).reducer,
  delete: createSliceBuilder<CompanyResource>('deleteCompany', deleteCompanyRequestAsync).reducer,
})

import { useEffect, VoidFunctionComponent } from 'react'
import { Table } from 'antd'
import { useAppDispatch } from 'store/hooks'
import { CustomerUpdateRequestWithEmail } from 'models'
import { AdminCustomerResource } from 'api/main'
import RowExpanded from 'Components/UiKit/RowExpanded/RowExpanded'
import { NavLink, useParams } from 'react-router-dom'
import { CompanyUserFormFields } from 'Components/Companies/CompanyUsers/CompanyUserFormFields'
import { companyUsersListRequest, selectCompanyUserById, tableSelectors } from 'store/companyUsers'
import CDTable from 'Components/Shared/CDTable'

const CompanyUsersTable: VoidFunctionComponent = () => {
  const { companyId } = useParams<{ companyId: string }>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(companyUsersListRequest(Number(companyId)))
  }, [dispatch, companyId])

  const renderActions = (_value: any, item: AdminCustomerResource) => {
    return (
      <NavLink key="something" to={`/companies/${companyId}/user/${item.id}`}>
        Edit
      </NavLink>
    )
  }

  return (
    <>
      <CDTable<AdminCustomerResource>
        selectors={tableSelectors}
        expandable={{
          expandedRowRender: record => {
            return (
              <RowExpanded<CustomerUpdateRequestWithEmail>
                selector={selectCompanyUserById(record.id)}
                formFields={CompanyUserFormFields}
              />
            )
          },
          expandIconColumnIndex: 2,
        }}
      >
        <Table.Column title="User Name" dataIndex="email" />
        <Table.Column title="Action" render={renderActions} />
      </CDTable>
    </>
  )
}

export default CompanyUsersTable

import {
  IsoCodeResource,
  IsoCodeRequest,
  AdminUserResource,
  AdminCustomerResource,
  AdvanceInfoUpdateRequest,
  AdvanceInfoWithDetailResource,
  ArrivalResource,
  ArrivalUpdateRequest,
  CheckedAreaResource,
  CheckedAreaUpdateRequestBody,
  DamageEstimateResource,
  UpdateDamageEstimateRequest,
} from 'api/main'
import { UserUpdateRequestWithEmail, CustomerUpdateRequestWithEmail } from 'models'

export const mapIsoResToReq = (isoRes: IsoCodeResource): IsoCodeRequest => {
  return {
    ...isoRes,
    container_type_id: isoRes.container_type.id,
    container_material_id: isoRes.container_material.id,
  }
}

export const mapUserResToReq = (userRes: AdminUserResource): UserUpdateRequestWithEmail => {
  return {
    first_name: userRes.first_name,
    last_name: userRes.last_name,
    date_of_birth: userRes.date_of_birth,
    phone_number: userRes.phone_number,
    address: userRes.address,
    role_id: userRes.role?.id,
    depot_ids: userRes.depots.map(dep => dep.id),
    email: userRes.email,
  }
}

export const mapCompanyUserResToReq = (res: AdminCustomerResource): CustomerUpdateRequestWithEmail => {
  return {
    first_name: res.first_name,
    last_name: res.last_name,
    date_of_birth: res.date_of_birth,
    phone_number: res.phone_number,
    address: res.address,
    email: res.email,
  }
}

export const mapAdvInfoResToReq = (res: AdvanceInfoWithDetailResource): AdvanceInfoUpdateRequest => {
  return {
    container_csc_date: res.container_csc_date,
    container_max_gross_weight: res.container_max_gross_weight,
    container_tare_weight: res.container_tare_weight,
    container_material: res.container_material,
    container_manufacturing_date: res.container_manufacturing_date,
    has_lease_info: res.has_lease_info,
    lease_info_lessee: res.lease_info_lessee,
    lease_info_on_hire_date: res.lease_info_on_hire_date,
    lease_info_dpp_currency: res.lease_info_dpp_currency,
    lease_info_dpp_amount: res.lease_info_dpp_amount,
    note: res.note,
    expiration_date: res.expiration_date,
    lift_in_paid_by: res.lift_in_paid_by,
    lift_out_paid_by: res.lift_out_paid_by,
    rent_paid_by: res.rent_paid_by,
    lift_in_invoice_type: res.lift_in_invoice_type,
    lift_out_invoice_type: res.lift_out_invoice_type,
    rent_invoice_type: res.rent_invoice_type,
  }
}

export const mapArrivalResToReq = (res: ArrivalResource): ArrivalUpdateRequest => {
  return {
    arrival_license_plate_number: res?.license_plate_number || undefined,
    arrival_transportation_company: res?.transportation_company || undefined,
    arrival_note: res?.arrival_note || undefined,
  }
}

export const mapCheckedResReq = (res: CheckedAreaResource): CheckedAreaUpdateRequestBody => {
  return {
    checked_note: res?.checked_note || '',
    checked_condition: res?.checked_condition,
    checked_only_washing: res?.checked_only_washing,
    checked_grade: res?.container_grade,
  }
}

export const mapEstimateResReq = (res: DamageEstimateResource): UpdateDamageEstimateRequest => {
  return {
    number: res.item_number,
    damage: res.damage,
    reference: res.reference,
    quantity: res.quantity,
    repair: res.repair,
    length: res.length,
    width: res.width,
    hours: res.hours,
    company_id: res.company_id,
    component: res.component,
    location: res.location,
    paid_by_third_party: res.paid_by_third_party,
    material: res.material,
    labor_rate: res.labor_rate,
  }
}

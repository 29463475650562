import { createAsyncThunk } from '@reduxjs/toolkit'
import type { AppGetMenuResource } from 'api/main'
import { AppMenuService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export const accessRequestAsync = createAsyncThunk<AppGetMenuResource, void, { rejectValue: string }>(
  'list/accessRequestAsync',
  async (_, { rejectWithValue }) => {
    try {
      return await AppMenuService.appDepot()
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AppContainerNumberValidationRequest,
  AppContainerService,
  AppOutReferenceResource,
  AppTruckOffRequest,
  AppTruckPickUpRequest,
  DropOffToArrivalCollection,
  TruckPickUpCollectionResource,
} from 'api/main'
import { handleApiCallError } from 'store/helper'

export const dropOffRequest = createAsyncThunk<
  DropOffToArrivalCollection,
  { requestBody: AppTruckOffRequest },
  { rejectValue: string }
>('dropOff/ordering', async ({ requestBody }, { rejectWithValue }) => {
  try {
    return await AppContainerService.appContainerDropOff(requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const dropOffValidationRequest = createAsyncThunk<
  void,
  { requestBody: AppContainerNumberValidationRequest },
  { rejectValue: string }
>(
  'dropOffValidation/ordering',
  async ({ requestBody }, { rejectWithValue }) => {
    try {
      return await AppContainerService.appContainerDropOffValidation(requestBody)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const pickUpListRequest = createAsyncThunk<AppOutReferenceResource, { 
  reference: any,
}, { rejectValue: string }>(
  'pickUp/ordering',
  async ({ reference }, { rejectWithValue }) => {
    try {
      return await AppContainerService.appContainerPickUpOutReferenceReferenceGet(reference)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const pickUpRequest = createAsyncThunk<
  TruckPickUpCollectionResource,
  { reference: number, requestBody: AppTruckPickUpRequest },
  { rejectValue: string }
>(
    'pickUp/ordering', async ({ reference, requestBody }, { rejectWithValue }) => {
  try {
    return await AppContainerService.appContainerPickUpOutReferenceReferencePost(reference, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

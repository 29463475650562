import { ListState } from 'models'
import type { OutReferenceResource } from 'api/main'
import type { NormalState } from 'models/ApiState'
import { RootState } from '../index'

type OutReferenceListState = ListState<OutReferenceResource>
export const selectOutReferenceListData = (state: RootState): OutReferenceListState['data'] => state.outReference.list.data
export const selectOutReferencePaginationData = (state: RootState): OutReferenceListState['paginationData'] =>
  state.outReference.list.paginationData
export const selectOutReferenceListStatus = (state: RootState): OutReferenceListState['status'] => state.outReference.list.status

export const selectOutReferenceAddStatus = (state: RootState): NormalState<void>['status'] =>
  state.outReference.add.status

export const selectOutReferenceDeleteStatus = (state: RootState): NormalState<void>['status'] =>
  state.outReference.delete.status

export const selectOutReferenceUpdateStatus = (state: RootState): NormalState<void>['status'] =>
  state.outReference.update.status

export const selectMoveToReleaseStatus = (state: RootState): NormalState<void>['status'] =>
state.outReference.moveToRelease.status

export const tableSelectors = {
  data: selectOutReferenceListData,
  status: selectOutReferenceListStatus,
  pagination: selectOutReferencePaginationData,
}

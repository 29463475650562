import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal, Button } from 'antd'
import { SubmitHandler, useWatch } from 'react-hook-form'
import { DamagedFilterFormFields } from 'Components/Damageds/DamagedFilter/DamagedFilterFormFields'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { isoCodeListRequestAsync, selectIsoCodeListStatus, selectIsoCodeListMappedData } from 'store/isoCodes'
// import {  ArrivalUpdateRequest } from 'api/main'
import { DamagedFilterModel } from 'models/forms/DamagedFilterModel'
import useCDForm from 'hooks/useCDForm'
import { MappedOption, Status } from 'models'
import { filterInitialData } from 'Components/Damageds/helper'

interface Props {
  onSubmit: SubmitHandler<DamagedFilterModel>
  onCancel: () => void
  visible?: boolean
  initialData: DamagedFilterModel
}

const DamagedFilter: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, initialData }) => {
  const { handleSubmit, reset, renderInput, control, setValue } = useCDForm<DamagedFilterModel>(DamagedFilterFormFields)
  const dispatch = useAppDispatch()
  const listStatus = useAppSelector(selectIsoCodeListStatus)
  const isoCodes = useAppSelector(selectIsoCodeListMappedData)
  useEffect(() => {
    if (listStatus === Status.IDLE) {
      dispatch(isoCodeListRequestAsync({ perPage: 20, page: 1, query: '' }))
    }
  }, [dispatch, listStatus])

  useEffect(() => {
    if (visible) {
      reset(initialData)
    }
  }, [reset, visible, initialData])

  const onCancelForm = () => {
    onCancel()
  }

  const status = useWatch({
    control,
    name: 'status',
  })

  useEffect(() => {
    if (status !== 'rejected') {
      setValue('state', '')
    }
  }, [setValue,status])

  return (
    <Modal
      visible={visible}
      title="Filter"
      okText="Apply"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onSubmit(filterInitialData)
          }}
        >
          Reset
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit(onSubmit)}>
          Filter
        </Button>,
      ]}
    >
      <Form name="damaged_filter" layout="vertical">
        {Object.keys(DamagedFilterFormFields).map((entry: string) => {
          const typedEntry = entry as keyof DamagedFilterModel
          const options: MappedOption[] = DamagedFilterFormFields[typedEntry].options || []
          let newOptions
          if (entry !== 'isoCode') {
            newOptions = [{ value: '', label: 'All' }, ...options]
          } else {
            newOptions = isoCodes
          }

          // eslint-disable-next-line sonarjs/no-small-switch
          switch (typedEntry) {
            case 'state':
              return (
                <span key={entry}>
                  <p style={{ fontWeight: 500 }}>{DamagedFilterFormFields[typedEntry].label}</p>
                  {renderInput(typedEntry, newOptions, false, status !== 'rejected')}
                </span>
              )
            default:
              return (
                <span key={entry}>
                  <p style={{ fontWeight: 500 }}>{DamagedFilterFormFields[typedEntry].label}</p>
                  {renderInput(typedEntry, newOptions, false)}
                </span>
              )
          }
        })}
      </Form>
    </Modal>
  )
}

export default DamagedFilter

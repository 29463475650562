import { useEffect, VoidFunctionComponent } from 'react'
import { Button, Table, Space } from 'antd'
import { NavLink, useParams } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  invoicesListRequestAsync,
  deleteInvoiceRequestAsync,
  selectInvoicesListData,
  selectInvoicesListStatus,
  selectInvoiceByName,
} from 'store/invoices'
import expandIcon from 'Components/UiKit/ExpandIcon'
import { selectDepotsListStatus, } from 'store/depots'
import useFetchDepotsOnce from 'hooks/useFetchDepotsOnce'
import { AdminDepotResource, CompanyDepotFeesResource, CompanyDepotRequest } from 'api/main'
import { Status } from 'models'
import RowExpanded from 'Components/UiKit/RowExpanded/RowExpanded'
import { invoiceFields } from '../Invoice/AddInvoice/FormFields'

const CompanyInvoicesTable: VoidFunctionComponent = () => {
  const depotStatus = useAppSelector(selectDepotsListStatus)
  const invoiceStatus = useAppSelector(selectInvoicesListStatus)
  const [depotData] = useFetchDepotsOnce()
  const invoiceData = useAppSelector(selectInvoicesListData)
  const { companyId } = useParams<{ companyId: string }>()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(invoicesListRequestAsync(Number(companyId)))
  }, [dispatch, companyId])

  const getInvoice = (name: string): CompanyDepotFeesResource | undefined =>
    invoiceData?.find(invoice => invoice.name === name)

  const deleteInvoiceHandler = async (compId: number, depotId: number) => {
    await dispatch(deleteInvoiceRequestAsync({ companyId: compId, depotId }))
    dispatch(invoicesListRequestAsync(compId))
  }

  // TODO: change any type when backend api is ready
  const renderActions = (_value: any, item: AdminDepotResource) => {
    const depotInvoice = getInvoice(item.name)
    return depotInvoice ? (
      <Space>
        <NavLink key="something" to={`/companies/${companyId}/invoices/${item.id}/${depotInvoice.id}`}>
          Edit
        </NavLink>
        <Button
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            deleteInvoiceHandler(Number(companyId), item.id)
          }}
        />
      </Space>
    ) : (
      <NavLink key="something" to={`/companies/${companyId}/invoices/${item.id}`}>
        + Add Invoicing Details
      </NavLink>
    )
  }
  return (
    <Table
      dataSource={depotData}
      rowKey={rc => rc.name}
      loading={depotStatus === Status.PENDING || invoiceStatus === Status.PENDING}
      expandable={{
        expandedRowRender: record => {
          return (
            <RowExpanded<CompanyDepotRequest> selector={selectInvoiceByName(record.name)} formFields={invoiceFields} />
          )
        },
        rowExpandable: record => !!getInvoice(record.name),
        expandIconColumnIndex: 2,
        expandIcon: expandIcon,
      }}
    >
      <Table.Column width="80%" title="Depot" dataIndex="name" />
      <Table.Column title="Action" render={renderActions} />
    </Table>
  )
}

export default CompanyInvoicesTable

import { VFC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAppDispatch } from '../../store/hooks'
import { tokenUtils } from 'utils'
import { setStatus } from 'store/auth/login'
import { Status } from 'models'
import { adminAboutMeRequest } from 'store/auth/actions'

// TODO: implement this component whenever its task is started
const DashboardPage: VFC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const token = tokenUtils.getToken()

    if (!token) {
      dispatch(setStatus(Status.IDLE))
      history.push('/login')
    }
  }, [dispatch, history])

  useEffect(() => {
    dispatch(adminAboutMeRequest())
  },[dispatch])

  return <div>Dashboard</div>
}

export default DashboardPage

import { VoidFunctionComponent } from 'react'
import { Table } from 'antd'
import { DamageEstimateResource } from 'api/main'
import RepairColumn from 'Components/Shared/RepairColumn'

interface Props {
  initialData: DamageEstimateResource[]
}

const RepairingDetails: VoidFunctionComponent<Props> = ({ initialData }) => {
  return (
    <>
      <Table
        dataSource={initialData}
        rowKey={rc => rc.id || 0}
        locale={{ emptyText: 'No result' }}
        pagination={false}
      >
        <Table.Column title="Item number" dataIndex="item_number" />
        <Table.Column title="Component" dataIndex="component" />
        <Table.Column title="Damage" dataIndex="damage" />
        <Table.Column
          title="Repair"
          dataIndex="repair"
          render={(text: string, record: DamageEstimateResource) => <RepairColumn record={record} />}
        />
        <Table.Column title="Location" dataIndex="location" />
        <Table.Column title="Quantity" dataIndex="quantity" />
        <Table.Column title="Length" dataIndex="length" />
        <Table.Column title="Width" dataIndex="width" />
        <Table.Column title="Hours" dataIndex="hours" />
      </Table>
    </>
  )
}

export default RepairingDetails

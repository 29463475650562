/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CotainerNumbersResponse } from '../models/CotainerNumbersResponse';
import type { GateOutRequestBody } from '../models/GateOutRequestBody';
import type { OutQueueCollection } from '../models/OutQueueCollection';
import { request as __request } from '../core/request';

export class AdminContainerOutQueueService {

    /**
     * Delete out queue
     * With this end point you can delete an out queue.
     * @param outQueue
     * @returns void
     * @throws ApiError
     */
    public static async adminOutQueueOutQueueDelete(
        outQueue: number,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/admin/out_queue/${outQueue}`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * GateOut part of an out queue
     * With this end point you can gate_out part of an out queue.
     * @param outQueue
     * @param requestBody Gate out request body
     * @returns void
     * @throws ApiError
     */
    public static async adminOutQueueOutQueueGateOutPost(
        outQueue: number,
        requestBody?: GateOutRequestBody,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/out_queue/${outQueue}/gate_out`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Auto compelte container number for an out queue release modal.
     * This end point will auto compelte container number.
     * @param outQueue
     * @param query Search query over available container numbers for out queue.
     * @returns CotainerNumbersResponse List of container numbers
     * @throws ApiError
     */
    public static async adminOutQueueOutQueueContainerNumbers(
        outQueue: number,
        query: string,
    ): Promise<CotainerNumbersResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/out_queue/${outQueue}/container/numbers`,
            query: {
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Get out queue list
     * This end point will return all out queue items.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over OutQueues.
     * @returns OutQueueCollection OutQueue collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdOutQueueList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<OutQueueCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/out_queue`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
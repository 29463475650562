import * as yup from 'yup'
import { editCheckedFormFields } from 'Components/Checkings/editCheckedFormFields'
import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'
import { FromCheckingToCheckedRequestBody } from 'api/main'

const editArrivalSchema = yup.object().shape<GeneralSchemaType<FromCheckingToCheckedRequestBody>>({
  checked_condition: yup.string().required(`'${editCheckedFormFields.checked_condition?.label}' is a required field`),
  checked_only_washing: yup
    .boolean()
    .nullable(),
  checked_grade: yup.string().required(`'${editCheckedFormFields.checked_grade?.label}' is a required field`),
  checked_note: yup.string().nullable(),
})

export default editArrivalSchema

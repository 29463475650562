import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminAuthService, AdminForgotPasswordRequest } from '../../../api/main'
import { handleApiCallError } from 'store/helper'

export const forgotPasswordRequestAsync = createAsyncThunk<void, AdminForgotPasswordRequest, { rejectValue: string }>(
  'forgotPassword/forgotPasswordRequestAsync',
  async (body, { rejectWithValue }) => {
    try {
      return await AdminAuthService.adminAuthPasswordForgot(body)
    } catch (e) {
      return handleApiCallError(e, rejectWithValue)

    }
  }
)

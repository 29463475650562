import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { PageHeader, Card } from 'antd'
import { Status } from 'models'
import { SubmitHandler } from 'react-hook-form'
import { CustomerUpdateRequest } from 'api/main'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectCompanyUserById, companyUserUpdateRequest, selectCompanyUserUpdateStatus } from 'store/companyUsers'
import { mapCompanyUserResToReq } from 'models/ReqResMapper'
import EditUserForm from './EditUserForm'

const EditUserPage = () => {
  const { companyId, userId } = useParams<{ companyId: string; userId: string }>()
  const updateStatus = useAppSelector(selectCompanyUserUpdateStatus)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const user = useAppSelector(selectCompanyUserById(Number(userId)))
  const onSubmitForm: SubmitHandler<CustomerUpdateRequest> = async values => {
    await dispatch(companyUserUpdateRequest({ userId: Number(userId), requestBody: values }))
    history.push(`/companies/${companyId}`)
  }
  return (
    <>
      <PageHeader ghost={false} onBack={() => history.push(`/companies/${companyId}`)} title="Edit user" />
      <Card>
        {user && (
          <EditUserForm
            initialValues={mapCompanyUserResToReq(user)}
            onSubmit={onSubmitForm}
            isSubmitting={updateStatus === Status.PENDING}
          />
        )}
      </Card>
    </>
  )
}

export default EditUserPage

import { Redirect, Route } from 'react-router-dom'
import { FunctionComponent, ReactNode } from 'react'

export interface PrivateRoutePresentationProps {
  isAuthorized: boolean
  pathname?: string
}

export const PrivateRoutePresentation: FunctionComponent<PrivateRoutePresentationProps> = ({
  children,
  isAuthorized,
  pathname = '',
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }): ReactNode =>
        isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal, Button } from 'antd'
import { SubmitHandler } from 'react-hook-form'
import { WashingFilterFormFields } from 'Components/Washings/WashingFilter/WashingFilterFormFields'
import { WashingFilterModel } from 'models/forms/WashingFilterModel'
import useCDForm from 'hooks/useCDForm'

interface Props {
  onSubmit: SubmitHandler<WashingFilterModel>
  onCancel: () => void
  visible?: boolean
  initialData: WashingFilterModel
}

const WashingFilter: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, initialData }) => {
  const { handleSubmit, reset, renderInput } = useCDForm<WashingFilterModel>(WashingFilterFormFields)

  useEffect(() => {
    if (visible) {
      reset(initialData)
    }
  }, [reset, visible, initialData])

  const onCancelForm = () => {
    onCancel()
  }

  const statusOprions = [{ value: '', label: 'All' }, ...(WashingFilterFormFields.status.options || [])]

  return (
    <Modal
      visible={visible}
      title="Filter"
      okText="Apply"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onSubmit({ status: '' })
          }}
        >
          Reset
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit(onSubmit)}>
          Filter
        </Button>,
      ]}
    >
      <Form name="washing_filter" layout="vertical">
        <span>
          <p style={{ fontWeight: 500 }}>{WashingFilterFormFields.status.label}</p>
          {renderInput('status', statusOprions, false)}
        </span>
      </Form>
    </Modal>
  )
}

export default WashingFilter

import { useState, useEffect, VoidFunctionComponent } from 'react'
import { PageHeader, Table } from 'antd'
import HeaderExtra from '../Shared/PageHeader/HeaderExtra'
import Pagination from 'Components/UiKit/Pagination'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import {
  selectIsoCodeListData,
  selectIsoCodeListStatus,
  selectIsoCodeListPaginationData,
  isoCodeListRequestAsync,
} from 'store/isoCodes'
import { materialListRequestAsync } from 'store/material/actions'
import { containerTypeListRequestAsync } from 'store/containerType/actions'
import { selectMaterialListStatus } from 'store/material/selectors'
import { selectContainerTypeListStatus } from 'store/containerType/selectors'
import type { IsoCodeResource } from 'api/main/models/IsoCodeResource'
import { colors } from 'constants/colors'
import { Status } from 'models'
import searchedParamUtils from 'utils/searchedParam'

const itemsPerPage = 20
const renderAction = (_value: any, item: IsoCodeResource) => (
  <NavLink key="something" to={`iso-code/${item.id}`}>
    Edit
  </NavLink>
)

const ISOCodesPage: VoidFunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const isoCodesData = useAppSelector(selectIsoCodeListData)
  const listStatus = useAppSelector(selectIsoCodeListStatus)
  const materialListStatus = useAppSelector(selectMaterialListStatus)
  const containerTypeListStatus = useAppSelector(selectContainerTypeListStatus)
  const paginationData = useAppSelector(selectIsoCodeListPaginationData)
  const materialFetched = materialListStatus !== Status.IDLE
  const containerTypeFetched = containerTypeListStatus !== Status.IDLE
  useEffect(() => {
    dispatch(isoCodeListRequestAsync({ perPage: 20, page: currentPage, query: searchedParamUtils.getSearchedParam().searched || '' }))
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  }, [dispatch, currentPage])

  //fetch materials and container type just once
  useEffect(() => {
    if (!materialFetched) {
      dispatch(materialListRequestAsync({ perPage: 20, page: currentPage, query: '' }))
    }
    if (!containerTypeFetched) {
      dispatch(containerTypeListRequestAsync({ perPage: 20, page: currentPage, query: '' }))
    }
  }, [dispatch, materialFetched, currentPage, containerTypeFetched])

  const onSearch = (value: string) => {
    searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
    dispatch(isoCodeListRequestAsync({ perPage: 20, page: currentPage, query: value }))
  }
  const onPressCreate = () => {
    history.push(`/iso-code`)
  }
  return (
    <>
      <PageHeader
        ghost={false}
        title="ISO Codes"
        extra={
          <HeaderExtra
            defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : ''}
            searchPlaceholder="Search for an ISO Code"
            onSearch={onSearch}
            buttons={[{ title: 'Create ISO Code', onClick: onPressCreate }]}
          />
        }
      />
      <Table
        dataSource={isoCodesData}
        rowKey={rc => rc.id || 0}
        locale={{ emptyText: 'No result' }}
        className="mt-3"
        loading={listStatus === Status.PENDING}
        scroll={{ x: true }}
        pagination={false}
      >
        <Table.Column title="ISO code" dataIndex="code" />
        <Table.Column title="Container type" dataIndex={['container_type', 'name']} />
        <Table.Column title="Height" dataIndex="height" />
        <Table.Column title="Width" dataIndex="width" />
        <Table.Column title="Length" dataIndex="length" />
        <Table.Column width="30%" title="Description" dataIndex="description" />
        <Table.Column title="Action" key="action" render={renderAction} />
      </Table>
      <Pagination
        pageSize={itemsPerPage}
        total={paginationData?.total}
        current={currentPage}
        onChange={page => setCurrentPage(page)}
        style={{ backgroundColor: colors.GRAY }}
      />
    </>
  )
}

export default ISOCodesPage

import { ListState } from 'models'
import type { CotainerNumbersResponse, OutQueueResource } from 'api/main'
import type { NormalState } from 'models/ApiState'
import { RootState } from '../index'

type containersListState = ListState<CotainerNumbersResponse>

type OutQueueListState = ListState<OutQueueResource>
export const selectOutQueueListData = (state: RootState): OutQueueListState['data'] => state.outQueue.list.data
export const selectOutQueuePaginationData = (state: RootState): OutQueueListState['paginationData'] =>
  state.outQueue.list.paginationData
export const selectOutQueueListStatus = (state: RootState): OutQueueListState['status'] => state.outQueue.list.status

export const selectOutQueueDeleteStatus = (state: RootState): NormalState<void>['status'] =>
  state.outQueue.delete.status

  export const selectOutQueueGateOutStatus = (state: RootState): NormalState<void>['status'] =>
  state.outQueue.gateOut.status

export const selectContainersListData = (state: RootState): containersListState['data'] => state.outQueue.suggestedList.data
export const selectContainersListStatus = (state: RootState): containersListState['status'] => state.outQueue.suggestedList.status

export const tableSelectors = {
  data: selectOutQueueListData,
  status: selectOutQueueListStatus,
  pagination: selectOutQueuePaginationData,
}

import { Row, Col } from 'antd'
// import { invoiceFields } from '../AddInvoice/FormFields'
import { useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import styled from 'styled-components'

type Props<T> = {
  selector: (state: RootState) => T | undefined
  formFields: GeneralFormFieldType<T>
}

const RowExpanded = <T,>({ selector, formFields }: Props<T>) => {
  type TKeys = keyof T
  const selectedData: T | undefined = useAppSelector(selector)
  return (
    <Row gutter={[16, 16]}>
      {Object.entries(formFields || {}).map(([key, value]: [string, any]) => {
        const typedKey: TKeys = key as TKeys
        return (
          <Col key={key} span={4}>
            <Header>{value.label}</Header>
            <p>{selectedData && selectedData[typedKey]}</p>
          </Col>
        )
      })}
    </Row>
  )
}
export default RowExpanded

const Header = styled.p`
  font-weight: 500;
`
import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal } from 'antd'
import { SubmitHandler, useForm } from 'react-hook-form'
import Input from 'Components/UiKit/Inputs/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import createCompanyUserSchema from 'schemas/createCompanyUserSchema'
import { AdminInviteCustomerRequest } from 'api/main'

interface Props {
  onSubmit: SubmitHandler<AdminInviteCustomerRequest>
  onCancel: () => void
  loading?: boolean
  visible?: boolean
}

const UserFormModal: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, loading }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AdminInviteCustomerRequest>({ resolver: yupResolver(createCompanyUserSchema) })

  useEffect(() => {
    if (visible) {
      reset()
    }
  }, [reset, visible])

  const onCancelForm = () => {
    onCancel()
  }

  return (
    <Modal
      visible={visible}
      title="Create a user"
      okText="Create"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      confirmLoading={loading}
    >
      <Form name="internal_user" layout="vertical" noValidate>
        <Input
          label="Email"
          errorClassName="ant-form-item-has-error"
          control={control}
          name="email"
          placeholder="example@test.com"
          error={errors.email?.message}
        />
      </Form>
    </Modal>
  )
}

export default UserFormModal

import { LoginPage } from '../../Components/Auth/Login/LoginPage'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { VoidFunctionComponent } from 'react'
import { Col, Image, Row } from 'antd'
import ContainerImage from 'assets/images/container-depot.jpg'
import ForgotPasswordPage from 'Components/Auth/ForgotPassword/ForgotPasswordPage'
import ResetPasswordPage from 'Components/Auth/ResetPassword/ResetPasswordPage'
import RegisterPage from 'Components/Auth/Register/RegisterPage'
const authPageRoutes: RouteProps[] = [
  {
    exact: true,
    path: '/login',
    children: <LoginPage />,
  },
  {
    exact: true,
    path: '/forgot-password',
    children: <ForgotPasswordPage />,
  },
  {
    exact: true,
    path: '/reset-password',
    children: <ResetPasswordPage />,
  },
  {
    exact: true,
    path: '/register',
    children: <RegisterPage isNormal={true}/>,
  },
  {
    exact: true,
    path: '/company/register',
    children: <RegisterPage isNormal={false}/>,
  },
]

export const paths = [authPageRoutes.map(route => route.path)].flat().filter(path => path) as string[]

const AuthRoutes: VoidFunctionComponent = () => {
  return (
    <Row className="h-100">
      <Col xs={0} lg={12} className="h-inherit">
        <Image preview={false} src={ContainerImage} alt="Container Depot" width="100%" height="100vh" />
      </Col>
      <Col xs={24} lg={12} className="h-inherit">
        <Row className="h-inherit" justify="center" align="middle">
          <Col xs={20} sm={16} lg={12}>
            <Switch>
              {authPageRoutes.map((route, index) => (
                <Route key={index} {...route} />
              ))}
            </Switch>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default AuthRoutes

import { Layout } from 'antd'
import styled from 'styled-components'

const { Sider } = Layout

const Sidebar = styled(Sider)`
  min-height: calc(100vh - 48px);
`

export default Sidebar

/* eslint-disable sonarjs/cognitive-complexity */
import { useCallback, useEffect, useState, VoidFunctionComponent } from 'react'
import { Button, message, PageHeader, Space, Table } from 'antd'
import { useLocation } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import HeaderExtra from '../Shared/PageHeader/HeaderExtra'
import { CompanyRequest, CompanyResource } from 'api/main'
import { NavLink } from 'react-router-dom'
import CompanyFormModal from './CompanyFormModal'
import { colors } from 'constants/colors'
import { showConfirmModal } from 'store/app'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  createCompanyRequestAsync,
  companiesListRequestAsync,
  updateCompanyRequestAsync,
  deleteCompanyRequestAsync,
  selectCreateCompanyError,
  selectCreateCompanyStatus,
  selectUpdateCompanyError,
  selectUpdateCompanyStatus,
  selectDeleteCompanyStatus,
  tableSelectors,
} from 'store/companies'
import { Status } from 'models'
import { useFirstRender } from 'hooks'
import CDTable from 'Components/Shared/CDTable'
import searchedParamUtils from 'utils/searchedParam'

const itemsPerPage = 20

const CompaniesPage: VoidFunctionComponent = () => {
  const createStatus = useAppSelector(selectCreateCompanyStatus)
  const createError = useAppSelector(selectCreateCompanyError)
  const updateStatus = useAppSelector(selectUpdateCompanyStatus)
  const updateError = useAppSelector(selectUpdateCompanyError)
  const deleteStatus = useAppSelector(selectDeleteCompanyStatus)
  const dispatch = useAppDispatch()
  const isMounted = useFirstRender()
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState(1)
  const [modalVisible, setModalVisibility] = useState(false)
  const [query, setQuery] = useState('')
  const [selectedCompany, setSelectedCompany] = useState<CompanyResource>()

  useEffect(() => {
    dispatch(companiesListRequestAsync({ perPage: itemsPerPage, page: currentPage, query: searchedParamUtils.getSearchedParam().searched || query }))
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage])

  useEffect(() => {
    dispatch(companiesListRequestAsync({ perPage: itemsPerPage, page: 1, query }))
  }, [dispatch, query])

  useEffect(() => {
    if (isMounted) {
      if (createStatus === Status.SUCCEEDED) {
        setModalVisibility(false)
        void message.success('Company successfully created')
        dispatch(companiesListRequestAsync({ perPage: itemsPerPage, page: currentPage, query: '' }))
      } else if (createStatus === Status.FAILED) {
        setModalVisibility(false)
        void message.error(createError)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus, dispatch, createError])

  useEffect(() => {
    if (isMounted) {
      if (updateStatus === Status.SUCCEEDED) {
        setModalVisibility(false)
        setSelectedCompany(undefined)
        void message.success('Company successfully updated')
        dispatch(companiesListRequestAsync({ perPage: itemsPerPage, page: currentPage, query: '' }))
      } else if (updateStatus === Status.FAILED) {
        setModalVisibility(false)
        setSelectedCompany(undefined)
        void message.error(updateError)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateError, updateStatus])

  const onSearch = (value: string): void => {
    searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
    setQuery(value)
  }

  const onPressCreate = (): void => {
    setSelectedCompany(undefined)
    setModalVisibility(true)
  }

  const onSubmitCreateOrEdit = async (values: CompanyRequest, id?: number): Promise<void> => {
    if (id) {
      await dispatch(updateCompanyRequestAsync({ ...values, id }))
    } else {
      await dispatch(createCompanyRequestAsync(values))
    }
    setSelectedCompany(undefined)
  }

  const onPressEdit = (values: CompanyResource) => {
    setSelectedCompany(values)
    setModalVisibility(true)
  }

  const onCancelCreate = (): void => {
    setModalVisibility(false)
    setSelectedCompany(undefined)
  }

  const renderActions = useCallback(
    (_value: any, item: CompanyResource) => {
      return (
        <Space>
          <NavLink key="something" to={`companies/${item.id}`}>
            View
          </NavLink>
          <Button type="link" onClick={() => onPressEdit(item)}>
            Edit
          </Button>
          <DeleteOutlined
            style={{ color: colors.RED }}
            onClick={async () => {
              setSelectedCompany(item)
              dispatch(
                showConfirmModal({
                  message: `Are you sure you want to delete ${item?.name}?`,
                  onYes: async () => {
                    if (item) {
                      await dispatch(deleteCompanyRequestAsync(item.id))
                      await dispatch(companiesListRequestAsync({ perPage: itemsPerPage, page: currentPage, query: '' }))
                    }
                  },
                  confirmLoading: deleteStatus === Status.PENDING,
                })
              )
            }}
          />
        </Space>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteStatus, dispatch]
  )

  return (
    <>
      <PageHeader
        ghost={false}
        title="Companies"
        extra={
          <HeaderExtra
            defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : ''}
            searchPlaceholder="Search for a company"
            onSearch={onSearch}
            buttons={[{ title: 'Create company', onClick: onPressCreate }]}
          />
        }
      />
      <CDTable<CompanyResource> selectors={tableSelectors} onPaginate={(page: number)=>setCurrentPage(page)}>
        <Table.Column width="40%" title="Name" dataIndex="name" />
        <Table.Column width="40%" title="Customer number" dataIndex="customer_number" />
        <Table.Column title="Actions" key="action" render={renderActions} />
      </CDTable>
      <CompanyFormModal
        initialData={selectedCompany}
        loading={createStatus === Status.PENDING}
        onCancel={onCancelCreate}
        onSubmit={onSubmitCreateOrEdit}
        visible={modalVisible}
      />
    </>
  )
}

export default CompaniesPage

import { ListState } from 'models'
import type { WashingAreaResource } from 'api/main'
import { RootState } from '../index'

type washingListState = ListState<WashingAreaResource>
export const selectWashingListData = (state: RootState): washingListState['data'] => state.washings.list.data
export const selectWashingPaginationData = (state: RootState): washingListState['paginationData'] =>
  state.washings.list.paginationData

export const selectWashingListStatus = (state: RootState): washingListState['status'] => state.washings.list.status

export const tableSelectors = {
  data: selectWashingListData,
  status: selectWashingListStatus,
  pagination: selectWashingPaginationData,
}

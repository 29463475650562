import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { WashingFilterModel } from 'models/forms/WashingFilterModel'
import { WashingStatus } from 'constants/ContainerCycleConsts'

export const WashingFilterFormFields: GeneralFormFieldType<WashingFilterModel> = {
  status: {
    label: 'Status',
    placeholder: 'Status',
    type: InputType.RADIO,
    options: WashingStatus,
  },
}

import { ListState } from 'models'
import type { ContainerResource, CotainerNumbersResponse } from 'api/main'
import { RootState } from '../index'

type allContainersListState = ListState<ContainerResource>
type containersListState = ListState<CotainerNumbersResponse>

export const selectAllContainersListData = (state: RootState): allContainersListState['data'] => state.allContainers.list.data
export const selectAllContainersPaginationData = (state: RootState): allContainersListState['paginationData'] =>
  state.allContainers.list.paginationData
export const selectAllContainersListStatus = (state: RootState): allContainersListState['status'] => state.allContainers.list.status

export const selectContainersListData = (state: RootState): containersListState['data'] => state.allContainers.suggestedList.data
export const selectContainersListStatus = (state: RootState): containersListState['status'] => state.allContainers.suggestedList.status

export const tableSelectors = {
  data: selectAllContainersListData,
  pagination: selectAllContainersPaginationData,
  status: selectAllContainersListStatus,
}

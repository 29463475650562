import { useEffect, useState, VoidFunctionComponent } from 'react'
import { PageHeader, Table } from 'antd'
import { useLocation } from 'react-router-dom'
import Pagination from 'Components/UiKit/Pagination'
import { AdminUserResource, AdminInviteUserRequest } from 'api/main'
import { NavLink } from 'react-router-dom'
import { MultiValueRenderer } from '../UiKit/DataDisplay'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  selectUsersListData,
  selectUsersListPaginationData,
  selectUsersListStatus,
  usersListRequestAsync,
  usersInviteRequestAsync,
  selectUserInviteStatus,
} from 'store/users'
import { Status } from 'models'
import HeaderExtra from '../Shared/PageHeader/HeaderExtra'
import UserFormModal from './UserFormModal'
import { selectRolesListMappedData, roleListsRequestAsync, selectRolesListStatus } from 'store/roles'
import useFetchDepotsOnce from 'hooks/useFetchDepotsOnce'
import { colors } from 'constants/colors'
import searchedParamUtils from 'utils/searchedParam'

const renderActions = (_value: any, item: AdminUserResource) => {
  return (
    <NavLink key="somethin" to={`users/${item.id}`}>
      Edit
    </NavLink>
  )
}

const itemsPerPage = 20

export const UsersPage: VoidFunctionComponent = () => {
  const status = useAppSelector(selectUsersListStatus)
  const usersData = useAppSelector(selectUsersListData)
  const paginationData = useAppSelector(selectUsersListPaginationData)
  const [,depots] = useFetchDepotsOnce()
  const roles = useAppSelector(selectRolesListMappedData)
  const rolesStatus = useAppSelector(selectRolesListStatus)
  const inviteStatus = useAppSelector(selectUserInviteStatus)
  const dispatch = useAppDispatch()
  const location = useLocation()

  const rolesFetched = rolesStatus !== Status.IDLE
  const [currentPage, setCurrentPage] = useState(1)
  const [inviteModalVisible, setInviteModalVisibility] = useState(false)
  const [query, setQuery] = useState('')

  useEffect(() => {
    dispatch(usersListRequestAsync({ perPage: itemsPerPage, page: currentPage, query: searchedParamUtils.getSearchedParam().searched || query }))
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  }, [dispatch, currentPage, query])

  useEffect(() => {
    if (!rolesFetched) dispatch(roleListsRequestAsync())
  }, [dispatch,rolesFetched])

  const onSearch = (value: string): void => {
    searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
    setQuery(value)
  }

  const onPressInvite = (): void => {
    setInviteModalVisibility(true)
  }

  const onSubmitInvite = async (_values: AdminInviteUserRequest) => {
    await dispatch(usersInviteRequestAsync(_values))
    setInviteModalVisibility(false)
  }

  const onCancelInvite = (): void => {
    setInviteModalVisibility(false)
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title="Users"
        extra={
          <HeaderExtra
            defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : ''}
            searchPlaceholder="Search for a user"
            onSearch={onSearch}
            buttons={[{title: "Invite user", onClick:onPressInvite}]}
          />
        }
      />
      <Table
        dataSource={usersData}
        rowKey={rc => rc.id}
        locale={{ emptyText: 'No result' }}
        pagination={false}
        className="mt-3"
        loading={status === Status.PENDING}
      >
        <Table.Column width="16%" title="First name" dataIndex="first_name" />
        <Table.Column width="16%" title="Last name" dataIndex="last_name" />
        <Table.Column width="16%" title="Username" dataIndex="email" />
        <Table.Column
          width="16%"
          title="Depot name"
          dataIndex="depots"
          render={data => <MultiValueRenderer data={data} dataKey="name" />}
        />
        <Table.Column width="14%" title="User Role" dataIndex={['role','name']} />
        <Table.Column title="Actions" key="action" render={renderActions} />
      </Table>
      <Pagination
        pageSize={itemsPerPage}
        total={paginationData?.total}
        current={currentPage}
        onChange={page => setCurrentPage(page)}
        style={{ backgroundColor: colors.GRAY }}
      />
      <UserFormModal
        roleLoading={rolesStatus === Status.PENDING}
        loading={inviteStatus === Status.PENDING}
        roles={roles}
        depots={depots}
        onSubmit={onSubmitInvite}
        onCancel={onCancelInvite}
        visible={inviteModalVisible}
      />
    </>
  )
}

export default UsersPage

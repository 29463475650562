import { useState, useEffect } from 'react'
import { Button } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { colors } from 'constants/colors'

interface Props<T> {
  onClick: () => void
  initSearchVals: T
  newSearchVals: T
}

function compareWithInitial<T>(initialFilter: T, newfilters: T) {
  let isEqual = true
  Object.keys(initialFilter).forEach((key: string) => {
    const typedKey = key as keyof T
    if (newfilters[typedKey] !== initialFilter[typedKey]) {
      isEqual = false
    }
  })
  return isEqual
}

function FilterButton<T>({ onClick, initSearchVals, newSearchVals }: Props<T>) {
  const [dotVisible, setDotVisible] = useState<boolean>(false)
  useEffect(() => {
    setDotVisible(!compareWithInitial(initSearchVals, newSearchVals))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSearchVals])
  return (
    <ButtonWrapper>
      <Button icon={<FilterOutlined />} onClick={onClick}>
        Filter
      </Button>
      {dotVisible && <Dot />}
    </ButtonWrapper>
  )
}

export default FilterButton

const ButtonWrapper = styled.div`
  position: relative;
`

const Dot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: ${colors.PRIMARY};
  border-radius: 50%;
  top: -3px;
  right: -3px;
`

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { authReducers } from './auth'
import { usersReducers } from './users'
import { companyReducers } from './companies'
import { depotsReducers } from './depots/reducer'
import { invoicesReducers } from './invoices'
import { isoCodeReducers } from './isoCodes/reducer'
import { materialReducers } from './material/reducer'
import { ContainerTypeReducers } from './containerType/reducer'
import { companyUsersReducer } from './companyUsers/reducer'
import { rolesReducers } from './roles/reducer'
import { advInfoReducers } from './advInfo'
import { arrivalsReducers } from './arrivals'
import { checkingReducers } from './checkings'
import { checkedReducers } from './checkeds'
import { damagedReducers } from './damageds'
import {repairingsReducers} from './repairings'
import { availablesReducers } from './availables'
import { allContainersReducers } from './allContainers'
import { washingsReducers } from './washings'
import { outReferenceReducers } from './outReference'
import { outQueueReducers } from './outQueue'
import { orderingReducers } from './ordering'
import { accessReducers } from './access/reducer'
import appReducer from './app/reducers'
import { errorToastMessage } from './MiddleWares'

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducers,
    users: usersReducers,
    companies: companyReducers,
    depots: depotsReducers,
    invoices: invoicesReducers,
    isoCodes: isoCodeReducers,
    material: materialReducers,
    ContainerType: ContainerTypeReducers,
    roles: rolesReducers,
    companyUsers: companyUsersReducer,
    advancedInfo: advInfoReducers,
    arrivals: arrivalsReducers,
    checkings: checkingReducers,
    checkeds: checkedReducers,
    damageds: damagedReducers,
    repairings: repairingsReducers,
    availables: availablesReducers,
    allContainers: allContainersReducers,
    washings: washingsReducers,
    outReference: outReferenceReducers,
    outQueue: outQueueReducers,
    ordering: orderingReducers,
    access: accessReducers,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these paths in the state
        ignoredPaths: ['app.confirmModal.onYes', `app.confirmModal.onNo`],
      },
    }).concat(errorToastMessage),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

import { render, RenderOptions } from '@testing-library/react'
import { MemoryRouter } from 'react-router'
import { FunctionComponent, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { store } from '../store'

function renderWithRouter(ui: ReactElement, options: RenderOptions = {}) {
  return render(ui, { ...options, wrapper: MemoryRouter })
}

const AllProviders: FunctionComponent = ({ children }) => {
  return (
    <Provider store={store}>
      <MemoryRouter>{children}</MemoryRouter>
    </Provider>
  )
}

const testingUtils = {
  renderWithRouter,
  renderWithAllProviders: (ui: ReactElement, options?: Omit<RenderOptions, 'wrapper'>) =>
    render(ui, { wrapper: AllProviders, ...options }),
}

export default testingUtils

import { NormalState, ListState } from 'models'
import { CompanyDepotFeesResource, CompanyDepotRequest } from 'api/main'
import { RootState } from '../index'

type InvoiceListState = ListState<CompanyDepotFeesResource>

export const selectCreateInvoiceStatus = (state: RootState): NormalState<CompanyDepotFeesResource>['status'] =>
  state.invoices.create.status

export const selectUpdateInvoiceStatus = (state: RootState): NormalState<CompanyDepotFeesResource>['status'] =>
  state.invoices.update.status

export const selectInvoicesListData = (state: RootState): InvoiceListState['data'] => state?.invoices.list.data

export const selectInvoicesListStatus = (state: RootState): InvoiceListState['status'] => state.invoices.list.status
export const selectInvoice =
  (id: number): ((state: RootState) => CompanyDepotFeesResource | undefined) =>
  (state: RootState): CompanyDepotFeesResource | undefined =>
    state.invoices.list.data?.find(invoice => invoice?.id === id)
export const selectInvoiceByName =
  (name: string): ((state: RootState) => CompanyDepotRequest | undefined) =>
  (state: RootState): CompanyDepotRequest | undefined =>
    state.invoices.list.data?.find(invoice => invoice?.name === name)?.fees

import { VoidFunctionComponent, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, Form, Row, Col } from 'antd'
import Input from '../../UiKit/Inputs/Input'
import { userFields } from './userFormFields'
import { MappedOption } from 'models'
// import { Text } from '../../UiKit/Typography'
import { yupResolver } from '@hookform/resolvers/yup'
import editUserSchema from 'schemas/editUserSchema'
import { UserUpdateRequest } from 'api/main'
import { UserUpdateRequestWithEmail } from 'models'

type UserUpdateRequestKeys = keyof UserUpdateRequest

interface Props {
  initialValues: UserUpdateRequestWithEmail
  depots: MappedOption[]
  roles: MappedOption[]
  onSubmit: SubmitHandler<UserUpdateRequest>
  isSubmitting?: boolean
}
const UserForm: VoidFunctionComponent<Props> = ({ onSubmit, isSubmitting, initialValues, depots, roles }) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserUpdateRequest>({ resolver: yupResolver(editUserSchema) })

  useEffect(() => {
    reset(initialValues)
  }, [reset, initialValues])

  const options: any = {
    role_id: roles,
    depot_ids: depots,
  }
  return (
    <Form layout="vertical" name="updateUser" onFinish={handleSubmit(onSubmit)} noValidate>
      <Row gutter={16}>
        {Object.entries(userFields).map(entry => {
          const value = entry[1]
          const key: UserUpdateRequestKeys = entry[0] as UserUpdateRequestKeys
          if (!value.hideInForm) {
            return (
              <Col key={key} span={8}>
                <Input
                  label={value.label}
                  errorClassName="ant-form-item-has-error"
                  control={control}
                  name={key}
                  options={options[key]}
                  placeholder={value.placeholder}
                  error={(errors[key] as any)?.message}
                  type={value.type}
                  disabled={value.disabled}
                />
              </Col>
            )
          } else return null
        })}
      </Row>

      <Form.Item className="text-right">
        <Button htmlType="submit" type="primary" loading={isSubmitting}>
          Update
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UserForm

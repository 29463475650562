import { VoidFunctionComponent, useEffect, useState } from 'react'
import { Form, Button, Row, Col, Select } from 'antd'
import JsFileDownloader from 'js-file-downloader'
import useFetchDepotsOnce from 'hooks/useFetchDepotsOnce'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectDepotsListStatus } from 'store/depots'
import { companiesListRequestAsync, selectCompaniesListMappedData, selectCompaniesListStatus } from 'store/companies'
import { Status } from 'models'
import { tokenUtils } from 'utils'
import moment from 'moment'
import ResultModal from 'Components/Reports/ResultModal'
import { OpenAPI } from 'api/main'

interface Props {
    isSummary?: boolean
}

interface ValueTypes {
    depotId: number
    companyId: number
}

const StockReport: VoidFunctionComponent<Props> = ({ isSummary }) => {
    const dispatch = useAppDispatch()
    const [depotData] = useFetchDepotsOnce()
    const depotStatus = useAppSelector(selectDepotsListStatus)
    const companiesData = useAppSelector(selectCompaniesListMappedData)
    const companyStatus = useAppSelector(selectCompaniesListStatus)

    const [requestStatus, setRequestStatus] = useState<Status>(Status.IDLE)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [values, setValues] = useState<ValueTypes>({ companyId: 1, depotId: 1 })

    useEffect(() => {
        // get companies if not get before
        if (companyStatus !== Status.SUCCEEDED && companyStatus !== Status.PENDING) {
          dispatch(companiesListRequestAsync({ perPage: 100, page: 1, query: '' }))
        }
      }, [dispatch, companyStatus])

      const currentDate = (): string => moment().format('MM-DD-YYYY')

      const handleChange = (value: number, key:string): void => {
        let newValues: any = { ...values }
        newValues[key] = value
        setValues(newValues)
      }

      const download = new JsFileDownloader({
        url: `${OpenAPI.BASE}/api/admin/report/${isSummary ? 'stock-summary' : 'stock'}/company/${values?.companyId}?depot_id=${values?.depotId}`,
        autoStart: false,
        headers: [
            { name: 'authorization', value: `Bearer ${tokenUtils.getToken()}` || '' }
          ],
        filename: `${isSummary ? 'stockSummaryReport' : 'stockReport'}-${currentDate()}.xlsx`,
      })

    const onFinish = async (): Promise<void> => {
        setRequestStatus(Status.PENDING)
        setIsModalVisible(true)
        download.start()
            .then(function(){
                setRequestStatus(Status.SUCCEEDED)
            })
            .catch(function(error){
                setRequestStatus(Status.FAILED)
            })
    }

  return (
    <>
        <Form
            name={isSummary ? "stockSummary" : "stock"}
            onFinish={onFinish}
            layout="vertical"
        >
            <Row gutter={[24, 8]}>
                <Col span={12}>
                    <Form.Item
                        label="Depot"
                        name="depotId"
                        rules={[{ required: true, message: 'Please selet a depot!' }]}
                    >
                        <Select
                            placeholder="Select a Depot"
                            loading={depotStatus === Status.PENDING}
                            onChange={(value: number) => handleChange(value, 'depotId')}
                        >
                            {depotData?.map((item: any) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Company"
                        name="companyId"
                        rules={[{ required: true, message: 'Please select a company!' }]}
                    >
                        <Select
                            placeholder="Select a Company"
                            options={companiesData}
                            loading={companyStatus === Status.PENDING}
                            onChange={(value: number) => handleChange(value, 'companyId')}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify='end'>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Generate Report
                    </Button>
                </Form.Item>
            </Row>
        </Form>
        <ResultModal
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            requestStatus={requestStatus}
            title={ isSummary ? 'Stock Summary Report' : 'Stock Report'}
            tryAgain={onFinish}
        />
    </>
  )
}

export default StockReport

import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { AdvanceInfoUpdateRequest } from 'api/main'

export const AdvInfoFormFields: GeneralFormFieldType<AdvanceInfoUpdateRequest> = {
  container_csc_date: {
    label: 'CSC Date',
    placeholder: 'ACEP',
    // type: InputType.DATE,
  },
  container_max_gross_weight: {
    label: 'Max Gross Weight',
    placeholder: 'Max Gross Weight',
  },
  container_tare_weight: {
    label: 'Tare Weight',
    placeholder: 'Tare Weight',
  },
  container_material: {
    label: 'Material',
    placeholder: 'Material',
    type: InputType.RADIO,
  },
  container_manufacturing_date: {
    label: 'Manufacturing Date',
    placeholder: 'Manufacturing Date',
    type: InputType.DATE,
  },
  has_lease_info:{
    label: 'On Lease',
    placeholder: 'On Lease',
    type: InputType.SWITCH
  },
  lease_info_lessee: {
    label: 'Lessee',
    placeholder: 'Lessee',
  },
  lease_info_on_hire_date: {
    label: 'On Hire Date',
    placeholder: 'On Hire Date',
    type: InputType.DATE,
  },
  lease_info_dpp_currency: {
    label: 'Expiration Date',
    placeholder: 'Select Currency',
    type: InputType.SELECT,

  },
  lease_info_dpp_amount: {
    label: 'Insurance Amount/DPP',
    placeholder: '',
  },
  note: {
    label: 'Note',
    placeholder: 'Example text',
    type: InputType.TEXTAREA,
  },
  expiration_date: {
    label: 'Expiration Date',
    placeholder: 'Select Date',
    type: InputType.DATE,
  },
  lift_in_paid_by: {
    label: 'Lift In Paid By',
    placeholder: 'No Placeholder',
    type: InputType.SELECT,
  },
  lift_in_invoice_type: {
    label: 'No Label',
    placeholder: 'Lift In Invoice Type',
    type: InputType.SELECT,
  },
  lift_out_paid_by: {
    label: 'Lift Out Paid By',
    placeholder: 'No Placeholder',
    type: InputType.SELECT,
  },
  lift_out_invoice_type: {
    label: 'No Label',
    placeholder: 'No Placeholder',
    type: InputType.SELECT,
  },
  rent_paid_by: {
    label: 'Rent Paid By',
    placeholder: 'No Placeholder',
    type: InputType.SELECT,
  },
  rent_invoice_type: {
    label: 'No Label',
    placeholder: 'No Placeholder',
    type: InputType.SELECT,
  },
}

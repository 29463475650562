import { VoidFunctionComponent } from 'react'
import { Menu } from 'antd'

interface Props {
  onLogout: () => void
}

const AccountMenu: VoidFunctionComponent<Props> = ({ onLogout }) => {
  return (
    <Menu>
      <Menu.Item key="logout" onClick={onLogout}>
        Log out
      </Menu.Item>
    </Menu>
  )
}

export default AccountMenu

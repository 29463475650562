import { VoidFunctionComponent, useState, useEffect } from 'react'
import { Form, Modal, Input as AntInput, Button, Row, Select, Col } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { ReleaseOutReferenceContainerInfoBodyProperty, ReleaseOutReferenceRequestBody } from 'api/main'
import { useAppSelector } from 'store/hooks'
import { selectMoveToReleaseStatus } from 'store/outReference'
import { selectContainerTypeListMappedData } from 'store/containerType'
import { MappedOption, Status } from 'models'
import { CountainerSize } from 'constants/ContainerCycleConsts'

const { TextArea } = AntInput

interface Props {
  onSubmit: (values: ReleaseOutReferenceRequestBody) => void
  onCancel: () => void
  loading?: boolean
  visible?: boolean
  containerInfo?: Array<ReleaseOutReferenceContainerInfoBodyProperty>
}

const MoveToReleaseModal: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, loading, containerInfo }) => {
  const containerTypes = useAppSelector(selectContainerTypeListMappedData)
  const releaseStatus = useAppSelector(selectMoveToReleaseStatus)
  const [form] = Form.useForm()

  const [filteredContainerSize, setFilteredContainerSize] = useState<MappedOption[]>([])
  const [filteredContainerType, setFilteredContainerType] = useState<MappedOption[]>([])

  const findSpecificItems = (allItems: any[], key: string) : string[] => {
    return _.uniq(allItems.map((item: any) => item[key]))
  }

  const createValidArray = (validItems: string[], allItems: MappedOption[]): MappedOption[] => {
    return validItems.map((item: any) => {
      const res = allItems.find(i => item?.toString() === i.label)

      return {
        label: res?.label + '',
        value: res?.value + ''
      }
    })
  }

  useEffect(() => {
    if(containerInfo && containerInfo?.length > 0) {
      const validSizes = findSpecificItems(containerInfo, 'size')
      const newContainerSize = createValidArray(validSizes, CountainerSize)
      const validTypes = findSpecificItems(containerInfo, 'type')
      const newContainerType = createValidArray(validTypes, containerTypes)

      setFilteredContainerSize(newContainerSize)
      setFilteredContainerType(newContainerType)
    }
  }, [containerInfo, containerTypes])

  const onFinish = (values: ReleaseOutReferenceRequestBody) => {
    onSubmit(values)
  }

  const onCancelForm = () => {
    onCancel()
    form.resetFields()
  }

  return (
    <Modal
      visible={visible}
      title="Release Container"
      okText="Release Container"
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onFinish(values)
          })
          .catch(info => {
            // TODO: handle errors if we want to show it in current modal.
          })
        }}
      onCancel={onCancelForm}
      confirmLoading={loading}
      okButtonProps={{ loading: releaseStatus === Status.PENDING }}
    >
      <Form layout="vertical" form={form} name="move_to_releae" onFinish={onFinish} autoComplete="off">
        <Form.Item
          name="license_plate_number"
          label="License Plate Number"
          initialValue={null}
        >
          <AntInput placeholder='License Plate Number' />
        </Form.Item>
        <Form.Item
          name="transportation_company"
          label="Transportation Company"
          rules={[{ required: true, message: 'Transportation Company is required!' }]}
        >
          <AntInput placeholder='Transportation Company' />
        </Form.Item>
        <Form.List
          name="container_infos"
          rules={[
            {
              validator: async (_, container_infos) => {
                if (!container_infos || container_infos.length < 1) {
                  return Promise.reject(new Error('At least 1 container information is required!'))
                }
              },
            },
          ]}
          initialValue={[{ size: undefined, type_id: undefined, count: undefined }]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} gutter={[16, 16]}>
                  <Col span={7}>
                    <Form.Item
                      {...field}
                      label="Size"
                      name={[field.name, 'size']}
                      rules={[{ required: true, message: 'Size is required!' }]}
                    >
                      <Select
                        placeholder="Size"
                        options={filteredContainerSize}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...field}
                      label="Type"
                      name={[field.name, 'type_id']}
                      rules={[{ required: true, message: 'Type is required!' }]}
                    >
                      <Select
                        placeholder="Type"
                        options={filteredContainerType}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...field}
                      label="Count"
                      name={[field.name, 'count']}
                      rules={[{ required: true, message: 'Count is required!' }]}
                    >
                      <AntInput placeholder='Count' />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <MinusCircleOutlined hidden={!index} className='dynamic-delete-button' onClick={() => remove(field.name)} />
                  </Col>
                </Row>
              ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Size, Type, Count
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item
          name="note"
          label="Note"
          initialValue={null}
        >
          <TextArea placeholder='Put your notes' rows={4} />
      </Form.Item>
    </Form>
    </Modal>
  )
}

export default MoveToReleaseModal

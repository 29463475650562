import moment from 'moment'

interface Type {
  date?: string
}

function changeDateformat({ date }: Type): string {
  return date ? moment(date).format('MM/DD/YYYY') : ''
}

const dateFormat = {
  changeDateformat,
}

export default dateFormat

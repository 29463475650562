/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContainerCollection } from '../models/ContainerCollection';
import type { ContainerNumberValidationForGateOutRequestBody } from '../models/ContainerNumberValidationForGateOutRequestBody';
import type { CotainerNumbersResponse } from '../models/CotainerNumbersResponse';
import { request as __request } from '../core/request';

export class AdminContainerService {

    /**
     * Validate container number for gate out
     * Check if the container is ready for gate_out
     * @param requestBody Gate out container number validation request body
     * @returns void
     * @throws ApiError
     */
    public static async adminContainerGateOutValidation(
        requestBody: ContainerNumberValidationForGateOutRequestBody,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/container/gate_out/validation`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Get Container list
     * This end point will return all containers.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over containers.
     * @returns ContainerCollection Container collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdContainerList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<ContainerCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/container`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Auto compelte container number.
     * This end point will auto compelte container number.
     * @param query Search query over available container numbers.
     * @returns CotainerNumbersResponse List of container numbers
     * @throws ApiError
     */
    public static async adminContainerNumbers(
        query: string,
    ): Promise<CotainerNumbersResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/container/numbers`,
            query: {
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
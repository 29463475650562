import { FunctionComponent, useState, VoidFunctionComponent } from 'react'
import { Form, Input as AntInput, Select, Radio, DatePicker, Switch, Checkbox } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Control, Controller } from 'react-hook-form'
import FormErrorMessage from '../Feedbacks/FormErrorMessage'
import { InputType, MappedOption } from 'models'
import moment from 'moment'

const { RangePicker } = DatePicker

interface Props {
  control: Control<any>
  name: string
  placeholder: string
  error?: string | false
  Icon?: FunctionComponent
  type?: InputType
  containerClassName?: string
  errorClassName?: string
  label?: string
  options?: MappedOption[]
  changeHandler?: () => void
  loading?: boolean
  disabled?: boolean
  required?: boolean
}

const Input: VoidFunctionComponent<Props> = ({
  type = InputType.TEXT,
  control,
  name,
  placeholder,
  error,
  Icon,
  containerClassName = '',
  errorClassName = '',
  label,
  options = [],
  changeHandler = () => {},
  loading = false,
  disabled = false,
  required = false,
}) => {
  const [isFocused, setFocus] = useState(false)
  return (
    <Form.Item
      label={type === InputType.CHECKBOX ? ' ' : label}
      className={error ? errorClassName : containerClassName}
      required={required}
    >
      <Controller
        // eslint-disable-next-line sonarjs/cognitive-complexity
        render={({ field }) => {
          const { onChange } = field
          switch (type) {
            case InputType.MULTISELECT:
              return (
                <Select
                  data-testid={name}
                  mode="multiple"
                  allowClear
                  className="w-100"
                  placeholder={placeholder}
                  {...field}
                  onChange={onChange}
                  options={options}
                  optionFilterProp="label"
                  loading={loading}
                  disabled={disabled}
                />
              )
            case InputType.SELECT:
              return (
                <Select
                  showSearch
                  allowClear
                  data-testid={name}
                  className="w-100"
                  placeholder={placeholder}
                  {...field}
                  onChange={onChange}
                  options={options}
                  optionFilterProp="label"
                  loading={loading}
                  disabled={disabled}
                />
              )
            case InputType.RADIO:
              return (
                <Radio.Group
                  // value={value}
                  disabled={disabled}
                  {...field}
                  onChange={onChange}
                  data-testid={name}
                  className="w-100"
                >
                  {options.map(opt => {
                    return (
                      <Radio className="mb-2" key={opt.value} value={opt.value}>
                        {opt.label}
                      </Radio>
                    )
                  })}
                </Radio.Group>
              )
              case InputType.DATE: {
                return (
                  <DatePicker
                    disabled={disabled}
                    {...field}
                    value={field?.value && moment(field.value)}
                    onChange={v => {
                      onChange(v?.format('YYYY-MM-DD'))
                    }}
                    data-testid={name}
                    className="w-100"
                  />
              )
            }
            case InputType.RANGE_DATE: {
              return (
                <RangePicker
                  disabled={disabled}
                  value={field?.value?.length > 0 ? [moment(field.value[0], 'YYYY-MM-DD'), moment(field.value[1], 'YYYY-MM-DD')] : null}
                  onChange={(dates) => {
                    const data = dates ? [dates[0]?.format('YYYY-MM-DD'), dates[1]?.format('YYYY-MM-DD')] : dates
                    onChange(data)
                  }}
                  data-testid={name}
                  className="w-100"
                />
              )
            }
            case InputType.SWITCH: {
              return (
                <Switch
                  disabled={disabled}
                  {...field}
                  checked={field.value}
                  onChange={e => {
                    onChange(e)
                    changeHandler && changeHandler()
                  }}
                  data-testid={name}
                  className="w-100"
                />
              )
            }
            case InputType.CHECKBOX: {
              return (
                <Checkbox
                  disabled={disabled}
                  {...field}
                  checked={field.value}
                  onChange={e => {
                    onChange(e)
                    changeHandler && changeHandler()
                  }}
                  data-testid={name}
                  className="w-100"
                >
                  {label}
                </Checkbox>
              )
            }
            case InputType.TEXTAREA:
              return <AntInput.TextArea data-testid={name} className="w-100" placeholder={placeholder} {...field} />
            case InputType.PASSWORD:
              return (
                <AntInput.Password
                  data-testid={name}
                  className="w-100"
                  placeholder={placeholder}
                  {...field}
                  disabled={disabled}
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              )
            default:
              return (
                <AntInput
                  onFocus={() => setFocus(true)}
                  onBlurCapture={() => setFocus(false)}
                  type={type}
                  prefix={!!Icon && !isFocused ? <Icon /> : <span />}
                  placeholder={placeholder}
                  {...field}
                  onChange={e => {
                    changeHandler && changeHandler()
                    onChange(e)
                  }}
                  disabled={disabled}
                />
              )
          }
        }}
        name={name}
        control={control}
      />
      {error && <FormErrorMessage message={error} />}
    </Form.Item>
  )
}

export default Input

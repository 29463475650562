import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal, Row, Col, Spin } from 'antd'
import { NavLink } from 'react-router-dom'
import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { SubmitHandler, useWatch } from 'react-hook-form'
import { CreateDamageEstimateRequest, UpdateDamageEstimateRequest } from 'api/main'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { selectCompaniesListMappedData } from 'store/companies'
import { invoicesListRequestAsync, selectInvoicesListStatus, selectInvoiceByName } from 'store/invoices'
import {
  createEstimationRequest,
  selectCreateEstimateStatus,
  selectupdateEstimationStatus,
  RepairDetailsListRequest,
  updateEstimationRequest,
} from 'store/damageds'
import { EditEstimationFormFields } from 'Components/Damageds/RepairDetails/EditEstimation/EditEstimationFormFields'
import editEstimationSchema from 'schemas/addEstimationSchema'
import useCDForm from 'hooks/useCDForm'
import { Status, MappedOption } from 'models'

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />

interface Props {
  isUpdate?: boolean
  onCancel: () => void
  onSubmit?: () => void
  visible?: boolean
  depot: MappedOption
  advInfoId: number
  estimateId?: number
  initialData: UpdateDamageEstimateRequest
}

const EditEstimationModal: VoidFunctionComponent<Props> = ({
  isUpdate = false,
  visible,
  onCancel,
  onSubmit,
  advInfoId,
  estimateId,
  depot,
  initialData,
}) => {
  const { handleSubmit, reset, renderInput, control, setValue, getValues } = useCDForm<CreateDamageEstimateRequest>(
    EditEstimationFormFields,
    editEstimationSchema
  )
  const companiesData = useAppSelector(selectCompaniesListMappedData)
  const invoiceStatus = useAppSelector(selectInvoicesListStatus)
  const invoice = useAppSelector(selectInvoiceByName(depot.label))
  const createStatus = useAppSelector(selectCreateEstimateStatus)
  const updateStatus = useAppSelector(selectupdateEstimationStatus)
  const dispatch = useAppDispatch()

  const submitHandler: SubmitHandler<CreateDamageEstimateRequest> = async (values: CreateDamageEstimateRequest) => {
    let result
    if (isUpdate) {
      if (estimateId) {
        result = await dispatch(updateEstimationRequest({ damageEstimate: estimateId, requestBody: values }))
      }
    } else {
      result = await dispatch(createEstimationRequest({ advanceInfoId: advInfoId, requestBody: values }))
    }
    if (!(result as any).error) {
      dispatch(RepairDetailsListRequest(Number(advInfoId)))
      if (onSubmit) {
        onSubmit()
      } else {
        onCancel()
      }
    }
  }

  const onCancelForm = () => {
    onCancel()
  }
  const companyId = useWatch({
    control,
    name: 'company_id',
  })

  const payByThirdParty = useWatch({
    control,
    name: 'paid_by_third_party',
  })

  const labourRate = useWatch({
    control,
    name: 'labor_rate',
  })

  // set Initial data
  useEffect(() => {
    if (visible) {
      reset(initialData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, visible])

  // get invoices when ever company change
  useEffect(() => {
    if (companyId) {
      dispatch(invoicesListRequestAsync(companyId))
    }
  }, [dispatch, companyId])

  // set company to default when the user turn switch off
  useEffect(() => {
    if (!payByThirdParty && initialData.company_id) {
      setValue('company_id', initialData.company_id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payByThirdParty, setValue])

  // set labour rate when ever company invoice change
  useEffect(() => {
    if (invoice?.lift_out_fee) {
      setValue('labor_rate', invoice?.repair_fee_per_hour)
    } else {
      reset({ ...getValues(), labor_rate: undefined })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, reset, setValue])

  return (
    <Modal
      visible={visible}
      title="Add Estimation"
      okText="Save"
      onOk={handleSubmit(submitHandler)}
      onCancel={onCancelForm}
      confirmLoading={createStatus === Status.PENDING || updateStatus === Status.PENDING}
      width={860}
    >
      <Form name="edit_repair" layout="vertical">
        <Row gutter={[16, 16]}>
          {Object.keys(EditEstimationFormFields).map(fieldName => {
            const key = fieldName as keyof CreateDamageEstimateRequest
            switch (fieldName) {
              case 'company_id':
                return (
                  <Col key={key} span={8}>
                    {renderInput(key, companiesData, true, !payByThirdParty)}
                  </Col>
                )
              case 'paid_by_third_party':
                return (
                  <Col key={key} span={8}>
                    <p>{EditEstimationFormFields.paid_by_third_party?.label}</p>
                    <Col key={key} span={6}>
                      {renderInput(key, undefined, false)}
                    </Col>
                  </Col>
                )
              case 'labor_rate':
                return (
                  <Col key={key} span={8}>
                    <p style={{ padding: '0 0 8px', margin: '0' }}>{EditEstimationFormFields.labor_rate?.label}</p>
                    <Spin spinning={invoiceStatus === Status.PENDING} indicator={antIcon}>
                      {renderInput(key, undefined, false, true)}
                    </Spin>
                    {labourRate === undefined && (
                      <NavLink key="something" to={`/companies/${companyId}/invoices/${depot.value}`}>
                        Add Invoice <ArrowRightOutlined />
                      </NavLink>
                    )}
                  </Col>
                )
              default:
                return (
                  <Col key={key} span={8}>
                    {renderInput(key)}
                  </Col>
                )
            }
          })}
        </Row>
      </Form>
    </Modal>
  )
}

export default EditEstimationModal

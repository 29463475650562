import { combineReducers } from '@reduxjs/toolkit'
import { listSliceBuilder, createSliceBuilder } from 'store/helper'
import type { AdvanceInfoResource } from 'api/main'
import {
  outReferenceListRequest,
  outReferenceAddRequest,
  outReferenceDeleteRequest,
  outReferenceUpdateRequest,
} from 'store/outReference'
import { moveToReleaseRequest } from './actions'

export const outReferenceReducers = combineReducers({
  list: listSliceBuilder<AdvanceInfoResource>('listOutReference', outReferenceListRequest).reducer,
  add: createSliceBuilder<void>('addOutReference', outReferenceAddRequest).reducer,
  delete: createSliceBuilder<void>('deleteOutReference', outReferenceDeleteRequest).reducer,
  update: createSliceBuilder<void>('updateOutReference', outReferenceUpdateRequest).reducer,
  moveToRelease : createSliceBuilder<void>('moveToRelease', moveToReleaseRequest).reducer,
})

import * as yup from 'yup'
import { AdvInfoFormFields } from 'Components/AdvanceInfo/AdvInfoFormFields'
import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'
import { AdvanceInfoUpdateRequest } from 'api/main'

const dateTransform = (value: any, originalValue: any) => {
  if (originalValue) {
    return new Date(originalValue)
  }
  return new Date()
}

const advInfoSchema = yup.object().shape<GeneralSchemaType<AdvanceInfoUpdateRequest>>({
  container_csc_date: yup.string().required(`'${AdvInfoFormFields.container_csc_date?.label}' is a required field`),
  container_max_gross_weight: yup
    .number()
    .typeError(`'${AdvInfoFormFields.container_max_gross_weight?.label}' must be a number`)
    .required(`'${AdvInfoFormFields.container_max_gross_weight?.label}' is a required field`),
  container_tare_weight: yup
    .number()
    .typeError(`'${AdvInfoFormFields.container_tare_weight?.label}' must be a number`)
    .required(`'${AdvInfoFormFields.container_tare_weight?.label}' is a required field`),
  container_material: yup
    .number()
    .typeError(`'${AdvInfoFormFields.container_material?.label}' must be a number`)
    .required(`'${AdvInfoFormFields.container_material?.label}' is a required field`),
  container_manufacturing_date: yup
    .date()
    .transform(dateTransform)
    .when('has_lease_info', {
      is: true,
      then: yup
        .date()
        .transform(dateTransform)
        .required(`'${AdvInfoFormFields.lease_info_on_hire_date?.label}' is a required field`),
    }),
  has_lease_info: yup.boolean(),
  lease_info_lessee: yup
    .string()
    .nullable()
    .when('has_lease_info', {
      is: true,
      then: yup.string().required(`'${AdvInfoFormFields.lease_info_lessee?.label}' is a required field`),
    }),
  lease_info_on_hire_date: yup
    .date()
    .transform(dateTransform)
    .when('has_lease_info', {
      is: true,
      then: yup
        .date()
        .transform(dateTransform)
        .required(`'${AdvInfoFormFields.lease_info_on_hire_date?.label}' is  required field`),
    }),
  lease_info_dpp_amount: yup.number().nullable().when('has_lease_info', {
    is: true,
    then: yup.number().required(`'${AdvInfoFormFields.lease_info_dpp_amount?.label}' is a required field`),
  }),

  note: yup.string().nullable(),
  expiration_date: yup
    .date()
    .typeError(`'${AdvInfoFormFields.expiration_date?.label}' must be a date`)
    .required(`'${AdvInfoFormFields.expiration_date?.label}' is a required field`),
  lift_in_paid_by: yup.number().required(`'${AdvInfoFormFields.lift_in_paid_by?.label}' is a required field`),
  lift_out_paid_by: yup.number().required(`'${AdvInfoFormFields.lift_out_paid_by?.label}' is a required field`),
  rent_paid_by: yup.number().required(`'${AdvInfoFormFields.rent_paid_by?.label}' is a required field`),
})

export default advInfoSchema

import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { FromCheckingToCheckedRequestBody } from 'api/main'
import { ContainerGrades, ContainerConditions } from 'constants/ContainerCycleConsts'

export const editCheckedFormFields: GeneralFormFieldType<FromCheckingToCheckedRequestBody> = {
  checked_condition: {
    label: 'Condition',
    placeholder: 'Condition',
    type: InputType.RADIO,
    options: ContainerConditions,
  },
  checked_only_washing: {
    label: 'Only Washing',
    placeholder: 'Only Washing',
    type: InputType.CHECKBOX,
  },
  checked_grade: {
    label: 'Grade',
    placeholder: 'Grade',
    type: InputType.SELECT,
    options: ContainerGrades,
  },
  checked_note: {
    label: 'Notes (optional)',
    placeholder: 'Put your notes',
    type: InputType.TEXTAREA,
  },
}

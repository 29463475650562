import { PrivateRoutePresentation, PrivateRoutePresentationProps } from '../RoutePresentation'
import { RootState } from '../../store'
import { RouteProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { Status } from '../../models'
import { tokenUtils } from 'utils'

export const LoginGuardRoute = connect<PrivateRoutePresentationProps, RecordObject, RouteProps, RootState>(
  (state: RootState): PrivateRoutePresentationProps => {
    const { status } = state.auth.login
    const token = tokenUtils.getToken()

    return {
      isAuthorized: status !== Status.SUCCEEDED || !token,
      pathname: localStorage.getItem('path') || '/home',
    }
  }
)(PrivateRoutePresentation)

export type RecordObject<T = unknown> = Record<string, T>

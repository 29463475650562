import {useState, VoidFunctionComponent} from 'react'
import { Table, Button, Space } from 'antd'
import { FileTextFilled } from '@ant-design/icons'
import { showConfirmModal, MoveToNextStep } from 'store/app'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { tableSelectors, moveToCheckingRequest, moveToCheckingStatus, arrivalsListRequest } from 'store/arrivals'
import type { ArrivalResource } from 'api/main'
import { Status, MappedOption } from 'models'
import EditArrival from './EditArrival'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import CDTable from 'Components/Shared/CDTable'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}

const ArrivalsTab: VoidFunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }: Props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useAppDispatch()
  const moveStatus = useAppSelector(moveToCheckingStatus)
    onPaginateChange = (page: number) => {
        dispatch(
            arrivalsListRequest({
                depotId: Number(depot.value),
                list: { perPage: 20, page: page, query: '' },
            })
        )
        setCurrentPage(page)
    }
    const renderAction = (_value: any, item: ArrivalResource) => (
    <Space>
      <Button
        className='p-0'
        type="link"
        onClick={() => {
          dispatch(
            showConfirmModal({
              message: `Are you sure you want to move Container ${item.container_number} to Checking Area?`,
              onYes: async () => {
                if (item.id) {
                  const result = await dispatch(moveToCheckingRequest(item.id))
                  if (!(result as any).error) {
                    dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.checkingArea].title))
                    dispatch(
                      arrivalsListRequest({
                        depotId: Number(depot.value),
                        list: { perPage: 20, page: currentPage, query: '' },
                      })
                    )
                  }
                }
              },
              confirmLoading: moveStatus === Status.PENDING,
            })
          )
        }}
      >
        Move to Check. Area
      </Button>
      {item.arrival_note && <FileTextFilled style={{ color: '#FFC53D' }} />}
    </Space>
  )
  return (
    <>
      <CDTable<ArrivalResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        expandable={{
          expandedRowRender: (record:ArrivalResource) => {
            return <EditArrival arrival={record} depot={depot} />
          },
          expandIconColumnIndex: 8,
        }}
        configName={containerLifeCyles.arrivals}
        onPaginate={onPaginateChange}
      >
        <Table.Column title="Actions" key="action" render={renderAction} />
      </CDTable>
    </>
  )
}

export default ArrivalsTab

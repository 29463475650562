/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDamageEstimateRequest } from '../models/CreateDamageEstimateRequest';
import type { DamageAreaCollection } from '../models/DamageAreaCollection';
import type { DamageEstimateResource } from '../models/DamageEstimateResource';
import type { ImportDamageEstimateEDIRequest } from '../models/ImportDamageEstimateEDIRequest';
import type { SetDamageStatusRequest } from '../models/SetDamageStatusRequest';
import type { UpdateDamageEstimateRequest } from '../models/UpdateDamageEstimateRequest';
import { request as __request } from '../core/request';

export class AdminContainerDamagedService {

    /**
     * Get Damage Estimates Area list
     * This end point will return all estimates for a given advance_info.
     * @param advanceInfoId
     * @returns DamageEstimateResource successfull
     * @throws ApiError
     */
    public static async adminDepotDepotIdAdvanceInfoAdvanceInfoIdList(
        advanceInfoId: number,
    ): Promise<Array<DamageEstimateResource>> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/advance_info/${advanceInfoId}/damage_estimate`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Create damage estimate for the given advance info
     * With this end point you can create an estimate for the advance info.
     * @param advanceInfoId
     * @param requestBody Create damage estimate
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoAdvanceInfoIdDamageEstimatePost(
        advanceInfoId: number,
        requestBody?: CreateDamageEstimateRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/advance_info/${advanceInfoId}/damage_estimate`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Get Damaged Area list
     * This end point will return all Damaged Area information.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search over Damaged Area.
     * @param grade Search over Damaged Are.
     * @param stockType Search over Damaged Are.
     * @param status Search over Damaged Are.
     * @param rejectState Search over Damaged Are.
     * @param isoCode Search over Damaged Are.
     * @returns DamageAreaCollection Damaged area collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdDamagedList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
        grade?: string,
        stockType?: string,
        status?: string,
        rejectState?: string,
        isoCode?: string,
    ): Promise<DamageAreaCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/damaged`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
                'grade': grade,
                'stock_type': stockType,
                'status': status,
                'reject_state': rejectState,
                'iso_code': isoCode,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Delete damage estimate
     * With this end point you can delete an estimate.
     * @param damageEstimate
     * @returns void
     * @throws ApiError
     */
    public static async adminDamageEstimateDamageEstimateDelete(
        damageEstimate: number,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/admin/damage_estimate/${damageEstimate}`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * update damage estimate
     * With this end point you can update an exist estimate.
     * @param damageEstimate
     * @param requestBody Update damage estimate
     * @returns void
     * @throws ApiError
     */
    public static async adminDamageEstimateDamageEstimatePatch(
        damageEstimate: number,
        requestBody?: UpdateDamageEstimateRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/damage_estimate/${damageEstimate}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Imports containers' damages via an EDI file
     * With this end point you can upload damage estimates in an edi file.
     * @param requestBody Import advance Info from edi request body
     * @returns void
     * @throws ApiError
     */
    public static async adminDamageEstimateImportEdiPost(
        requestBody?: ImportDamageEstimateEDIRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/damage_estimate/import/edi`,
            body: requestBody,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Set status of the active damage info of an advance info
     * With this end point you can set status of the active damage info of an advance info.
     * @param advanceInfoId
     * @param requestBody Setdamage status requestt body
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoAdvanceInfoIdDamagedStatusUpdate(
        advanceInfoId: number,
        requestBody?: SetDamageStatusRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/advance_info/${advanceInfoId}/damaged/status`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * From checked to damaged state
     * With this end point you can move a container from checked state to damaged.
     * @param advanceInfoId
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoAdvanceInfoIdDamagedPost(
        advanceInfoId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/advance_info/${advanceInfoId}/damaged`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

}
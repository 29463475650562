import { VoidFunctionComponent } from 'react'
import { PageHeader, Card, Typography } from 'antd'
import StockReport from 'Components/Reports/StockReport'
import DailyReport from 'Components/Reports/DailyReport'

const { Title } = Typography

const Reports: VoidFunctionComponent = () => {
  return (
    <>
      <PageHeader
        ghost={false}
        title="Reoprts"
        className='mb-3'
      />
      <Card className='mb-3'>
          <Title level={5}>Daily Report</Title>
          <DailyReport />
      </Card>
      <Card className='mb-3'>
        <Title level={5}>Stock Report</Title>
        <StockReport />
      </Card>
      <Card className='mb-3'>
        <Title level={5}>Stock Summary Report</Title>
        <StockReport isSummary />
      </Card>
    </>
  )
}

export default Reports

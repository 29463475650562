import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { loginRequestAsync, logoutRequestAsync } from './asyncActions'
import { AdminTokenResource } from '../../../api/main'
import { Status } from '../../../models'
import { RootState } from '../../index'

export interface LoginState {
  error: string | { message: string } | null
  data: AdminTokenResource | null
  status: Status
}

const initialState: LoginState = {
  data: null,
  error: null,
  status: Status.IDLE,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload
    },
  },

  extraReducers: builder => {
    builder
      .addCase(loginRequestAsync.pending, state => {
        state.status = Status.PENDING
        state.error = null
      })
      .addCase(loginRequestAsync.fulfilled, (state, action) => {
        state.status = Status.SUCCEEDED
        state.data = action.payload
      })
      .addCase(loginRequestAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
      .addCase(logoutRequestAsync.fulfilled, state => {
        state.status = Status.IDLE
        state.data = null
      })
  },
})

export const { setStatus } = loginSlice.actions

export const selectData = (state: RootState): LoginState['data'] => state.auth.login.data
export const selectLoginError = (state: RootState): LoginState['error'] => state.auth.login.error
export const selectLoginStatus = (state: RootState): LoginState['status'] => state.auth.login.status

export default loginSlice.reducer

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  RepairingAreaCollection,
  AdminContainerRepairingService,
  AdminContainerRepairedService,
  AdminContainerWashingService,
} from 'api/main'
import { handleApiCallError } from 'store/helper'

export const repairingListRequest = createAsyncThunk<
  RepairingAreaCollection,
  {
    depotId: number
    perPage?: number
    page?: number
    query?: string
    status?: string
  },
  { rejectValue: string }
>('list/repairingListRequestAsync', async ({ depotId, perPage, page, query, status }, { rejectWithValue }) => {
  try {
    return await AdminContainerRepairingService.adminDepotDepotIdRepairingList(depotId, perPage, page, query, status)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const moveToRepairedRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'moveToRepaired/moveToRepairedRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerRepairedService.adminAdvanceInfoAdvanceInfoIdRepairedPost(advanceInfoId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const moveToWashingRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'moveToWashing/moveToWashingRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerWashingService.adminAdvanceInfoAdvanceInfoIdWashingPost(advanceInfoId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

import { ChangeEvent, VoidFunctionComponent, ReactNode } from 'react'
import { Button, Input } from 'antd'
import { callbackUtils } from '../../../utils'
import { PlusOutlined, FilterOutlined } from '@ant-design/icons'

interface Props {
  searchPlaceholder: string
  onSearch: (query: string) => void
  buttons?: { title: string; onClick: () => void; type?: 'add' | 'filter' }[]
  buttonComponents?: () => ReactNode
  defaultValue?: string
}

const HeaderExtra: VoidFunctionComponent<Props> = ({ buttons, searchPlaceholder, onSearch, buttonComponents, defaultValue = '' }) => {
  const debouncedSearch = callbackUtils.debounce(onSearch, 500)

  const onChangeValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 1 || target.value.length === 0) {
      debouncedSearch(target.value)
    }
  }

  return (
    <>
      <Input.Search defaultValue={defaultValue} onChange={onChangeValue} placeholder={searchPlaceholder} onSearch={onSearch} />
      {buttons?.map(({ title, onClick, type = 'add' }, index) =>
        type === 'add' ? (
          <Button key={index} icon={<PlusOutlined />} onClick={onClick} type="primary">
            {title}
          </Button>
        ) : (
          <Button key={index} icon={<FilterOutlined />} onClick={onClick}>
            {title}
          </Button>
        )
      )}
      {buttonComponents && buttonComponents()}
    </>
  )
}

export default HeaderExtra

import { RootState } from '../index'
import type { NormalState, ListState } from 'models/ApiState'
import { AdminUserResource, AdminCustomerResource } from 'api/main'

export type CompanyUsersListState = ListState<AdminUserResource>

export const selectCreateUserStatus = (state: RootState): NormalState<void>['status'] =>
  state.companyUsers.create.status

export const selectCompanyUsersListData = (state: RootState): CompanyUsersListState['data'] =>
  state.companyUsers.list.data

export const selectCompanyUsersListStatus = (state: RootState): CompanyUsersListState['status'] =>
  state.companyUsers.list.status

export const selectCompanyUsersListPaginationData = (state: RootState): CompanyUsersListState['paginationData'] =>
  state.companyUsers.list.paginationData

export const selectCompanyUserById =
  (id: number): ((state: RootState) => AdminUserResource | undefined) =>
  (state: RootState): AdminUserResource | undefined =>
    state.companyUsers.list.data?.find(user => user?.id === id)

export const selectCompanyUserUpdateStatus = (state: RootState): NormalState<AdminCustomerResource>['status'] =>
  state.companyUsers.update.status

export const tableSelectors = {
  data: selectCompanyUsersListData,
  status: selectCompanyUsersListStatus,
  pagination: selectCompanyUsersListPaginationData,
}

import { VoidFunctionComponent } from 'react'
import { PageHeader, Card } from 'antd'
import ISOCodeForm from './ISOCodeForm'
import { SubmitHandler } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { IsoCodeRequest } from 'api/main'
import {
  createISoCodeRequestAsync,
  selectIsoCodeCreateStatus,
  selectIsoCode,
  updateIsoCodeRequestAsync,
  selectIsoCodeUpdateStatus,
} from 'store/isoCodes'
import { selectMaterialListMappedData } from 'store/material'
import { selectContainerTypeListMappedData } from 'store/containerType'
import { Status, mapIsoResToReq } from 'models'

const CreateISOCodePage: VoidFunctionComponent = () => {
  const dispatch = useAppDispatch()
  const createStatus = useAppSelector(selectIsoCodeCreateStatus)
  const updateStatus = useAppSelector(selectIsoCodeUpdateStatus)
  const { isoCodeId } = useParams<{ isoCodeId: string }>()
  const selectedIsoCode = useAppSelector(selectIsoCode(Number(isoCodeId)))
  const history = useHistory()

  const materialMappedData = useAppSelector(selectMaterialListMappedData)
  const typesMappedData = useAppSelector(selectContainerTypeListMappedData)

  const onSubmitForm: SubmitHandler<IsoCodeRequest> = async values => {
    let result
    if (isoCodeId) {
      result = await dispatch(updateIsoCodeRequestAsync({ isoCode: Number(isoCodeId), requestBody: values }))
    } else {
      result = await dispatch(createISoCodeRequestAsync(values))
    }
    //TODO remove this any and find the solution
    if (!(result as any).error) {
      history.push('/iso-codes')
    }
  }
  return (
    <>
      <PageHeader ghost={false} onBack={history.goBack} title="Admin - Create an ISO Code" />
      <Card>
        <ISOCodeForm
          onSubmit={onSubmitForm}
          isSubmitting={createStatus === Status.PENDING || updateStatus === Status.PENDING}
          initialValues={selectedIsoCode && mapIsoResToReq(selectedIsoCode)}
          materials={materialMappedData}
          containerTypes={typesMappedData}
        />
      </Card>
    </>
  )
}

export default CreateISOCodePage

import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal } from 'antd'
import { SubmitHandler, useForm } from 'react-hook-form'
import Input from '../UiKit/Inputs/Input'
import { InputType, MappedOption } from 'models'
import { yupResolver } from '@hookform/resolvers/yup'
import inviteInternalUserSchema from 'schemas/inviteInternalUserSchema'
import {AdminInviteUserRequest} from 'api/main'

interface Props {
  onSubmit: SubmitHandler<AdminInviteUserRequest>
  onCancel: () => void
  loading?: boolean
  visible?: boolean
  depots: MappedOption[]
  roles: MappedOption[]
  roleLoading: boolean
}

const UserFormModal: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, loading, depots, roles, roleLoading }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AdminInviteUserRequest>({ resolver: yupResolver(inviteInternalUserSchema) })

  useEffect(() => {
    if (visible) {
      reset()
    }
  }, [reset, visible])

  const onCancelForm = () => {
    onCancel()
  }

  return (
    <Modal
      visible={visible}
      title="Invite a user"
      okText="Invite"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      confirmLoading={loading}
    >
      <Form name="internal_user" layout="vertical" noValidate>
        <Input
          label="Email"
          errorClassName="ant-form-item-has-error"
          control={control}
          name="email"
          placeholder="example@test.com"
          error={errors.email?.message}
        />
        <Input
          label="Depots"
          errorClassName="ant-form-item-has-error"
          control={control}
          name="depot_ids"
          type={InputType.MULTISELECT}
          placeholder="Select depots"
          error={errors.depot_ids && (errors.depot_ids as any).message}
          options={depots}
        />
        <Input
          label="Role"
          errorClassName="ant-form-item-has-error"
          control={control}
          name="role_id"
          type={InputType.SELECT}
          placeholder="Select a role"
          error={errors.role_id?.message}
          options={roles}
          loading={roleLoading}
        />
      </Form>
    </Modal>
  )
}

export default UserFormModal

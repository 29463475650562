import { VoidFunctionComponent } from 'react'
import { Modal, Row, Image, Typography, Divider, Button, Col } from 'antd'
import {
    LoadingOutlined,
  } from '@ant-design/icons'
  import styled from 'styled-components'
import { Status } from 'models'
import TickImage from '../../assets/images/tick.png'
import { colors } from 'constants/colors'

const { Text } = Typography

interface Props {
    setIsModalVisible: (value: boolean) => void
    isModalVisible: boolean
    requestStatus: Status
    title: string
    tryAgain: () => Promise<void>
}

const ResultModal: VoidFunctionComponent<Props> = ({ setIsModalVisible, isModalVisible, requestStatus, title, tryAgain }) => {
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Modal
        title={`Generating ${title}`}
        visible={isModalVisible}
        okButtonProps={{ hidden: true }}
        onCancel={handleCancel}
        footer={null}
    >
        <Row justify='center' className='mb-4'>
            {
                requestStatus === Status.PENDING  ? (
                    <LoadingOutlinedStyled />
                ) : (
                    <Image preview={false} src={TickImage} alt="download" />
                )
            }
        </Row>
        <Row justify='center'>
            <Text>
                {requestStatus === Status.PENDING ? `${title} Being Ready Download` : `${title} is ready`}
            </Text>
        </Row>
        <Row justify='center'>
            <Text>
                {requestStatus === Status.PENDING ? 'will start automatically after generating' : 'Download will start soon'}
            </Text>
        </Row>
        <DividerStyled/>
        <Row justify='space-between'>
            <Col>
            {
                requestStatus === Status.FAILED ? (
                    <>
                        <Text className='mt-1'>
                            Download hasn’t Started?
                        </Text>
                        <Button type='link' onClick={tryAgain}>
                            Try Again
                        </Button>
                    </>
                ) : null
            }
            </Col>
            <Col>
                <Button type='ghost' onClick={handleCancel}>Ok</Button>
            </Col>
        </Row>
    </Modal>
  )
}

export default ResultModal

const LoadingOutlinedStyled = styled(LoadingOutlined)`
  font-size: 30px;
  color: ${colors.PRIMARY};
`
const DividerStyled = styled(Divider)`
  margin-top: 40px;
  margin-bottom: 15px;
`

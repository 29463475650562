import { VoidFunctionComponent, useEffect, useState, ChangeEvent } from 'react'
import { SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { Modal, Card, Row, Col, Form, Input as AntInput, Select, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useFetchDepotsOnce from 'hooks/useFetchDepotsOnce'
import useCDForm from 'hooks/useCDForm'
import { 
  AdminDepotWithAreaResource,
  CreateOutReferenceContainerInfoBodyProperty,
  CreateOutReferenceRequestBody,
} from 'api/main'
import {
  outReferenceAddRequest,
  outReferenceListRequest,
  selectOutReferenceAddStatus,
} from 'store/outReference'
import { 
  containerTypeListRequestAsync,
  selectContainerTypeListMappedData,
  selectContainerTypeListStatus,
} from 'store/containerType'
import { selectCompaniesListMappedData } from 'store/companies'
import { MappedOption } from 'models'
import {
  ContainerGrades,
  HPLTypes,
  liftOutInvoiceTypes,
  CountainerSize,
  StockTypesInOutRef,
} from 'constants/ContainerCycleConsts'
import { Status } from 'models'
import OutReferenceSchem from 'schemas/OutReferenceSchem';
import { AddOutReferenceFormFields } from 'Components/OutReferences/AddOutReferenceFormFields'
import { colors } from 'constants/colors';

interface Props {
  onCancel: () => void
  depot: MappedOption
  visible?: boolean
}

type CreateOutReferenceRequestBodyKeys = keyof CreateOutReferenceRequestBody

const AddOutReference: VoidFunctionComponent<Props> = ({ visible, onCancel, depot }) => {
  const dispatch = useAppDispatch()
  const companiesData = useAppSelector(selectCompaniesListMappedData)
  const containerTypeListStatus = useAppSelector(selectContainerTypeListStatus)
  const containerTypes = useAppSelector(selectContainerTypeListMappedData)
  const containerTypeFetched = containerTypeListStatus !== Status.IDLE
  const [fieldCount, setFieldCount] = useState([1])
  const [depotData] = useFetchDepotsOnce()
  const [depotList, setDepotList] = useState([])
  const [containerInfos, setContainerInfos] = useState<any[]>([{
    size: undefined,
    type_id: undefined,
    total_count: undefined,
    area: undefined,
  }])
  const addStatus = useAppSelector(selectOutReferenceAddStatus)
  const setErrList = useState<string[]>([])[1]

  useEffect(() => {
    if (!containerTypeFetched) {
      dispatch(containerTypeListRequestAsync({ perPage: 100, page: 1, query: '' }))
    }
  }, [dispatch, containerTypeFetched])

  useEffect(() => {
    if(depotData && depot) {
      const data: any = depotData.find(((item: AdminDepotWithAreaResource) => item.name === depot.label))
      setDepotList(data?.areas)
    }
  }, [depotData, depot])

  const { handleSubmit, renderInput, reset } = useCDForm<CreateOutReferenceRequestBody & CreateOutReferenceContainerInfoBodyProperty>(
    AddOutReferenceFormFields,
    OutReferenceSchem,
  )

  const onCancelForm = (): void => {
    setErrList([])
    onCancel()
    reset()
  }

  const onSubmitForm: SubmitHandler<CreateOutReferenceRequestBody> = async (values) => {
    const result = await dispatch(outReferenceAddRequest({
      depotId: Number(depot.value),
      requestBody: {...values, container_infos: containerInfos }
    }))

    if ((result as any)?.error) {
      const errObject = (result as any)?.payload?.errors
      const errArray: string[] = []
      if (errObject) {
        Object.keys(errObject).forEach(key => {
          errObject[key] && errArray.push(errObject[key][0])
        })
      }
      setErrList(errArray)
    } else {
      dispatch(outReferenceListRequest({
        depotId: Number(depot.value),
        list: { perPage: 20, page: 1, query: '' },
      }))

      onCancelForm()
    }
  }

  const addField = (): void => {
    setFieldCount([...fieldCount, fieldCount.length + 1])
    setContainerInfos([...containerInfos, {
      size: undefined,
      type_id: undefined,
      total_count: undefined,
      area: undefined,
    }])
  }

  const handleChangeContainerInfo = (value: string | number, name: string, index: number) => {
    containerInfos[index-1][name]= value
    setContainerInfos([...containerInfos])
  }

  const renderPaidBy = (paidBy: CreateOutReferenceRequestBodyKeys, payType: CreateOutReferenceRequestBodyKeys, payTypes: MappedOption[]) => {
    return (
      <>
        <ErrorWrapper>* </ErrorWrapper>
        <LableWrapper>Lift Out Paid by</LableWrapper>
        <FlexInputGroup compact>
          {renderInput(payType, payTypes, false)}
          <InputWrapper>{renderInput(paidBy, companiesData, false)}</InputWrapper>
        </FlexInputGroup>
      </>
    )
  }

  return (
    <Modal
      visible={visible}
      title={`New Out Reference: ${depot.label}`}
      okText="Submit"
      onOk={handleSubmit(onSubmitForm)}
      okButtonProps={{ loading: addStatus === Status.PENDING }}
      onCancel={onCancelForm}
      bodyStyle={{ padding: 0 }}
      width={850}
    >
        <Form layout="vertical" name="iso_code" onFinish={handleSubmit(onSubmitForm)}>
          <Row>
            <Col span={24}>
              <Card style={{ height: '100%' }}>
                <Row gutter={[20, 20]}>
                  <Col span={8}>{renderInput('reference', undefined, undefined, undefined, undefined, true)}</Col>
                  <Col span={8}>{renderInput('stock_type_id', StockTypesInOutRef, undefined, undefined, undefined, true)}</Col>
                  <Col span={8}>{renderInput('company_id', companiesData, undefined, undefined, undefined, true)}</Col>
                </Row>
                <Row gutter={[20, 20]}>
                  <Col span={8}>{renderInput('grade', ContainerGrades, undefined, undefined, undefined, true )}</Col>
                  <Col span={8}>{renderPaidBy('lift_out_paid_by_company_id', 'lift_out_invoice_type', liftOutInvoiceTypes)}</Col>
                  <Col span={8}>{renderInput('HPL', HPLTypes, undefined, undefined, undefined, true)}</Col>
                </Row>
                <Row gutter={[20, 20]}>
                  <Col span={8}>{renderInput('valid_from', undefined, undefined, undefined, undefined, true)}</Col>
                  <Col span={8}>{renderInput('expiration_date', undefined, undefined, undefined, undefined, true)}</Col>
                  <Col span={8}>{renderInput('lessee')}</Col>
                </Row>
                
                {
                  fieldCount?.map((index) => (
                  <Row gutter={[8, 8]}>
                    <Col span={6}>
                      <Form.Item required label="Size">
                        <Select
                          value={containerInfos[index-1]['size']}
                          placeholder="Size"
                          options={CountainerSize}
                          onChange={(value: string) => handleChangeContainerInfo(value, 'size', index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item required label="Type">
                        <Select
                          value={containerInfos[index-1]['type_id']}
                          allowClear
                          showSearch
                          placeholder="Type"
                          options={containerTypes}
                          onChange={(value: string) => handleChangeContainerInfo(value, 'type_id', index)}
                        />
                      </Form.Item>
                      </Col>
                    <Col span={6}>
                      <Form.Item required label="Count">
                        <AntInput
                          value={containerInfos[index-1]['total_count']}
                          placeholder="Count"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeContainerInfo(Number(e.target.value), 'total_count', index)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item required label="Area">
                        <Select
                          value={containerInfos[index-1]['area']}
                          placeholder="Area"
                          onChange={(value: string) => handleChangeContainerInfo(value, 'area', index)}
                          disabled={depotList?.length === 0}
                        >
                          {depotList?.map((item: any) => (
                            <Select.Option key={item.id} value={item.name}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  ))
                }
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Button icon={<PlusOutlined />} block type="dashed" onClick={addField}>Add Size, Type, Count</Button>
                  </Col>
                </Row>
                <br />
                <Row gutter={[8, 8]}>
                  <Col span={24}>{renderInput('note')}</Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
    </Modal>
  )
}

export default AddOutReference

const FlexInputGroup = styled(AntInput.Group)`
  display: flex !important;
`
const InputWrapper = styled.span`
  flex-grow: 1;
`
const LableWrapper = styled.span`
  display: inline-block;
  margin-bottom: 8px;
`
const ErrorWrapper = styled.span`
  color: ${colors.RED};
`

import { createAsyncThunk } from '@reduxjs/toolkit'
import { IsoCodeRequest, IsoCodeResource, IsoCodeUpdateRequest, AdminIsoCodeService } from 'api/main'
import type { IsoCodeCollection } from 'api/main/models/IsoCodeCollection'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'
export const createISoCodeRequestAsync = createAsyncThunk<IsoCodeResource, IsoCodeRequest, { rejectValue: string }>(
  'createIsoCode/createIsoCodeRequestAsync',
  async (body, { rejectWithValue }) => {
    try {
      return await AdminIsoCodeService.adminIsoCodeCreate(body)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const isoCodeListRequestAsync = createAsyncThunk<IsoCodeCollection, GeneralListRequest, { rejectValue: string }>(
  'list/isoCodeListRequestAsync',
  async ({ page, perPage, query }, { rejectWithValue }) => {
    try {
      return await AdminIsoCodeService.adminIsoCodeList(perPage, page, query)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue, 'iso codes list')
    }
  }
)

export const updateIsoCodeRequestAsync = createAsyncThunk<
  IsoCodeResource,
  { isoCode: number; requestBody: IsoCodeUpdateRequest },
  { rejectValue: string }
>('update', async ({ isoCode, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminIsoCodeService.adminIsoCodeUpdate(isoCode, requestBody)
  } catch (e: any) {
   return handleApiCallError(e, rejectWithValue)
  }
})

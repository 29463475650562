import { VoidFunctionComponent } from 'react'
import { Table, Button, Space } from 'antd'
import { useHistory } from 'react-router-dom'
import type { ContainerResource } from 'api/main'
import { ContainerArea, MappedOption } from 'models'
import { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import CDTable from 'Components/Shared/CDTable'
import { tableSelectors } from 'store/allContainers'
import { stateTextMap } from 'Components/Shared/LifeCyclePageFactory/helper'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
}

interface NavigationProps {
  pathname: string
  item: ContainerResource
}

const AllContainersTabs: VoidFunctionComponent<Props> = ({ depot, onPaginateChange }: Props) => {
  const history = useHistory()
  const navigateTo = ({ pathname, item }: NavigationProps) => {
    history.push({ pathname, state: { containerId: item.id, containerNumber: item.number, depot}})
  }
  const renderAction = (_value: any, item: ContainerResource) => (
    <Space>
      <Button
        className='p-0'
        type="link"
        onClick={() => {
          // eslint-disable-next-line sonarjs/no-small-switch
          switch (item.area) {
            case ContainerArea.ADVANCE_INFO:
              navigateTo({ pathname: `/${containerLifeCyles.advanceInfo}`, item })
              break
            case ContainerArea.ARRIVAL:
              navigateTo({ pathname: `/${containerLifeCyles.arrivals}`, item })
              break
            case ContainerArea.CHECKING:
              navigateTo({ pathname: `/${containerLifeCyles.checkingArea}`, item })
              break
            case ContainerArea.CHECKED:
              navigateTo({ pathname: `/${containerLifeCyles.checkedArea}`, item })
              break
            case ContainerArea.DAMAGED:
              navigateTo({ pathname: `/${containerLifeCyles.damagedArea}`, item })
              break
            case ContainerArea.REPAIRING:
              navigateTo({ pathname: `/${containerLifeCyles.repairingArea}`, item })
              break
            case ContainerArea.WASHING:
              navigateTo({ pathname: `/${containerLifeCyles.washingArea}`, item })
              break
            case ContainerArea.AVAILABLE:
              navigateTo({ pathname: `/${containerLifeCyles.availableArea}`, item })
              break
            // case ContainerArea.RELEASED:
            //   navigateTo({ pathname: `/${containerLifeCyles.releasedArea}`, item })
            //   break
            default:
              break
          }
        }}
      >
        {mapActionText(item)}
      </Button>
    </Space>
  )
  return (
    <>
      <CDTable<ContainerResource>
        selectors={tableSelectors}
        configName={containerLifeCyles.allContainers}
        onPaginate={onPaginateChange}
      >
        <Table.Column
          title="State"
          dataIndex="area"
          render={(text: string) => (
            <p style={{ marginBottom: 0 }}>{stateTextMap(text)}</p>
          )}
        />
        <Table.Column title="Actions" key="action" render={renderAction} />
      </CDTable>
    </>
  )
}

export default AllContainersTabs

const mapActionText = (record: ContainerResource): string => {
  switch (record.area) {
    case ContainerArea.ADVANCE_INFO:
      return 'Show in Advance Info'
    case ContainerArea.ARRIVAL:
      return 'Show in Arrivals'
    case ContainerArea.CHECKING:
      return 'Show in Checking'
    case ContainerArea.CHECKED:
      return 'Show in Checked'
    case ContainerArea.DAMAGED:
      return 'Show in Damaged'
    case ContainerArea.REPAIRING:
      return 'Show in Repairing'
    case ContainerArea.WASHING:
      return 'Show in Washing'
    case ContainerArea.AVAILABLE:
      return 'Show in Available'
    default:
      return '-'
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminAuthService, AdminLoginRequest, AdminTokenResource } from '../../../api/main'
import { tokenUtils } from '../../../utils'
import { handleApiCallError } from 'store/helper'

export const loginRequestAsync = createAsyncThunk<AdminTokenResource, AdminLoginRequest, { rejectValue: string }>(
  'login/loginRequest',
  async (body, { rejectWithValue }) => {
    try {
      const response = await AdminAuthService.adminAuthLogin(body)
      tokenUtils.setToken(response.access_token)

      return response
    } catch (e) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const logoutRequestAsync = createAsyncThunk<void, void, { rejectValue: string }>(
  'login/logoutRequest',
  async () => {
    try {
      await AdminAuthService.adminAuthLogout()
      tokenUtils.removeToken()
      caches?.delete('apiCache')
    } catch (e) {
      tokenUtils.removeToken()
      // TODO: check faild status
    } finally {
      caches?.delete('apiCache')
    }
  }
)

import { VoidFunctionComponent } from 'react'
import { Modal, Table } from 'antd'
import GateOutConfirmList from "./GateOutConfirmList"
import { GateOutRequestBody } from 'api/main'
import { Status } from 'models'

interface Props {
  onCancel: () => void
  loading?: boolean
  visible?: boolean
  onSubmit: () => void
  allContainers?: GateOutRequestBody
  transCompany: string
  licencePlate: string
  gateOutStatus: Status
}

const columns = [
  {
    title: 'Trans. Company',
    dataIndex: 'transCompany',
    key: 'trans',
  },
  {
    title: 'License Plate N.',
    dataIndex: 'licencePlate',
    key: 'license',
  },
]

const ConfirmGateOut: VoidFunctionComponent<Props> = ({
  visible, 
  onCancel, 
  loading, 
  onSubmit, 
  allContainers,
  transCompany,
  licencePlate,
  gateOutStatus,
}) => {
  const onCancelForm = (): void => {
    onCancel()
  }

  const data = [
    { transCompany, licencePlate }
  ]

  return (
    <Modal
      visible={visible}
      title="Please, confirm gating out of the following containers"
      okText="Confirm"
      onOk={() => {
        onSubmit()
      }}
      onCancel={onCancelForm}
      confirmLoading={loading}
      okButtonProps={{ loading }}
    >
      <Table className='mb-4' columns={columns} dataSource={data} bordered pagination={false} />
      <GateOutConfirmList
        dataSource={allContainers?.containers || []} 
        header='Containers to gate out'
        title='Container N.'
      />
      <GateOutConfirmList
        dataSource={allContainers?.other_containers || []} 
        header='Other Containers on the truck'
        title='Container N.'
      />
    </Modal>
  )
}

export default ConfirmGateOut

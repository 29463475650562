import { VoidFunctionComponent } from 'react'
import styled from 'styled-components'
import { Col, Row, Image, Typography, Button } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import checkCircle from '../../assets/images/check-circle.png'
import { selectDropOffData, selectPickUpData } from 'store/ordering'
import { useAppSelector } from 'store/hooks'
import { DropOffToArrivalResourceResource, TruckPickUpResource } from 'api/main'
import { LastAction } from './OrderingTypes'

const { Text, Title } = Typography

interface Props {
    selectedDepot: string
    goHome: () => void
    lastAction?: LastAction
}

const SuccessRequest: VoidFunctionComponent<Props> = ({ selectedDepot, goHome, lastAction }) => {
    const pickUpDatadata = useAppSelector<any>(selectPickUpData)
    const dropOffData = useAppSelector<any>(selectDropOffData)

    return (
        <>
            <Row justify="center">
                <Image className='mb-3' preview={false} src={checkCircle} alt="failed" />
            </Row>
            <Row justify="center" className='mb-3 mt-3'>
                <Title level={4}>Your request is successfully sent!</Title>
            </Row>
            <StyledRow>
                <StyledTitle>
                    {
                        lastAction === LastAction.PICK_UP ? (
                            <Text strong>{`Please, come pick up the container from the following areas of ${selectedDepot} depot.`}</Text>
                        ) : (
                            <Text strong>{`Please, bring container to the following areas of ${selectedDepot} depot.`}</Text>
                        )
                    }
                </StyledTitle>
                {
                    lastAction === LastAction.PICK_UP ? (
                        pickUpDatadata?.map((item: TruckPickUpResource) => (
                            <Row className='mt-3'>
                                <StyledCheckCircleOutlined />
                                <Text className='ml-1'>{`Container with size:${item.size}, type:${item.type}, count:${item.count} must be taken from`}</Text>
                                &nbsp;
                                <Text strong>{`Area ${item.area}`}</Text>.
                            </Row>
                        ))) : (
                            dropOffData?.map((item: DropOffToArrivalResourceResource) => (
                                <Row className='mt-3'>
                                    <StyledCheckCircleOutlined />
                                    <Text className='ml-1'>{`Container ${item.container_number} must be taken to`}</Text>
                                    &nbsp;
                                    <Text strong>{`Area ${item.area}`}</Text>.
                                </Row>
                            ))
                        )
                }
            </StyledRow>
            <Row gutter={[20, 20]} className='mt-3'>
                <Col span={24}>
                    <Row justify="center">
                        <Button type='primary' onClick={goHome}>
                            Go Home
                        </Button>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SuccessRequest

const StyledCheckCircleOutlined =  styled(CheckCircleOutlined)`
  margin-top: 4px;
`

const StyledRow =  styled(Row)`
  background-color: #FAFAFA;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 24px;
  padding-bottom: 24px;
`
const StyledTitle =  styled(Row)`
  font-size: 15px;
`

import { ListState, NormalState } from 'models'
import type { CheckedAreaResource } from 'api/main'
import { RootState } from '../index'

type CheckedsListState = ListState<CheckedAreaResource>
export const selectCheckedsListData = (state: RootState): CheckedsListState['data'] => state.checkeds.list.data
export const selectCheckedsPaginationData = (state: RootState): CheckedsListState['paginationData'] =>
  state.checkeds.list.paginationData

export const selectCheckedsListStatus = (state: RootState): CheckedsListState['status'] => state.checkeds.list.status

export const selectCheckedUpdateStatus = (state: RootState): NormalState<void>['status'] => state.checkeds.update.status

export const tableSelectors = {
  data: selectCheckedsListData,
  status: selectCheckedsListStatus,
  pagination: selectCheckedsPaginationData,
}

export const selectMoveToDamagedStatus = (state: RootState): NormalState<void>['status'] =>
  state.checkeds.moveToDamaged.status
import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { CreateDamageEstimateRequest } from 'api/main'

export const EditEstimationFormFields: GeneralFormFieldType<CreateDamageEstimateRequest> = {
  reference: {
    label: 'Reference',
    placeholder: 'Reference',
  },
  number: {
    label: 'Estimate Number',
    placeholder: 'Estimate Number',
  },
  component: {
    label: 'Component',
    placeholder: 'Component',
  },
  damage: {
    label: 'Damage',
    placeholder: 'Damage',
  },
  repair: {
    label: 'Repair',
    placeholder: 'Repair',
  },
  location: {
    label: 'Location',
    placeholder: 'Location',
  },
  quantity: {
    label: 'Quantity',
    placeholder: 'Quantity',
  },
  length: {
    label: 'Length',
    placeholder: 'Length',
  },
  width: {
    label: 'Width',
    placeholder: 'Width',
  },
  labor_rate: {
    label: 'Labor Rate',
    placeholder: 'Labor Rate',
  },
  hours: {
    label: 'Hours',
    placeholder: 'Hours',
  },
  material: {
    label: 'Material',
    placeholder: 'Material',
  },
  paid_by_third_party: {
    label: 'Paid by third party',
    placeholder: 'Paid by third party',
    type: InputType.SWITCH
  },
  company_id:{
    label: 'Company',
    placeholder: 'Company',
    type: InputType.SELECT
  }
}

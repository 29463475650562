import { combineReducers } from '@reduxjs/toolkit'
import { createSliceBuilder } from 'store/helper'
import {
  dropOffRequest,
  dropOffValidationRequest,
  pickUpListRequest,
} from 'store/ordering'
import {
  AppOutReferenceResource,
  DropOffToArrivalCollection,
  TruckPickUpCollectionResource
} from 'api/main'
import { pickUpRequest } from './actions'

export const orderingReducers = combineReducers({
  dropOff: createSliceBuilder<DropOffToArrivalCollection>('DropOff', dropOffRequest).reducer,
  dropOffValidation: createSliceBuilder<void>('validationDropOff', dropOffValidationRequest).reducer,
  pickUpList: createSliceBuilder<AppOutReferenceResource>('listPickUp', pickUpListRequest).reducer,
  pickUp: createSliceBuilder<TruckPickUpCollectionResource>('PickUp', pickUpRequest).reducer,
})

import { useState,memo, VoidFunctionComponent } from 'react'
import { Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { colors } from '../../../constants/colors'
import styled from 'styled-components'

interface Props {
  message: string
  visible: boolean
  onYes?: () => void
  onNo?: () => void
}

const YesNoModal: VoidFunctionComponent<Props> = memo(({ message, visible, onYes, onNo }) => {
  const [isLoading,setIsLoading] = useState(false)
  return (
    <Modal
      visible={visible}
      centered
      width={500}
      onCancel={onNo}
      onOk={async() => {
        if (onYes) {
          setIsLoading(true)
          await onYes()
          setIsLoading(false)
        }
      }}
      okText="Yes"
      cancelText="No"
      confirmLoading={isLoading}
    >
      <MessageWrapper>
        <InfoCircleOutlined style={{ color: colors.YELLOW_WARNING, fontSize: '22px' }} />
        <Message>{message}</Message>
      </MessageWrapper>
    </Modal>
  )
})
export default YesNoModal

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
`
const Message = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 16px;
`

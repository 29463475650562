import { InputType } from 'models'
import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { OutReferenceFilterModel } from 'models/forms/OutReferenceFilterModel'
import { StockTypes, ContainerGrades } from 'constants/ContainerCycleConsts'

export const OutRefrenceFilterFormFields: GeneralFormFieldType<OutReferenceFilterModel> = {
  creation: {
    label: 'creation',
    placeholder: 'creation',
    type: InputType.RANGE_DATE,
  },
  valid: {
    label: 'valid',
    placeholder: 'valid',
    type: InputType.RANGE_DATE,
  },
  expiration: {
    label: 'expiry',
    placeholder: 'expiry',
    type: InputType.RANGE_DATE,
  },
  stockType: {
    label: 'Stock Type',
    placeholder: 'Stock Type',
    type: InputType.RADIO,
    options: StockTypes,
  },
  grade: {
    label: 'Grade',
    placeholder: 'Grade',
    type: InputType.RADIO,
    options: ContainerGrades,
  },
}

import { FunctionComponent, useState, useEffect } from 'react'
import { Table, Button, Space } from 'antd'
import { FileTextFilled } from '@ant-design/icons'
import {
  gateOutRequest,
  outQueueDeleteRequest,
  outQueueListRequest,
  selectOutQueueGateOutStatus,
  tableSelectors,
  containersListRequest,
  selectContainersListStatus,
  selectContainersListData,
} from 'store/outQueue'
import type { GateOutRequestBody, OutQueueResource } from 'api/main'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { showConfirmModal } from 'store/app'
import { MappedOption, Status } from 'models'
import { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import CDTable from 'Components/Shared/CDTable'
import OutQueueDetail from 'Components/OutQueue/OutQueueDetail'
import GateOutFields from 'Components/OutQueue/GateOutFields'
import VisyAccessFields from 'Components/OutQueue/VisyAccessFields'
import ConfirmGateOut from 'Components/OutQueue/ConfirmGateOut'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const OutQueueTab: FunctionComponent<Props> = ({ depot, onPaginateChange }) => {
  const dispatch = useAppDispatch()
  const [gateOutFieldsVisibility, setGateOutFieldsVisibility] = useState<boolean>(false)
  const [visyAccessMode, setVisyAccessMode] = useState<boolean>(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false)
  const [fieldsCount, setFieldsCount] = useState<number>(0)
  const [containers, setContainers] = useState<string[]>([])
  const [otherContainers, setOtherContainers] = useState<string[]>([])
  const [allContainers, setAllContainers] = useState<GateOutRequestBody>()
  const [clickedItem, setClickedItem] = useState<OutQueueResource>()
  const setErrList = useState<string[]>([])[1]
  const [currentPage, setCurrentPage] = useState(1)
  onPaginateChange = (page: number) => {
    dispatch(outQueueListRequest({
      depotId: Number(depot.value),
      list: { perPage: 20, page: page, query: '' }
    }))
    setCurrentPage(page)
  }

  const gateOutStatus = useAppSelector(selectOutQueueGateOutStatus)
  const containerListData = useAppSelector<any>(selectContainersListData)
  const containerListStatus = useAppSelector<Status>(selectContainersListStatus)

  const resetFields = (): void => {
    setContainers(Array(fieldsCount).fill(''))
  }

  useEffect(() => {
    setContainers(Array(fieldsCount).fill(''))
  }, [fieldsCount, setContainers])

  const outQueueRequest = (): void => {
    dispatch(outQueueListRequest({ 
      depotId: Number(depot.value),
      list: { perPage: 20, page: currentPage, query: '' }
    }))
  }

  const onDeleteCallBack = async (id?: number): Promise<void> => {
    if (id) {
      await dispatch(outQueueDeleteRequest(id))
      outQueueRequest()
    }
  }

  const onDelete = async (id?: number, outReferenceId?: string): Promise<void> => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to delete from out queue?`,
        onYes: () => onDeleteCallBack(id),
      })
    )
  }

  const renderAction = (_value: any, item: OutQueueResource): JSX.Element => (
    <Space>
      <Button
        className='p-0'
        type="link"
        onClick={() => {
          setFieldsCount(item?.count || 0) 
          setGateOutFieldsVisibility(true)
          setClickedItem(item)
        }}
      >
        Gate out
      </Button>
      {item.note && <FileTextFilled style={{ color: '#FFC53D' }} />}
    </Space>
  )

  const onNext = (): void => {
    const filteredContainers = containers.filter((el) => {
      return el !== ''
    })
    if(filteredContainers.length < 5 && clickedItem?.license_plate_number) {
      setVisyAccessMode(true)
      const otherFieldsCount = 4 - filteredContainers.length
      const otherFields = Array(otherFieldsCount).fill('')
      setContainers(filteredContainers)
      setOtherContainers(otherFields)
    } else {
      setContainers(filteredContainers)
      setConfirmModalVisible(true)
    }
    setGateOutFieldsVisibility(false)
  }

  const onSendPass = (): void => {
    const filteredCtherContainers = otherContainers.filter((el) => {
      return el !== ''
    })
    setAllContainers({
      containers,
      other_containers: filteredCtherContainers,
    })
    setConfirmModalVisible(true)
    setVisyAccessMode(false)
  }

  const onSubmit = async () => {
    const result = await dispatch(gateOutRequest({
      outQueue: clickedItem?.id || 0,
      requestBody: allContainers,
    }))

    if ((result as any)?.error) {
      const errObject = (result as any)?.payload?.errors
      const errArray: string[] = []
      if (errObject) {
        Object.keys(errObject).forEach(key => {
          errObject[key] && errArray.push(errObject[key][0])
        })
      }
      setErrList(errArray)
    } else {
      outQueueRequest()
      setConfirmModalVisible(false)
      resetFields()
    }
  }

  const onChangeGateOutFields = (values: string) => {
    if (values.length > 2) {
      dispatch(containersListRequest({ outQueue: clickedItem?.id || 0, query: values }))
    }
  }

  let gateOutModal
  if (gateOutFieldsVisibility) {
    gateOutModal = (
      <GateOutFields
        containerListStatus={containerListStatus}
        containerListData={containerListData}
        containers={containers}
        setContainers={setContainers}
        onNext={onNext}
        onChange={onChangeGateOutFields}
        visible={gateOutFieldsVisibility}
        onCancel={() => {
          setGateOutFieldsVisibility(false)
          resetFields()
        }}
      />
    )
  } else if (visyAccessMode) {
    gateOutModal = (
      <VisyAccessFields
        containers={containers}
        setOtherContainers={setOtherContainers}
        otherContainers={otherContainers}
        onSendPass={onSendPass}
        visible={visyAccessMode}
        onCancel={() => {
          setVisyAccessMode(false)
          resetFields()
        }}
      />
    )
  } else if (confirmModalVisible) {
    gateOutModal = (
      <ConfirmGateOut
        gateOutStatus={gateOutStatus}
        transCompany={clickedItem?.transportation_company || ''}
        licencePlate={clickedItem?.license_plate_number || ''}
        allContainers={allContainers}
        onSubmit={onSubmit}
        visible={confirmModalVisible}
        loading={gateOutStatus === Status.PENDING}
        onCancel={() => {
          setConfirmModalVisible(false)
          resetFields()
        }}
      />
    )
  }

  return (
    <>
      <CDTable<OutQueueResource>
        selectors={tableSelectors}
        expandable={{
          expandedRowRender: (record: OutQueueResource) => {
            return record.id ? <OutQueueDetail record={record} onDelete={onDelete} /> : null
          },
          expandIconColumnIndex: 10,
        }}
        configName={containerLifeCyles.outQueue}
        onPaginate={onPaginateChange}
      >
        <Table.Column title="Action" key="action" render={renderAction} />
      </CDTable>
      {gateOutModal}
    </>
  )
}

export default OutQueueTab

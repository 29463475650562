enum LanguageType {
    SUOMI = 'suomi',
    ENGLISH = 'english',
    РУССКИЙ = 'Русский',
}

enum ActionType {
    HELSINKI_DROP_OFF = 'helsinki-dropOff',
    KOTKA_DROP_OFF = 'kotka-dropOff',
    HELSINKI_PICK_UP = 'helsinki-pickUp',
    KOTKA_PICK_UP = 'kotka-pickUp',
}

enum DepotType {
    HELSINKI = 'helsinki',
    KOTKA = 'kotka',
}

export interface FormValue {
    chooseLanguage: LanguageType
    chooseAction: ActionType
}

enum LastAction {
    DROP_OFF = 'dropOff',
    PICK_UP = 'pickUp',
}

export {
    LanguageType,
    ActionType,
    DepotType,
    LastAction,
}

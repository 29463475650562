import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  selectDepotsListMappedData,
  selectDepotsListStatus,
  depotsListRequestAsync,
  selectDepotsListData,
} from 'store/depots'
import { AdminDepotCollection } from 'api/main'
import { MappedOption, Status } from 'models'

const useFetchDepotsOnce = (): [AdminDepotCollection, MappedOption[]] => {
  const depotsStatus = useAppSelector(selectDepotsListStatus)
  const mappedDepots = useAppSelector(selectDepotsListMappedData)
  const depots: AdminDepotCollection = useAppSelector(selectDepotsListData)
  const dispatch = useAppDispatch()

  const depotsFetched = depotsStatus !== Status.IDLE

  useEffect(() => {
    if (!depotsFetched) {
      dispatch(depotsListRequestAsync())
    }
  }, [dispatch, depotsFetched])

  return [depots, mappedDepots]
}

export default useFetchDepotsOnce

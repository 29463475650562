import { ListState, NormalState } from 'models'
import type { DamagedAreaResource, DamageEstimateResource } from 'api/main'
// import type { NormalState } from 'models/ApiState'
import { RootState } from '../index'

type damagedListState = ListState<DamagedAreaResource>
export const selectDamagedListData = (state: RootState): damagedListState['data'] => state.damageds.list.data
export const selectDamagedPaginationData = (state: RootState): damagedListState['paginationData'] =>
  state.damageds.list.paginationData

export const selectDamagedListStatus = (state: RootState): damagedListState['status'] => state.damageds.list.status

export const tableSelectors = {
  data: selectDamagedListData,
  status: selectDamagedListStatus,
  pagination: selectDamagedPaginationData,
}

export const selectRepairDetailsListData = (state: RootState): NormalState<Array<DamageEstimateResource>>['data'] =>
  state.damageds.repairDetailsList.data

export const selectRepairDetailsLisStatus = (state: RootState): NormalState<Array<DamageEstimateResource>>['status'] =>
  state.damageds.repairDetailsList.status

export const selectCreateEstimateStatus = (state: RootState): NormalState<void>['status'] =>
  state.damageds.createEstimation.status

export const deleteEstimationStatus = (state: RootState): NormalState<void>['status'] =>
  state.damageds.deleteEstimation.status

export const selectupdateEstimationStatus = (state: RootState): NormalState<void>['status'] =>
  state.damageds.updateEstimation.status

export const setStatusStatus = (state: RootState): NormalState<void>['status'] => state.damageds.setStatus.status

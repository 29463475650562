import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerOutQueueService,
  CotainerNumbersResponse,
  GateOutRequestBody,
  OutQueueResource
} from '../../api/main'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const outQueueListRequest = createAsyncThunk<
  OutQueueResource,
  { 
    depotId: number,
    list: GeneralListRequest,
  },
  { rejectValue: string }
>('list/outQueue', async ({ 
  depotId,
  list: { perPage, page, query },
}, { rejectWithValue }) => {
  try {
    return await AdminContainerOutQueueService.adminDepotDepotIdOutQueueList(
      depotId,
      perPage,
      page,
      query,
    )
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const outQueueDeleteRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'delete/outQueue',
  async (outQueue, { rejectWithValue }) => {
    try {
      return await AdminContainerOutQueueService.adminOutQueueOutQueueDelete(outQueue)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const gateOutRequest = createAsyncThunk<void, { 
  outQueue: number,
  requestBody?: GateOutRequestBody,
}, { rejectValue: string }>(
  'gateOut/outQueue',
  async ({ outQueue, requestBody }, { rejectWithValue }) => {
    try {
      return await AdminContainerOutQueueService.adminOutQueueOutQueueGateOutPost(outQueue, requestBody)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const containersListRequest = createAsyncThunk<
  CotainerNumbersResponse,
  {
    outQueue: number,
    query: string,
  },
  { rejectValue: string }
>('gateOut/containersListRequestAsync', async ({ outQueue, query }, { rejectWithValue }) => {
  try {
    return await AdminContainerOutQueueService.adminOutQueueOutQueueContainerNumbers(outQueue, query)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

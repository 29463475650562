import { useEffect, useState, ReactNode } from 'react'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { Card } from 'antd'
import { companiesListRequestAsync, selectCompaniesListStatus } from 'store/companies'
import { materialListRequestAsync, selectMaterialListStatus } from 'store/material'
import { selectUserDepotsListMappedData } from 'store/auth/selectors'
import { adminAboutMeRequest } from 'store/auth/actions'
import { selectAboutMeStatus } from 'store/auth/selectors'
import { MappedOption, Status } from 'models'
import { colors } from 'constants/colors'
import activeDepotUtils from '../../../utils/activeDepot'

type Props = {
  headerRrender: (theDepot: MappedOption, page: number) => ReactNode
  tabRrender: (theDepot: MappedOption, onPaginateChange: (page: number) => void) => ReactNode
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const DepotsTab = ({ tabRrender, headerRrender }: Props) => {
  const [currentPage, setCurrentPage] = useState(1)

  const companyStatus = useAppSelector(selectCompaniesListStatus)
  const materialStatus = useAppSelector(selectMaterialListStatus)
  const depotsStatus = useAppSelector(selectAboutMeStatus)

  const dispatch = useAppDispatch()

  const onPaginateChangeHandler = (page: number) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    // get AboutMe if not get before
    if (depotsStatus !== Status.SUCCEEDED && depotsStatus !== Status.PENDING) {
      dispatch(adminAboutMeRequest())
    }
  }, [dispatch, depotsStatus])

  useEffect(() => {
    // get compnies if not get before
    if (companyStatus !== Status.SUCCEEDED && companyStatus !== Status.PENDING) {
      dispatch(companiesListRequestAsync({ perPage: 100, page: 1, query: '' }))
    }
    // get materials if not get before
    if (materialStatus !== Status.SUCCEEDED && materialStatus !== Status.PENDING) {
      dispatch(materialListRequestAsync({}))
    }
  }, [dispatch, companyStatus, materialStatus])

  const depots = useAppSelector(selectUserDepotsListMappedData)

  const tabList: Array<{ tab: string; key: string }> | undefined = depots?.map(depot => {
    return { key: depot.value.toString(), tab: depot.label }
  })

  const [activeTab, setActiveTab] = useState(
    activeDepotUtils.getActiveDepot() ? JSON.parse(activeDepotUtils.getActiveDepot() + '') : tabList && tabList[0]
  )
  useEffect(() => {
    if (activeDepotUtils.getActiveDepot()) {
      setActiveTab(JSON.parse(activeDepotUtils.getActiveDepot() + ''))
    } else {
      setActiveTab(tabList && tabList[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depots?.length])

  const tabContents: Record<string, ReactNode> = {}
  depots?.forEach(depot => (tabContents[depot.value] = tabRrender(depot, onPaginateChangeHandler)))
  return (
    <>
      {activeTab && headerRrender({ label: activeTab?.tab, value: activeTab?.key }, currentPage)}
      <Card
        tabList={tabList}
        activeTabKey={activeTab?.key}
        onTabChange={key => {
          activeDepotUtils.setActiveDepot(JSON.stringify(tabList?.find(tab => tab.key === key)))
          setActiveTab(tabList?.find(tab => tab.key === key))
          setCurrentPage(1)
        }}
        loading={depotsStatus === Status.PENDING || companyStatus === Status.PENDING}
        bodyStyle={{ padding: 0, backgroundColor: colors.GRAY }}
      >
        {activeTab && tabContents[activeTab.key]}
      </Card>
    </>
  )
}

export default DepotsTab

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminUserCollection,
  AdminUserService,
  AdminAuthService,
  AdminInviteUserRequest,
  UserRegistrationRequest,
  UserTokenResource,
  AdminUserResource,
  UserUpdateRequest,
} from 'api/main'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const usersListRequestAsync = createAsyncThunk<AdminUserCollection, GeneralListRequest, { rejectValue: string }>(
  'list/usersListRequestAsync',
  async ({ page, perPage, query }, { rejectWithValue }) => {
    try {
      return await AdminUserService.adminUser(perPage, page, query)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue, 'users list')
    }
  }
)

export const usersInviteRequestAsync = createAsyncThunk<void, AdminInviteUserRequest, { rejectValue: string }>(
  'invite/usersInviteRequestAsync',
  async (body, { rejectWithValue }) => {
    try {
      return await AdminAuthService.adminAuthInvite(body)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const userRegisterRequestAsync = createAsyncThunk<
  UserTokenResource,
  UserRegistrationRequest,
  { rejectValue: string }
>('register/userRegisterRequestAsync', async (body, { rejectWithValue }) => {
  try {
    return await AdminAuthService.adminAuthRegister(body)
  } catch (e: any) {
   return handleApiCallError(e, rejectWithValue)
  }
})

export const userUpdateRequestAsync = createAsyncThunk<
  AdminUserResource,
  { userId: number; requestBody: UserUpdateRequest },
  { rejectValue: string }
>('update/userUpdateRequestAsync', async ({ userId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminUserService.adminUserUserUpdate(userId, requestBody)
  } catch (e: any) {
   return handleApiCallError(e, rejectWithValue)
  }
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerCheckingService,
  AdminContainerCheckedService,
  CheckingAreaCollection,
  FromCheckingToCheckedRequestBody,
} from 'api/main'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const checkingListRequest = createAsyncThunk<
  CheckingAreaCollection,
  { depotId: number; list: GeneralListRequest },
  { rejectValue: string }
>('list/checkingsListRequestAsync', async ({ depotId, list: { perPage, page, query } }, { rejectWithValue }) => {
  try {
    return await AdminContainerCheckingService.adminDepotDepotIdCheckingList(depotId, perPage, page, query)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const moveToCheckedRequest = createAsyncThunk<
  void,
  {
    advanceInfoId: number
    requestBody?: FromCheckingToCheckedRequestBody
  },
  { rejectValue: string }
>('moveToChecked/moveTocheckedRequest', async ({ advanceInfoId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerCheckedService.adminAdvanceInfoAdvanceInfoIdCheckedPost(advanceInfoId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

import { useEffect, VoidFunctionComponent } from 'react'
import { Row, Col, Form, Modal } from 'antd'
import { SubmitHandler, useWatch } from 'react-hook-form'
import { editCheckedFormFields } from 'Components/Checkings/editCheckedFormFields'
import editCheckedSchema from 'schemas/editCheckedSchema'
import { FromCheckingToCheckedRequestBody } from 'api/main'
import useCDForm from 'hooks/useCDForm'

interface Props {
  initialValues: FromCheckingToCheckedRequestBody
  onSubmit: SubmitHandler<FromCheckingToCheckedRequestBody>
  onCancel: () => void
  loading?: boolean
  visible?: boolean
}

const MoveToCheckedModal: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, loading, initialValues }) => {

  const { control, getValues, setValue, handleSubmit, reset, renderInput } = useCDForm<FromCheckingToCheckedRequestBody>(
    editCheckedFormFields,
    editCheckedSchema
  )

  useEffect(() => {
    if (visible) {
      reset(initialValues)
    }
  }, [reset, visible, initialValues])

  const onCancelForm = () => {
    onCancel()
  }

  const condition = useWatch({
    control,
    name: 'checked_condition',
  })
  useEffect(() => {
    if (getValues().checked_condition === 'available') {
      setValue('checked_only_washing', false)
    }
  })
  return (
    <Modal
      visible={visible}
      title="Checking Info"
      okText="Add"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      confirmLoading={loading}
    >
      <Form name="move_to_checked1" layout="vertical" noValidate>
        <Row gutter={[16, 16]}>
          <Col span={12}>{renderInput('checked_condition', editCheckedFormFields.checked_condition?.options)}</Col>
          <Col span={8} style={{ alignSelf: 'end' }}>
            {renderInput('checked_only_washing', undefined, true, condition === 'available')}
          </Col>
        </Row>

        {renderInput('checked_grade', editCheckedFormFields.checked_grade?.options)}
        {renderInput('checked_note')}
      </Form>
    </Modal>
  )
}

export default MoveToCheckedModal

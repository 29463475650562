import { useState, VoidFunctionComponent } from 'react'
import { Table, Button, Space } from 'antd'
import { FileTextFilled } from '@ant-design/icons'
import { SubmitHandler } from 'react-hook-form'
import { MoveToNextStep } from 'store/app'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { moveToCheckedRequest, checkingListRequest, selectMoveToCheckedStatus } from 'store/checkings'
import type { CheckingAreaResource, FromCheckingToCheckedRequestBody } from 'api/main'
import { MappedOption, Status } from 'models'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import { ContainerGrades, ContainerConditions } from 'constants/ContainerCycleConsts'
import MoveToCheckedModal from './MoveToCheckedModal'
import CheckingInfo from './CheckingInfo'
import CDTable from 'Components/Shared/CDTable'
import { tableSelectors } from 'store/checkings'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}
//TODO add pagination

const CheckingsTab: VoidFunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }: Props) => {
  const [containerId, setContainerId] = useState(0)
  const [moveModalVisible, setMoveModalVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
    onPaginateChange = (page: number) => {
        dispatch(checkingListRequest({ depotId: Number(depot.value), list: { perPage: 20, page: page, query: '' } }))
        setCurrentPage(page)
    }
  const dispatch = useAppDispatch()
  const MoveToCheckedStatus = useAppSelector(selectMoveToCheckedStatus)
  const movingInitialValue: FromCheckingToCheckedRequestBody = {
    checked_condition: ContainerConditions[0].value.toString(),
    checked_only_washing: false,
    checked_grade: ContainerGrades[0].value.toString(),
    checked_note: '',
  }

  const onMove: SubmitHandler<FromCheckingToCheckedRequestBody> = async values => {
    const result = await dispatch(moveToCheckedRequest({ advanceInfoId: containerId, requestBody: values }))
    if (!(result as any)?.error) {
      setMoveModalVisible(false)
      dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.checkedArea].title))
      await dispatch(checkingListRequest({ depotId: Number(depot.value), list: { perPage: 20, page: currentPage, query: '' } }))
    }
  }

  const renderAction = (_value: any, item: CheckingAreaResource) => (
    <Space>
      <Button
        className='p-0'
        type="link"
        onClick={() => {
          item.id && setContainerId(item.id)
          setMoveModalVisible(true)
        }}
      >
        Move to Checked Area
      </Button>
      {item.arrival_note && <FileTextFilled style={{ color: '#FFC53D' }} />}
    </Space>
  )
  return (
    <>
      <CDTable<CheckingAreaResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        expandable={{
          expandedRowRender: (record: CheckingAreaResource) => {
            return <CheckingInfo initData={record} />
          },
          expandIconColumnIndex: 3,
        }}
        configName={containerLifeCyles.checkingArea}
        onPaginate={onPaginateChange}
      >
        <Table.Column title="Actions" key="action" render={renderAction} />
      </CDTable>
      <MoveToCheckedModal
        visible={moveModalVisible}
        onSubmit={onMove}
        onCancel={() => setMoveModalVisible(false)}
        initialValues={movingInitialValue}
        loading={MoveToCheckedStatus === Status.PENDING}
      />
    </>
  )
}

export default CheckingsTab

import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { DamagedFilterModel } from 'models/forms/DamagedFilterModel'
import { StockTypes, DamagedStatus, ContainerGrades, DamagedFilterStates } from 'constants/ContainerCycleConsts'

export const DamagedFilterFormFields: GeneralFormFieldType<DamagedFilterModel> = {
  stockType: {
    label: 'Stock Type',
    placeholder: 'Stock Type',
    type: InputType.RADIO,
    options: StockTypes,
  },
  grade: {
    label: 'Grade',
    placeholder: 'Grade',
    type: InputType.RADIO,
    options: ContainerGrades,
  },

  status: {
    label: 'Status',
    placeholder: 'Status',
    type: InputType.RADIO,
    options: DamagedStatus,
  },
  state: {
    label: 'State',
    placeholder: 'State',
    type: InputType.RADIO,
    options: DamagedFilterStates,
  },
  isoCode: {
    label: 'ISO Code',
    placeholder: 'ISO Code',
    type: InputType.SELECT,
  },
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerService,
  ContainerCollection,
  CotainerNumbersResponse,
} from 'api/main'
import { handleApiCallError } from 'store/helper'

export const allContainersListRequest = createAsyncThunk<
ContainerCollection,
  {
    depotId: number,
    perPage?: number,
    page?: number,
    query?: string,
  },
  { rejectValue: string }
>('list/allContainersListRequestAsync', async ({ depotId, perPage, page, query }, { rejectWithValue }) => {
  try {
    return await AdminContainerService.adminDepotDepotIdContainerList(depotId, perPage, page, query)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const containersListRequest = createAsyncThunk<
  CotainerNumbersResponse,
  {
    query: string,
  },
  { rejectValue: string }
>('list/containersListRequestAsync', async ({ query }, { rejectWithValue }) => {
  try {
    return await AdminContainerService.adminContainerNumbers(query)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

import { VoidFunctionComponent } from 'react'
import { Table } from 'antd'
import { DamageEstimateResource } from 'api/main'

interface Props {
  initialData: DamageEstimateResource[]
}

const DamgeRepairEstimateDetails: VoidFunctionComponent<Props> = ({ initialData }) => {
  return (
    <>
      <Table
        dataSource={initialData}
        rowKey={rc => rc.id || 0}
        locale={{ emptyText: 'No result' }}
        pagination={false}
      >
        <Table.Column title="Component" dataIndex="component" />
        <Table.Column title="Repair" dataIndex="repair" />
        <Table.Column title="Location" dataIndex="location" />
      </Table>
    </>
  )
}

export default DamgeRepairEstimateDetails

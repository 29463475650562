/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdvanceInfoCollection } from '../models/AdvanceInfoCollection';
import type { AdvanceInfoUpdateRequest } from '../models/AdvanceInfoUpdateRequest';
import type { AdvanceInfoWithDetailResource } from '../models/AdvanceInfoWithDetailResource';
import type { EdiImportAdvanceInfoRequest } from '../models/EdiImportAdvanceInfoRequest';
import type { ExcelImportAdvanceInfoRequest } from '../models/ExcelImportAdvanceInfoRequest';
import { request as __request } from '../core/request';

export class AdminContainerAdvanceInfoService {

    /**
     * Get Advance Info list
     * This end point will return all Advance Info information.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over Advance Info.
     * @returns AdvanceInfoCollection Advance Info collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdAdvanceInfoList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<AdvanceInfoCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/advance_info`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Get an advance Info
     * With this api you can get an existing advnace info.
     * @param advanceInfoId
     * @returns AdvanceInfoWithDetailResource Advance Info with detail resource response
     * @throws ApiError
     */
    public static async adminAdvanceInfoGet(
        advanceInfoId: number,
    ): Promise<AdvanceInfoWithDetailResource> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/advance_info/${advanceInfoId}`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * Delete Advance Info
     * With this end point you can delete an existing advnace info.
     * @param advanceInfoId
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoDelete(
        advanceInfoId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/admin/advance_info/${advanceInfoId}`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * Update Advance Info information
     * This end point will update information of the selected Advance Info.
     * @param advanceInfoId
     * @param requestBody Advance Info update request body
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoUpdate(
        advanceInfoId: number,
        requestBody?: AdvanceInfoUpdateRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/advance_info/${advanceInfoId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Import Advance Info via edi File
     * With this end point you can import an edi file to insert into advance info
     * @param depotId
     * @param requestBody Import advance Info from edi request body
     * @returns void
     * @throws ApiError
     */
    public static async adminDepotDepotIdAdvanceInfoImportEdiPost(
        depotId: number,
        requestBody?: EdiImportAdvanceInfoRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/depot/${depotId}/advance_info/import/edi`,
            body: requestBody,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * Import Advance Info
     * With this end point you can import an excel file to insert into advance info
     * @param depotId
     * @param requestBody Import advance Info from excel request body
     * @returns void
     * @throws ApiError
     */
    public static async adminDepotDepotIdAdvanceInfoImportExcelPost(
        depotId: number,
        requestBody?: ExcelImportAdvanceInfoRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/depot/${depotId}/advance_info/import/excel`,
            body: requestBody,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

}
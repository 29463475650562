import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerOutReferenceService,
  OutReferenceCollection,
  CreateOutReferenceRequestBody,
  OutReferenceUpdateRequestBody,
  ReleaseOutReferenceRequestBody,
} from 'api/main'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const outReferenceListRequest = createAsyncThunk<
  OutReferenceCollection,
  { 
    depotId: number,
    list: GeneralListRequest,
    creationFrom?: string,
    creationTo?: string,
    validFrom?: string,
    validTo?: string,
    expirationFrom?: string,
    expirationTo?: string,
    stockType?: string,
    grade?: string,
  },
  { rejectValue: string }
>('list/outReference', async ({ 
  depotId,
  list: { perPage, page, query },
  creationFrom,
  creationTo,
  validFrom,
  validTo,
  expirationFrom,
  expirationTo,
  stockType,
  grade, 
}, { rejectWithValue }) => {
  try {
    return await AdminContainerOutReferenceService.adminDepotDepotIdOutReferenceList(
      depotId,
      perPage,
      page,
      query,
      creationFrom,
      creationTo,
      validFrom,
      validTo,
      expirationFrom,
      expirationTo,
      stockType = stockType || undefined,
      grade = grade || undefined,
    )
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const outReferenceDeleteRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'delete/outReference',
  async (outReferenceId, { rejectWithValue }) => {
    try {
      return await AdminContainerOutReferenceService.adminOutReferenceOutReferenceDelete(outReferenceId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const outReferenceAddRequest = createAsyncThunk<CreateOutReferenceRequestBody, {
  depotId: number, requestBody: CreateOutReferenceRequestBody
}, { rejectValue: string }>(
  'add/outReference',
  async ({ depotId, requestBody }, { rejectWithValue }) => {
    try {
      return await AdminContainerOutReferenceService.adminDepotDepotIdOutReferencePost(depotId, requestBody)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const outReferenceUpdateRequest = createAsyncThunk<
  void,
  { outReferenceId: number; requestBody: OutReferenceUpdateRequestBody },
  { rejectValue: string }
>('update/outReference', async ({ outReferenceId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerOutReferenceService.adminOutReferenceUpdate(outReferenceId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const moveToReleaseRequest = createAsyncThunk<
  void,
  {
    outReferenceId: number
    requestBody?: ReleaseOutReferenceRequestBody
  },
  { rejectValue: string }
>('moveToRelease/outReference', async ({ outReferenceId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerOutReferenceService.adminOutReferenceOutReferenceReleasePost(outReferenceId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

import { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { selectmenuBadgeName, MoveToNextStep } from 'store/app'
import styled from 'styled-components'
import { Badge } from 'antd'
import {colors} from 'constants/colors'

interface Props {
  menuName: string
}
const MenuBadge = ({ menuName }: Props) => {
  const nexStepName = useAppSelector(selectmenuBadgeName)
  const [opacity, setOpacity] = useState(1)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (nexStepName === menuName) {
      setTimeout(() => {
        dispatch(MoveToNextStep(''))
        setOpacity(1)
      }, 5000)
      setTimeout(() => {
        setOpacity(0)
      },200)
    }
   
  }, [dispatch, menuName, nexStepName])
  return nexStepName === menuName ? (
    <StyledBadge
      count={2}
      overflowCount={1}
      style={{ backgroundColor: colors.GREEN, marginLeft: '10px' }}
      opacity={opacity}
    />
  ) : null
}

export default MenuBadge
interface BadgeProps {
  opacity: number
}
const StyledBadge = styled(Badge)<BadgeProps>`
  opacity: ${({ opacity }) => opacity};
  transition: opacity 5s ;
`

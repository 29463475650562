/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminChangePasswordRequest } from '../models/AdminChangePasswordRequest';
import type { AdminForgotPasswordRequest } from '../models/AdminForgotPasswordRequest';
import type { AdminInviteUserRequest } from '../models/AdminInviteUserRequest';
import type { AdminLoginRequest } from '../models/AdminLoginRequest';
import type { AdminProfileResource } from '../models/AdminProfileResource';
import type { AdminResetPasswordRequest } from '../models/AdminResetPasswordRequest';
import type { AdminResource } from '../models/AdminResource';
import type { AdminTokenResource } from '../models/AdminTokenResource';
import type { AdminUpdateProfileRequest } from '../models/AdminUpdateProfileRequest';
import type { UserRegistrationRequest } from '../models/UserRegistrationRequest';
import type { UserTokenResource } from '../models/UserTokenResource';
import { request as __request } from '../core/request';

export class AdminAuthService {

    /**
     * Change password
     * By calling this end point the authenticated user can change the password.
     * @param requestBody Admin change password request body
     * @returns void
     * @throws ApiError
     */
    public static async adminAuthPasswordChange(
        requestBody: AdminChangePasswordRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/auth/password/change`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Forgot password
     * Ask for reset password email.
     * @param requestBody Admin forgot password request body
     * @returns void
     * @throws ApiError
     */
    public static async adminAuthPasswordForgot(
        requestBody: AdminForgotPasswordRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/auth/password/forgot`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Retrieve current user information
     * This end point will return information of the currently authenticated user.
     * @returns AdminProfileResource Admin profile resource response
     * @throws ApiError
     */
    public static async adminAuthMe(): Promise<AdminProfileResource> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/auth/me`,
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Update admin info
     * By calling this end point the currently authenticated admin might update the information.
     * @param requestBody Update admin profile info request body
     * @returns AdminResource Admin user resource response
     * @throws ApiError
     */
    public static async adminAuthMeUpdate(
        requestBody: AdminUpdateProfileRequest,
    ): Promise<AdminResource> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/auth/me`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Invite an admin.
     * By calling this end point an invitation email will be sent.
     * @param requestBody Admin invite user request body
     * @returns void
     * @throws ApiError
     */
    public static async adminAuthInvite(
        requestBody: AdminInviteUserRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/auth/invite`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Login admin
     * this end point validates admin credentials and if it's ok issues a token.
     * @param requestBody Admin login request body
     * @returns AdminTokenResource Admin Token resource response
     * @throws ApiError
     */
    public static async adminAuthLogin(
        requestBody: AdminLoginRequest,
    ): Promise<AdminTokenResource> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/auth/login`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Logout admin
     * this end point will revoke current token.
     * @returns void
     * @throws ApiError
     */
    public static async adminAuthLogout(): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/admin/auth/logout`,
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Reset password endpoint
     * With reset password token to reset password the user should call this endpoint.
     * @param requestBody Admin reset password request body
     * @returns void
     * @throws ApiError
     */
    public static async adminAuthPasswordReset(
        requestBody: AdminResetPasswordRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/auth/password/reset`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Registeration endpoint
     * To register a user this endpoint should be called.
     * @param requestBody User registration request body
     * @returns UserTokenResource User Token resource response
     * @throws ApiError
     */
    public static async adminAuthRegister(
        requestBody: UserRegistrationRequest,
    ): Promise<UserTokenResource> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/auth/register`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
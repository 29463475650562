import { useEffect, VoidFunctionComponent } from 'react'
import { Row, Col, Form, Button } from 'antd'
import { useWatch, SubmitHandler } from 'react-hook-form'
import { editCheckedFormFields } from 'Components/Checkings/editCheckedFormFields'
import editCheckedSchema from 'schemas/editCheckedSchema'
import { CheckedAreaResource, CheckedAreaUpdateRequestBody } from 'api/main'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { checkedUpdateRequest, checkedListRequest, selectCheckedUpdateStatus } from 'store/checkeds'
import { Status, MappedOption, mapCheckedResReq } from 'models'
import useCDForm from 'hooks/useCDForm'

interface Props {
  initialValues: CheckedAreaResource
  depot: MappedOption
  currentPage: number
}

const EditCheckedModal: VoidFunctionComponent<Props> = ({ initialValues, depot, currentPage }) => {
  const { control, getValues, setValue, handleSubmit, reset, renderInput } = useCDForm<CheckedAreaUpdateRequestBody>(
    editCheckedFormFields,
    editCheckedSchema
  )

  const dispatch = useAppDispatch()
  const updateStatus = useAppSelector(selectCheckedUpdateStatus)
  const onSubmitForm: SubmitHandler<CheckedAreaUpdateRequestBody> = async values => {
    if (initialValues.id) {
      const result = await dispatch(checkedUpdateRequest({ checkedId: initialValues.id, requestBody: values }))
      if (!(result as any)?.error) {
        dispatch(checkedListRequest({ depotId: Number(depot.value), list: { perPage: 20, page: currentPage, query: '' } }))
      }
    }
  }

  useEffect(() => {
    reset(mapCheckedResReq(initialValues))
  }, [reset, initialValues])

  const condition = useWatch({
    control,
    name: 'checked_condition',
  })
  useEffect(() => {
    if (getValues().checked_condition === 'available') {
      setValue('checked_only_washing', false)
    }
  })
  return (
    <Form name="move_to_checked" layout="vertical" onFinish={handleSubmit(onSubmitForm)}>
      <Row gutter={[16, 16]}>
        <Col span={7}>{renderInput('checked_condition', editCheckedFormFields.checked_condition?.options)}</Col>
        <Col span={4}>{renderInput('checked_only_washing', undefined, true, condition === 'available')}</Col>
        <Col span={6}>{renderInput('checked_grade', editCheckedFormFields.checked_grade?.options)}</Col>
        <Col span={7}>{renderInput('checked_note')}</Col>
      </Row>
      <Form.Item className="text-right">
        <Button htmlType="submit" type="primary" loading={updateStatus === Status.PENDING}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default EditCheckedModal

import styled from 'styled-components'
import { colors } from '../../../../constants/colors'

interface Props {
  collapsed?: boolean
  defaultWidth: number
  collapsedWidth: number
}

const SidebarCollapseTrigger = styled.div<Props>`
  width: ${({ collapsed, collapsedWidth, defaultWidth }) => (collapsed ? collapsedWidth : defaultWidth)}px;
  border-top: 1px solid ${colors.LINK_WATER};
  font-size: ${({ collapsed }) => (collapsed ? 16 : 14)}px;
`

export default SidebarCollapseTrigger

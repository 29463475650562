import { VoidFunctionComponent } from 'react'
import { Radio, Form, Row, RadioChangeEvent, Button } from 'antd'
import { LanguageType, ActionType, FormValue } from "./OrderingTypes"

interface Props {
    setLanguage: (value: LanguageType) => void
    language?: LanguageType
    submitAction: (value: FormValue) => void
}

const ChooseAction: VoidFunctionComponent<Props> = ({ setLanguage, language, submitAction }) => {
    const onChangeLanguage = (event: RadioChangeEvent) => {
        setLanguage(event.target.value)
    }

    return (
        <Form
        name="choose_action"
        layout='vertical'
        onFinish={submitAction}
        >
            <Form.Item name="chooseLanguage" label="Choose a Language" colon={false}>
                <Radio.Group onChange={onChangeLanguage}>
                    <Radio value={LanguageType.SUOMI}>Suomi</Radio>
                    <Radio value={LanguageType.ENGLISH}>English</Radio>
                    <Radio value={LanguageType.РУССКИЙ}>Русский</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="chooseAction" label="Choose Action" colon={false} hidden={!language}>
                <Radio.Group>
                    <Row className='mb-3'>
                        <Radio value={ActionType.HELSINKI_DROP_OFF}>Container Drop Off - Helsinki</Radio>
                        <Radio value={ActionType.KOTKA_DROP_OFF}>Container Drop off - Kotka</Radio>
                    </Row>
                    <Row>
                        <Radio value={ActionType.HELSINKI_PICK_UP}>Container Pick-Up - Helsinki</Radio>
                        <Radio value={ActionType.KOTKA_PICK_UP}>Container Pick-Up - Kotka</Radio>
                    </Row>
                </Radio.Group>
            </Form.Item>
            <Form.Item hidden={!language}>
                <Button type="primary" htmlType="submit">
                    Continue
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ChooseAction

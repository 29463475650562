import { ListState, NormalState } from 'models'
import type { CheckingAreaResource } from 'api/main'
import { RootState } from '../index'

type CheckingsListState = ListState<CheckingAreaResource>
export const selectCheckingsListData = (state: RootState): CheckingsListState['data'] => state.checkings.list.data
export const selectCheckingsPaginationData = (state: RootState): CheckingsListState['paginationData'] =>
  state.checkings.list.paginationData

export const selectCheckingsListStatus = (state: RootState): CheckingsListState['status'] => state.checkings.list.status

export const selectMoveToCheckedStatus = (state: RootState): NormalState<void>['status'] =>
  state.checkings.moveToArrival.status

export const tableSelectors = {
  data: selectCheckingsListData,
  status: selectCheckingsListStatus,
  pagination: selectCheckingsPaginationData,
}

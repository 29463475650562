/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminRoleCollection } from '../models/AdminRoleCollection';
import { request as __request } from '../core/request';

export class AdminRoleService {

    /**
     * Get roles list
     * this end point will return all roles.
     * @returns AdminRoleCollection Admin role collection response
     * @throws ApiError
     */
    public static async adminRole(): Promise<AdminRoleCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/role`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

}
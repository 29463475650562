import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store'
import { BrowserRouter as Router, Router as HistoyRouter } from 'react-router-dom'
import Routes from './routes'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import apiConfig from './api/config'
import history from './utils/history'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

apiConfig()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <HistoyRouter history={history}>
          <Routes />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </HistoyRouter>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { useEffect, VoidFunctionComponent } from 'react'
import { Logo } from '../../Shared'
import { Alert, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import ResetPasswordForm from './ResetPasswordForm'
import { ResetPasswordFormData, Status } from '../../../models'
import { useQueryParam } from '../../../hooks'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectResetPassError, selectResetPassStatus } from '../../../store/auth/resetPassword'
import { resetPasswordRequestAsync } from '../../../store/auth/resetPassword/asyncActions'
import { AdminResetPasswordRequest } from '../../../api/main'

const { Title } = Typography

const ResetPasswordPage: VoidFunctionComponent = () => {
  const query = useQueryParam()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectResetPassStatus)
  const error = useAppSelector(selectResetPassError)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (status === Status.SUCCEEDED) {
      timeoutId = setTimeout(() => {
        history.replace('/login')
      }, 3000)
    }
    return () => clearTimeout(timeoutId)
  }, [history, status])

  const onSubmitReset = (values: ResetPasswordFormData) => {
    const token = query.get('token')
    if (token) {
      const body: AdminResetPasswordRequest = {
        password: values.password,
        token,
      }
      dispatch(resetPasswordRequestAsync(body))
    }
  }

  return (
    <>
      {error && <Alert className="mb-6" message={error} type="error" showIcon closable />}
      {status === Status.SUCCEEDED && (
        <Alert
          className="mb-6"
          message="Your password has been successfully changed! You are being redirected to the login page..."
        />
      )}
      <Logo isResponsive />
      <Title level={4} className="mv-6 mv-xs-3 text-xs-center">
        Reset Password
      </Title>
      <ResetPasswordForm onSubmit={onSubmitReset} isSubmitting={status === Status.PENDING} />
    </>
  )
}

export default ResetPasswordPage

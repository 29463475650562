/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminUserCollection } from '../models/AdminUserCollection';
import type { AdminUserResource } from '../models/AdminUserResource';
import type { UserUpdateRequest } from '../models/UserUpdateRequest';
import { request as __request } from '../core/request';

export class AdminUserService {

    /**
     * Retrieve user information
     * This end point will return information of the selected user.
     * @param userId
     * @returns AdminUserResource Admin user resource response
     * @throws ApiError
     */
    public static async adminUserUser(
        userId: number,
    ): Promise<AdminUserResource> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/user/${userId}`,
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * Update user information
     * This end point will update information of the selected user.
     * @param userId
     * @param requestBody User update request body
     * @returns AdminUserResource Admin user resource response
     * @throws ApiError
     */
    public static async adminUserUserUpdate(
        userId: number,
        requestBody: UserUpdateRequest,
    ): Promise<AdminUserResource> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/user/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Get users list
     * this end point will return all users information.
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over users.
     * @returns AdminUserCollection Admin user collection response
     * @throws ApiError
     */
    public static async adminUser(
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<AdminUserCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/user`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

}
import * as yup from 'yup'
import { IsoCodeFormFields } from '../Components/ISOCodes/CreateISOCode/IsoCodeFormFields'
import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'
import { IsoCodeRequest } from 'api/main/models/IsoCodeRequest'

const createIsoCodeSchema = yup.object().shape<GeneralSchemaType<IsoCodeRequest>>({
  code: yup.string().required(`'${IsoCodeFormFields.code.label}' is a required field`),
  height: yup
    .number()
    .typeError(`'${IsoCodeFormFields.height.label}' must be a number`)
    .required(`'${IsoCodeFormFields.height.label}' is a required field`),
  width: yup
    .number()
    .typeError(`'${IsoCodeFormFields.width.label}' must be a number`)
    .required(`'${IsoCodeFormFields.width.label}' is a required field`),
  length: yup
    .number()
    .typeError(`'${IsoCodeFormFields.length.label}' must be a number`)
    .required(`'${IsoCodeFormFields.length.label}' is a required field`),
  teu: yup
    .number()
    .typeError(`'${IsoCodeFormFields.teu.label}' must be a number`)
    .required(`'${IsoCodeFormFields.teu.label}' is a required field`),
  max_gross_weight: yup
    .number()
    .typeError(`'${IsoCodeFormFields.max_gross_weight.label}' must be a number`)
    .required(`'${IsoCodeFormFields.max_gross_weight.label}' is a required field`),
  container_type_id: yup.number().required(`'${IsoCodeFormFields.container_type_id.label}' is a required field`),
  container_material_id: yup
    .number()
    .required(`'${IsoCodeFormFields.container_material_id.label}' is a required field`),
})

export default createIsoCodeSchema

import { VoidFunctionComponent, useEffect, useState } from 'react'
import { Form, Button, Row, Col, Select, DatePicker } from 'antd'
import moment from 'moment'
import JsFileDownloader from 'js-file-downloader'
import useFetchDepotsOnce from 'hooks/useFetchDepotsOnce'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectDepotsListStatus } from 'store/depots'
import { companiesListRequestAsync, selectCompaniesListMappedData, selectCompaniesListStatus } from 'store/companies'
import { Status } from 'models'
import { tokenUtils } from 'utils'
import ResultModal from 'Components/Reports/ResultModal'
import { OpenAPI } from 'api/main'

interface ValueTypes {
    depotId: number
    companyId: number
    date: string
}

const DailyReport: VoidFunctionComponent = () => {
    const dispatch = useAppDispatch()
    const [depotData] = useFetchDepotsOnce()
    const depotStatus = useAppSelector(selectDepotsListStatus)
    const companiesData = useAppSelector(selectCompaniesListMappedData)
    const companyStatus = useAppSelector(selectCompaniesListStatus)

    const [requestStatus, setRequestStatus] = useState<Status>(Status.IDLE)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [values, setValues] = useState<ValueTypes>({ companyId: 1, depotId: 1, date: '' })

    useEffect(() => {
        // get companies if not get before
        if (companyStatus !== Status.SUCCEEDED && companyStatus !== Status.PENDING) {
          dispatch(companiesListRequestAsync({ perPage: 100, page: 1, query: '' }))
        }
      }, [dispatch, companyStatus])

      const currentDate = (): string => moment().format('MM-DD-YYYY')

      const handleChange = (value: number | string, key:string): void => {
        let newValues: any = { ...values }
        newValues[key] = value
        setValues(newValues)
      }

      const download = new JsFileDownloader({ 
        url: `${OpenAPI.BASE}/api/admin/report/daily/company/${values?.companyId}?depot_id=${values?.depotId}&report_date=${values?.date}`,
        autoStart: false,
        headers: [
            { name: 'authorization', value: `Bearer ${tokenUtils.getToken()}` || '' }
          ],
        filename: `dailyReport-${currentDate()}.xlsx`,
        nativeFallbackOnError: true
      })

    const onFinish = async () => {
        setRequestStatus(Status.PENDING)
        setIsModalVisible(true)
        download.start()
            .then(function(){
                setRequestStatus(Status.SUCCEEDED)
            })
            .catch((error) => {
                setRequestStatus(Status.FAILED)
            })
    }

    function disabledDate(current: any) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }

return (
    <>
        <Form
            name="dailyReport"
            onFinish={onFinish}
            layout="vertical"
        >
            <Row gutter={[24, 8]}>
                <Col span={7}>
                    <Form.Item
                        label="Depot"
                        name="depotId"
                        rules={[{ required: true, message: 'Please selet a depot!' }]}
                    >
                        <Select
                            placeholder="Select a Depot"
                            loading={depotStatus === Status.PENDING}
                            onChange={(value: number) => handleChange(value, 'depotId')}
                        >
                            {depotData?.map((item: any) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        label="Company"
                        name="companyId"
                        rules={[{ required: true, message: 'Please select a company!' }]}
                    >
                        <Select
                            placeholder="Select a Company"
                            options={companiesData}
                            loading={companyStatus === Status.PENDING}
                            onChange={(value: number) => handleChange(value, 'company')}
                        />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[{ required: true, message: 'Please select a date!' }]}
                    >
                        <DatePicker
                            placeholder="Select a Date"
                            style={{ width: '100%' }}
                            onChange={(_: any, dateString: string) => handleChange(dateString, 'date')}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify='end'>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Generate Report
                    </Button>
                </Form.Item>
            </Row>
        </Form>
        <ResultModal
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            requestStatus={requestStatus}
            title='Daily Report'
            tryAgain={onFinish}
        />
    </>
  )
}

export default DailyReport

import type { DamageEstimateResource } from 'api/main'
import DropSvg from 'assets/images/drop-silhouette.svg'
import Icon, { ToolFilled, CloseCircleFilled } from '@ant-design/icons'

import styled from 'styled-components'

const DropIcon = (props: any) => <Icon component={() => <img alt="dropIcon" src={DropSvg} />} {...props} />

interface Props {
  record: DamageEstimateResource
}

const WashColumn = ({ record }: Props) => (
  <RepairContainer>
    {record.repair}
    <IconWrapper>{mapTextIcon(record.icon)}</IconWrapper>
  </RepairContainer>
)

const mapTextIcon = (icon: string | undefined): any => {
  switch (icon) {
    case 'repairing_icon':
      return <ToolFilled />
    case 'washing_icon':
      return <DropIcon />
    default:
      return <CloseCircleFilled />
  }
}

export default WashColumn
const RepairContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
`
const IconWrapper = styled.span`
  margin-left: 1px;
`

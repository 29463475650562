const searchedParamKey = 'searchedParam'

function getSearchedParam(): { pathname: string; searched: string } {
  return JSON.parse(localStorage.getItem(searchedParamKey) || '{}')
}

function setSearchedParam(searchedParams: { pathname: string; searched: string }) {
  localStorage.setItem(searchedParamKey, JSON.stringify(searchedParams))
}

function removeSearchedParam() {
  localStorage.removeItem(searchedParamKey)
}

const searchedParamUtils = {
  getSearchedParam,
  setSearchedParam,
  removeSearchedParam,
}

export default searchedParamUtils

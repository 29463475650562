import { ListState } from 'models'
import type { AvailableAreaResource } from 'api/main'
import { RootState } from '../index'

type availableListState = ListState<AvailableAreaResource>
export const selectAvailableListData = (state: RootState): availableListState['data'] => state.availables.list.data
export const selectAvailablePaginationData = (state: RootState): availableListState['paginationData'] =>
  state.availables.list.paginationData
export const selectAvailableListStatus = (state: RootState): availableListState['status'] => state.availables.list.status

export const tableSelectors = {
  data: selectAvailableListData,
  pagination: selectAvailablePaginationData,
  status: selectAvailableListStatus,
}

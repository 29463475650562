import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { RepairingFilterModel } from 'models/forms/RepairingFilterModel'
import { RepairingStatus } from 'constants/ContainerCycleConsts'

export const RepairingFilterFormFields: GeneralFormFieldType<RepairingFilterModel> = {
  status: {
    label: 'Status',
    placeholder: 'Status',
    type: InputType.RADIO,
    options: RepairingStatus,
  },
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArrivalCollection } from '../models/ArrivalCollection';
import type { ArrivalUpdateRequest } from '../models/ArrivalUpdateRequest';
import type { FromAdvanceInfoToArrivalRequest } from '../models/FromAdvanceInfoToArrivalRequest';
import { request as __request } from '../core/request';

export class AdminContainerArrivalService {

    /**
     * Get Arrival list
     * This end point will return all Arivals information.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over Arrivals.
     * @returns ArrivalCollection Arrival collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdArrivalList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<ArrivalCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/arrival`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * From advance info to arrival state
     * With this end point you can move an advanceInfo from advnace info state to arrival.
     * @param advanceInfoId
     * @param requestBody From advance info to Arrival
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoAdvanceInfoIdArrivalPost(
        advanceInfoId: number,
        requestBody?: FromAdvanceInfoToArrivalRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/advance_info/${advanceInfoId}/arrival`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Update Arrival information
     * This end point will update information of the selected Arrival.
     * @param advanceInfoId
     * @param requestBody Arrival update request body
     * @returns void
     * @throws ApiError
     */
    public static async adminArrivalUpdate(
        advanceInfoId: number,
        requestBody?: ArrivalUpdateRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/advance_info/${advanceInfoId}/arrival`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
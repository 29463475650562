import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { CreateOutReferenceContainerInfoBodyProperty, CreateOutReferenceRequestBody } from 'api/main'

export const AddOutReferenceFormFields: GeneralFormFieldType<CreateOutReferenceRequestBody & CreateOutReferenceContainerInfoBodyProperty> = {
  reference: {
    label: 'Reference ID',
    placeholder: 'Reference ID',
  },
  stock_type_id: {
    label: 'Stock Type',
    placeholder: 'Stock Type',
    type: InputType.SELECT,
  },
  company_id: {
    label: 'company',
    placeholder: 'company',
    type: InputType.SELECT,
  },
  grade: {
    label: 'Grade',
    placeholder: 'Grade',
    type: InputType.SELECT,
  },
  lift_out_paid_by_company_id: {
    label: 'Lift Out Paid By',
    placeholder: 'Lift Out Paid By',
    type: InputType.SELECT,
  },
  lift_out_invoice_type: {
    label: 'No Label',
    placeholder: 'invoice',
    type: InputType.SELECT,
  },
  HPL: {
    label: 'HPL',
    placeholder: 'HPL',
    type: InputType.RADIO,
  },
  note: {
    label: 'Note',
    placeholder: 'Example text',
    type: InputType.TEXTAREA,
  },
  valid_from: {
    label: 'Valid From',
    placeholder: 'Select Date',
    type: InputType.DATE,
  },
  expiration_date: {
    label: 'Expiry Date',
    placeholder: 'Select Date',
    type: InputType.DATE,
  },
  lessee: {
    label: 'Lessee',
    placeholder: 'Lessee',
  },
  size: {
    label: 'size',
    placeholder: 'size',
    type: InputType.SELECT,
  },
  type_id: {
    label: 'type',
    placeholder: 'type',
    type: InputType.SELECT,
  },
  total_count: {
    label: 'total',
    placeholder: 'total',
    type: InputType.SELECT,
  }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IsoCodeCollection } from '../models/IsoCodeCollection';
import type { IsoCodeRequest } from '../models/IsoCodeRequest';
import type { IsoCodeResource } from '../models/IsoCodeResource';
import type { IsoCodeUpdateRequest } from '../models/IsoCodeUpdateRequest';
import { request as __request } from '../core/request';

export class AdminIsoCodeService {

    /**
     * Get ISO Code list
     * This end point will return all ISO Codes information.
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over ISO Codes.
     * @returns IsoCodeCollection ISO Code collection response
     * @throws ApiError
     */
    public static async adminIsoCodeList(
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<IsoCodeCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/iso_code`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Create a new ISO Code
     * With this end point you can create a new ISO Code.
     * @param requestBody ISO Code request body
     * @returns IsoCodeResource ISO Code resource response
     * @throws ApiError
     */
    public static async adminIsoCodeCreate(
        requestBody: IsoCodeRequest,
    ): Promise<IsoCodeResource> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/iso_code`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Retrieve ISO Code information
     * This end point will return information of the selected ISO Code.
     * @param isoCode
     * @returns IsoCodeResource ISO Code resource response
     * @throws ApiError
     */
    public static async adminIsoCodeShow(
        isoCode: number,
    ): Promise<IsoCodeResource> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/iso_code/${isoCode}`,
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * Update ISO Code information
     * This end point will update information of the selected ISO Code.
     * @param isoCode
     * @param requestBody ISO Code update request body
     * @returns IsoCodeResource ISO Code resource response
     * @throws ApiError
     */
    public static async adminIsoCodeUpdate(
        isoCode: number,
        requestBody: IsoCodeUpdateRequest,
    ): Promise<IsoCodeResource> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/iso_code/${isoCode}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal } from 'antd'
import { SubmitHandler } from 'react-hook-form'
import { EditArrivalFormFields } from 'Components/Arrivals/EditArrivalformFields'
import editArrivalSchema from 'schemas/editArrivalsSchema'
import { FromAdvanceInfoToArrivalRequest, ArrivalUpdateRequest } from 'api/main'
import useCDForm from 'hooks/useCDForm'

interface Props {
  onSubmit: SubmitHandler<FromAdvanceInfoToArrivalRequest>
  onCancel: () => void
  loading?: boolean
  visible?: boolean
}

const MoveToArrivalModal: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, loading }) => {
  const { handleSubmit, reset, renderInput } = useCDForm<ArrivalUpdateRequest>(EditArrivalFormFields, editArrivalSchema)

  useEffect(() => {
    if (visible) {
      reset()
    }
  }, [reset, visible])

  const onCancelForm = () => {
    onCancel()
  }

  return (
    <Modal
      visible={visible}
      title="Arrivals Info"
      okText="Add"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      confirmLoading={loading}
    >
      <Form name="move_to_arrival" layout="vertical" >
        {Object.keys(EditArrivalFormFields).map((entry: string) => {
          return <span key={entry}>{renderInput(entry as keyof ArrivalUpdateRequest)}</span>
        })}
      </Form>
    </Modal>
  )
}

export default MoveToArrivalModal

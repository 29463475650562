import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal } from 'antd'
import Input from '../UiKit/Inputs/Input'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import createCompanySchema from '../../schemas/createCompanySchema'
import { CompanyRequest, CompanyResource } from '../../api/main'

interface Props {
  onSubmit: (values: CompanyRequest, id?: number) => void
  onCancel: () => void
  loading?: boolean
  visible?: boolean
  initialData?: CompanyResource
}

const CompanyFormModal: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, loading, initialData }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<CompanyRequest>({
    resolver: yupResolver(createCompanySchema),
  })

  useEffect(() => {
    if (visible) {
      reset(initialData || {})
    }
  }, [reset, visible, initialData])

  const onCancelForm = () => {
    onCancel()
  }

  const onSubmitForm: SubmitHandler<CompanyRequest> = values => {
    onSubmit(values, initialData?.id)
  }

  return (
    <Modal
      visible={visible}
      title={initialData ? 'Update a company' : 'Create a company'}
      okText={initialData ? 'Update' : 'Create'}
      onOk={handleSubmit(onSubmitForm)}
      onCancel={onCancelForm}
      confirmLoading={loading}
    >
      <Form name="company" layout="vertical" noValidate>
        <Input
          label="Company name"
          errorClassName="ant-form-item-has-error"
          control={control}
          name="name"
          placeholder="Company name"
          error={errors.name?.message}
        />
        <Input
          label="Customer number"
          errorClassName="ant-form-item-has-error"
          control={control}
          name="customer_number"
          placeholder="Customer number"
          error={errors.customer_number?.message}
        />
      </Form>
    </Modal>
  )
}

export default CompanyFormModal

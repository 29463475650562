import { combineReducers } from '@reduxjs/toolkit'
import { listSliceBuilder, createSliceBuilder } from 'store/helper'
import type { AdvanceInfoResource, AdvanceInfoWithDetailResource } from 'api/main'
import {
  advInfoListRequest,
  advInfoImportRequest,
  advInfoDeleteRequest,
  advInfoGetRequest,
  advInfoUpdateRequest,
  moveToArrivalRequest
} from 'store/advInfo'

export const advInfoReducers = combineReducers({
  list: listSliceBuilder<AdvanceInfoResource>('listAdvInfo', advInfoListRequest).reducer,
  import: createSliceBuilder<void>('importAdvInfo', advInfoImportRequest).reducer,
  delete: createSliceBuilder<void>('deleteAdvInfo', advInfoDeleteRequest).reducer,
  get: createSliceBuilder<AdvanceInfoWithDetailResource>('getAdvInfo', advInfoGetRequest).reducer,
  update: createSliceBuilder<void>('updateAdvInfo', advInfoUpdateRequest).reducer,
  moveToArrival : createSliceBuilder<void>('moveToArrival', moveToArrivalRequest).reducer,
})

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableAreaCollection } from '../models/AvailableAreaCollection';
import { request as __request } from '../core/request';

export class AdminContainerAvailableService {

    /**
     * Get Available Area list
     * This end point will return all Available Area information.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over Available Area.
     * @returns AvailableAreaCollection Available area collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdAvailableList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<AvailableAreaCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/available`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * From arrival to available state
     * With this end point you can move a container to arival state.
     * @param advanceInfoId
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoAdvanceInfoIdAvailablePost(
        advanceInfoId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/advance_info/${advanceInfoId}/available`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

}
import { combineReducers } from '@reduxjs/toolkit'
import { listSliceBuilder, createSliceBuilder } from 'store/helper'
import type { DamagedAreaResource, DamageEstimateResource } from 'api/main'
import {
  damagedListRequest,
  RepairDetailsListRequest,
  createEstimationRequest,
  deleteEstimationRequest,
  updateEstimationRequest,
  setStatusRequest
} from 'store/damageds'

export const damagedReducers = combineReducers({
  list: listSliceBuilder<DamagedAreaResource>('listDamageds', damagedListRequest).reducer,
  repairDetailsList: createSliceBuilder<Array<DamageEstimateResource>>('repairDetailList', RepairDetailsListRequest)
    .reducer,
  createEstimation: createSliceBuilder<void>('createEstimation', createEstimationRequest).reducer,
  deleteEstimation: createSliceBuilder<void>('deleteEstimation', deleteEstimationRequest).reducer,
  updateEstimation: createSliceBuilder<void>('updateEstimation',updateEstimationRequest).reducer,
  setStatus: createSliceBuilder<void>('setStatus', setStatusRequest).reducer,
})

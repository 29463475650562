import { useEffect } from 'react'
import { PageHeader } from 'antd'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { outQueueListRequest } from 'store/outQueue'
import { MappedOption } from 'models'
import HeaderExtra from 'Components/Shared/PageHeader/HeaderExtra'
import searchedParamUtils from 'utils/searchedParam'

type Props = {
  depot: MappedOption
  page: number
}
const OutQueueHeader = ({ depot, page }: Props) => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(outQueueListRequest({
      depotId: Number(depot.value),
      list: { perPage: 20, page, query: searchedParamUtils.getSearchedParam().searched || '' },
    }))
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  }, [
    dispatch,
    depot.value,
    page,
  ])

  return (
    <>
      <PageHeader
        ghost={false}
        title="Out Queue"
        extra={
          <HeaderExtra
          defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : ''}
            searchPlaceholder="Search by container number, customer or Queue"
            onSearch={(value: string) => {
              searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
              dispatch(
                outQueueListRequest({
                  depotId: Number(depot.value),
                  list: { perPage: 20, page: 1, query: value },
                })
              )
            }}
          />
        }
      />
    </>
  )
}

export default OutQueueHeader

import { lazy, Suspense, useEffect, VoidFunctionComponent } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { paths as authPaths } from './Auth'
import { paths as mainPaths } from './Main'
import { LoginGuardRoute } from './Auth/guard'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { MainGuardRoute } from './Main/guard'
import { tokenUtils } from '../utils'
import { setStatus } from '../store/auth/login'
import { Status } from '../models'
import YesNoModal from 'Components/UiKit/YesNoModal/YesNoModal'
import { selectConfirmModalData, hideConfirmModal } from 'store/app'
import Ordering from "../Components/Ordering";

const AuthRoutes = lazy(() => import('./Auth'))
const MainRoutes = lazy(() => import('./Main'))

const Routes: VoidFunctionComponent = () => {
  const dispatch = useAppDispatch()
  const confirmModal = useAppSelector(selectConfirmModalData)
  useEffect(() => {
    const token = tokenUtils.getToken()
    if (token) {
      dispatch(setStatus(Status.SUCCEEDED))
    }
  }, [dispatch])

  return (
    <>
      <Switch>
        <Suspense fallback={<div>Loading...</div>}>
          <Route exact path="/">
            <Redirect to={{ pathname: '/home' }} />
          </Route>
          <Route exact path="/truck-ordering">
            <Ordering />
          </Route>
          <MainGuardRoute path={mainPaths}>
            <MainRoutes />
          </MainGuardRoute>
          <LoginGuardRoute path={authPaths}>
            <AuthRoutes />
          </LoginGuardRoute>
        </Suspense>
      </Switch>
      <YesNoModal
        message={confirmModal.message}
        visible={confirmModal.visible}
        onNo={() => {
          dispatch(hideConfirmModal({ onNo: confirmModal.onNo }))
        }}
        onYes={async ()=>{
          if(confirmModal.onYes){
            await confirmModal.onYes()
          }
          dispatch(hideConfirmModal({ onNo: confirmModal.onNo }))
        }}
      />
    </>
  )
}

export default Routes

import { useEffect, VoidFunctionComponent } from 'react'
import { Avatar, Dropdown, Row, Spin } from 'antd'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import AccountMenu from './AccountMenu'
import { adminAboutMeRequest } from 'store/auth/actions'
import { useAppDispatch } from 'store/hooks'

interface Props {
  name: string
  onLogout: () => void
  isLoadingn: boolean
}

const HeaderAccount: VoidFunctionComponent<Props> = ({ name, onLogout, isLoadingn }) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(adminAboutMeRequest())
  },[dispatch])

  return (
    <Row justify="center" align="middle">
      <Avatar icon={<UserOutlined />} />
      <Dropdown arrow overlay={<AccountMenu onLogout={onLogout} />} className="ml-2" trigger={['click']}>
        <div role="button" className="pointer white ant-dropdown-link">
          {isLoadingn ? <Spin /> : name || 'name' }
          <DownOutlined className="ml-3" />
        </div>
      </Dropdown>
    </Row>
  )
}

export default HeaderAccount

import { VoidFunctionComponent, useState } from 'react'
import { Col, Row, Card, Layout } from 'antd'
import ContainerImage from 'assets/images/ordering-background.jpg'
import { colors } from 'constants/colors'
import { Header as StyledHeader } from 'Components/UiKit/Layout'
import { Logo } from 'Components/Shared'
import ChooseAction from "Components/Ordering/ChooseAction"
import PickUp from "Components/Ordering/PickUp"
import DropOff from "Components/Ordering/DropOff"
import SuccessRequest from "Components/Ordering/SuccessRequest"
import FailedRequest from "Components/Ordering/FailedRequest"
import { LanguageType, FormValue, ActionType, DepotType, LastAction } from "Components/Ordering/OrderingTypes"

const Ordering: VoidFunctionComponent = () => {
    const [language, setLanguage] = useState<LanguageType>()
    const [isVisiblePickUp, setVisiblePickUp] = useState<boolean>()
    const [isVisibleDropOff, setVisibleDropOff] = useState<boolean>()
    const [selectedDepot, setDepot] = useState<DepotType>(DepotType.HELSINKI)
    const [isFaild, setVisibleFaild] = useState<boolean>(false)
    const [isSuccess, setVisibleSuccess] = useState<boolean>(false)
    const [lastAction, saveLastAction] = useState<LastAction>()

    const submitAction =(values: FormValue) => {
        switch (values.chooseAction) {
            case ActionType.HELSINKI_DROP_OFF:
                setDepot(DepotType.HELSINKI)
                setVisibleDropOff(true)
                saveLastAction(LastAction.DROP_OFF)
                break
            case ActionType.KOTKA_DROP_OFF:
                setDepot(DepotType.KOTKA)
                setVisibleDropOff(true)
                saveLastAction(LastAction.DROP_OFF)
                break
            case ActionType.HELSINKI_PICK_UP:
                setDepot(DepotType.HELSINKI)
                setVisiblePickUp(true)
                saveLastAction(LastAction.PICK_UP)
                break
            case ActionType.KOTKA_PICK_UP:
                setDepot(DepotType.KOTKA)
                setVisiblePickUp(true)
                saveLastAction(LastAction.PICK_UP)
                break
            default:
                break
        }
    }

    const failed = (lastAction: LastAction): void =>{
        setVisiblePickUp(false)
        setVisibleDropOff(false)
        setVisibleFaild(true)
        saveLastAction(lastAction)
    }

    const success = (): void =>{
        setVisiblePickUp(false)
        setVisibleDropOff(false)
        setVisibleSuccess(true)
    }

    const goHome = (): void => {
        setVisibleFaild(false)
        setVisibleSuccess(false)
        setVisiblePickUp(false)
        setVisibleDropOff(false)
    }

    const tryAgain = (): void => {
        if (lastAction === LastAction.DROP_OFF) {
            setVisibleDropOff(true)
        } else {
            setVisiblePickUp(true)
        }
        setVisibleFaild(false)
    }

    return (
        <Layout>
            <StyledHeader>
                <Row justify="space-between" align="middle">
                    <Logo color={colors.WHITE} />
                </Row>
            </StyledHeader>
            <Row  justify="center" className="h-100" style={{ backgroundImage: `url(${ContainerImage})` }}>
                <Col span={isVisibleDropOff || isVisiblePickUp ? 16 : 10} className='mt-7'>
                    <Card bordered={false}>
                        {
                            !isVisibleDropOff && !isVisiblePickUp && !isFaild && !isSuccess ? (
                                <ChooseAction
                                    setLanguage={setLanguage}
                                    language={language}
                                    submitAction={submitAction}
                                />
                            ) : null
                        }
                        {
                            isVisibleDropOff && (
                                <DropOff
                                    selectedDepot={selectedDepot}
                                    failed={failed}
                                    success={success}
                                />
                            )
                        }
                        {
                            isVisiblePickUp && (
                                <PickUp
                                    selectedDepot={selectedDepot}
                                    success={success}
                                    failed={failed}
                                    depotId= {selectedDepot === DepotType.KOTKA ? 2 : 1}
                                />
                            )
                        }
                        {
                            isSuccess && (
                                <SuccessRequest
                                    selectedDepot= {selectedDepot}
                                    goHome={goHome}
                                    lastAction={lastAction}
                                />
                            )
                        }
                        {
                            isFaild && (
                                <FailedRequest
                                    goHome={goHome}
                                    tryAgain={tryAgain}
                                />
                            )
                        }
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default Ordering

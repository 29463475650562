/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppRegistrationRequest = {
    first_name: string;
    last_name: string;
    email: string;
    language: AppRegistrationRequest.language;
    password: string;
}

export namespace AppRegistrationRequest {

    export enum language {
        EN = 'en',
        FI = 'fi',
    }


}

import {useState, VoidFunctionComponent} from 'react'
import { Table, Button, Space } from 'antd'
import type { WashingAreaResource } from 'api/main'
import { MappedOption } from 'models'
import { useAppDispatch } from 'store/hooks'
import { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import CDTable from 'Components/Shared/CDTable'
import { tableSelectors } from 'store/washings'
import ColumnWithDots from 'Components/Shared/ColumnWithDot'
import { colors } from 'constants/colors'
import { gradeTextMap } from 'Components/Shared/LifeCyclePageFactory/helper'
import WashingsDetails from 'Components/Washings/WashingsDetails'
import { showConfirmModal, MoveToNextStep } from 'store/app'
import lifeCyclesConfig from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import { washingListRequest, moveToWashedRequest } from 'store/washings'
import { moveToAvailableRequest } from 'store/availables'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}

const WashingsTab: VoidFunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }: Props) => {
  const dispatch = useAppDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    onPaginateChange = (page: number) => {
        dispatch(
            washingListRequest({
                depotId: Number(depot.value),
                perPage: 20,
                page: page,
                query: '',
            })
        )
        setCurrentPage(page)
    }
  const washingRequest = () => {
    dispatch(
      washingListRequest({
        depotId: Number(depot.value),
        perPage: 20,
        page: currentPage,
        query: '',
      })
    )
  }

  const moveToWashed = (item: WashingAreaResource) => {
    const onYesCallback = async () => {
      if (!item.id) {
        return
      }
      const result = await dispatch(moveToWashedRequest(item.id))
      if (!(result as any).error) {
        // TODO : wher continer should go after wash is done (for animation)
        washingRequest()
      }
    }
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to mark Container ${item.container_number} as washed?`,
        onYes: onYesCallback
        // confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const moveToAvailable = (item: WashingAreaResource) => {
    const onYesCallback = async () => {
      if (!item.id) {
        return
      }
      const result = await dispatch(moveToAvailableRequest(item.id))
      if (!(result as any).error) {
        // TODO : wher continer should go after wash is done (for animation)
        dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.availableArea].title))
        washingRequest()
      }
    }
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to move Container ${item.container_number} to Available Area?`,
        onYes: onYesCallback,
        // confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const renderAction = (_value: any, item: WashingAreaResource) => (
    <Space>
      <Button
        className='p-0'
        type="link"
        onClick={() => {
          // eslint-disable-next-line sonarjs/no-small-switch
          switch (item.action) {
            case 'washing_is_done':
              moveToWashed(item)
              break
            case 'move_to_available':
              moveToAvailable(item)
              break
            default:
              break
          }
        }}
      >
        {mapActionText(item)}
      </Button>
    </Space>
  )
  return (
    <>
      <CDTable<WashingAreaResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        configName={containerLifeCyles.washingArea}
        onPaginate={onPaginateChange}
        expandable={{
          expandedRowRender: (record: WashingAreaResource) => {
            return (
              record.damage_estimates && (
                <div style={{ margin: 0 }}>
                  <WashingsDetails initialData={record.damage_estimates} />
                </div>
              )
            )
          },
          // rowExpandable: (record: DamagedAreaResource) => {
          //   if (record.damage_estimates && record.damage_estimates.length > 0) {
          //     return true
          //   }
          //   return false
          // },
          expandIconColumnIndex: 7,
        }}
      >
        <Table.Column
          title="Grade"
          dataIndex="grade"
          render={(text: string, record: WashingAreaResource) => (
            <p style={{ marginBottom: 0 }}>{gradeTextMap(text)}</p>
          )}
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          render={(text: string, record: WashingAreaResource) => (
            <ColumnWithDots<WashingAreaResource> record={record} mapper={mapTextAndColor} />
          )}
        />
        <Table.Column title="Actions" key="action" render={renderAction} />
      </CDTable>
    </>
  )
}

export default WashingsTab

const mapTextAndColor = (record: WashingAreaResource): [string, string] => {
  switch (record.status) {
    case 'washing_area_washed':
      return ['Wash Done', colors.GREEN]
    case 'washing_area':
      return ['In Washing', colors.YELLOW_WARNING]
    default:
      return ['In Washing', colors.YELLOW_WARNING]
  }
}

const mapActionText = (record: WashingAreaResource): string => {
  switch (record.action) {
    case 'move_to_available':
      return 'Move to Available Area'
    case 'washing_is_done':
      return 'Wash is Done'
    default:
      return '-'
  }
}

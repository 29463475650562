import { useState } from 'react'
import { useEffect } from 'react'
import { PageHeader } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import HeaderExtra from '../Shared/PageHeader/HeaderExtra'
import { useAppDispatch } from 'store/hooks'
import { MappedOption } from 'models'
import { advInfoListRequest } from 'store/advInfo'
import AddAdvInfo from './AddAdvInfo'
import searchedParamUtils from 'utils/searchedParam'

type Props = {
  depot: MappedOption
  page: number
  containerNumber: string
}
const AdvInfoHeader = ({ depot, page, containerNumber }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    dispatch(advInfoListRequest({ depotId: Number(depot.value), list: { perPage: 20, page, query: searchedParamUtils.getSearchedParam().searched || containerNumber } }))
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  }, [dispatch, depot.value, page, containerNumber])
  
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false)

  const onPressCreate = () => {
    setAddModalVisible(true)
  }

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <PageHeader
        onBack={containerNumber ? goBack : undefined}
        ghost={false}
        title="Advance Info"
        extra={
          <HeaderExtra
            defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : containerNumber}
            searchPlaceholder="Search by container number, customer or reference"
            onSearch={(value: string) => {
              searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
              dispatch(
                advInfoListRequest({
                  depotId: Number(depot.value),
                  list: { perPage: 20, page: 1, query: value },
                })
              )
            }}
            buttons={[{ title: 'New XLS file', onClick: onPressCreate }]}
          />
        }
      />
      <AddAdvInfo onCancel={() => setAddModalVisible(false)} visible={addModalVisible} depot={depot} />
    </>
  )
}

export default AdvInfoHeader

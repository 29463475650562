/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppContainerNumberValidationRequest } from '../models/AppContainerNumberValidationRequest';
import type { AppOutReferenceResource } from '../models/AppOutReferenceResource';
import type { AppTruckOffRequest } from '../models/AppTruckOffRequest';
import type { AppTruckPickUpRequest } from '../models/AppTruckPickUpRequest';
import type { DropOffToArrivalCollection } from '../models/DropOffToArrivalCollection';
import type { TruckPickUpCollectionResource } from '../models/TruckPickUpCollectionResource';
import { request as __request } from '../core/request';

export class AppContainerService {

    /**
     * Validate container number
     * Check if the container is available in advance_info_area
     * @param requestBody App container number validation request body
     * @returns void
     * @throws ApiError
     */
    public static async appContainerDropOffValidation(
        requestBody: AppContainerNumberValidationRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/container/drop_off/validation`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Truck off request
     * move containers from advance_info to arrival via truck drop off
     * @param requestBody App truck dropp off request
     * @returns DropOffToArrivalCollection Drop off to arrival collection response
     * @throws ApiError
     */
    public static async appContainerDropOff(
        requestBody: AppTruckOffRequest,
    ): Promise<DropOffToArrivalCollection> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/container/drop_off`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Validate out reference and return info
     * Validate out reference and return its container info
     * @param reference
     * @returns AppOutReferenceResource Out Reference resource response
     * @throws ApiError
     */
    public static async appContainerPickUpOutReferenceReferenceGet(
        reference: string,
    ): Promise<AppOutReferenceResource> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/container/pick_up/out_reference/${reference}`,
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Truck pick up request
     * Submission of container pick up
     * @param reference
     * @param requestBody Truck pickup request body
     * @returns TruckPickUpCollectionResource Truck pickup collection response
     * @throws ApiError
     */
    public static async appContainerPickUpOutReferenceReferencePost(
        reference: number,
        requestBody: AppTruckPickUpRequest,
    ): Promise<TruckPickUpCollectionResource> {
        const result = await __request({
            method: 'POST',
            path: `/api/app/container/pick_up/out_reference/${reference}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
import * as yup from 'yup'
import { userFields } from 'Components/Users/User/userFormFields'
import type { UserUpdateRequest } from 'api/main'

import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'

const editUserSchema = yup.object().shape<GeneralSchemaType<UserUpdateRequest>>({
  first_name: yup.string().required(`'${userFields.first_name?.label}' is a required field`),
  last_name: yup.string().required(`${userFields.last_name?.label}' is a required field`),
  // address: yup.string().required(`'${userFields?.address?.label}' is a required field`),
  // date_of_birth: yup.string(),
  // phone_number: yup.string().required(`'${userFields?.phone_number?.label}' is a required field`),
  depot_ids:  yup.array().of(yup.number()).min(1).required(),
  role_id: yup.number().required(),
})

export default editUserSchema

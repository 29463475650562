import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerAdvanceInfoService,
  AdminContainerArrivalService,
  AdvanceInfoCollection,
  AdvanceInfoWithDetailResource,
  AdvanceInfoUpdateRequest,
  FromAdvanceInfoToArrivalRequest,
} from 'api/main'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const advInfoListRequest = createAsyncThunk<
  AdvanceInfoCollection,
  { depotId: number; list: GeneralListRequest },
  { rejectValue: string }
>('list/advInfoListRequestAsync', async ({ depotId, list: { perPage, page, query } }, { rejectWithValue }) => {
  try {
    return await AdminContainerAdvanceInfoService.adminDepotDepotIdAdvanceInfoList(depotId, perPage, page, query)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const advInfoImportRequest = createAsyncThunk<
  void,
  { depotId: number; requestBody?: any },
  { rejectValue: string }
>('import/advInfoImportRequest', async ({ depotId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerAdvanceInfoService.adminDepotDepotIdAdvanceInfoImportExcelPost(depotId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const advInfoDeleteRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'delete/advInfoDeleteRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerAdvanceInfoService.adminAdvanceInfoDelete(advanceInfoId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const advInfoGetRequest = createAsyncThunk<AdvanceInfoWithDetailResource, number, { rejectValue: string }>(
  'get/advInfoGetRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerAdvanceInfoService.adminAdvanceInfoGet(advanceInfoId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const advInfoUpdateRequest = createAsyncThunk<
  void,
  { advanceInfoId: number; requestBody: AdvanceInfoUpdateRequest },
  { rejectValue: string }
>('update/advInfoUpdateRequest', async ({ advanceInfoId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerAdvanceInfoService.adminAdvanceInfoUpdate(advanceInfoId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const moveToArrivalRequest = createAsyncThunk<
  void,
  {
    containerId: number
    requestBody?: FromAdvanceInfoToArrivalRequest
  },
  { rejectValue: string }
>('moveToArrival/moveToArrivalRequest', async ({ containerId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerArrivalService.adminAdvanceInfoAdvanceInfoIdArrivalPost(containerId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

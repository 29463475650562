import { combineReducers } from '@reduxjs/toolkit'
import { companyCreateUsersRequestAsync, companyUsersListRequest, companyUserUpdateRequest } from 'store/companyUsers'
import { AdminUserResource, AdminCustomerResource } from 'api/main'
import { createSliceBuilder, listSliceBuilder } from 'store/helper'

export const companyUsersReducer = combineReducers({
  list: listSliceBuilder<AdminUserResource>('listCompany', companyUsersListRequest).reducer,
  create: createSliceBuilder<void>('createCompanyUser', companyCreateUsersRequestAsync).reducer,
  update: createSliceBuilder<AdminCustomerResource>('updateCompanyUser', companyUserUpdateRequest).reducer,
})

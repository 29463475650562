import { combineReducers } from '@reduxjs/toolkit'
import {
  containerTypeListRequestAsync,
} from 'store/containerType/actions'
import { listSliceBuilder } from 'store/helper'
import type { ContainerTypeResource } from 'api/main'

export const ContainerTypeReducers = combineReducers({
  list: listSliceBuilder<ContainerTypeResource>('listContainerType', containerTypeListRequestAsync).reducer,
})

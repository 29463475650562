import { VoidFunctionComponent } from 'react'
import { List, Typography, Row, Col } from 'antd'

interface Props {
  dataSource: string[]
  header: string
  title: string
}

const GateOutConfirmList: VoidFunctionComponent<Props> = ({
   dataSource, 
   header, 
   title,
}) => {
  return (
    dataSource.length > 0 ? (
        <Row>
        <Col span={24} className='mb-2'>
            <Typography.Text strong>{header}</Typography.Text>
        </Col>
        <Col span={24} className='mb-4'>
            <List
                header={<Typography.Text strong>{title}</Typography.Text>}
                bordered
                dataSource={dataSource}
                renderItem={(item: string) => (
                <List.Item>
                    {item}
                </List.Item>
                )}
            />
        </Col>
        </Row>
    ) : null
  )
}

export default GateOutConfirmList

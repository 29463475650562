import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal, Button } from 'antd'
import { SubmitHandler } from 'react-hook-form'
import { RepairingFilterFormFields } from 'Components/Repairings/RepairingFilter/RepairingFilterFormFields'
import { RepairingFilterModel } from 'models/forms/RepairingFilterModel'
import useCDForm from 'hooks/useCDForm'

interface Props {
  onSubmit: SubmitHandler<RepairingFilterModel>
  onCancel: () => void
  visible?: boolean
  initialData: RepairingFilterModel
}

const RepairingFilter: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, initialData }) => {
  const { handleSubmit, reset, renderInput } = useCDForm<RepairingFilterModel>(RepairingFilterFormFields)

  useEffect(() => {
    if (visible) {
      reset(initialData)
    }
  }, [reset, visible, initialData])

  const onCancelForm = () => {
    onCancel()
  }

  const statusOprions = [{ value: '', label: 'All' }, ...(RepairingFilterFormFields.status.options || [])]

  return (
    <Modal
      visible={visible}
      title="Filter"
      okText="Apply"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onSubmit({ status: '' })
          }}
        >
          Reset
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit(onSubmit)}>
          Filter
        </Button>,
      ]}
    >
      <Form name="repairing_filter" layout="vertical">
        <span>
          <p style={{ fontWeight: 500 }}>{RepairingFilterFormFields.status.label}</p>
          {renderInput('status', statusOprions, false)}
        </span>
      </Form>
    </Modal>
  )
}

export default RepairingFilter

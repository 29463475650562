import * as yup from 'yup'
import { CompanyUserFormFields } from 'Components/Companies/CompanyUsers/CompanyUserFormFields'
import { CustomerUpdateRequestWithEmail } from 'models'
import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'

const registerSchema = yup.object().shape<GeneralSchemaType<CustomerUpdateRequestWithEmail>>({
  first_name: yup.string().required(`'${CompanyUserFormFields.first_name?.label}' is a required field`),
  last_name: yup.string().required(`${CompanyUserFormFields.last_name?.label}' is a required field`),
  email: yup.string().email().required(),
  address: yup.string().required(`'${CompanyUserFormFields?.address?.label}' is a required field`),
  phone_number: yup.string().required(`'${CompanyUserFormFields?.phone_number?.label}' is a required field`),
})

export default registerSchema

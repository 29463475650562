import React from 'react'
import { selectUser } from 'store/users/selectors'
import { useParams, useHistory } from 'react-router-dom'
import { PageHeader, Card } from 'antd'
import {Status } from 'models'
import { SubmitHandler } from 'react-hook-form'
import { selectDepotsListMappedData } from 'store/depots'
import { selectRolesListMappedData } from 'store/roles'
import { UserUpdateRequest, AdminUserResource } from 'api/main'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { userUpdateRequestAsync, selectUserUpdateStatus } from 'store/users'
import {mapUserResToReq} from 'models/ReqResMapper'
import UserForm from './UserForm'

const UserPage = () => {
  const { userId } = useParams<{ userId: string }>()
  const user: AdminUserResource | undefined = useAppSelector(selectUser(Number(userId)))
  const updateStatus = useAppSelector(selectUserUpdateStatus)
  const depots = useAppSelector(selectDepotsListMappedData)
  const roles = useAppSelector(selectRolesListMappedData)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const onSubmitForm: SubmitHandler<UserUpdateRequest> = async values => {
    await dispatch(userUpdateRequestAsync({ userId: Number(userId), requestBody: values }))
    history.push('/users')
  }
  return (
    <>
    <PageHeader
        ghost={false}
        onBack={history.goBack}
        title="Edit user"
      />
    <Card >
      {user && (
        <UserForm initialValues={mapUserResToReq(user)} roles={roles} depots={depots} onSubmit={onSubmitForm} isSubmitting={updateStatus=== Status.PENDING} />
      )}
    </Card>
    </>
  )
}

export default UserPage

import { combineReducers } from '@reduxjs/toolkit'
import { listSliceBuilder, createSliceBuilder } from 'store/helper'
import {
  outQueueListRequest,
  outQueueDeleteRequest,
  gateOutRequest,
  containersListRequest,
} from 'store/outQueue'
import { CotainerNumbersResponse, OutQueueResource } from '../../api/main'

export const outQueueReducers = combineReducers({
  list: listSliceBuilder<OutQueueResource>('listOutQueue', outQueueListRequest).reducer,
  delete: createSliceBuilder<void>('deleteOutQueue', outQueueDeleteRequest).reducer,
  gateOut: createSliceBuilder<void>('gateOutOutQueue', gateOutRequest).reducer,
  suggestedList: listSliceBuilder<CotainerNumbersResponse>('listContainers', containersListRequest).reducer,
})

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminInviteCustomerRequest,
  AdminCompanyService,
  AdminUserResource,
  CustomerUpdateRequest,
  AdminCustomerResource,
  CustomerTokenResource,
  CustomerRegistrationRequest,
} from 'api/main'
import { handleApiCallError } from 'store/helper'

export const companyUsersListRequest = createAsyncThunk<Array<AdminUserResource>, number, { rejectValue: string }>(
  'list/companyUserListRequest',
  async (companyId, { rejectWithValue }) => {
    try {
      return await AdminCompanyService.adminCompanyCompanyUser(companyId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const companyCreateUsersRequestAsync = createAsyncThunk<
  void,
  { companyId: number; requestBody: AdminInviteCustomerRequest },
  { rejectValue: string }
>('create/companyCreateUserRequestAsync', async ({ companyId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminCompanyService.adminCompanyUserInvite(companyId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const companyUserUpdateRequest = createAsyncThunk<
  AdminCustomerResource,
  { userId: number; requestBody: CustomerUpdateRequest },
  { rejectValue: string }
>('update/companyUserUpdateRequest', async ({ userId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminCompanyService.adminCompanyUserUserUpdate(userId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const companyUserRegisterRequest = createAsyncThunk<
  CustomerTokenResource,
  CustomerRegistrationRequest,
  { rejectValue: string }
>('register/companyUserRegisterRequestAsync', async (body, { rejectWithValue }) => {
  try {
    return await AdminCompanyService.adminCompanyUserRegister(body)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

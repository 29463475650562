import * as yup from 'yup'
import { invoiceFields } from '../Components/Companies/Invoice/AddInvoice/FormFields'
import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'
import type { CompanyDepotRequest } from 'api/main/models/CompanyDepotRequest'

const addInvoiceDetailsSchema = yup.object().shape<GeneralSchemaType<CompanyDepotRequest>>({
  lift_in_fee: yup.number().required(`'${invoiceFields.lift_in_fee.label}' is a required field`),
  lift_out_fee: yup.number().required(`${invoiceFields.lift_out_fee.label}' is a required field`),
  extra_lift_fee: yup.number().required(`'${invoiceFields.extra_lift_fee.label}' is a required field`),
  storage_fee_per_day: yup.number().required(`'${invoiceFields.storage_fee_per_day.label}' is a required field`),
  special_storage_fee_per_day: yup.number().required(`'${invoiceFields.repair_fee_per_hour.label}' is a required field`),
  repair_fee_per_hour: yup.number().required(`'${invoiceFields.repair_fee_per_hour.label}' is a required field`),
  storage_free_days: yup.number().required(`'${invoiceFields.storage_free_days.label}' is a required field`),
  customer_number: yup.string().required(`'${invoiceFields.customer_number.label}' is a required field`),
  vessel_lift_in_fee: yup.number().required(`'${invoiceFields.vessel_lift_in_fee.label}' is a required field`),
  vessel_lift_out_fee: yup.number().required(`'${invoiceFields.vessel_lift_out_fee.label}' is a required field`),
  special_lift_in_fee: yup.number().required(`'${invoiceFields.special_lift_in_fee.label}' is a required field`),
  special_lift_out_fee: yup.number().required(`'${invoiceFields.special_lift_out_fee.label}' is a required field`),
  repair_lift_fee: yup.number().required(`'${invoiceFields.repair_lift_fee.label}' is a required field`),
  extra_work_per_hour: yup.number().required(`'${invoiceFields.extra_work_per_hour.label}' is a required field`),
  extra_work_with_machine_per_hour: yup
    .number()
    .required(`${invoiceFields.extra_work_with_machine_per_hour.label} is a required field`),
})

export default addInvoiceDetailsSchema

import { createAsyncThunk } from '@reduxjs/toolkit'
import { CompanyRequest, CompanyResource, AdminCompanyService, CompanyCollection } from 'api/main'
import { GeneralListRequest } from 'models'
import CompanyUpdateRequest from 'models/requests/CompanyUpdateRequest'
import { handleApiCallError } from 'store/helper'

export const createCompanyRequestAsync = createAsyncThunk<CompanyResource, CompanyRequest, { rejectValue: string }>(
  'createCompany/createCompanyRequestAsync',
  async (body, { rejectWithValue }) => {
    try {
      return await AdminCompanyService.adminCompanyCreate(body)
    } catch (e:any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const companiesListRequestAsync = createAsyncThunk<
  CompanyCollection,
  GeneralListRequest,
  { rejectValue: string }
>('list/companiesListRequestAsync', async ({ page, perPage, query }, { rejectWithValue }) => {
  try {
    return await AdminCompanyService.adminCompanyShow(perPage, page, query)
  } catch (e:any) {
    return handleApiCallError(e, rejectWithValue, 'companies list')
  }
})

export const updateCompanyRequestAsync = createAsyncThunk<
  CompanyResource,
  CompanyUpdateRequest,
  { rejectValue: string }
>('createCompany/updateCompanyRequestAsync', async (body, { rejectWithValue }) => {
  try {
    const { id, ...rest } = body
    return await AdminCompanyService.adminCompanyCompanyUpdate(id, rest)
  } catch (e:any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const deleteCompanyRequestAsync = createAsyncThunk<void,number,{ rejectValue: string }>('delete/deleteCompanyRequestAsync', async(companyId, { rejectWithValue })=>{
  try{
    return await AdminCompanyService.apiAdminCompanyCompanyIdDelete(companyId)
  } catch (e:any) {
    return handleApiCallError(e, rejectWithValue)
  }
})
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminResource = {
    readonly id: number;
    readonly first_name: string;
    readonly last_name: string;
    readonly is_admin: boolean;
    readonly language: AdminResource.language;
    /**
     * User unique email address
     */
    readonly email: string;
    /**
     * Initial creation timestamp
     */
    readonly created_at: string;
    /**
     * Last update timestamp
     */
    readonly updated_at: string;
}

export namespace AdminResource {

    export enum language {
        EN = 'en',
        FI = 'fi',
    }


}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminDepotCollection, AdminDepotService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export const depotsListRequestAsync = createAsyncThunk<AdminDepotCollection, void, { rejectValue: string }>(
  'list/depotsListRequestAsync',
  async (_body, { rejectWithValue }) => {
    try {
      return await AdminDepotService.adminDepot()
    } catch (e:any) {
      return handleApiCallError(e, rejectWithValue, 'depots list')
    }
  }
)
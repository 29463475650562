import { ListState, NormalState } from 'models'

import { AdminUserResource } from 'api/main'
import { RootState } from '../index'

type UsersListState = ListState<AdminUserResource>

export const selectUsersListData = (state: RootState): UsersListState['data'] => state.users.list.data
export const selectUsersListPaginationData = (state: RootState): UsersListState['paginationData'] =>
  state.users.list.paginationData
export const selectUsersListError = (state: RootState): UsersListState['error'] => state.users.list.error
export const selectUsersListStatus = (state: RootState): UsersListState['status'] => state.users.list.status

export const selectUserInviteStatus = (state: RootState): NormalState<AdminUserResource>['status'] =>
  state.users.invite.status

export const selectUserRegisterStatus = (state: RootState): NormalState<AdminUserResource>['status'] =>
  state.users.register.status

export const selectUser =
  (userId: number): ((state: RootState) => AdminUserResource | undefined) =>
  (state: RootState): AdminUserResource | undefined =>
    state.users.list.data?.find(user => user?.id === userId)

export const selectUserUpdateStatus = (state: RootState): NormalState<AdminUserResource>['status'] =>
  state.users.update.status

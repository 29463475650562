import {  useEffect } from 'react'
import { PageHeader } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import HeaderExtra from 'Components/Shared/PageHeader/HeaderExtra'
import { useAppDispatch } from 'store/hooks'
import { MappedOption } from 'models'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import { availableListRequest } from 'store/availables'
import searchedParamUtils from 'utils/searchedParam'

type Props = {
  depot: MappedOption
  fieldName: containerLifeCyles
  page: number
  containerNumber: string
}

const AvailablesHeader = ({ depot, fieldName, page, containerNumber }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    dispatch(
      availableListRequest({
        depotId: Number(depot.value),
        perPage: 20,
        page,
        query: searchedParamUtils.getSearchedParam().searched || containerNumber,
      })
    )
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  }, [dispatch, depot.value, page, containerNumber])

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <PageHeader
        onBack={containerNumber ? goBack : undefined}
        ghost={false}
        title={lifeCyclesConfig[fieldName].title}
        extra={
          <HeaderExtra
            defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : containerNumber}
            searchPlaceholder="Search by container number, customer or reference"
            onSearch={(value: string) => {
              searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
              dispatch(
                availableListRequest({
                  depotId: Number(depot.value),
                  perPage: 20,
                  page: 1,
                  query: value,
                })
              )
            }}
          />
        }
      />
    </>
  )
}

export default AvailablesHeader

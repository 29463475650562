import React, { VoidFunctionComponent } from 'react'
import RegisterForm from './RegisterForm'
import { SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { UserRegistrationRequest } from 'api/main'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { userRegisterRequestAsync, selectUserRegisterStatus } from 'store/users'
import { companyUserRegisterRequest } from 'store/companyUsers'
import { Typography } from 'antd'
import { Logo } from '../../Shared'
import { useQueryParam } from 'hooks'
import { Status } from 'models'

const { Title } = Typography

type Props = {
  isNormal: boolean
}

const RegisterPage: VoidFunctionComponent<Props> = ({ isNormal }: Props) => {
  const query = useQueryParam()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const registerStatus = useAppSelector(selectUserRegisterStatus)
  const onRegister: SubmitHandler<UserRegistrationRequest> = async data => {
    const token: string = query.get('token') || ''
    const tokenized: UserRegistrationRequest = { ...data, token }
    let result = null
    if (isNormal) {
      result = await dispatch(userRegisterRequestAsync(tokenized))
    } else {
      result = await dispatch(companyUserRegisterRequest(tokenized))
    }
    //TODO remove this any and find the solution
    if (!(result as any)?.error) {
      history.push('/login')
    }
  }
  return (
    <>
      {/* {error && <Alert className="mb-6" message={error} type="error" showIcon closable />} */}
      <Logo isResponsive />
      <Title level={4} className="mv-6 mv-xs-3 text-xs-center">
        Accept Invitation
      </Title>
      <RegisterForm onSubmit={onRegister} isSubmitting={registerStatus === Status.PENDING} />
    </>
  )
}
export default RegisterPage

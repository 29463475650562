enum InputType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  PASSWORD = 'password',
  EMAIL = 'email',
  SELECT = 'select',
  EDITABLESELECT = 'editableselect',
  MULTISELECT = 'multiselect',
  RADIO='radio',
  CHECKBOX = 'checkbox',
  DATE='date',
  RANGE_DATE= 'range_date',
  SWITCH = 'switch',
}

export default InputType

import { combineReducers } from '@reduxjs/toolkit'
import {
  materialListRequestAsync,
} from 'store/material/actions'
import { listSliceBuilder } from 'store/helper'
import type { ContainerMaterialResource } from 'api/main'

export const materialReducers = combineReducers({
  list: listSliceBuilder<ContainerMaterialResource>('listMaterial', materialListRequestAsync).reducer,
})

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminCustomerResource } from '../models/AdminCustomerResource';
import type { AdminInviteCustomerRequest } from '../models/AdminInviteCustomerRequest';
import type { AdminUserResource } from '../models/AdminUserResource';
import type { CompanyCollection } from '../models/CompanyCollection';
import type { CompanyDepotRequest } from '../models/CompanyDepotRequest';
import type { CompanyRequest } from '../models/CompanyRequest';
import type { CompanyResource } from '../models/CompanyResource';
import type { CustomerRegistrationRequest } from '../models/CustomerRegistrationRequest';
import type { CustomerTokenResource } from '../models/CustomerTokenResource';
import type { CustomerUpdateRequest } from '../models/CustomerUpdateRequest';
import type { DepotFeesCollection } from '../models/DepotFeesCollection';
import type { UpdateCompanyDepotRequest } from '../models/UpdateCompanyDepotRequest';
import type { UpdateCompanyRequest } from '../models/UpdateCompanyRequest';
import { request as __request } from '../core/request';

export class AdminCompanyService {

    /**
     * Get companies list
     * this end point will return all companies information.
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over company.
     * @returns CompanyCollection Company collection response
     * @throws ApiError
     */
    public static async adminCompanyShow(
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<CompanyCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/company`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Create New Company
     * with this end point you can create new company.
     * @param requestBody Company request body
     * @returns CompanyResource Company resource response
     * @throws ApiError
     */
    public static async adminCompanyCreate(
        requestBody: CompanyRequest,
    ): Promise<CompanyResource> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/company`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Delete an existing company, if it doesn't relate to any users or depot fees
     * With this endpoint, you can delete an existing company.
     * @param companyId
     * @returns void
     * @throws ApiError
     */
    public static async apiAdminCompanyCompanyIdDelete(
        companyId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/admin/company/${companyId}`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Update Company
     * with this end point you can update an existing company.
     * @param companyId
     * @param requestBody Update company request body
     * @returns CompanyResource Company resource response
     * @throws ApiError
     */
    public static async adminCompanyCompanyUpdate(
        companyId: number,
        requestBody?: UpdateCompanyRequest,
    ): Promise<CompanyResource> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/company/${companyId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Registeration endpoint
     * To register a Customer this endpoint should be called.
     * @param requestBody Customer registration request body
     * @returns CustomerTokenResource Customer Token resource response
     * @throws ApiError
     */
    public static async adminCompanyUserRegister(
        requestBody: CustomerRegistrationRequest,
    ): Promise<CustomerTokenResource> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/company/user/register`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Get company users list
     * this end point will return all users related to company.
     * @param companyId
     * @returns AdminUserResource successfull
     * @throws ApiError
     */
    public static async adminCompanyCompanyUser(
        companyId: number,
    ): Promise<Array<AdminUserResource>> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/company/${companyId}/user`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Retrieve user information
     * This end point will return information of the selected user.
     * @param userId
     * @returns AdminCustomerResource Admin customer resource response
     * @throws ApiError
     */
    public static async adminCompanyUserUser(
        userId: number,
    ): Promise<AdminCustomerResource> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/company/user/${userId}`,
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * Update customer information
     * This end point will update information of the selected customer.
     * @param userId
     * @param requestBody Customer update request body
     * @returns AdminCustomerResource Admin customer resource response
     * @throws ApiError
     */
    public static async adminCompanyUserUserUpdate(
        userId: number,
        requestBody: CustomerUpdateRequest,
    ): Promise<AdminCustomerResource> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/company/user/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Invite a Customer to a company.
     * By calling this end point an invitation email will be sent.
     * @param companyId
     * @param requestBody Admin invite Customer request body
     * @returns void
     * @throws ApiError
     */
    public static async adminCompanyUserInvite(
        companyId: number,
        requestBody: AdminInviteCustomerRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/company/${companyId}/user/invite`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Get all invoicing details for a company
     * with this end point you can get all invoicing details for a company.
     * @param companyId
     * @returns DepotFeesCollection Depot fees collection response
     * @throws ApiError
     */
    public static async apiAdminCompanyCompanyIdDepotGet(
        companyId: number,
    ): Promise<DepotFeesCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/company/${companyId}/depot`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Add invoicing details for a certain depot
     * with this end point you can add invoicing fees for a depot in a company.
     * @param companyId
     * @param depotId
     * @param requestBody Company depot request body
     * @returns void
     * @throws ApiError
     */
    public static async apiAdminCompanyCompanyIdDepotDepotIdCreate(
        companyId: number,
        depotId: number,
        requestBody: CompanyDepotRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/company/${companyId}/depot/${depotId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Delete an exist invoicing details for the given depot
     * with this end point you can delete an exist invoicing fees the given depot.
     * @param companyId
     * @param depotId
     * @returns void
     * @throws ApiError
     */
    public static async apiAdminCompanyCompanyIdDepotDepotIdDelete(
        companyId: number,
        depotId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/admin/company/${companyId}/depot/${depotId}`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Update an exist invoicing details for the given depot
     * with this end point you can update an exist invoicing fees the given depot.
     * @param companyId
     * @param depotId
     * @param requestBody Update company depot request body
     * @returns void
     * @throws ApiError
     */
    public static async apiAdminCompanyCompanyIdDepotDepotIdUpdate(
        companyId: number,
        depotId: number,
        requestBody: UpdateCompanyDepotRequest,
    ): Promise<void> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/company/${companyId}/depot/${depotId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
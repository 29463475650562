import { useEffect } from 'react'
import { Card, PageHeader, Form, Button, Row, Col } from 'antd'
import Input from '../../../UiKit/Inputs/Input'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import addInvoiceDetailsSchema from 'schemas/addInvoiceDetailsSchema'
import { invoiceFields } from './FormFields'
import {
  createInvoiceRequestAsync,
  selectCreateInvoiceStatus,
  selectInvoice,
  updateInvoiceRequestAsync,
  selectUpdateInvoiceStatus,
} from 'store/invoices'
import {selectDepotData} from 'store/depots'
import { Status } from 'models'
import type { CompanyDepotRequest } from 'api/main/models/CompanyDepotRequest'

type CompanyDepotRequestKeys = keyof CompanyDepotRequest

const AddInvoicePage = () => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<CompanyDepotRequest>({ resolver: yupResolver(addInvoiceDetailsSchema) })
  const history = useHistory()
  const dispatch = useAppDispatch()
  const createStatus = useAppSelector(selectCreateInvoiceStatus)
  const updateStatus = useAppSelector(selectUpdateInvoiceStatus)
  const { companyId, depotId, invoiceId } = useParams<{ companyId: string; depotId: string; invoiceId: string }>()
  const selectedInvoice = useAppSelector(selectInvoice(Number(invoiceId)))
  const depot = useAppSelector(selectDepotData(Number(depotId)))
  const isNew = !selectedInvoice
  useEffect(() => {
    reset(selectedInvoice?.fees)
  }, [reset, selectedInvoice])

  const onSubmitForm: SubmitHandler<CompanyDepotRequest> = async values => {
    if (isNew) {
      await dispatch(
        createInvoiceRequestAsync({
          companyId: Number(companyId),
          depotId: Number(depotId),
          requestBody: { ...values },
        })
      )
      history.goBack()
    } else {
      dispatch(
        updateInvoiceRequestAsync({
          companyId: Number(companyId),
          depotId: Number(depotId),
          requestBody: { ...values },
        })
      )
    }
  }

  return (
    <>
      <PageHeader ghost={false} onBack={history.goBack} title={depot?.name} />
      <Card>
        <Form name="internal_user" layout="vertical" noValidate>
          <Row gutter={[8, 8]}>
            {Object.entries(invoiceFields).map(entry => {
              // const [key, value] = entry
              const value = entry[1]
              const key: CompanyDepotRequestKeys = entry[0] as CompanyDepotRequestKeys
              return (
                <Col key={key} span={8}>
                  <Input
                    label={value.label}
                    errorClassName="ant-form-item-has-error"
                    control={control}
                    name={key}
                    placeholder={value.placeholder}
                    error={errors[key]?.message}
                  />
                </Col>
              )
            })}
          </Row>
          <Button
            style={{ float: 'right' }}
            type="primary"
            onClick={handleSubmit(onSubmitForm)}
            loading={createStatus === Status.PENDING || updateStatus === Status.PENDING}
          >
            {isNew ? 'Create' : 'Update'}
          </Button>
        </Form>
      </Card>
    </>
  )
}
export default AddInvoicePage

import { VoidFunctionComponent, useState } from 'react'
import { Modal, Row, Col, Form, AutoComplete, Select } from 'antd'
import { Status } from 'models'
import { callbackUtils } from 'utils'
import { CotainerNumbersResponse } from 'api/main'

interface Props {
  onCancel: () => void
  visible?: boolean
  onNext: () => void
  containers: string[]
  onChange: (value: string) => void
  containerListStatus: Status
  containerListData: CotainerNumbersResponse
  setContainers: (value: string[]) => void
}

const VisyAccessFields: VoidFunctionComponent<Props> = ({
  visible, 
  onCancel, 
  onNext, 
  containers,
  onChange,
  containerListStatus,
  containerListData,
  setContainers,
}) => {
  const [activeInput, setActiveInput] = useState<number>(0)
  const [form] = Form.useForm()

  const onCancelForm = (): void => {
    onCancel()
    form.resetFields()
  }

  const onFinish = (): void => {
    onNext()
    onCancelForm()
  }

  const debouncedSearch = callbackUtils.debounce((value: string) => onChange(value), 500)

  async function handleChange(value: any, index: any) {
    setActiveInput(index)
    if (value.length > 2) {
      debouncedSearch(value)
    }
  }

  const onSelect = (value: string, index: number) => {
    let newContainers = [...containers]
    newContainers[index] = value

    setContainers(newContainers)
    setActiveInput(index)
    onChange(value)
  }

  return (
    <Modal
      visible={visible}
      title="Gate Out"
      okText="Next"
      onOk={() => {
        onNext()
      }}
      onCancel={onCancelForm}
    >
      <Form form={form} layout="vertical" name="gate_out" onFinish={onFinish} autoComplete="off">
        <Row gutter={[20, 8]}>
          {
            containers.map((_: any, index: number) => (
              <Col key={index} span={12}>
                <Form.Item
                  name={`container ${index + 1}`}
                  label={`Container ${index + 1}`}
                  initialValue={containers[index]}
                  hasFeedback={activeInput === index && containerListStatus === Status.PENDING}
                  validateStatus={containerListStatus === Status.PENDING ? "validating" : undefined}
                  rules={[{ min: 3, message: 'At least 3 characteres is needed!' }]}
                >
                    <AutoComplete
                      value={containers[index]}
                      placeholder='Container'
                      onSearch={(value: string) => handleChange(value, index)}
                      onSelect={(value: string) => onSelect(value, index)}
                    >
                      {
                        containerListData?.numbers?.map((item: string) => (
                          <Select.Option key={item} value={item}>{item}</Select.Option>
                        ))
                      }
                    </AutoComplete>
                </Form.Item>
              </Col>
            ))
          }
        </Row>
      </Form>
    </Modal>
  )
}

export default VisyAccessFields

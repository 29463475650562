enum ContainerArea {
    ADVANCE_INFO = 'advance_info',
    ARRIVAL = 'arrival',
    CHECKING = 'checking_area',
    CHECKED = 'checked_area',
    DAMAGED = 'damaged_area',
    REPAIRING = 'repairing_area',
    WASHING = 'washing_area',
    AVAILABLE='available',
    RELEASED = 'released',
  }
  
  export default ContainerArea
  
import React from 'react'
import { arrivalsListRequest } from 'store/arrivals'
import { checkingListRequest } from 'store/checkings'
import { checkedListRequest } from 'store/checkeds'
import { damagedListRequest } from 'store/damageds'
import {repairingListRequest} from 'store/repairings'
import { availableListRequest } from 'store/availables'
import { allContainersListRequest } from 'store/allContainers'
import { washingListRequest } from 'store/washings'
import { outReferenceListRequest } from 'store/outReference'
import { outQueueListRequest } from 'store/outQueue'
import { MappedOption } from 'models'
import DepotLifeCycleHeader from './DepotLifeCycleHeader'
import AdvInfoHeader from 'Components/AdvanceInfo/AdvInfoHeader'
import ArrivalsTab from 'Components/Arrivals/ArrivalsTab'
import AdvInfoTab from 'Components/AdvanceInfo/AdvInfoTab'
import CheckingsTab from 'Components/Checkings/CheckingsTab'
import CheckedsTab from 'Components/Checkeds/CheckedsTab'
import DamagedsTab from 'Components/Damageds/damagedsTab'
import RepairingsTab from 'Components/Repairings/RepairingsTab'
import DamagedHeader from 'Components/Damageds/DamagedHeader'
import RepairingsHeader from 'Components/Repairings/RepairingsHeader'
import AvailablesHeader from 'Components/Availables/AvailablesHeader'
import AvailablesTabs from 'Components/Availables/AvailablesTabs'
import AllContainersHeader from 'Components/AllContainers/AllContainersHeader'
import AllContainersTabs from 'Components/AllContainers/AllContainersTabs'
import WashingsHeader from 'Components/Washings/WashingsHeader'
import WashingsTab from 'Components/Washings/WashingsTab'
import OutReferenceHeader from 'Components/OutReferences/OutReferenceHeader'
import OutReferenceTab from 'Components/OutReferences/OutReferenceTab'
import OutQueueHeader from 'Components/OutQueue/OutQueueHeader'
import OutQueueTab from 'Components/OutQueue/OutQueueTab'

export interface ConfigType {
  menuTitle?: string
  title: string
  searchFunc?: any
  TabComponent: React.FunctionComponent<{ depot: MappedOption; onPaginateChange?: (page: number) => void; expandedId?: number }> | null
  HeaderComponent: React.FunctionComponent<{ depot: MappedOption; fieldName: containerLifeCyles; page: number; containerNumber: string}>
  columns?: { title: string; dataIndex: string }[]
}

export enum containerLifeCyles {
  allContainers = 'all-containers',
  advanceInfo = 'advance-info',
  arrivals = 'arrivals',
  checkingArea = 'checking-area',
  checkedArea = 'checked-area',
  damagedArea = 'damaged-area',
  repairingArea = 'repairing-area',
  washingArea = 'washing-area',
  availableArea = 'available-area',
  outReference = 'out-reference',
  outQueue = 'out-queue',
  // releasedArea = 'released-area',
}

const lifeCyclesConfig: { [key in containerLifeCyles]: ConfigType } = {
  'all-containers': {
    menuTitle: 'All',
    title: 'All Containers',
    searchFunc: allContainersListRequest,
    HeaderComponent: AllContainersHeader,
    TabComponent: AllContainersTabs,
    columns: [
      { title: 'Container number', dataIndex: 'number' },
      { title: 'Company', dataIndex: 'company' },
      { title: 'ISO code', dataIndex: 'iso_code' },
    ],
  },
  'advance-info': {
    title: 'Advance Info',
    HeaderComponent: AdvInfoHeader,
    TabComponent: AdvInfoTab,
    columns: [
      { title: 'Container number', dataIndex: 'container_number' },
      { title: 'Customer', dataIndex: 'customer_name' },
      { title: 'Stock type', dataIndex: 'stock_type' },
      { title: 'ISO code', dataIndex: 'iso_code' },
      { title: 'Reference', dataIndex: 'reference' },
    ],
  },
  arrivals: {
    title: 'Arrivals',
    searchFunc: arrivalsListRequest,
    HeaderComponent: DepotLifeCycleHeader,
    TabComponent: ArrivalsTab,
    columns: [
      { title: 'Container number', dataIndex: 'container_number' },
      { title: 'Company', dataIndex: 'customer_name' },
      { title: 'Stock type', dataIndex: 'stock_type' },
      { title: 'ISO code', dataIndex: 'iso_code' },
      { title: 'Trans. Company', dataIndex: 'transportation_company' },
      { title: 'Depot Area', dataIndex: 'depot_area' },
    ],
  },
  'checking-area': {
    title: 'Checking Area',
    searchFunc: checkingListRequest,
    HeaderComponent: DepotLifeCycleHeader,
    TabComponent: CheckingsTab,
    columns: [
      { title: 'Container number', dataIndex: 'container_number' },
      { title: 'Company', dataIndex: 'customer_name' },
    ],
  },
  'checked-area': {
    title: 'Checked Area',
    searchFunc: checkedListRequest,
    HeaderComponent: DepotLifeCycleHeader,
    TabComponent: CheckedsTab,
    columns: [
      { title: 'Container number', dataIndex: 'container_number' },
      { title: 'Company', dataIndex: 'customer_name' },
      { title: 'Stock type', dataIndex: 'stock_type' },
    ],
  },
  'damaged-area': {
    title: 'Damaged Area',
    searchFunc: damagedListRequest,
    HeaderComponent: DamagedHeader,
    TabComponent: DamagedsTab,
    columns: [
      { title: 'Container number', dataIndex: 'container_number' },
      { title: 'Company', dataIndex: 'customer_name' },
      { title: 'Stock type', dataIndex: 'stock_type' },
      { title: 'ISO code', dataIndex: 'iso_code' },
    ],
  },
  'repairing-area': {
    title: 'Repairing Area',
    searchFunc: repairingListRequest,
    HeaderComponent: RepairingsHeader,
    TabComponent: RepairingsTab,
    columns: [
      { title: 'Container number', dataIndex: 'container_number' },
      { title: 'Company', dataIndex: 'customer_name' },
      { title: 'ISO code', dataIndex: 'iso_code' },
    ],
  },
  'washing-area': {
    title: 'Washing Area',
    searchFunc: washingListRequest,
    HeaderComponent: WashingsHeader,
    TabComponent: WashingsTab,
    columns: [
      { title: 'Container number', dataIndex: 'container_number' },
      { title: 'Company', dataIndex: 'customer_name' },
      { title: 'ISO code', dataIndex: 'iso_code' },
    ],
  },
  'available-area': {
    title: 'Available Area',
    searchFunc: availableListRequest,
    HeaderComponent: AvailablesHeader,
    TabComponent: AvailablesTabs,
    columns: [
      { title: 'Container number', dataIndex: 'container_number' },
      { title: 'Company', dataIndex: 'customer_name' },
      { title: 'Stock type', dataIndex: 'stock_type' },
    ],
  },
  'out-reference': {
    title: 'Out Reference',
    searchFunc: outReferenceListRequest,
    HeaderComponent: OutReferenceHeader,
    TabComponent: OutReferenceTab,
    columns: [
      { title: 'Company', dataIndex: 'company' },
      { title: 'Out Reference', dataIndex: 'reference' },
    ],
  },
  'out-queue': {
    title: 'Out Queue',
    searchFunc: outQueueListRequest,
    HeaderComponent: OutQueueHeader,
    TabComponent: OutQueueTab,
    columns: [
      { title: 'Trans. Company', dataIndex: 'transportation_company' },
      { title: 'License Plate N.', dataIndex: 'license_plate_number' },
      { title: 'Company', dataIndex: 'company' },
      { title: 'Stock type', dataIndex: 'stock_type' },
      { title: 'Grade', dataIndex: 'grade' },
      { title: 'Size', dataIndex: 'size' },
      { title: 'Type', dataIndex: 'type' },
      { title: 'Count', dataIndex: 'count' },
      { title: 'Out Reference ID', dataIndex: 'out_reference_id' },
    ],
  },
  // 'released-area': {
  //   title: 'Released Area',
  //   searchFunc: advInfoListRequest,
  //   HeaderComponent: DepotLifeCycleHeader,
  //   TabComponent: null,
  // },
}

export default lifeCyclesConfig

import { VoidFunctionComponent, ChangeEvent } from 'react'
import { Modal, Row, Col, Form, Typography, Input } from 'antd'

const { Text } = Typography

interface Props {
  onCancel: () => void
  visible?: boolean
  onSendPass: () => void
  containers: string[]
  setOtherContainers: (value: string[]) => void
  otherContainers: string[]
}

const GateOutFields: VoidFunctionComponent<Props> = ({
  visible, 
  onCancel, 
  onSendPass, 
  containers,
  setOtherContainers,
  otherContainers,
}) => {
  const [form] = Form.useForm()

  const onCancelForm = (): void => {
    onCancel()
    form.resetFields()
  }

  const onFinish = () => {
    onSendPass()
    onCancelForm()
  }

  function handleChange(value: string, index: number) {
    let newContainers = [...otherContainers]
    newContainers[index] = value

    setOtherContainers(newContainers)
  }

  return (
    <Modal
      visible={visible}
      title="Visy Access Pass"
      okText="Send Pass"
      onOk={() => {
        onSendPass()
      }}
      onCancel={onCancelForm}
      width={650}
    >
      <Form form={form} layout="vertical" name="gate_out" onFinish={onFinish} autoComplete="off">
        <Row>
          <Text className='mb-4'>If there are more containers you need to give access pass to, you can indicate in here.</Text>
        </Row>
        <Row gutter={[20, 20]}>
          {
            containers.map((_: any, index: number) => (
              <Col key={index} span={12}>
                <Form.Item
                  name={`container ${index + 1}`}
                  label={`Container ${index + 1}`}
                  initialValue={containers[index]}
                >
                  <Input
                    disabled
                    placeholder='Container'
                  />
                </Form.Item>
              </Col>
            ))
          }
          {
            otherContainers.map((_: any, index: number) => (
              <Col key={index} span={12}>
                <Form.Item
                  name={`container  ${index + containers.length + 1}`}
                  label={`Container ${index + containers.length + 1}`}
                  initialValue={otherContainers[index]}
                >
                  <Input placeholder='Container' onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, index)} />
                </Form.Item>
              </Col>
            ))
          }
        </Row>
      </Form>
    </Modal>
  )
}

export default GateOutFields

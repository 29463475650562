import { Status } from '../../../models'
import { createSlice } from '@reduxjs/toolkit'
import { resetPasswordRequestAsync } from './asyncActions'
import { RootState } from '../../index'

export interface ResetPasswordState {
  status: Status
  error: string | null
}

const initialState: ResetPasswordState = {
  status: Status.IDLE,
  error: null,
}

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetPasswordRequestAsync.pending, state => {
        state.status = Status.PENDING
        state.error = null
      })
      .addCase(resetPasswordRequestAsync.fulfilled, state => {
        state.status = Status.SUCCEEDED
      })
      .addCase(resetPasswordRequestAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const selectResetPassStatus = (state: RootState): ResetPasswordState['status'] => state.auth.resetPassword.status
export const selectResetPassError = (state: RootState): ResetPasswordState['error'] => state.auth.resetPassword.error

export default resetPasswordSlice.reducer

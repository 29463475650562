import { VoidFunctionComponent } from 'react'
import { Table } from 'antd'
import type { AvailableAreaResource } from 'api/main'
import { MappedOption } from 'models'
import { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import CDTable from 'Components/Shared/CDTable'
import { tableSelectors } from 'store/availables'
import { gradeTextMap } from 'Components/Shared/LifeCyclePageFactory/helper'
import { dateFormat } from "utils";

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}

const AvailablesTabs: VoidFunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }: Props) => {
  return (
    <>
      <CDTable<AvailableAreaResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        configName={containerLifeCyles.availableArea}
        onPaginate={onPaginateChange}
      >
        <Table.Column
          title="Grade"
          dataIndex="grade"
          render={(text: string, record: AvailableAreaResource) => (
            <p style={{ marginBottom: 0 }}>{gradeTextMap(text)}</p>
          )}
        />
        <Table.Column
          title="Gate in Date"
          dataIndex="gate_in_date"
          render={(text: string, record: AvailableAreaResource) => (
            <p style={{ marginBottom: 0 }}>{dateFormat.changeDateformat({ date: record.gate_in_date })}</p>
          )}
        />
        <Table.Column
          title="Repair Complete Date"
          dataIndex="repair_complete_date"
          render={(text: string, record: AvailableAreaResource) => (
            <p style={{ marginBottom: 0 }}>{dateFormat.changeDateformat({ date: record.repair_complete_date })}</p>
          )}
        />
      </CDTable>
    </>
  )
}

export default AvailablesTabs

import { VoidFunctionComponent, useRef, useEffect } from 'react'
import { MenuItem as MenuItemType } from '../../../models'
import { Menu } from 'antd'
import MenuBadge from 'Components/Shared/MenuBadge'
import { MenuItem, SubMenu } from 'Components/UiKit/Navigation'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

interface Props {
  items: MenuItemType[]
}

const pathStorage = localStorage.getItem('path') ?? '/'

const SidebarMenu: VoidFunctionComponent<Props> = ({ items }) => {
  const location = useLocation()
  const clickRef: any = useRef(null)

  useEffect(() => {
    clickRef?.current?.click()
  }, [])

  return (
    <Menu defaultOpenKeys={['men1']} selectedKeys={[location.pathname?.replace('/', '')]} className="h-100-percent" mode="inline">
      {items?.map(item => {
        if (item.items && item.items.length > 0) {
          return (
            <SubMenu key={item.key} icon={item.icon} title={item.title}>
              {item.items.map(it => (
                <MenuItem key={it.key}>
                  <>
                    {it.path && it.path !== pathStorage ? (
                      <NavLink onClick={() => localStorage.setItem('path', it.path + '')} to={it.path}>{it.title}</NavLink> 
                    ) : it.title}
                    {it.path && it.path === pathStorage ? (
                      <NavLink ref={clickRef} onClick={() => localStorage.setItem('path', it.path + '')} to={it.path}></NavLink> 
                    ) : null}
                   <MenuBadge menuName={it.title}/>
                  </>
                </MenuItem>
              ))}
            </SubMenu>
          )
        }
        if (item.path === pathStorage) {
          return (
            <MenuItem key={item.key} icon={item.icon}>
              {item.path ? <NavLink onClick={() => localStorage.setItem('path', item.path + '')} ref={clickRef} to={item.path}>{item.title}</NavLink> : item.title}
            </MenuItem>
          )
        }
        return (
          <MenuItem key={item.key} icon={item.icon}>
            {item.path ? <NavLink onClick={() => localStorage.setItem('path', item.path + '')} to={item.path}>{item.title}</NavLink> : item.title}
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default SidebarMenu

import { useEffect, useState, VoidFunctionComponent } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Switch, Button, Col, Form, Row } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from 'Components/UiKit/Inputs/Input'

import { IsoCodeRequest } from 'api/main/models/IsoCodeRequest'
import { IsoCodeFormFields } from './IsoCodeFormFields'
import createIsoCodeSchema from 'schemas/createISOCodeSchema'

import { MappedOption } from 'models'

const calculateTEU = (length: number): number => length / 20

type IsoCodeRequestKeys = keyof IsoCodeRequest
interface Props {
  onSubmit: SubmitHandler<IsoCodeRequest>
  isSubmitting?: boolean
  initialValues: IsoCodeRequest | undefined
  materials: MappedOption[]
  containerTypes: MappedOption[]
}

const CreateISOCodeForm: VoidFunctionComponent<Props> = ({ isSubmitting, onSubmit, initialValues, materials,containerTypes }) => {
  const {
    getValues,
    setValue,
    reset,
    trigger,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IsoCodeRequest>({ resolver: yupResolver(createIsoCodeSchema) })

  const [isSpecial, setIsSpecial] = useState<boolean>(false)

  const length = useWatch({
    control,
    name: 'length',
  })

  useEffect(() => {
    if (initialValues) setIsSpecial(Number(initialValues.teu) !== calculateTEU(Number(initialValues.length)))
  }, [initialValues])

  useEffect(() => {
    reset(initialValues)
  }, [reset, initialValues])

  useEffect(() => {
    const numLength = Number(getValues().length)
    if (!isNaN(numLength) && !isSpecial) {
      setValue('teu', calculateTEU(numLength))
    }
  }, [length, getValues, isSpecial, setValue])

  const options: any = {
    container_material_id: materials,
    container_type_id : containerTypes
  }

  return (
    <Form layout="vertical" name="iso_code" onFinish={handleSubmit(onSubmit)} noValidate>
      <Row gutter={16}>
        {Object.entries(IsoCodeFormFields).map(entry => {
          const value = entry[1]
          const key: IsoCodeRequestKeys = entry[0] as IsoCodeRequestKeys
          // eslint-disable-next-line sonarjs/no-small-switch
          switch (key) {
            case 'teu':
              return (
                <Col key={key} span={8}>
                  <Input
                    label={value.label}
                    errorClassName="ant-form-item-has-error"
                    control={control}
                    name={key}
                    placeholder={value.placeholder}
                    error={errors[key]?.message}
                    disabled={!isSpecial}
                  />
                </Col>
              )
            default:
              return (
                <Col key={key} span={8}>
                  <Input
                    label={value.label}
                    errorClassName="ant-form-item-has-error"
                    control={control}
                    name={key}
                    options={options[key]}
                    placeholder={value.placeholder}
                    error={errors[key]?.message}
                    type={value.type}
                  />
                </Col>
              )
          }
        })}
        <Col span={8}>
          <Switch
            checkedChildren="Special"
            unCheckedChildren="Special"
            checked={isSpecial}
            onChange={e => {
              const length = Number(getValues().length)
              if (!isNaN(length)) {
                setValue('teu', calculateTEU(length))
              }
              setIsSpecial(prev => !prev)
              trigger()
            }}
          />
        </Col>
      </Row>
      <Form.Item className="text-right">
        <Button htmlType="submit" type="primary" loading={isSubmitting}>
          {initialValues ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CreateISOCodeForm

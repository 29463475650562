import { memo, VoidFunctionComponent } from 'react'

interface Props {
  message: string
}

// This component is only used to show form errors in ant style
export const FormErrorMessage: VoidFunctionComponent<Props> = memo(({ message }) => (
  <div className="ant-form-item-explain ant-form-item-explain-error">
    <div role="alert">{message}</div>
  </div>
))

export default FormErrorMessage

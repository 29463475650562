import { NormalState, ListState } from 'models'

import type { IsoCodeResource } from 'api/main'
import { RootState } from '../index'
import { MappedOption } from 'models'

type isoCodeListState = ListState<IsoCodeResource>

export const selectIsoCodeListData = (state: RootState): isoCodeListState['data'] => state.isoCodes.list.data

export const selectIsoCodeListMappedData = (state: RootState): MappedOption[] =>
  state.isoCodes.list.data.map(code => ({ label: code.code, value: code.id }))

export const selectIsoCodeListPaginationData = (state: RootState): isoCodeListState['paginationData'] =>
  state.isoCodes.list.paginationData
export const selectIsoCodeListStatus = (state: RootState): isoCodeListState['status'] => state.isoCodes.list.status
export const selectIsoCode =
  (id: number): ((state: RootState) => IsoCodeResource | undefined) =>
  (state: RootState): IsoCodeResource | undefined =>
    state.isoCodes.list.data?.find(ic => ic?.id === id)

export const selectIsoCodeCreateStatus = (state: RootState): NormalState<IsoCodeResource>['status'] =>
  state.isoCodes.create.status

export const selectIsoCodeUpdateStatus = (state: RootState): NormalState<IsoCodeResource>['status'] =>
  state.isoCodes.update.status

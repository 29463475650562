import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal, Button } from 'antd'
import { SubmitHandler } from 'react-hook-form'
import useCDForm from 'hooks/useCDForm'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { isoCodeListRequestAsync, selectIsoCodeListStatus } from 'store/isoCodes'
import { MappedOption, Status } from 'models'
import { OutReferenceFilterModel } from 'models/forms/OutReferenceFilterModel'
import { OutReferenceFilterInitialData } from 'Components/OutReferences/helper'
import { OutRefrenceFilterFormFields } from 'Components/OutReferences/OutRefrenceFilter/OutRefrenceFilterFormFields'

interface Props {
  onSubmit: SubmitHandler<OutReferenceFilterModel>
  onCancel: () => void
  visible?: boolean
  initialData: OutReferenceFilterModel
}

const OutRefrenceFilter: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, initialData }) => {
  const { handleSubmit, reset, renderInput } = useCDForm<OutReferenceFilterModel>(OutRefrenceFilterFormFields)
  const dispatch = useAppDispatch()
  const listStatus = useAppSelector(selectIsoCodeListStatus)
  useEffect(() => {
    if (listStatus === Status.IDLE) {
      dispatch(isoCodeListRequestAsync({ perPage: 20, page: 1, query: '' }))
    }
  }, [dispatch, listStatus])

  useEffect(() => {
    if (visible) {
      reset(initialData)
    }
  }, [reset, visible, initialData])

  const onCancelForm = (): void => {
    onCancel()
  }

  return (
    <Modal
      visible={visible}
      title="Filter"
      okText="Apply"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onSubmit(OutReferenceFilterInitialData)
          }}
        >
          Reset
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit(onSubmit)}>
          Filter
        </Button>,
      ]}
    >
      <Form name="damaged_filter" layout="vertical">
        {Object.keys(OutRefrenceFilterFormFields).map((entry: string) => {
          const typedEntry = entry as keyof OutReferenceFilterModel
          const options: MappedOption[] = OutRefrenceFilterFormFields[typedEntry].options || []
          return (
            <span key={entry}>
              <p style={{ fontWeight: 500 }}>{OutRefrenceFilterFormFields[typedEntry].label}</p>
              {renderInput(typedEntry, options, false)}
            </span>
          )
        })}
      </Form>
    </Modal>
  )
}

export default OutRefrenceFilter

import React from 'react'
import { MiddlewareAPI, isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { notification } from 'antd'
import ErrorToast from 'Components/UiKit/ErrorToast'
import { colors } from 'constants/colors'

const noToastActions = ['import/advInfoImportRequest']

const openNotification = (title: string, description: any) => {
  const errDesc: string[] = []
  if (description) {
    Object.keys(description).forEach(key => {
      description[key] && errDesc.push(description[key][0])
    })
  }

  notification.error({
    message: title,
    description: React.createElement(ErrorToast, { decriptionArr: errDesc }),
    placement: 'bottomLeft',
    style:{
      backgroundColor: colors.ERR_NOTIF,
    },
  })
}

const isInNotToast = (actionName:string)=>{
  let isIn = false;
  noToastActions.forEach(actName=>{
    if(actionName.startsWith(actName)){
      isIn = true;
    }
  })
  return isIn;
}
/**
 *  show a toast!
 */
export const errorToastMessage: Middleware = (api: MiddlewareAPI) => next => action => {
  if (isRejectedWithValue(action) && !isInNotToast(action.type)) {
    openNotification(action.payload.message, action.payload.errors)
  }
  return next(action)
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  DamageAreaCollection,
  AdminContainerDamagedService,
  AdminContainerRepairingService,
  DamageEstimateResource,
  CreateDamageEstimateRequest,
  UpdateDamageEstimateRequest,
  SetDamageStatusRequest,
} from 'api/main'
// import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const damagedListRequest = createAsyncThunk<
  DamageAreaCollection,
  {
    depotId: number
    perPage?: number
    page?: number
    query?: string
    grade?: string
    stockType?: string
    status?: string
    isoCode?: string
  },
  { rejectValue: string }
>(
  'list/damagedListRequestAsync',
  async ({ depotId, perPage, page, query, grade, stockType, status, isoCode }, { rejectWithValue }) => {
    try {
      return await AdminContainerDamagedService.adminDepotDepotIdDamagedList(
        depotId,
        perPage,
        page,
        query,
        grade,
        stockType,
        status,
        isoCode
      )
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const RepairDetailsListRequest = createAsyncThunk<
  Array<DamageEstimateResource>,
  number,
  { rejectValue: string }
>('list/repairDetailsListRequestAsync', async (advanceInfoId, { rejectWithValue }) => {
  try {
    return await AdminContainerDamagedService.adminDepotDepotIdAdvanceInfoAdvanceInfoIdList(advanceInfoId)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const createEstimationRequest = createAsyncThunk<
  void,
  { advanceInfoId: number; requestBody?: CreateDamageEstimateRequest },
  { rejectValue: string }
>('create/createEstimationRequest', async ({ advanceInfoId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerDamagedService.adminAdvanceInfoAdvanceInfoIdDamageEstimatePost(
      advanceInfoId,
      requestBody
    )
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const deleteEstimationRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'delete/deleteEstimationRequest',
  async (estimateId, { rejectWithValue }) => {
    try {
      return await AdminContainerDamagedService.adminDamageEstimateDamageEstimateDelete(estimateId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

export const updateEstimationRequest = createAsyncThunk<
  void,
  { damageEstimate: number; requestBody: UpdateDamageEstimateRequest },
  { rejectValue: string }
>('update/updateEstimationRequest', async ({ damageEstimate, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerDamagedService.adminDamageEstimateDamageEstimatePatch(damageEstimate, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const setStatusRequest = createAsyncThunk<
  void,
  { advanceInfoId: number; requestBody: SetDamageStatusRequest },
  { rejectValue: string }
>('setStatus/setStatusRequest', async ({ advanceInfoId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerDamagedService.adminAdvanceInfoAdvanceInfoIdDamagedStatusUpdate(
      advanceInfoId,
      requestBody
    )
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})


export const moveToRepairingRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'moveToRepairing/moveToRepairingRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerRepairingService.adminAdvanceInfoAdvanceInfoIdRepairingPost(advanceInfoId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)
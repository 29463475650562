import { combineReducers } from '@reduxjs/toolkit'
import { createSliceBuilder, listSliceBuilder } from 'store/helper'
import type { IsoCodeResource } from 'api/main'
import { isoCodeListRequestAsync, createISoCodeRequestAsync, updateIsoCodeRequestAsync } from 'store/isoCodes/actions'

export const isoCodeReducers = combineReducers({
  list: listSliceBuilder<IsoCodeResource>('listIsoCode', isoCodeListRequestAsync).reducer,
  create: createSliceBuilder<IsoCodeResource>('createIsoCode', createISoCodeRequestAsync).reducer,
  update: createSliceBuilder<IsoCodeResource>('updateIsoCode', updateIsoCodeRequestAsync).reducer,
})

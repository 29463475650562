import { VoidFunctionComponent } from 'react'
import { Header as StyledHeader } from '../../UiKit/Layout'
import { Row } from 'antd'
import { colors } from '../../../constants/colors'
import { Logo } from '../index'
import HeaderAccount from './HeaderAccount'
import { useAppDispatch } from '../../../store/hooks'
import { logoutRequestAsync } from '../../../store/auth/login/asyncActions'

interface Props {
  name: string
  isLoadingn: boolean
}

const Header: VoidFunctionComponent<Props> = ({ name, isLoadingn }) => {
  const dispatch = useAppDispatch()
  const onClickLogout: () => void = () => {
    dispatch(logoutRequestAsync())
  }

  return (
    <StyledHeader>
      <Row justify="space-between" align="middle">
        <Logo color={colors.WHITE} />
        <HeaderAccount name={name} onLogout={onClickLogout} isLoadingn={isLoadingn} />
      </Row>
    </StyledHeader>
  )
}

export default Header


import { MappedOption } from 'models'
import type { NormalState } from 'models/ApiState'
import { RootState } from '../index'
import { AdminDepotResource } from 'api/main'
import {  AdminProfileResource } from 'api/main'


export const selectAboutMeStatus = (state: RootState): NormalState<AdminProfileResource>['status'] =>
state.auth.aboutMe.status

export const selectUserDepotsData = (state: RootState): Array<AdminDepotResource> | undefined => 
state.auth.aboutMe.data?.depots

export const selectUserNameData = (state: RootState): string | undefined => 
`${state.auth.aboutMe.data?.first_name ? `${state.auth.aboutMe.data?.first_name} ${state.auth.aboutMe.data?.last_name}` : ''}`

export const selectUserDepotsListMappedData = (state: RootState): MappedOption[] | undefined =>
  state.auth.aboutMe.data?.depots.map(lis => ({ label: lis.name, value: lis.id }))
import { SubmitHandler, useForm } from 'react-hook-form'
import { VoidFunctionComponent } from 'react'
import { AdminForgotPasswordRequest } from '../../../api/main'
import { Button, Form } from 'antd'
import Input from '../../UiKit/Inputs/Input'
import { yupResolver } from '@hookform/resolvers/yup'
import forgetPasswordSchema from '../../../schemas/forgetPasswordSchema'
import { InputType } from '../../../models'

interface Props {
  onSubmit: SubmitHandler<any>
  isSubmitting?: boolean
}

const ForgotPasswordForm: VoidFunctionComponent<Props> = ({ isSubmitting, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AdminForgotPasswordRequest>({ resolver: yupResolver(forgetPasswordSchema) })

  return (
    <Form name="forget-password" onFinish={handleSubmit(onSubmit)} noValidate>
      <Input
        errorClassName="ant-form-item-has-error"
        containerClassName="mb-6"
        control={control}
        name="email"
        placeholder="Enter email"
        type={InputType.EMAIL}
        error={errors.email?.message}
      />
      <Form.Item>
        <Button htmlType="submit" type="primary" className="w-100" loading={isSubmitting}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ForgotPasswordForm

import { RootState } from '../index'
import type { NormalState } from 'models/ApiState'
import { CompanyResource } from 'api/main'
import { MappedOption, ListState } from 'models'

export type CompaniesListState = ListState<CompanyResource>

export const selectCreateCompanyData = (state: RootState): NormalState<CompanyResource>['data'] =>
  state.companies.create.data
export const selectCreateCompanyStatus = (state: RootState): NormalState<CompanyResource>['status'] =>
  state.companies.create.status
export const selectCreateCompanyError = (state: RootState): NormalState<CompanyResource>['error'] =>
  state.companies.create.error

export const selectCompaniesListData = (state: RootState): CompaniesListState['data'] => state.companies.list.data
export const selectCompaniesListMappedData = (state: RootState): MappedOption[] =>
  state.companies.list.data.map(comp => ({ label: comp.name, value: comp.id }))
export const selectCompaniesListPaginationData = (state: RootState): CompaniesListState['paginationData'] =>
  state.companies.list.paginationData
export const selectCompaniesListError = (state: RootState): CompaniesListState['error'] => state.companies.list.error
export const selectCompaniesListStatus = (state: RootState): CompaniesListState['status'] => state.companies.list.status
export const selectCompanyWithId =
  (id: number) =>
  (state: RootState): CompanyResource | undefined =>
    state.companies.list.data.find(comp => comp.id === id)

export const tableSelectors = {
  data: selectCompaniesListData,
  status: selectCompaniesListStatus,
  pagination: selectCompaniesListPaginationData,
}

export const selectUpdateCompanyData = (state: RootState): NormalState<CompanyResource>['data'] =>
  state.companies.update.data
export const selectUpdateCompanyStatus = (state: RootState): NormalState<CompanyResource>['status'] =>
  state.companies.update.status
export const selectUpdateCompanyError = (state: RootState): NormalState<CompanyResource>['error'] =>
  state.companies.update.error

export const selectDeleteCompanyStatus = (state: RootState): NormalState<CompanyResource>['status'] =>
  state.companies.delete.status

import { useState, useEffect } from 'react'
import { PageHeader } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import HeaderExtra from 'Components/Shared/PageHeader/HeaderExtra'
import { useAppDispatch } from 'store/hooks'
import { MappedOption } from 'models'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import DamagedFilter from 'Components/Damageds/DamagedFilter/DamagedFilter'
import { DamagedFilterModel } from 'models/forms/DamagedFilterModel'
import { damagedListRequest } from 'store/damageds'
import { filterInitialData } from 'Components/Damageds/helper'
import FilterButton from 'Components/Shared/FilterButton'
import searchedParamUtils from 'utils/searchedParam'

type Props = {
  depot: MappedOption
  fieldName: containerLifeCyles
  containerNumber: string
}

const DamagedHeader = ({ depot, fieldName, containerNumber }: Props) => {
  const [searchParams, setSearchParams] = useState<DamagedFilterModel>(filterInitialData)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    dispatch(
      damagedListRequest({
        depotId: Number(depot.value),
        perPage: 20,
        page: 1,
        query: searchedParamUtils.getSearchedParam().searched || containerNumber,
        grade: searchParams.grade,
        stockType: searchParams.stockType,
        status: searchParams.status,
        isoCode: searchParams.isoCode,
      })
    )
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  }, [dispatch, depot.value, searchParams.grade, searchParams.stockType, searchParams.status, searchParams.isoCode, containerNumber])

  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false)
  const onPressFilter = () => setFilterModalVisible(true)
  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <PageHeader
        onBack={containerNumber ? goBack : undefined}
        ghost={false}
        title={lifeCyclesConfig[fieldName].title}
        extra={
          <HeaderExtra
            defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : containerNumber}
            searchPlaceholder="Search by container number, customer or reference"
            onSearch={(value: string) => {
              searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
              dispatch(
                damagedListRequest({
                  depotId: Number(depot.value),
                  perPage: 20,
                  page: 1,
                  query: value,
                })
              )
            }}
            buttonComponents={() => (
              <FilterButton<DamagedFilterModel>
                onClick={onPressFilter}
                initSearchVals={filterInitialData}
                newSearchVals={searchParams}
              />
            )}
          />
        }
      />
      <DamagedFilter
        visible={filterModalVisible}
        onSubmit={values => {
          setSearchParams(values)
          setFilterModalVisible(false)
        }}
        onCancel={() => setFilterModalVisible(false)}
        initialData={searchParams}
      />
    </>
  )
}

export default DamagedHeader

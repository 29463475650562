import { VoidFunctionComponent } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, Form } from 'antd'
import Input from '../../UiKit/Inputs/Input'
import { InputType, ResetPasswordFormData } from '../../../models'
import { LockOutlined } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import resetPasswordSchema from '../../../schemas/resetPasswordSchema'

interface Props {
  onSubmit: SubmitHandler<ResetPasswordFormData>
  isSubmitting?: boolean
}

const ResetPasswordForm: VoidFunctionComponent<Props> = ({ onSubmit, isSubmitting }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({ resolver: yupResolver(resetPasswordSchema) })

  return (
    <Form name="reset-password" onFinish={handleSubmit(onSubmit)} noValidate>
      <Input
        errorClassName="ant-form-item-has-error mb-1"
        containerClassName="mb-3"
        control={control}
        name="password"
        Icon={LockOutlined}
        placeholder="New password"
        type={InputType.PASSWORD}
        error={errors.password?.message}
      />
      <Input
        errorClassName="ant-form-item-has-error mb-1"
        containerClassName="mb-3"
        control={control}
        name="password_confirmation"
        Icon={LockOutlined}
        placeholder="Confirm password"
        type={InputType.PASSWORD}
        error={errors.password_confirmation?.message}
      />
      <Form.Item>
        <Button htmlType="submit" type="primary" className="w-100" loading={isSubmitting}>
          Confirm
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ResetPasswordForm

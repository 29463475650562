import {useState, VoidFunctionComponent} from 'react'
import { Table, Button, Space } from 'antd'
import type { RepairingAreaResource } from 'api/main'
import { MappedOption } from 'models'
import { useAppDispatch } from 'store/hooks'
import { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import CDTable from 'Components/Shared/CDTable'
import { tableSelectors } from 'store/repairings'
import ColumnWithDots from 'Components/Shared/ColumnWithDot'
import { gradeTextMap } from 'Components/Shared/LifeCyclePageFactory/helper'
import RepairingDetails from 'Components/Repairings/RepairingDetails'
import { showConfirmModal, MoveToNextStep } from 'store/app'
import lifeCyclesConfig from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import { repairingListRequest, moveToRepairedRequest, moveToWashingRequest } from 'store/repairings'
import { moveToAvailableRequest } from 'store/availables'
import { repairingActions, mapActionText,mapTextAndColor } from './helper'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}

const RepairingsTab: VoidFunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }: Props) => {
  const dispatch = useAppDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    onPaginateChange = (page: number) => {
        dispatch(
            repairingListRequest({
                depotId: Number(depot.value),
                perPage: 20,
                page: page,
                query: '',
            })
        )
        setCurrentPage(page)
    }
  const moveToRepaired = (item: RepairingAreaResource) => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to mark Container ${item.container_number} as Repaired?`,
        onYes: async () => {
          if (item.id) {
            const result = await dispatch(moveToRepairedRequest(item.id))
            if (!(result as any).error) {
              // TODO : wher continer should go after repair is done (for animation)
              dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.repairingArea].title))
              dispatch(
                repairingListRequest({
                  depotId: Number(depot.value),
                  perPage: 20,
                  page: currentPage,
                  query: '',
                })
              )
            }
          }
        },
        // confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const moveToWashing = (item: RepairingAreaResource) => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to move Container ${item.container_number} to Washing Area?`,
        onYes: async () => {
          if (item.id) {
            const result = await dispatch(moveToWashingRequest(item.id))
            if (!(result as any).error) {
              // TODO : wher continer should go after repair is done (for animation)
              dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.washingArea].title))
              dispatch(
                repairingListRequest({
                  depotId: Number(depot.value),
                  perPage: 20,
                  page: currentPage,
                  query: '',
                })
              )
            }
          }
        },
        // confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const moveToAvailable = (item: RepairingAreaResource) => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to move Container ${item.container_number} to Available Area?`,
        onYes: async () => {
          if (item.id) {
            const result = await dispatch(moveToAvailableRequest(item.id))
            if (!(result as any).error) {
              // TODO : wher continer should go after repair is done (for animation)
              dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.availableArea].title))
              dispatch(
                repairingListRequest({
                  depotId: Number(depot.value),
                  perPage: 20,
                  page: currentPage,
                  query: '',
                })
              )
            }
          }
        },
        // confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const renderAction = (_value: any, item: RepairingAreaResource) => (
    <Space>
      <Button
        className='p-0'
        type="link"
        onClick={() => {
          switch (item.action) {
            case repairingActions.repair_is_done:
              moveToRepaired(item)
              break
            case repairingActions.move_to_washing:
              moveToWashing(item)
              break
            case 'move_to_available':
              moveToAvailable(item)
              break
            default:
              break
          }
        }}
      >
        {mapActionText(item)}
      </Button>
    </Space>
  )
  return (
    <>
      <CDTable<RepairingAreaResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        configName={containerLifeCyles.repairingArea}
        onPaginate={onPaginateChange}
        expandable={{
          expandedRowRender: (record: RepairingAreaResource) => {
            return (
              record.damage_estimates && (
                <div style={{ margin: 0 }}>
                  <RepairingDetails initialData={record.damage_estimates} />
                </div>
              )
            )
          },
          // rowExpandable: (record: DamagedAreaResource) => {
          //   if (record.damage_estimates && record.damage_estimates.length > 0) {
          //     return true
          //   }
          //   return false
          // },
          expandIconColumnIndex: 7,
        }}
      >
        <Table.Column
          title="Grade"
          dataIndex="grade"
          render={(text: string, record: RepairingAreaResource) => (
            <p style={{ marginBottom: 0 }}>{gradeTextMap(text)}</p>
          )}
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          render={(text: string, record: RepairingAreaResource) => (
            <ColumnWithDots<RepairingAreaResource> record={record} mapper={mapTextAndColor} />
          )}
        />
        <Table.Column title="Actions" key="action" render={renderAction} />
      </CDTable>
    </>
  )
}

export default RepairingsTab


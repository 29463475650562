import { combineReducers } from '@reduxjs/toolkit'
import { listSliceBuilder, createSliceBuilder } from 'store/helper'
import type { CheckedAreaResource, CheckedAreaUpdateRequestBody } from 'api/main'
import { checkedListRequest, checkedUpdateRequest, moveToDamagedRequest } from 'store/checkeds'

export const checkedReducers = combineReducers({
  list: listSliceBuilder<CheckedAreaResource>('listCheckeds', checkedListRequest).reducer,
  update: createSliceBuilder<CheckedAreaUpdateRequestBody>('updatechecked', checkedUpdateRequest).reducer,
  moveToDamaged: createSliceBuilder<void>('moveToDamaged', moveToDamagedRequest).reducer,
})

import { useEffect } from 'react'
import { Form, Row, Col, Button } from 'antd'
import { SubmitHandler } from 'react-hook-form'
import { ArrivalUpdateRequest, ArrivalResource } from 'api/main'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Status, mapArrivalResToReq, MappedOption } from 'models'
import { EditArrivalFormFields } from './EditArrivalformFields'
import editArrivalSchema from 'schemas/editArrivalsSchema'
import { arrivalsListRequest, selectArrivalUpdateStatus, arrivalUpdateRequest } from 'store/arrivals'
import styled from 'styled-components'
import useCDForm from 'hooks/useCDForm'
type Props = {
  arrival: ArrivalResource
  depot: MappedOption
}

const EditArrival = ({ arrival, depot }: Props) => {
  const dispatch = useAppDispatch()
  const { handleSubmit, reset, renderInput } = useCDForm<ArrivalUpdateRequest>(EditArrivalFormFields,editArrivalSchema)

  const updateStatus = useAppSelector(selectArrivalUpdateStatus)

  const onSubmitForm: SubmitHandler<ArrivalUpdateRequest> = async values => {
    if (arrival.id) {
      const result = await dispatch(arrivalUpdateRequest({ arrivalId: arrival.id, requestBody: values }))
      if (!(result as any)?.error) {
        dispatch(arrivalsListRequest({ depotId: Number(depot.value), list: { perPage: 20, page: 1, query: '' } }))
      }
    }
  }
  useEffect(() => {
    reset(mapArrivalResToReq(arrival))
  }, [reset, arrival])

  return (
    <Form name="edit_arrival" layout="vertical" onFinish={handleSubmit(onSubmitForm)}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          {renderInput('arrival_license_plate_number')}
          {renderInput('arrival_transportation_company')}
        </Col>
        <Col span={8}>{renderInput('arrival_note')}</Col>
        <Col span={8}>
          <Header>Notes from Advance Info</Header>
          <p>{arrival && arrival.advance_info_note}</p>
        </Col>
      </Row>
      <Form.Item className="text-right">
        <Button htmlType="submit" type="primary" loading={updateStatus === Status.PENDING}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default EditArrival

const Header = styled.p`
  font-weight: 500;
`

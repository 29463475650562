import { OpenAPI } from './main'
import { tokenUtils } from '../utils'
type Headers = Record<string, string>;
export default function apiConfig() {
  OpenAPI.BASE = process.env.REACT_APP_API_URL || 'https://nklxqf2el0.execute-api.eu-central-1.amazonaws.com'
  OpenAPI.TOKEN = async (): Promise<string> => {
    return tokenUtils.getToken() || ''
  }
  OpenAPI.HEADERS = async (options: any):Promise<Headers> => {
    if (options.path.endsWith('advance_info/import/excel')) {
      options.formData = options.body
      options.body = undefined
    }
    return {}
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerInfoService,
  ContainerMaterialCollection,
} from 'api/main'
import { handleApiCallError } from 'store/helper'
import { GeneralListRequest } from 'models'

export const materialListRequestAsync = createAsyncThunk<
  ContainerMaterialCollection,
  GeneralListRequest,
  { rejectValue: string }
>('list/materialListRequestAsync', async ({ page, perPage, query }, { rejectWithValue }) => {
  try {
    return await AdminContainerInfoService.adminContainerMaterial(perPage, page, query)
  } catch (e: any) {
   return handleApiCallError(e, rejectWithValue)
  }
})

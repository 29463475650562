import { Table, Row, Col } from 'antd'
import { OutReferenceContainerInfoResource, OutReferenceResource } from 'api/main'
import { colors } from 'constants/colors'
import styled from 'styled-components'
import EditTableCell from 'Components/OutReferences/EditTableCell'

const infoColumns = [
  {
    title: 'size',
    dataIndex: 'size',
    key: 'size',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Total/Left',
    dataIndex: 'total_count',
    key: 'total_count',
    render: (text: string, record: OutReferenceContainerInfoResource) => (
      <>
        {`${record.total_count}/${record.left_count}`}
      </>
    )
  },
]

const OutReferenceDetail = ({ record }: { record: OutReferenceResource }) => {
  return (
    <RowStyled>
      <Col span={14}>
        <Row className='mb-5'>
          <EditTableCell title='Creation Date' text={record.created_at} />
          <EditTableCell title='Expiry Date' text={record.expiration_date} />
          <EditTableCell title='Lift Out Paid by' text={record.lift_out_paid_by} />
          <EditTableCell title='Lessee' text={record.lessee} />
          <EditTableCell title='HPL' text={record.HPL ? "Yes" : "No"} span={4} />
        </Row>
        <Row>
          <EditTableCell title='Note' text={record.note}  span={12} />
        </Row>
      </Col>
      <Col span={8} offset={2}>
        <TableStyled dataSource={record.container_info} columns={infoColumns} pagination={false} />
      </Col>
    </RowStyled>
  )
}

export default OutReferenceDetail

const RowStyled = styled(Row)`
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  background: ${colors.WHITE};
`

const TableStyled = styled(Table)`
border: 1px solid ${colors.GRAY};
`

import * as yup from 'yup'
import { EditEstimationFormFields } from 'Components/Damageds/RepairDetails/EditEstimation/EditEstimationFormFields'
import type { CreateDamageEstimateRequest } from 'api/main'

import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'

const editEstimationSchema = yup.object().shape<GeneralSchemaType<CreateDamageEstimateRequest>>({
  reference: yup.string().required(`'${EditEstimationFormFields.reference?.label}' is a required field`),
  number: yup.string().required(`'${EditEstimationFormFields.number?.label}' is a required field`),
  component: yup.string().required(`'${EditEstimationFormFields.component?.label}' is a required field`),
  damage: yup.string().required(`'${EditEstimationFormFields.damage?.label}' is a required field`),
  repair: yup.string().required(`'${EditEstimationFormFields.repair?.label}' is a required field`),
  location: yup.string().required(`'${EditEstimationFormFields.location?.label}' is a required field`),
  quantity: yup.string().required(`'${EditEstimationFormFields.quantity?.label}' is a required field`),
  length: yup.string().required(`'${EditEstimationFormFields.length?.label}' is a required field`),
  width: yup.string().required(`'${EditEstimationFormFields.width?.label}' is a required field`),
  labor_rate: yup.string().when('paid_by_third_party',{
    is: 'true',
    then: yup.string().required(`'${EditEstimationFormFields.labor_rate?.label}' is a required field`),
  }),
  // .required(`'${EditEstimationFormFields.labor_rate?.label}' is a required field`),
  hours: yup.string().required(`'${EditEstimationFormFields.hours?.label}' is a required field`),
  material: yup.string().required(`'${EditEstimationFormFields.material?.label}' is a required field`),
  paid_by_third_party: yup
    .string()
    .required(`'${EditEstimationFormFields.paid_by_third_party?.label}' is a required field`),
  company_id: yup.string().required(`'${EditEstimationFormFields.company_id?.label}' is a required field`),
})

export default editEstimationSchema

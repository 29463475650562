import * as yup from 'yup'
import { AddOutReferenceFormFields } from 'Components/OutReferences/AddOutReferenceFormFields'
import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'
import { CreateOutReferenceRequestBody } from 'api/main'

const OutReferenceSchem = yup.object().shape<GeneralSchemaType<CreateOutReferenceRequestBody>>({
  reference: yup.string().required(`'${AddOutReferenceFormFields.reference?.label}' is a required field`),
  stock_type_id: yup.string().required(`'${AddOutReferenceFormFields.stock_type_id?.label}' is a required field`),
  company_id: yup.string().required(`'${AddOutReferenceFormFields.company_id?.label}' is a required field`),
  grade: yup.string().required(`'${AddOutReferenceFormFields.grade?.label}' is a required field`),
  lift_out_paid_by_company_id: yup.string().required(`'${AddOutReferenceFormFields.lift_out_paid_by_company_id?.label}' is a required field`),
  HPL: yup.string().required(`'${AddOutReferenceFormFields.HPL?.label}' is a required field`),
  valid_from: yup.string().required(`'${AddOutReferenceFormFields.valid_from?.label}' is a required field`),
  expiration_date: yup.string().required(`'${AddOutReferenceFormFields.expiration_date?.label}' is a required field`),
})

export default OutReferenceSchem

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminAuthService, AdminResetPasswordRequest } from '../../../api/main'
import { handleApiCallError } from 'store/helper'

export const resetPasswordRequestAsync = createAsyncThunk<void, AdminResetPasswordRequest, { rejectValue: string }>(
  'resetPassword/resetPasswordRequestAsync',
  async (body, { rejectWithValue }) => {
    try {
      return await AdminAuthService.adminAuthPasswordReset(body)
    } catch (e) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

import { AppOutReferenceResource, DropOffToArrivalResourceResource, TruckPickUpCollectionResource } from 'api/main'
import type { NormalState } from 'models/ApiState'
import { RootState } from '../index'

export const selectDropOffStatus = (state: RootState): NormalState<void>['status'] =>
  state.ordering.dropOff.status
export const selectDropOffData = (state: RootState): NormalState<DropOffToArrivalResourceResource[]>['data'] =>
  state.ordering.dropOff.data

export const selectDropOffValidationOutStatus = (state: RootState): NormalState<void>['status'] =>
  state.ordering.dropOffValidation.status

export const selectPickUpListStatus = (state: RootState): NormalState<void>['status'] =>
  state.ordering.pickUpList.status
export const selectPickUpListData = (state: RootState): NormalState<AppOutReferenceResource>['data'] =>
  state.ordering.pickUpList.data

  export const selectPickUpStatus = (state: RootState): NormalState<void>['status'] =>
  state.ordering.pickUp.status
export const selectPickUpData = (state: RootState): NormalState<TruckPickUpCollectionResource>['data'] =>
  state.ordering.pickUp.data

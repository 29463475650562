import { ListState } from 'models'
import type { AdvanceInfoResource, AdvanceInfoWithDetailResource } from 'api/main'
import type { NormalState } from 'models/ApiState'
import { RootState } from '../index'

type AdvInfoListState = ListState<AdvanceInfoResource>
export const selectAdvInfoListData = (state: RootState): AdvInfoListState['data'] => state.advancedInfo.list.data
export const selectAdvInfoPaginationData = (state: RootState): AdvInfoListState['paginationData'] =>
  state.advancedInfo.list.paginationData

export const selectAdvInfoListStatus = (state: RootState): AdvInfoListState['status'] => state.advancedInfo.list.status
export const selectAdvInfoImportStatus = (state: RootState): NormalState<void>['status'] =>
  state.advancedInfo.import.status
export const selectAdvInfoDeleteStatus = (state: RootState): NormalState<void>['status'] =>
  state.advancedInfo.delete.status

export const selectAdvanceInfo = (state: RootState): NormalState<AdvanceInfoWithDetailResource>['data'] =>
  state.advancedInfo.get.data

export const selectAdvanceInfoStatus = (state: RootState): NormalState<AdvanceInfoWithDetailResource>['status'] =>
  state.advancedInfo.get.status

export const selectAdvInfoUpdateStatus = (state: RootState): NormalState<void>['status'] =>
  state.advancedInfo.update.status

export const selectMoveToArrivalStatus = (state: RootState): NormalState<void>['status'] =>
  state.advancedInfo.moveToArrival.status

export const tableSelectors = {
  data: selectAdvInfoListData,
  status: selectAdvInfoListStatus,
  pagination: selectAdvInfoPaginationData,
}

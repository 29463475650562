/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppUpdateUserRequest = {
    first_name?: string;
    last_name?: string;
    email?: string;
    language?: AppUpdateUserRequest.language;
}

export namespace AppUpdateUserRequest {

    export enum language {
        EN = 'en',
        FI = 'fi',
    }


}

import { useState, FunctionComponent } from 'react'
import { Table, Button, Space } from 'antd'
import { SubmitHandler } from 'react-hook-form'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import { showConfirmModal, MoveToNextStep } from 'store/app'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  moveToReleaseRequest,
  outReferenceDeleteRequest,
  outReferenceListRequest,
  selectMoveToReleaseStatus,
  tableSelectors,
} from 'store/outReference'
import type { OutReferenceResource, ReleaseOutReferenceRequestBody } from 'api/main'
import { Status, MappedOption, OutReferenceType, MappedOptionWithName } from 'models'
import MoveToReleaseModal from 'Components/OutReferences/MoveToReleaseModal'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import CDTable from 'Components/Shared/CDTable'
import OutReferenceDetail from 'Components/OutReferences/OutReferenceDetail'
import EditOutReference from 'Components/OutReferences/EditOutReference'
import { ContainerGrades, StockTypesInOutRef } from 'constants/ContainerCycleConsts'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const OutReferenceTab: FunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }) => {
  const [moveVisible, setMoveVisible] = useState(false)
  const [outReferenceId, setOutReferenceId] = useState<number>(0)
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
  const [updateValues, setUpdateValues] = useState<OutReferenceType>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const dispatch = useAppDispatch()
  const moveStatus = useAppSelector(selectMoveToReleaseStatus)
  const outReferenceRequest = (): void => {
    dispatch(outReferenceListRequest({ 
      depotId: Number(depot.value),
      list: { perPage: 20, page: currentPage, query: '' }
    }))
  }
  const onMove: SubmitHandler<ReleaseOutReferenceRequestBody> = async values => {
    const result = await dispatch(moveToReleaseRequest({ outReferenceId, requestBody: values }))
    if (!(result as any)?.error) {
      setMoveVisible(false)
      dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.outQueue].title))
      await outReferenceRequest()
    }
  }
  const onDeleteCallBack = async (id?: number): Promise<void> => {
    if (id) {
      await dispatch(outReferenceDeleteRequest(id))
      outReferenceRequest()
    }
  }
  const onDelete = async (id?: number, reference?: string): Promise<void> => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to delete out reference ${reference}`,
        onYes: () => onDeleteCallBack(id),
      })
    )
  }

  const onUpdate = (values: OutReferenceType) => {
    setUpdateValues(values)
    setEditModalVisible(true)
  }

  const renderAction = (_value: any, item: OutReferenceType & { reference: string }) => (
    <Space>
      <EditOutlined disabled style={{ color: colors.PRIMARY }} onClick={() => onUpdate(item)} />
      <DeleteOutlined
        style={item.total_count === item.left_count ? { color: colors.RED } : { color: colors.GRAY2 }}
        onClick={ item.total_count === item.left_count ? () => item.id && onDelete(item.id, item.reference) : undefined}
      />
      <Button
        type="link"
        onClick={() => {
          if (item.id) {
            setOutReferenceId(item.id)
            setUpdateValues(item)
          }
          if (item.action === 'released') {
            return
          }
          setMoveVisible(true)
        }}
        disabled={item.action === 'expired' || item.action === 'not_valid'}
        style={ item.action === 'released' ? { color: "#52C41A", cursor: 'default' } : {} }
      >
        {item.action}
      </Button>
    </Space>
  )
  return (
    <>
      <CDTable<OutReferenceResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        expandable={{
          expandedRowRender: (record: OutReferenceResource) => {
            if (expandedId) {
              return <OutReferenceDetail record={record} />
            }
            return record.id ? <OutReferenceDetail record={record} /> : null
          },
          expandIconColumnIndex: 7,
        }}
        configName={containerLifeCyles.outReference}
        onPaginate={(page) => {
          setCurrentPage(page)
          if (onPaginateChange) {
            onPaginateChange(page)
          }
        }
        }>
        <Table.Column
          title="Stock type"
          dataIndex="stock_type"
          render={(_, record: OutReferenceResource) => (
            <p style={{ marginBottom: 0 }}>{StockTypesInOutRef.find((item: MappedOptionWithName) => item?.name === record.stock_type)?.label}</p>
          )}
        />
        <Table.Column
          title="Grade"
          dataIndex="grade"
          render={(_, record: OutReferenceResource) => (
            <p style={{ marginBottom: 0 }}>{ContainerGrades.find((item: MappedOption) => item.value === record.grade)?.label}</p>
          )}
        />
        <Table.Column
          title="Valid From"
          dataIndex="valid_from"
          render={(_, record: OutReferenceResource) => (
            <p style={{ marginBottom: 0 }}>{record.valid_from}</p>
          )}
        />
        <Table.Column
          title="Total/Left"
          dataIndex="grade"
          render={(text: string, record: OutReferenceResource) => (
            <p style={{ marginBottom: 0 }}>{`${record.total_count}/${record.left_count}`}</p>
          )}
        />
        <Table.Column title="Action" key="action" render={renderAction} />
      </CDTable>
      <MoveToReleaseModal
        onSubmit={onMove}
        visible={moveVisible}
        loading={moveStatus === Status.PENDING}
        onCancel={() => {
          setMoveVisible(false)
        }}
        containerInfo={updateValues?.container_info}
      />
      {
        editModalVisible ? (
          <EditOutReference
            onCancel={() => setEditModalVisible(false)}
            visible={editModalVisible}
            depot={depot}
            defaultValues={updateValues}
            currentPage={currentPage}
          />
        ) : null
      }
    </>
  )
}

export default OutReferenceTab

import { useState, useEffect, VoidFunctionComponent } from 'react'
import { PageHeader, Table, Button, Space } from 'antd'
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  RepairDetailsListRequest,
  selectRepairDetailsListData,
  selectRepairDetailsLisStatus,
  deleteEstimationRequest,
  deleteEstimationStatus,
} from 'store/damageds'
import type { DamageEstimateResource, UpdateDamageEstimateRequest } from 'api/main'
import { Status } from 'models'
import { colors } from 'constants/colors'
import { showConfirmModal } from 'store/app'
import { selectUserDepotsListMappedData } from 'store/auth/selectors'
import EditEstimationModal from 'Components/Damageds/RepairDetails/EditEstimation/EditEstimationModal'
import { mapEstimateResReq } from 'models'
import { estmInintialData } from 'Components/Damageds/helper'
import RepairColumn from 'Components/Shared/RepairColumn'

const RepairDetailsPage: VoidFunctionComponent = () => {
  const { depotId, advInfoId, companyId, containerNumber } = useParams<{ depotId: string; advInfoId: string; companyId: string, containerNumber: string }>()
  const [selectedEstimate, setSelectedEstimate] = useState<UpdateDamageEstimateRequest>(estmInintialData)
  const [selectedEstimateId, setSelectedEstimateId] = useState<number | undefined>(0)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const listData = useAppSelector(selectRepairDetailsListData)
  const listStatus = useAppSelector(selectRepairDetailsLisStatus)
  const deleteStatus = useAppSelector(deleteEstimationStatus)
  const depots = useAppSelector(selectUserDepotsListMappedData)
  const theDepot = depots?.find(depot => depot.value === Number(depotId))

  const [estimateVisible, setEstimateVisible] = useState<boolean>(false)

  const history = useHistory()

  const renderAction = (_value: any, item: DamageEstimateResource) => {
    return (
      <Space>
        <EditOutlined style={{ color: colors.PRIMARY }} onClick={() => onUpdate(item)} />
        <DeleteOutlined style={{ color: colors.RED }} onClick={() => item.id && onDelete(item.id)} />
      </Space>
    )
  }

  const onDelete = (estimateId: number) => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to delete ${estimateId}?`,
        onYes: async () => {
          const result = await dispatch(deleteEstimationRequest(estimateId))
          if (!(result as any).error) {
            dispatch(RepairDetailsListRequest(Number(advInfoId)))
          }
        },
        confirmLoading: deleteStatus === Status.PENDING,
      })
    )
  }

  const onUpdate = (values: DamageEstimateResource) => {
    setIsUpdate(true)
    setSelectedEstimate(mapEstimateResReq(values))
    setSelectedEstimateId(values.id)
    setEstimateVisible(true)
  }

  useEffect(() => {
    dispatch(RepairDetailsListRequest(Number(advInfoId)))
  }, [dispatch, depotId, advInfoId])

  return (
    <>
      <PageHeader
        ghost={false}
        title={`Repair Details - Container ${containerNumber}`}
        extra={
          <ButtonWrapper>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setIsUpdate(false)
                setSelectedEstimate(estmInintialData)
                setSelectedEstimateId(undefined)
                setEstimateVisible(true)
              }}
            >
              Add New Item
            </Button>
          </ButtonWrapper>
        }
        onBack={history.goBack}
      />
      <Table
        dataSource={listData || undefined}
        rowKey={rc => rc.id || 0}
        locale={{ emptyText: 'No result' }}
        className="mt-3"
        loading={listStatus === Status.PENDING}
        scroll={{ x: true }}
        pagination={false}
      >
        <Table.Column title="Item number" dataIndex="item_number" />
        <Table.Column title="Approval date" dataIndex={'approval_date'} />
        <Table.Column title="Start date" dataIndex="start_date" />
        <Table.Column title="Reference" dataIndex="reference" />
        <Table.Column title="Estimate number" dataIndex="estimate_number" />
        <Table.Column title="Component" dataIndex="component" />
        <Table.Column title="Damage" dataIndex="damage" />
        <Table.Column
          title="Repair"
          dataIndex="repair"
          render={(text: string, record: DamageEstimateResource) => <RepairColumn record={record} />}
        />
        <Table.Column title="Location" dataIndex="location" />
        <Table.Column title="Quantity" dataIndex="quantity" />
        <Table.Column fixed="right" title="Action" key="action" render={renderAction} />
      </Table>
      {theDepot && estimateVisible && (
        <EditEstimationModal
          visible={estimateVisible}
          onCancel={() => {
            setEstimateVisible(false)
            setSelectedEstimate(estmInintialData)
            setSelectedEstimateId(undefined)
          }}
          initialData={{ ...selectedEstimate, company_id: Number(companyId) }}
          depot={theDepot}
          advInfoId={Number(advInfoId)}
          isUpdate={isUpdate}
          estimateId={selectedEstimateId}
        />
      )}
    </>
  )
}

export default RepairDetailsPage

const ButtonWrapper = styled.span`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`
// const RepairContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `

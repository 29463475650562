import type { RepairingAreaResource } from 'api/main'
import { colors } from 'constants/colors'

export enum repairingActions {
    move_to_available='move_to_available',
    repair_is_done= 'repair_is_done',
    move_to_washing = 'move_to_washing'
}
export enum repairingStatus {
    repairing_area_repaired = 'repairing_area_repaired',
    repairing_area = 'repairing_area'
}

export const mapActionText = (record: RepairingAreaResource): string => {
    switch (record.action) {
      case repairingActions.move_to_available:
        return 'Move to Available Area'
      case repairingActions.repair_is_done:
        return 'Repair is Done'
      case repairingActions.move_to_washing:
        return 'Move to Washing Area'
      default:
        return '-'
    }
  }

  export const mapTextAndColor = (record: RepairingAreaResource): [string, string] => {
    switch (record.status) {
      case repairingStatus.repairing_area_repaired:
        return ['Repair Done', colors.GREEN]
      case repairingStatus.repairing_area:
        return ['In Repair', colors.YELLOW_WARNING]
      default:
        return ['-', colors.YELLOW_WARNING]
    }
  }
  
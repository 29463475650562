import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { IsoCodeRequest } from 'api/main'
import { InputType } from 'models'

export const IsoCodeFormFields: GeneralFormFieldType<IsoCodeRequest> = {
  code: {
    label: 'ISO Code',
    placeholder: 'ISO Code',
  },
  container_type_id: {
    label: 'Container Type',
    placeholder: 'Select one Type',
    type: InputType.SELECT
  },
  container_material_id: {
    label: 'Container Material',
    placeholder: 'Select one Material',
    type: InputType.SELECT
  },
  height: {
    label: 'Height',
    placeholder: 'Height',
  },
  width: {
    label: 'Width',
    placeholder: 'Width',
  },
  length: {
    label: 'Length',
    placeholder: 'Length',
  },
  teu: {
    label: 'Teu',
    placeholder: 'Teu',
  },
  max_gross_weight:{
    label: 'Max Gross Weight (kg)',
    placeholder: 'Max Gross Weight (kg)',
  },
  description: {
    label: 'Description',
    placeholder: 'Description',
    type:InputType.TEXTAREA
  },
}

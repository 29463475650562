import { useState } from 'react'
import { useEffect } from 'react'
import { PageHeader } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { outReferenceListRequest } from 'store/outReference'
import { MappedOption } from 'models'
import { OutReferenceFilterModel } from 'models/forms/OutReferenceFilterModel'
import { OutReferenceFilterInitialData } from 'Components/OutReferences/helper'
import HeaderExtra from 'Components/Shared/PageHeader/HeaderExtra'
import FilterButton from 'Components/Shared/FilterButton'
import OutRefrenceFilter from 'Components/OutReferences/OutRefrenceFilter/OutRefrenceFilter'
import AddOutReference from 'Components/OutReferences/AddOutReference'
import searchedParamUtils from 'utils/searchedParam'

type Props = {
  depot: MappedOption
  page: number
  containerNumber: string
}
const OutReferenceHeader = ({ depot, page, containerNumber }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false)
  const [filterModalVisible, setFilterModalVisible] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useState<OutReferenceFilterModel>(OutReferenceFilterInitialData)
  const onPressFilter = () => setFilterModalVisible(true)
  const onPressCreate = () => {
    setAddModalVisible(true)
  }

  useEffect(() => {
    dispatch(outReferenceListRequest({
      depotId: Number(depot.value),
      list: { perPage: 20, page, query:  searchedParamUtils.getSearchedParam().searched || containerNumber },
      creationFrom: searchParams.creation[0],
      creationTo: searchParams.creation[1],
      validFrom: searchParams.valid[0],
      validTo: searchParams.valid[1],
      expirationFrom: searchParams.expiration[0],
      expirationTo: searchParams.expiration[1],
      stockType: searchParams.stockType,
      grade: searchParams.grade,
    }))
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  }, [
    dispatch,
    depot.value,
    page,
    containerNumber,
    searchParams.grade,
    searchParams.stockType,
    searchParams.creation,
    searchParams.valid,
    searchParams.expiration,
  ])

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      <PageHeader
        onBack={containerNumber ? goBack : undefined}
        ghost={false}
        title="Out Reference"
        extra={
          <HeaderExtra
            defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : containerNumber}
            searchPlaceholder="Search by container number, customer or reference"
            onSearch={(value: string) => {
              searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
              dispatch(
                outReferenceListRequest({
                  depotId: Number(depot.value),
                  list: { perPage: 20, page: 1, query: value },
                  creationFrom: searchParams.creation[0],
                  creationTo: searchParams.creation[1],
                  validFrom: searchParams.valid[0],
                  validTo: searchParams.valid[1],
                  expirationFrom: searchParams.expiration[0],
                  expirationTo: searchParams.expiration[1],
                  stockType: searchParams.stockType,
                  grade: searchParams.grade,
                })
              )
            }}
            buttons={[{ title: 'New Out Reference', onClick: onPressCreate }]}
            buttonComponents={() => (
              <FilterButton<OutReferenceFilterModel>
                onClick={onPressFilter}
                initSearchVals={OutReferenceFilterInitialData}
                newSearchVals={searchParams}
              />
            )}
          />
        }
      />
      <OutRefrenceFilter
        visible={filterModalVisible}
        onSubmit={values => {
          setSearchParams(values)
          setFilterModalVisible(false)
        }}
        onCancel={() => setFilterModalVisible(false)}
        initialData={searchParams}
      />
      <AddOutReference onCancel={() => setAddModalVisible(false)} visible={addModalVisible} depot={depot} />
    </>
  )
}

export default OutReferenceHeader

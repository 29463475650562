/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckedAreaCollection } from '../models/CheckedAreaCollection';
import type { CheckedAreaUpdateRequestBody } from '../models/CheckedAreaUpdateRequestBody';
import type { FromCheckingToCheckedRequestBody } from '../models/FromCheckingToCheckedRequestBody';
import { request as __request } from '../core/request';

export class AdminContainerCheckedService {

    /**
     * Get Checked Area list
     * This end point will return all Checked Area information.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over Checked Area.
     * @returns CheckedAreaCollection Checking area collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdCheckedList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<CheckedAreaCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/checked`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * From checking to checked state
     * With this end point you can move an advanceInfo from checking state to checked.
     * @param advanceInfoId
     * @param requestBody From checking to checked
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoAdvanceInfoIdCheckedPost(
        advanceInfoId: number,
        requestBody?: FromCheckingToCheckedRequestBody,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/advance_info/${advanceInfoId}/checked`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Update checked area information
     * This end point will update information of the selected checked area.
     * @param advanceInfoId
     * @param requestBody Checked area update request body
     * @returns void
     * @throws ApiError
     */
    public static async adminCheckedUpdate(
        advanceInfoId: number,
        requestBody?: CheckedAreaUpdateRequestBody,
    ): Promise<void> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/advance_info/${advanceInfoId}/checked`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
import { createSlice, PayloadAction, combineReducers } from '@reduxjs/toolkit'


export interface ShowPayload {
  message: string
  confirmLoading?: boolean
  onYes?: () => void
  onNo?: () => void
}
export interface ConfirmState extends ShowPayload {
  visible: boolean
}

const confirmInitialState: ConfirmState = {
  message: '',
  visible: false,
  confirmLoading: false,
  onYes: () => {},
  onNo: () => {},
}

export const ConfirmSlice = createSlice({
  name: 'confirm',
  initialState: confirmInitialState,
  reducers: {
    showConfirmModal: (state, action: PayloadAction<ShowPayload>) => {
      state.message = action.payload.message
      state.visible = true
      state.confirmLoading = action.payload.confirmLoading
      state.onYes = action.payload.onYes
      state.onNo = action.payload.onNo
    },
    hideConfirmModal: (state, action: PayloadAction<{ onNo?: () => void }>) => {
      state.message = confirmInitialState.message
      state.visible = confirmInitialState.visible
      state.confirmLoading = confirmInitialState.confirmLoading
      state.onYes = confirmInitialState.onYes
      state.onNo = action.payload.onNo || confirmInitialState.onNo
    },
  },
})

// Action creators are generated for each case reducer function
export const { showConfirmModal, hideConfirmModal } = ConfirmSlice.actions


const menuBadgeInitialState: {nextStep:string} = {
  nextStep: '',
}

export const menuBadgeSlice = createSlice({
  name: 'menuBadge',
  initialState: menuBadgeInitialState,
  reducers: {
    MoveToNextStep: (state, action: PayloadAction<string>) => {
      state.nextStep = action.payload
    },
  },
})

export const {MoveToNextStep} = menuBadgeSlice.actions

export default combineReducers({ confirmModal: ConfirmSlice.reducer, menuBadge: menuBadgeSlice.reducer })

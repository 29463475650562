import { FunctionComponent, useEffect, useState } from 'react'
import { Alert, Typography } from 'antd'
import { Logo } from '../../Shared'
import LoginForm from './LoginForm'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { loginRequestAsync } from 'store/auth/login/asyncActions'
import { adminAboutMeRequest } from 'store/auth/actions'

import { SubmitHandler } from 'react-hook-form'
import { AppLoginRequest } from '../../../api/main'
import { Status } from '../../../models'
import { selectLoginError, selectLoginStatus } from '../../../store/auth/login'

const { Title } = Typography

export const LoginPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectLoginStatus)
  const error: any = useAppSelector(selectLoginError)
  const [errors, setErrors] = useState<any>(null)

  useEffect(() => {
    if (error) {
      setErrors(error)
    }
  }, [error])

  const onSubmitLogin: SubmitHandler<AppLoginRequest> = async data => {
    await dispatch(loginRequestAsync(data))
    dispatch(adminAboutMeRequest())
  }

  return (
    <>
      {error && <Alert className="mb-6" message={errors ? errors.message : ''} type="error" showIcon closable />}
      <Logo isResponsive />
      <Title level={4} className="mv-6 mv-xs-3 text-xs-center">
        Log in
      </Title>
      <LoginForm onSubmit={onSubmitLogin} isSubmitting={status === Status.PENDING} />
    </>
  )
}

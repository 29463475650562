import { useState, VoidFunctionComponent } from 'react'
import { Sidebar as StyledSidebar } from '../../UiKit/Layout'
import SidebarCollapseTrigger from '../../UiKit/Layout/Sidebar/SidebarCollapseTrigger'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import SidebarMenu from './SidebarMenu'
import { MenuItem } from '../../../models'

const defaultWidth = 208
const collapsedWidth = 80

interface Props {
  menuItems: MenuItem[]
}

const Sidebar: VoidFunctionComponent<Props> = ({ menuItems }) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <StyledSidebar
      theme="light"
      breakpoint="lg"
      onBreakpoint={broken => {
        setCollapsed(broken)
      }}
      collapsible
      collapsed={collapsed}
      trigger={null}
      width={defaultWidth}
    >
      <SidebarMenu items={menuItems} />
      <SidebarCollapseTrigger
        className="ant-layout-sider-trigger"
        collapsed={collapsed}
        defaultWidth={defaultWidth}
        collapsedWidth={collapsedWidth}
        onClick={() => setCollapsed(c => !c)}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </SidebarCollapseTrigger>
    </StyledSidebar>
  )
}

export default Sidebar

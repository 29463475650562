/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOutReferenceRequestBody } from '../models/CreateOutReferenceRequestBody';
import type { OutReferenceCollection } from '../models/OutReferenceCollection';
import type { OutReferenceUpdateRequestBody } from '../models/OutReferenceUpdateRequestBody';
import type { ReleaseOutReferenceRequestBody } from '../models/ReleaseOutReferenceRequestBody';
import { request as __request } from '../core/request';

export class AdminContainerOutReferenceService {

    /**
     * Get Out Reference list
     * This end point will return all out reference information.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over Out References.
     * @param creationFrom
     * @param creationTo
     * @param validFrom
     * @param validTo
     * @param expirationFrom
     * @param expirationTo
     * @param stockType
     * @param grade
     * @returns OutReferenceCollection Out Reference collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdOutReferenceList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
        creationFrom?: string,
        creationTo?: string,
        validFrom?: string,
        validTo?: string,
        expirationFrom?: string,
        expirationTo?: string,
        stockType?: string,
        grade?: string,
    ): Promise<OutReferenceCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/out_reference`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
                'creation_from': creationFrom,
                'creation_to': creationTo,
                'valid_from': validFrom,
                'valid_to': validTo,
                'expiration_from': expirationFrom,
                'expiration_to': expirationTo,
                'stock_type': stockType,
                'grade': grade,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Create an out reference for the given depot
     * With this end point you can create an out reference for the depot.
     * @param depotId
     * @param requestBody Create out reference
     * @returns void
     * @throws ApiError
     */
    public static async adminDepotDepotIdOutReferencePost(
        depotId: number,
        requestBody?: CreateOutReferenceRequestBody,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/depot/${depotId}/out_reference`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Delete out reference
     * With this end point you can delete an out reference.
     * @param outReference
     * @returns void
     * @throws ApiError
     */
    public static async adminOutReferenceOutReferenceDelete(
        outReference: number,
    ): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/admin/out_reference/${outReference}`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * Release part of an out reference
     * With this end point you can release part of an out reference.
     * @param outReference
     * @param requestBody Release out reference
     * @returns void
     * @throws ApiError
     */
    public static async adminOutReferenceOutReferenceReleasePost(
        outReference: number,
        requestBody?: ReleaseOutReferenceRequestBody,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/out_reference/${outReference}/release`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * Update out reference information
     * This end point will update information of the selected out reference.
     * @param outReferenceId
     * @param requestBody Out reference update request body
     * @returns void
     * @throws ApiError
     */
    public static async adminOutReferenceUpdate(
        outReferenceId: number,
        requestBody?: OutReferenceUpdateRequestBody,
    ): Promise<void> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/admin/out_reference/${outReferenceId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
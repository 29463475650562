import { createAsyncThunk } from '@reduxjs/toolkit'
import { DepotFeesCollection, AdminCompanyService, CompanyDepotRequest } from 'api/main'
import { handleApiCallError } from 'store/helper'

export const invoicesListRequestAsync = createAsyncThunk<DepotFeesCollection, number, { rejectValue: string }>(
  'list/invoicesListRequestAsync',
  async (companyId, { rejectWithValue }) => {
    try {
      return await AdminCompanyService.apiAdminCompanyCompanyIdDepotGet(companyId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

type UpdateInvoiceType = {
  companyId: number
  depotId: number
  requestBody: CompanyDepotRequest
}

export const updateInvoiceRequestAsync = createAsyncThunk<void, UpdateInvoiceType, { rejectValue: string }>(
  'updateInvoice/updateInvoiceRequestAsync',
  async (body, { rejectWithValue }) => {
    const { companyId, depotId, requestBody } = body
    try {
      return await AdminCompanyService.apiAdminCompanyCompanyIdDepotDepotIdUpdate(companyId, depotId, requestBody)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

type DeleteInvoiceType = {
  companyId: number
  depotId: number
}

export const deleteInvoiceRequestAsync = createAsyncThunk<void, DeleteInvoiceType, { rejectValue: string }>(
  'deleteInvoice/deleteInvoiceRequestAsync',
  async (body, { rejectWithValue }) => {
    const { companyId, depotId } = body
    try {
      return await AdminCompanyService.apiAdminCompanyCompanyIdDepotDepotIdDelete(companyId, depotId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

type NewInvoiceType = {
  companyId: number
  depotId: number
  requestBody: CompanyDepotRequest
}

export const createInvoiceRequestAsync = createAsyncThunk<void, NewInvoiceType, { rejectValue: string }>(
  'createInvoice/createInvoiceRequestAsync',
  async (body, { rejectWithValue }) => {
    const { companyId, depotId, requestBody } = body
    try {
      return await AdminCompanyService.apiAdminCompanyCompanyIdDepotDepotIdCreate(companyId, depotId, requestBody)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

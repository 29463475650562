import * as yup from 'yup'
import { EditArrivalFormFields } from 'Components/Arrivals/EditArrivalformFields'
import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'
import { ArrivalUpdateRequest } from 'api/main'

const editArrivalSchema = yup.object().shape<GeneralSchemaType<ArrivalUpdateRequest>>({
  arrival_note: yup.string(),
  arrival_transportation_company: yup
    .string()
    .required(`'${EditArrivalFormFields.arrival_transportation_company?.label}' is a required field`),
  arrival_license_plate_number: yup.string(),
})

export default editArrivalSchema

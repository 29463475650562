import { ListState } from 'models'
import type { RepairingAreaResource } from 'api/main'
// import type { NormalState } from 'models/ApiState'
import { RootState } from '../index'

type repairingListState = ListState<RepairingAreaResource>
export const selectRepairingListData = (state: RootState): repairingListState['data'] => state.repairings.list.data
export const selectRepairingPaginationData = (state: RootState): repairingListState['paginationData'] =>
  state.repairings.list.paginationData

export const selectRepairingListStatus = (state: RootState): repairingListState['status'] => state.repairings.list.status

export const tableSelectors = {
  data: selectRepairingListData,
  status: selectRepairingListStatus,
  pagination: selectRepairingPaginationData,
}
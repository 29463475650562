import { VoidFunctionComponent } from 'react'
import { Row, Col, Typography } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { OutQueueResource } from 'api/main'
import EditTableCell from 'Components/OutReferences/EditTableCell'
import { colors } from 'constants/colors'

const { Text } = Typography

interface Props {
  record: OutQueueResource
  onDelete: (id: number, out_reference_id: string) => Promise<void>
}

const OutReferenceDetail: VoidFunctionComponent<Props> = ({ record, onDelete }: Props) => {
  return (
    <Row>
      <EditTableCell span={9} title='Notes' text={record.note} />
      <EditTableCell span={5}  title='Out Queue Creation Date' text={record.creation_date} />
      <EditTableCell span={6}  title='Out Queue Creation Time' text={record.creation_time} />
      <Col span={4} onClick={() => record.id && record.out_reference_id && onDelete(record.id, record.out_reference_id)} style={{ cursor: 'pointer' }}>
        <DeleteOutlined style={{ color: colors.RED }} />
        <Text className='ml-2' type='danger'>Delete from Out Queue</Text>
      </Col>
    </Row>
  )
}

export default OutReferenceDetail

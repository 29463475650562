import { combineReducers } from '@reduxjs/toolkit'
import { createSliceBuilder, listSliceBuilder } from 'store/helper'
import {
  createInvoiceRequestAsync,
  deleteInvoiceRequestAsync,
  invoicesListRequestAsync,
  updateInvoiceRequestAsync,
} from 'store/invoices/actions'

import type { CompanyDepotFeesResource } from 'api/main/models/CompanyDepotFeesResource'

export const invoicesReducers = combineReducers({
  create: createSliceBuilder<CompanyDepotFeesResource>('createInvoice', createInvoiceRequestAsync).reducer,
  list: listSliceBuilder<CompanyDepotFeesResource>('listInvoice', invoicesListRequestAsync).reducer,
  delete: createSliceBuilder<CompanyDepotFeesResource>('deleteInvoice', deleteInvoiceRequestAsync).reducer,
  update: createSliceBuilder<CompanyDepotFeesResource>('updateInvoice', updateInvoiceRequestAsync).reducer,
})

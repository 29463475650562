import { MappedOption, ListState } from 'models'
import { AdminDepotResource } from 'api/main'
import { RootState } from '../index'

export type DepotsListState = ListState<AdminDepotResource>

export const selectDepotsListData = (state: RootState): DepotsListState['data'] => state.depots?.list.data
export const selectDepotsListMappedData = (state: RootState): MappedOption[] =>
  state.depots.list.data.map(lis => ({ label: lis.name, value: lis.id }))
export const selectDepotsListStatus = (state: RootState): DepotsListState['status'] => state.depots?.list.status
export const selectDepotsListError = (state: RootState): DepotsListState['error'] => state.depots?.list.error

export const selectDepotData =
  (depotId: number): ((state: RootState) => AdminDepotResource | undefined) =>
  (state: RootState): AdminDepotResource | undefined =>
    state.depots?.list.data.find(depot => depot.id === depotId)

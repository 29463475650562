import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { DamagedStates, DamagedStatus, ContainerGrades } from 'constants/ContainerCycleConsts'
import {SetDamageStatusRequest} from 'api/main'

export const SetStatusFormFields: GeneralFormFieldType<SetDamageStatusRequest> = {
  status: {
    label: 'Status',
    placeholder: 'Status',
    type: InputType.RADIO,
    options: DamagedStatus,
  },
  approval_date: {
    label: 'Authorization Date',
    placeholder: 'Authorization Date',
    type: InputType.DATE,
  },
  reject_state: {
    label: 'State',
    placeholder: 'State',
    type: InputType.RADIO,
    options: DamagedStates,
  },
  grade: {
    label: 'Grade',
    placeholder: 'Grade',
    type: InputType.SELECT,
    options: ContainerGrades,
  },
  reject_note: {
    label: 'Notes (optional)',
    placeholder: 'Notes (optional)',
    type: InputType.TEXTAREA,
  },
}

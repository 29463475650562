// import { Table } from 'antd'
import type { DamageEstimateResource } from 'api/main'
import DropSvg from 'assets/images/drop-silhouette.svg'
import Icon, { ToolFilled } from '@ant-design/icons'

import styled from 'styled-components'

const DropIcon = (props: any) => <Icon component={() => <img alt="dropIcon" src={DropSvg} />} {...props} />

interface Props {
  record: DamageEstimateResource
}

const RepairColumn = ({ record }: Props) => (
  <RepairContainer>
    {record.repair}
    <IconWrapper>{record.icon === 'washing_icon' ? <DropIcon /> : <ToolFilled />}</IconWrapper>
  </RepairContainer>
)

export default RepairColumn
const RepairContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
`
const IconWrapper = styled.span`
  margin-left: 1px;
`

import { connect } from 'react-redux'
import { PrivateRoutePresentation, PrivateRoutePresentationProps } from '../RoutePresentation'
import { RouteProps } from 'react-router-dom'
import { RootState } from '../../store'
import { Status } from '../../models'
import { RecordObject } from '../Auth/guard'

export const MainGuardRoute = connect<PrivateRoutePresentationProps, RecordObject, RouteProps, RootState>(
  (state: RootState): PrivateRoutePresentationProps => {
    const { status } = state.auth.login
    return {
      isAuthorized: status === Status.SUCCEEDED,
      pathname: localStorage.getItem('path') || '/login',
    }
  }
)(PrivateRoutePresentation)

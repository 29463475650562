export const gradeTextMap = (gradeVal: string): string => {
  switch (gradeVal) {
    case 'food':
      return 'Food'
    case 'paper':
      return 'Paper'
    case 'general_cargo':
      return 'General Cargo'
    case 'scrap':
      return 'Scrap'
    default:
      return ''
  }
}

export const stateTextMap = (stateVal: string): string => {
  switch (stateVal) {
    case 'advance_info':
      return 'Advance Info'
    case 'arrival':
      return 'Arrivals'
    case 'checking_area':
      return 'Checking'
    case 'checked_area':
      return 'Checked'
    case 'damaged_area':
      return 'Damaged'
    case 'repairing_area':
      return 'Repairing'
    case 'washing_area':
      return 'Washing'
    case 'available':
      return 'Available'
    case 'released':
      return 'Released'
    default:
      return ''
  }
}

import styled from '@emotion/styled-base'
import { Pagination as AntPagination } from 'antd'
import { colors } from 'constants/colors'

const Pagination = styled(AntPagination)`
  align-self: self-end;
  float: right;
  padding-top: 25px;
  background-color: ${colors.GRAY};
`

export default Pagination

import { useEffect } from 'react'
import { Form, Card, Row, Col, Spin, Input as AntInput, Button } from 'antd'
import { SubmitHandler, useWatch } from 'react-hook-form'
import { Text } from 'Components/UiKit/Typography'
import { AdvInfoFormFields } from './AdvInfoFormFields'
import {
  selectAdvanceInfoStatus,
  selectAdvanceInfo,
  advInfoUpdateRequest,
  selectAdvInfoUpdateStatus,
} from 'store/advInfo'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Status, mapAdvInfoResToReq, MappedOption } from 'models'
import { AdvanceInfoUpdateRequest } from 'api/main'
import advInfoSchema from 'schemas/AdvInfoSchema'
import { selectCompaniesListMappedData } from 'store/companies'
import { liftInInvoiceTypes, liftOutInvoiceTypes, rentInvoiceTypes, Currencies } from 'constants/ContainerCycleConsts'
import { selectMaterialListMappedData } from 'store/material'
import styled from 'styled-components'
import useCDForm from 'hooks/useCDForm'

interface Props {
  advInfoId: number
}
type advInfoRequestKeys = keyof AdvanceInfoUpdateRequest

const AdvInfoEdit = ({ advInfoId }: Props) => {
  const { handleSubmit, reset, control, renderInput } = useCDForm<AdvanceInfoUpdateRequest>(
    AdvInfoFormFields,
    advInfoSchema
  )

  const hasLessee = useWatch({
    control,
    name: 'has_lease_info',
  })

  const dispatch = useAppDispatch()
  const advInfoGetStatus = useAppSelector(selectAdvanceInfoStatus)
  const advnfoGetData = useAppSelector(selectAdvanceInfo)
  const companiesData = useAppSelector(selectCompaniesListMappedData)
  const materialsData = useAppSelector(selectMaterialListMappedData)
  const updateStatus = useAppSelector(selectAdvInfoUpdateStatus)

  const onSubmitForm: SubmitHandler<AdvanceInfoUpdateRequest> = async values => {
    dispatch(advInfoUpdateRequest({ advanceInfoId: advInfoId, requestBody: values }))
  }

  const renderPaidBy = (paidBy: advInfoRequestKeys, payType: advInfoRequestKeys, payTypes: MappedOption[]) => {
    return (
      <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
        <p>{AdvInfoFormFields[paidBy]?.label}</p>
        <FlexInputGroup compact>
          {renderInput(payType, payTypes, false)}
          <InputWrapper>{renderInput(paidBy, companiesData, false)}</InputWrapper>
        </FlexInputGroup>
      </Col>
    )
  }

  useEffect(() => {
    if (advnfoGetData) {
      reset(mapAdvInfoResToReq(advnfoGetData))
    }
  }, [reset, advnfoGetData])

  return (
    <>
      <Spin spinning={advInfoGetStatus === Status.PENDING}>
        <Form layout="vertical" name="iso_code" onFinish={handleSubmit(onSubmitForm)}>
          <Row gutter={[16, 16]}>
            <Col span={9}>
              <Card style={{ height: '100%' }}>
                {renderInput('container_csc_date')}
                <Row gutter={[8, 8]}>
                  <Col span={12}>{renderInput('container_max_gross_weight')}</Col>
                  <Col span={12}>{renderInput('container_tare_weight')}</Col>
                </Row>
                {renderInput('container_material', materialsData)}
                {renderInput('expiration_date')}
                {renderInput('note')}
              </Card>
            </Col>
            <Col span={15}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card>
                    <Row>
                      <SwitchLabel>On Lease</SwitchLabel>
                      <Col span={1}>{renderInput('has_lease_info', undefined, false, false)}</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>{renderInput('lease_info_lessee', undefined, true, !hasLessee)}</Col>
                      <Col span={12}>{renderInput('container_manufacturing_date', undefined, true, !hasLessee)}</Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>{renderInput('lease_info_on_hire_date', undefined, true, !hasLessee)}</Col>
                      <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                        <Label>{AdvInfoFormFields.lease_info_dpp_amount?.label}</Label>
                        <FlexInputGroup compact className="w-100">
                          <InputWrapper>
                            {renderInput('lease_info_dpp_amount', undefined, false, !hasLessee)}
                          </InputWrapper>
                          {renderInput('lease_info_dpp_currency', Currencies, false, !hasLessee)}
                        </FlexInputGroup>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card>
                    <Row gutter={[8, 8]}>
                      {renderPaidBy('lift_in_paid_by', 'lift_in_invoice_type', liftInInvoiceTypes)}
                      {renderPaidBy('rent_paid_by', 'rent_invoice_type', rentInvoiceTypes)}
                    </Row>
                    <Row gutter={[8, 8]}>
                      {renderPaidBy('lift_out_paid_by', 'lift_out_invoice_type', liftOutInvoiceTypes)}
                    </Row>
                    <Form.Item className="text-right">
                      <Button htmlType="submit" type="primary" loading={updateStatus === Status.PENDING}>
                        Save
                      </Button>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  )
}

export default AdvInfoEdit

const Label = styled(Text)`
  display: block;
  margin: 0 0 8px;
`
const SwitchLabel = styled(Text)`
  font-weight: 500;
  display: inline-block;
  margin-top: 5px;
  margin-right: 14px;
  margin-bottom: 20px;
`
const FlexInputGroup = styled(AntInput.Group)`
  display: flex !important;
`
const InputWrapper = styled.span`
  flex-grow: 1;
`

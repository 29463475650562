/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContainerMaterialCollection } from '../models/ContainerMaterialCollection';
import type { ContainerStockTypeCollection } from '../models/ContainerStockTypeCollection';
import type { ContainerTypeCollection } from '../models/ContainerTypeCollection';
import { request as __request } from '../core/request';

export class AdminContainerInfoService {

    /**
     * Get container material list
     * this end point will return all container materials information.
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over container materials.
     * @returns ContainerMaterialCollection ContainerMaterial collection response
     * @throws ApiError
     */
    public static async adminContainerMaterial(
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<ContainerMaterialCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/container/material`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Get container stock types list
     * This end point will return all container stock types.
     * @returns ContainerStockTypeCollection Stock type collection response
     * @throws ApiError
     */
    public static async adminContainerStockTypes(): Promise<ContainerStockTypeCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/container/stock_type`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

    /**
     * Get container type list
     * this end point will return all container types information.
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over container types.
     * @returns ContainerTypeCollection ContainerType collection response
     * @throws ApiError
     */
    public static async adminContainerType(
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<ContainerTypeCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/container/type`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

}
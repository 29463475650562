import { useEffect, useRef } from 'react'

export default function useFirstRender(): boolean {
  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
  })

  return mountedRef.current
}

import { combineReducers } from '@reduxjs/toolkit'
import loginReducer from './login'
import resetPasswordReducer from './resetPassword'
import forgotPasswordReducer from './forgotPassword'
import { createSliceBuilder } from 'store/helper'
import { AdminProfileResource } from 'api/main'
import {adminAboutMeRequest} from './actions'

export const authReducers = combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  aboutMe:  createSliceBuilder<AdminProfileResource>('deleteCompany', adminAboutMeRequest).reducer,
})

import { useEffect, VoidFunctionComponent } from 'react'
import { Form, Modal } from 'antd'
import { SubmitHandler, useWatch } from 'react-hook-form'
import { SetStatusFormFields } from 'Components/Damageds/SetStatus/SetStatusFormFields'
import { SetDamageStatusRequest } from 'api/main'
import useCDForm from 'hooks/useCDForm'

interface Props {
  onSubmit: SubmitHandler<SetDamageStatusRequest>
  onCancel: () => void
  loading?: boolean
  visible?: boolean
  initialData:SetDamageStatusRequest
}

const SetStatusModal: VoidFunctionComponent<Props> = ({ onSubmit, visible, onCancel, loading,initialData }) => {
  const { handleSubmit, reset, renderInput, control } = useCDForm<SetDamageStatusRequest>(SetStatusFormFields)

  useEffect(() => {
    if (visible) {
      reset(initialData)
    }
  }, [initialData, reset, visible])

  const onCancelForm = () => {
    onCancel()
  }

  const status = useWatch({
    control,
    name: 'status',
  })

  const state = useWatch({
    control,
    name: 'reject_state',
  })
  return (
    <Modal
      visible={visible}
      title="Set Status"
      okText="Set"
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelForm}
      confirmLoading={loading}
    >
      <Form name="set_status" layout="vertical">
        <p style={{ fontWeight: 500 }}>{SetStatusFormFields.status?.label}</p>
        {renderInput('status', SetStatusFormFields.status?.options, false)}
        {status === 'approved' && (
          <>
            <p style={{ fontWeight: 500 }}>{SetStatusFormFields.approval_date?.label}</p>
            {renderInput('approval_date', undefined, false)}
          </>
        )}
        {status !== 'approved' && (
          <>
            <p style={{ fontWeight: 500 }}>{SetStatusFormFields.reject_state?.label}</p>
            {renderInput('reject_state', SetStatusFormFields.reject_state?.options, false)}
            <p style={{ fontWeight: 500 }}>{SetStatusFormFields.grade?.label}</p>
            {renderInput('grade', SetStatusFormFields.grade?.options, false, state === 'keep_in_damaged')}
            <p style={{ fontWeight: 500 }}>{SetStatusFormFields.reject_note?.label}</p>
            {renderInput('reject_note', undefined, false)}
          </>
        )}
      </Form>
    </Modal>
  )
}

export default SetStatusModal

import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { InputType } from 'models'
import { ArrivalUpdateRequest } from 'api/main'

export const EditArrivalFormFields: GeneralFormFieldType<ArrivalUpdateRequest> = {
  arrival_license_plate_number: {
    label: 'License Plate Number',
    placeholder: 'License Plate Number',
  },
  arrival_transportation_company: {
    label: 'Transportation Company',
    placeholder: 'Company Name',
  },
  arrival_note: {
    label: 'Notes',
    placeholder: 'Put your notes',
    type: InputType.TEXTAREA,
  },
}

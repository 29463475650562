import { VoidFunctionComponent, useEffect, useState } from 'react'
import { Form, Button, Typography, Input, Col, Row, Select, Divider, InputNumber } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { DepotType, LastAction } from 'Components/Ordering/OrderingTypes'
import { Status } from 'models'
import { pickUpListRequest, pickUpRequest, selectPickUpListData, selectPickUpListStatus } from 'store/ordering'
import { AppTruckPickUpRequest } from 'api/main'
import { callbackUtils } from 'utils'

const { Title, Text } = Typography

interface Props {
    selectedDepot: DepotType
    failed: (lastAction: LastAction) => void
    success: () => void
    depotId: number,
}

const validateMessages = {
    // Todo: the lable has to be sent like this structure in antd validation rules
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required!',
    types: {
    // eslint-disable-next-line no-template-curly-in-string
      email: '${label} is not a valid email!',
    },
  }

// eslint-disable-next-line sonarjs/cognitive-complexity
const ChooseAction: VoidFunctionComponent<Props> = ({ selectedDepot, failed, success, depotId }) => {
    const dispatch = useAppDispatch()
    const containerInfo = useAppSelector<any>(selectPickUpListData)
    const containerInfoStatus = useAppSelector<Status>(selectPickUpListStatus)
    const [reference, setReference] = useState<string>('')
    const [checkContainersValidation, setContainersValidation] = useState<undefined | 'validating' | 'error' | 'success'>(undefined)
    const [form] = Form.useForm()

    useEffect(() => {
        if(reference) {
            dispatch(pickUpListRequest({ reference }))
        }
    }, [dispatch, reference])

    useEffect(() => {
        switch (containerInfoStatus) {
            case Status.PENDING:
                setContainersValidation('validating')
                break
            case Status.SUCCEEDED:
                setContainersValidation('success')
                break
            case Status.FAILED:
                setContainersValidation('error')
                break
            default:
                setContainersValidation(undefined)
        }
    }, [containerInfoStatus])

    const onFinish = async (values: AppTruckPickUpRequest): Promise<void> => {
        const result = await dispatch(pickUpRequest({
            reference: containerInfo?.id,
            requestBody: {
                depot_id: depotId,
                company_email: values.company_email,
                transportation_company: values.transportation_company,
                license_plate_number: values.license_plate_number,
                container_infos: values.container_infos,
            }
        }))

        if ((result as any)?.error) {
            const errObject = (result as any)?.payload?.errors
            const errArray: string[] = []
            if (errObject) {
                Object.keys(errObject).forEach(key => {
                errObject[key] && errArray.push(errObject[key][0])
                })
            }
            failed(LastAction.PICK_UP)
            setContainersValidation('error')
        } else {
            setContainersValidation('success')
            success()
        }
        setContainersValidation(undefined)
    }

    const debouncedSearch = callbackUtils.debounce((value: string) => setReference(value), 500)

    const handleChange = async (value:  string) => {
        debouncedSearch(value)
    }

    return (
        <>
            <Title level={5} className='mb-6'>Container Pick Up - {selectedDepot}</Title>
            <Form
                form={form}
                name="pickUp"
                layout='vertical'
                onFinish={onFinish}
                validateMessages={validateMessages}
                autoComplete='off'
            >
            <Row gutter={[24, 8]}>
                <Col span={11} className='ml-2'>
                    <Text strong>Basic Information</Text>
                    <Form.Item
                        className='mt-3'
                        name="reference"
                        label="Out Reference ID"
                        rules={[{ required: true }]}
                        hasFeedback={ !!reference && checkContainersValidation !== undefined && containerInfoStatus !== Status.IDLE}
                        validateStatus={!!reference ? checkContainersValidation : undefined}
                    >
                        <Input onChange={(e) => handleChange(e.target.value)} placeholder='Out Reference ID' />
                    </Form.Item>
                    <Form.Item
                        name="license_plate_number"
                        label="License Plate Number"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder='License Plate Number' />
                    </Form.Item>
                    <Form.Item
                        name="transportation_company"
                        label="Transportation Company"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder='Transportation Company' />
                    </Form.Item>
                    <Form.Item
                        name="company_email"
                        label="Company Email"
                        rules={[{ type: 'email' }]}
                        initialValue={null}
                    >
                        <Input placeholder='Company Email' />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <Divider type='vertical' className='h-100-percent ml-3' />
                </Col>
                <Col span={11} className='ml-3'>
                    <Text strong>Containers</Text>
                    <Row className='mb-3' ></Row>
                    <Form.List
                        name="container_infos"
                        rules={[
                            {
                            validator: async (_, container_infos) => {
                                if (!container_infos || container_infos.length < 1) {
                                return Promise.reject(new Error('At least 1 container information is required!'))
                                }
                            },
                            },
                        ]}
                        initialValue={[{ size: undefined, type_id: undefined, count: undefined }]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                            {fields?.map((field, index) => (
                                <Row key={field.key} gutter={[16, 16]}>
                                    <Col span={7}>
                                        <Form.Item
                                            {...field}
                                            label="Size"
                                            name={[field.name, 'size']}
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                disabled={containerInfoStatus !== Status.SUCCEEDED}
                                                placeholder="Size"
                                                loading={containerInfoStatus === Status.PENDING}
                                            >
                                                {containerInfo?.rows_info?.sizes?.map((item: any) => (
                                                    <Select.Option key={item} value={Number(item)}>
                                                        {item}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            {...field}
                                            label="Type"
                                            name={[field.name, 'type_id']}
                                            rules={[{ required: true }]}
                                        >
                                        <Select
                                            disabled={containerInfoStatus !== Status.SUCCEEDED}
                                            placeholder="Type"
                                            loading={containerInfoStatus === Status.PENDING}
                                        >
                                            {containerInfo?.rows_info?.types?.map((item: any) => (
                                                <Select.Option key={item.id} value={Number(item.id)}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            {...field}
                                            label="Count"
                                            name={[field.name, 'count']}
                                            rules={[{ required: true }]}
                                        >
                                        <InputNumber
                                            min={0}
                                            max={containerInfo?.rows_info?.max_count}
                                            disabled={containerInfoStatus !== Status.SUCCEEDED}
                                            placeholder='Count'
                                        />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <MinusCircleOutlined hidden={!index} className='dynamic-delete-button' onClick={(): void => remove(field.name)} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Col span={21}>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Size, Type, Count
                                    </Button>
                                </Col>
                            <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                </Col>
            </Row>
            <Row justify='end'>
                    <Form.Item>
                        <Button
                            disabled={containerInfoStatus === Status.FAILED}
                            type="primary"
                            htmlType="submit"
                        >
                            Send Request
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    )
}

export default ChooseAction

import { VoidFunctionComponent, useState, useEffect } from 'react'
import { Table, Button, Row, Col } from 'antd'
import { FileTextOutlined } from '@ant-design/icons'
import moment from 'moment'
import { tableSelectors } from 'store/damageds'
import type { DamagedAreaResource, SetDamageStatusRequest } from 'api/main'
import { MappedOption } from 'models'
import { useAppDispatch } from 'store/hooks'
import { useHistory } from 'react-router-dom'
import { colors } from 'constants/colors'
import CDTable from 'Components/Shared/CDTable'
import SetStatusModal from 'Components/Damageds/SetStatus/SetStatusModal'
import { showConfirmModal, MoveToNextStep } from 'store/app'
import { damagedListRequest, setStatusRequest, moveToRepairingRequest } from 'store/damageds'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import EditEstimationModal from 'Components/Damageds/RepairDetails/EditEstimation/EditEstimationModal'
import DamgeRepairEstimateDetails from 'Components/Damageds/DamgeRepairEstimateDetails'
import { estmInintialData, mapTextAndColor, mapActionText,damagedActions } from 'Components/Damageds/helper'
import { gradeTextMap } from 'Components/Shared/LifeCyclePageFactory/helper'
import ColumnWithDots from 'Components/Shared/ColumnWithDot'
import { moveToWashingRequest } from 'store/repairings'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}

const DamagedsTab: VoidFunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }: Props) => {
  const [statusVisible, setStatusVisible] = useState<boolean>(false)
  const [selected, setSelected] = useState<DamagedAreaResource>()
  const [estimateVisible, setEstimateVisible] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
    onPaginateChange = (page: number) => {
        dispatch(
            damagedListRequest({
                depotId: Number(depot.value),
                perPage: 20,
                page: page,
                query: '',
            }))
        setCurrentPage(page)
    }
  const dispatch = useAppDispatch()
  const history = useHistory()

  const moveToRepairing = (item: DamagedAreaResource) => {
    setSelected(undefined)
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to move Container ${item.container_number} to Repairing Area?`,
        onYes: async () => {
          if (item.id) {
            const result = await dispatch(moveToRepairingRequest(item.id))
            if (!(result as any).error) {
              dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.repairingArea].title))
              dispatch(
                damagedListRequest({
                  depotId: Number(depot.value),
                  perPage: 20,
                  page: currentPage,
                  query: '',
                })
              )
            }
          }
        },
        // confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const moveToWashing = (item: DamagedAreaResource) => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to move Container ${item.container_number} to Washing Area?`,
        onYes: async () => {
          if (item.id) {
            const result = await dispatch(moveToWashingRequest(item.id))
            if (!(result as any).error) {
              // TODO : wher continer should go after repair is done (for animation)
              dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.washingArea].title))
              dispatch(
                damagedListRequest({
                  depotId: Number(depot.value),
                  perPage: 20,
                  page: currentPage,
                  query: '',
                })
              )
            }
          }
        },
        // confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const onStatusSet = async (values: SetDamageStatusRequest) => {
    if (selected?.id) {
      const result = await dispatch(setStatusRequest({ advanceInfoId: selected?.id, requestBody: values }))
      if (!(result as any).error) {
        dispatch(
          damagedListRequest({
            depotId: Number(depot.value),
            perPage: 20,
            page: currentPage,
            query: '',
          })
        )
      }
    }
    setSelected(undefined)
  }

  useEffect(() => {
    if (selected) {
      switch (selected.action) {
        case damagedActions.add_estimation:
          setEstimateVisible(true)
          break
        case damagedActions.set_status:
          setStatusVisible(true)
          break
        case damagedActions.move_to_washing:
          moveToWashing(selected)
          break
        case damagedActions.move_to_repairing:
        case damagedActions.move_to_available:
          moveToRepairing(selected)
          break
        case '-':
          break
        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const renderAction = (_value: any, item: DamagedAreaResource) => (
    <Row align="middle">
      <Col span={20}>
        <Button
          className='p-0'
          type="link"
          onClick={() => {
            setSelected(item)
          }}
        >
          {mapActionText(item)}
        </Button>
      </Col>
      <Col>
        <FileTextOutlined
          style={{ color: colors.PRIMARY }}
          onClick={() => {
            history.push(`/repair-details/${depot.value}/${item.id}/${item.customer_id}/${item.container_number}`)
          }}
        />
      </Col>
    </Row>
  )
  return (
    <>
      <CDTable<DamagedAreaResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        configName={containerLifeCyles.damagedArea}
        onPaginate={onPaginateChange}
        expandable={{
          expandedRowRender: (record: DamagedAreaResource) => {
            return (
              record.damage_estimates && (
                <div style={{ margin: 0 }}>
                  <DamgeRepairEstimateDetails initialData={record.damage_estimates} />
                </div>
              )
            )
          },
          rowExpandable: (record: DamagedAreaResource) => {
            if (record.damage_estimates && record.damage_estimates.length > 0) {
              return true
            }
            return false
          },
          expandIconColumnIndex: 7,
        }}
      >
        <Table.Column
          title="Grade"
          dataIndex="grade"
          render={(text: string, record: DamagedAreaResource) => (
            <p style={{ marginBottom: 0 }}>{gradeTextMap(text)}</p>
          )}
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          render={(text: string, record: DamagedAreaResource) => (
            <ColumnWithDots<DamagedAreaResource> record={record} mapper={mapTextAndColor} />
          )}
        />
        <Table.Column title="Actions" key="action" render={renderAction} />
      </CDTable>
      <SetStatusModal
        visible={statusVisible}
        onSubmit={values => {
          onStatusSet(values)
          setStatusVisible(false)
        }}
        onCancel={() => {
          setStatusVisible(false)
          setSelected(undefined)
        }}
        initialData={{ status: 'approved', approval_date: moment().format('MM-DD-YYYY') }}
      />
      {selected?.id && estimateVisible && (
        <EditEstimationModal
          visible={estimateVisible}
          onCancel={() => {
            setEstimateVisible(false)
            setSelected(undefined)
          }}
          initialData={{ ...estmInintialData, company_id: selected?.customer_id || estmInintialData.company_id }}
          depot={depot}
          advInfoId={selected?.id}
          onSubmit={() => {
            dispatch(
              damagedListRequest({
                depotId: Number(depot.value),
                perPage: 20,
                page: currentPage,
                query: '',
              })
            )
            setEstimateVisible(false)
            setSelected(undefined)
          }}
        />
      )}
    </>
  )
}

export default DamagedsTab

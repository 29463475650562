import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { MappedOption } from 'models'
import Input from 'Components/UiKit/Inputs/Input'

function useCdForm<T>(fieldsObj: GeneralFormFieldType<T>, schema?: Yup.AnyObjectSchema) {
  const {
    getValues,
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<T>({ resolver: schema && yupResolver(schema) })

  const renderInput = (
    fieldName: keyof T,
    options?: MappedOption[],
    withLabel: boolean = true,
    disabled: boolean = false,
    changeHandler: () => void = () => {},
    required?: boolean,
  ) => {
    return (
      <Input
        label={withLabel ? fieldsObj[fieldName]?.label : undefined}
        errorClassName="ant-form-item-has-error"
        control={control}
        name={fieldName.toString()}
        placeholder={fieldsObj[fieldName]?.placeholder || ''}
        options={options}
        type={fieldsObj[fieldName]?.type}
        error={(errors as any)[fieldName]?.message}
        disabled={disabled}
        changeHandler={changeHandler}
        required={required}
      />
    )
  }
  return { getValues, setValue, handleSubmit, reset, renderInput, control }
}

export default useCdForm

import { MappedOption, MappedOptionWithName } from 'models'
import {damagedStatuses} from 'Components/Damageds/helper'
import {repairingStatus} from 'Components/Repairings/helper'

export const liftInInvoiceTypes: MappedOption[] = [
  { label: 'vessel', value: 'vessel' },
  {
    label: 'special',
    value: 'special',
  },
  {
    label: 'yes',
    value: 'yes',
  },
  {
    label: 'no',
    value: 'no',
  },
]
export const liftOutInvoiceTypes: MappedOption[] = [
  { label: 'vessel', value: 'vessel' },
  {
    label: 'special',
    value: 'special',
  },
  {
    label: 'yes',
    value: 'yes',
  },
  {
    label: 'no',
    value: 'no',
  },
]
export const rentInvoiceTypes: MappedOption[] = [
  {
    label: 'special',
    value: 'special',
  },
  {
    label: 'yes',
    value: 'yes',
  },
  {
    label: 'no',
    value: 'no',
  },
]

export const Currencies: MappedOption[] = [
  {
    label: 'EURO',
    value: 'EURO',
  },
  {
    label: 'USD',
    value: 'USD',
  },
]

export const ContainerGrades: MappedOption[] = [
  {
    label: 'Food',
    value: 'food',
  },
  {
    label: 'Paper',
    value: 'paper',
  },
  {
    label: 'General Cargo',
    value: 'general_cargo',
  },
  {
    label: 'Scrap',
    value: 'scrap',
  },
]

export const ContainerConditions: MappedOption[] = [
  {
    label: 'Available',
    value: 'available',
  },
  {
    label: 'Damaged',
    value: 'damaged',
  },
]

export const StockTypes: MappedOption[] = [
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Reserved to Reference',
    value: 'reserved_to_reference',
  },
  {
    label: 'Repair Rejected',
    value: 'repair_refused',
  },
  {
    label: 'Out by Number',
    value: 'out_by_number',
  },
  {
    label: 'Sales',
    value: 'sales',
  },
]

export const StockTypesId: MappedOption[] = [
  {
    label: 'Normal',
    value: '1',
  },
  {
    label: 'Reserved to Reference',
    value: '2',
  },
  {
    label: 'Repair Rejected',
    value: '3',
  },
  {
    label: 'Out by Number',
    value: '4',
  },
  {
    label: 'Sales',
    value: '5',
  },
]

export const StockTypesInOutRef: MappedOptionWithName[] = [
  {
    label: 'Normal',
    name: 'normal',
    value: 1,
  },
  {
    label: 'Reserved to Reference',
    name: 'reserved_to_reference',
    value: 4,
  },
  {
    label: 'Repair Refused',
    name: 'repair_refused',
    value: 5,
  },
  {
    label: 'Out By Number',
    name: 'out_by_number',
    value: 3,
  },
  {
    label: 'Sales',
    name: 'sales',
    value: 2,
  },
]

export const DamagedStatus: MappedOption[] = [
  {
    value: damagedStatuses.approved,
    label: 'Approved',
  },
  {
    value: damagedStatuses.rejected,
    label: 'Rejected',
  },
]

export const DamagedStates: MappedOption[] = [
  {
    value: 'available',
    label: 'Available',
  },
  {
    value: 'keep_in_damaged',
    label: 'Keep in Damaged',
  },
]

export const DamagedFilterStates: MappedOption[] = [
  {
    value: 'available',
    label: 'Rejected  - Available',
  },
  {
    value: 'keep_in_damaged',
    label: 'Rejected - Keep in Damaged',
  },
]

export const RepairingStatus: MappedOption[] = [
  {
    value: repairingStatus.repairing_area,
    label: 'In Repair',
  },
  {
    value: repairingStatus.repairing_area_repaired,
    label: 'Repair Done',
  },
]

export const WashingStatus: MappedOption[] = [
  {
    value: 'washing_area',
    label: 'In Wash',
  },
  {
    value: 'washing_area_washed',
    label: 'Wash Done',
  },
]

export const HPLTypes: MappedOption[] = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
]

export const CountainerSize: MappedOption[] = [
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 45,
    label: '45',
  },
]

import { VoidFunctionComponent } from 'react'
import { Logo } from '../../Shared'
import { Alert, Typography } from 'antd'
import ForgotPasswordForm from './ForgotPasswordForm'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectForgotPassError, selectForgotPassStatus } from '../../../store/auth/forgotPassword'
import { Status } from '../../../models'
import { forgotPasswordRequestAsync } from '../../../store/auth/forgotPassword/asyncActions'
import { AdminForgotPasswordRequest } from '../../../api/main'

const { Title } = Typography

const ForgotPasswordPage: VoidFunctionComponent = () => {
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectForgotPassStatus)
  const error = useAppSelector(selectForgotPassError)

  const onSubmitForgot = (values: AdminForgotPasswordRequest) => {
    dispatch(forgotPasswordRequestAsync(values))
  }

  return (
    <>
      {error && <Alert className="mb-6" message={error} type="error" showIcon closable />}
      {status === Status.SUCCEEDED && (
        <Alert
          className="mb-6"
          message="If entered email is found in our database, you’ll get an email with a link needed to reset your password."
          type="success"
          showIcon
          closable
        />
      )}
      <Logo isResponsive />
      <Title level={4} className="mv-6 mv-xs-3 text-xs-center">
        Reset Password
      </Title>
      <ForgotPasswordForm onSubmit={onSubmitForgot} isSubmitting={status === Status.PENDING} />
    </>
  )
}

export default ForgotPasswordPage

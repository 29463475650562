import { VoidFunctionComponent } from 'react'
import { Col, Row, Image, Typography, Button } from 'antd'
import closeCircle from '../../assets/images/close-circle.png'


interface Props {
    goHome: () => void
    tryAgain: () => void
}

const FailedRequest: VoidFunctionComponent<Props> = ({ goHome, tryAgain }) => {
    return (
        <>
            <Row justify="center">
                <Image className='mb-3' preview={false} src={closeCircle} alt="failed" />
            </Row>
            <Row justify="center" className='mb-2'>
                <Typography.Title level={5}>Request Failed</Typography.Title>
            </Row>
            <Row justify="center" className='mb-3'>
                <Typography.Text>Please check your Request before resubmitting.</Typography.Text>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <Row justify="end">
                        <Button type='primary' onClick={goHome}>
                            Go Home
                        </Button>
                    </Row>
                </Col>
                <Col span={12}>
                    <Button type='default' onClick={tryAgain}>
                        Try Again
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default FailedRequest

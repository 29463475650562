import { useState, VoidFunctionComponent, ReactElement } from 'react'
import { Modal, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { advInfoImportRequest, selectAdvInfoImportStatus, advInfoListRequest } from 'store/advInfo'
import ErrorToast from 'Components/UiKit/ErrorToast'
import { UploadFile } from 'antd/lib/upload/interface'
import { MappedOption, Status } from 'models'
import { colors } from 'constants/colors'
import {Text} from 'Components/UiKit/Typography'

const { Dragger } = Upload

interface Props {
  onCancel: () => void
  depot: MappedOption
  visible?: boolean
}

const AddAdvInfo: VoidFunctionComponent<Props> = ({ visible, onCancel, depot }) => {
  const dispatch = useAppDispatch()

  const [fileList, setFileList] = useState<UploadFile<any>[]>([])
  const [errList, setErrList] = useState<string[]>([])
  const uploadStatus = useAppSelector(selectAdvInfoImportStatus)

  const onCancelForm = () => {
    setFileList([])
    setErrList([])
    onCancel()
  }

  const uploadDoc = async () => {
    const file = fileList[0]
    const result = await dispatch(
      advInfoImportRequest({ depotId: Number(depot.value), requestBody: { advance_info: file } })
    )
    if ((result as any)?.error) {
      const errObject = (result as any)?.payload?.errors
      const errArray: string[] = []
      if (errObject) {
        Object.keys(errObject).forEach(key => {
          errObject[key] && errArray.push(errObject[key][0])
        })
      }
      setErrList(errArray)
      const newFile = fileList[0]
      newFile.status = 'error'
      setFileList([newFile])
    } else {
      dispatch(advInfoListRequest({ depotId: Number(depot.value), list: { perPage: 20, page: 1, query: '' } }))

      onCancelForm()
    }
  }

  const props = {
    name: 'file',
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    multiple: false,
    onRemove: (file: any) => {
      setFileList([])
      setErrList([])
    },
    itemRender: (originNode: ReactElement, file: any, fileList: object[], actions: any) => {
      return (
        <div>
          {originNode}
          <ErrorToast decriptionArr={errList} color={colors.RED} />
        </div>
      )
    },
    beforeUpload: (file: UploadFile) => {
      setFileList([file])
      setErrList([])
      return false
    },
  }

  return (
    <Modal
      visible={visible}
      title="New Advance Info"
      okText="Upload"
      onOk={uploadDoc}
      okButtonProps={{ disabled: fileList.length < 1, loading: uploadStatus === Status.PENDING }}
      onCancel={onCancelForm}
      bodyStyle={{ padding: 0 }}
    >
      <Dragger {...props} fileList={fileList}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text"  data-testid="test-upload-text">{`Click or drag file to this area for ${depot.label} depot`}</p>
        <p className="ant-upload-hint">You can also <Text underline color={colors.PRIMARY}>download</Text> the sample file, fill it in and upload to the system.</p>
      </Dragger>
    </Modal>
  )
}

export default AddAdvInfo

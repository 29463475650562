/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppGetMenuResource } from '../models/AppGetMenuResource';
import { request as __request } from '../core/request';

export class AppMenuService {

    /**
     * Get depots list
     * This end point will return all depots.
     * @returns AppGetMenuResource App get menu resource response
     * @throws ApiError
     */
    public static async appDepot(): Promise<AppGetMenuResource> {
        const result = await __request({
            method: 'GET',
            path: `/api/app/menu-access`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
            },
        });
        return result.body;
    }

}
import type { CheckingAreaResource } from 'api/main'
import { Row, Col } from 'antd'
import styled from 'styled-components'

interface Props {
  initData: CheckingAreaResource
}

const CheckongInfo = ({ initData }: Props) => {
  return (
    <Row>
      <Col span={8}>
        <Header>Notes from Advance Info</Header>
        <p>{initData.advance_info_note}</p>
      </Col>
      <Col span={8}>
        <Header>Notes from Arrivals</Header>
        <p>{initData.arrival_note}</p>
      </Col>
    </Row>
  )
}

const Header = styled.p`
  font-weight: 500;
`

export default CheckongInfo

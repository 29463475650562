import { VoidFunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, Card, Row, Col, Form, Input as AntInput, Select, Radio, DatePicker, Button } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useFetchDepotsOnce from 'hooks/useFetchDepotsOnce'
import { AdminDepotWithAreaResource, OutReferenceResource } from 'api/main'
import {
  outReferenceListRequest,
  outReferenceUpdateRequest,
  selectOutReferenceUpdateStatus,
} from 'store/outReference'
import { containerTypeListRequestAsync, selectContainerTypeListMappedData, selectContainerTypeListStatus } from 'store/containerType'
import { selectCompaniesListMappedData } from 'store/companies'
import { MappedOption, OutReferenceType, Status } from 'models'
import { ContainerGrades, StockTypesInOutRef, liftOutInvoiceTypes, CountainerSize } from 'constants/ContainerCycleConsts'
import { colors } from 'constants/colors'

interface Props {
  onCancel: () => void
  depot: MappedOption
  visible?: boolean
  defaultValues?: OutReferenceType
  currentPage: number
}

const EditOutReference: VoidFunctionComponent<Props> = ({ visible, onCancel, depot, defaultValues, currentPage }) => {
  const dispatch = useAppDispatch()
  const [depotData] = useFetchDepotsOnce()
  const containerTypes = useAppSelector(selectContainerTypeListMappedData)
  const companiesData = useAppSelector(selectCompaniesListMappedData)
  const updateStatus = useAppSelector(selectOutReferenceUpdateStatus)
  const containerTypeListStatus = useAppSelector(selectContainerTypeListStatus)
  const containerTypeFetched = containerTypeListStatus !== Status.IDLE
  const [removedContainer, setRemovedContainer] = useState<number[]>([])
  const [depotList, setDepotList] = useState<MappedOption[]>([])
  const setErrList = useState<string[]>([])[1]
  const [form] = Form.useForm()

  useEffect(() => {
    if (!containerTypeFetched) {
      dispatch(containerTypeListRequestAsync({ perPage: 100, page: currentPage, query: '' }))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, containerTypeFetched])

  useEffect(() => {
    if(depotData && depot) {
      const data: any = depotData.find(((item: AdminDepotWithAreaResource) => item.name === depot.label))
      setDepotList(data?.areas)
    }

  }, [depotData, depot])

  useEffect(() => {
    form.resetFields()
    setRemovedContainer([])

    return () => { 
      form.resetFields()
    }
  }, [form, defaultValues])

  const onCancelForm = () => {
    onCancel()
    form.resetFields()
  }

  const onFinish = async (values: OutReferenceResource) => {
    const result = await dispatch(outReferenceUpdateRequest({
      outReferenceId: defaultValues?.id || 0,
      requestBody: {
        ...values,
        deleted_container_infos: removedContainer,
        expiration_date: moment(values.expiration_date).format('YYYY-MM-DD'),
        valid_from: moment(values.valid_from).format('YYYY-MM-DD'),
        note: values.note || null,
      }
    }))

    if ((result as any)?.error) {
      const errObject = (result as any)?.payload?.errors
      const errArray: string[] = []
      if (errObject) {
        Object.keys(errObject).forEach(key => {
          errObject[key] && errArray.push(errObject[key][0])
        })
      }
      setErrList(errArray)
    } else {
      dispatch(outReferenceListRequest({
        depotId: Number(depot.value),
        list: { perPage: 20, page: 1, query: '' },
      }))

      onCancelForm()
    }
  }

  const findValueByLabel = (allItems: any[], label?: string) => {
    return allItems.find(item => item?.label?.toLowerCase() === label?.toLowerCase())?.value
  }

  const findValueByValue = (allItems: any[], value?: string) => {
    return allItems.find(item => item?.value?.toLowerCase() === value?.toLowerCase())?.value
  }

  const findValueByName = (allItems: any[], name?: string) => {
    return allItems.find(item => item?.name === name)?.value
  }

  return (
    <Modal
      visible={visible}
      title={`New Out Reference: ${depot.label}`}
      okText="Submit"
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onFinish(values)
          })
          .catch(info => {
            // TODO: handle errors if we want to show it in current modal.
          })
      }}
      okButtonProps={{ loading: updateStatus === Status.PENDING }}
      onCancel={onCancelForm}
      bodyStyle={{ padding: 0 }}
      width={850}
    >
      <Form layout="vertical" form={form} name="update_out_reference" onFinish={onFinish} autoComplete="off">
          <Row>
            <Col span={24}>
              <Card>
                <Row gutter={[20, 20]}>
                  <Col span={8}>
                    <Form.Item
                      name="reference"
                      label="Reference"
                      rules={[{ required: true, message: 'Reference is required!' }]}
                      initialValue={defaultValues?.reference}
                    >
                      <AntInput placeholder='Reference' />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="stock_type_id"
                      label="Stock Type"
                      rules={[{ required: true, message: 'Stock Type is required!' }]}
                      initialValue={findValueByName(StockTypesInOutRef, defaultValues?.stock_type)}
                    >
                      <Select options={StockTypesInOutRef} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="company_id"
                      label="Company"
                      rules={[{ required: true, message: 'Company is required!' }]}
                      initialValue={findValueByLabel(companiesData, defaultValues?.company)}
                    >
                      <Select options={companiesData} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 20]}>
                  <Col span={8}>
                  <Form.Item
                      name="grade"
                      label="Grade"
                      rules={[{ required: true, message: 'Grade is required!' }]}
                      initialValue={findValueByValue(ContainerGrades, defaultValues?.grade)}
                    >
                      <Select options={ContainerGrades} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <ErrorWrapper>* </ErrorWrapper>
                    <LableWrapper>Lift Out Paid by</LableWrapper>
                    <FlexInputGroup compact>
                      <Form.Item
                        name="lift_out_invoice_type"
                        rules={[{ required: true, message: 'lift out invoice type is required!' }]}
                        initialValue={findValueByLabel(liftOutInvoiceTypes, defaultValues?.lift_out_invoice_type)}
                      >
                        <Select options={liftOutInvoiceTypes} />
                      </Form.Item>
                      <InputWrapper>
                      <Form.Item
                        name="lift_out_paid_by_company_id"
                        rules={[{ required: true, message: 'lift out paid by is required!' }]}
                        initialValue={findValueByLabel(companiesData, defaultValues?.lift_out_paid_by)}
                      >
                        <Select options={companiesData} />
                      </Form.Item>
                      </InputWrapper>
                    </FlexInputGroup>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="HPL"
                      label="HPL"
                      rules={[{ required: true, message: 'HPL is required!' }]}
                      initialValue={defaultValues?.HPL}
                    >
                      <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 20]}>
                  <Col span={8}>
                    <Form.Item
                      name="valid_from"
                      label="Valid from"
                      rules={[{ required: true, message: 'Valid from is required!' }]}
                      initialValue={moment(defaultValues?.valid_from)}
                    >
                      <DatePickerStyled />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="expiration_date"
                      label="Expiration date"
                      rules={[{ required: true, message: 'Expiration date is required!' }]}
                      initialValue={moment(defaultValues?.expiration_date)}
                    >
                      <DatePickerStyled />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="lessee"
                      label="Lessee"
                      initialValue={defaultValues?.lessee}
                    >
                      <AntInput placeholder='Lessee' />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.List
                  name="container_infos"
                  rules={[
                    {
                      validator: async (_, container_infos) => {
                        if (!container_infos || container_infos.length < 1) {
                          return Promise.reject(new Error('At least 1 container information is required!'))
                        }
                      },
                    },
                  ]}
                  initialValue={defaultValues?.container_info}
                >
                  {(fields: any[], { add, remove }: any, { errors }: any) => (
                    <>
                      {fields.map((field: any, index: number) => (
                        <Row key={field.key} gutter={[16, 8]}>
                          <Col span={0}>
                            <Form.Item
                              {...field}
                              label="Id"
                              name={[field.name, 'id']}
                              initialValue={defaultValues?.container_info[index]?.id ? defaultValues?.container_info[index]?.id : null}
                            >
                              <AntInput />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              label="Size"
                              name={[field.name, 'size']}
                              rules={[{ required: true, message: 'Size is required!' }]}
                            >
                              <Select
                                placeholder="Size"
                                options={CountainerSize}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              label="Type"
                              name={[field.name, 'type_id']}
                              rules={[{ required: true, message: 'Type is required!' }]}
                              initialValue={findValueByLabel(containerTypes, defaultValues?.container_info[index]?.type)}
                            >
                              <Select
                                placeholder="Type"
                                options={containerTypes}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              label="Count"
                              name={[field.name, 'total_count']}
                              rules={[{ required: true, message: 'Count is required!' }]}
                              initialValue={defaultValues?.container_info[index]?.total_count}
                            >
                              <AntInput placeholder='Count' />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...field}
                              label="Area"
                              name={[field.name, 'area']}
                              rules={[{ required: true, message: 'Area is required!' }]}
                              initialValue={findValueByLabel(depotList, defaultValues?.container_info[index]?.area)}
                            >
                              <Select
                                placeholder="Type"
                              >
                                {depotList?.map((item: any) => (
                                  <Select.Option key={item.id} value={item.name}>
                                    {item.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <MinusCircleOutlined
                              hidden={!index}
                              className='dynamic-delete-button'
                              onClick={() => {
                                remove(field.name)
                                setRemovedContainer([...removedContainer, defaultValues?.container_info[index]?.id || 0])
                              }}
                            />
                          </Col>
                        </Row>
                        ))}

                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Size, Type, Count
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                  </Form.List>
                  <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <Form.Item
                      name="note"
                      label="Note"
                      initialValue={defaultValues?.note}
                    >
                      <AntInput.TextArea rows={4} placeholder='Note' />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
    </Modal>
  )
}

export default EditOutReference

const FlexInputGroup = styled(AntInput.Group)`
  display: flex !important;
`
const InputWrapper = styled.span`
  flex-grow: 1;
`
const LableWrapper = styled.span`
  display: inline-block;
  margin-bottom: 8px;
`
const ErrorWrapper = styled.span`
  color: ${colors.RED};
`
const DatePickerStyled = styled(DatePicker)`
  width: 100%;
`

import { combineReducers } from '@reduxjs/toolkit'
import { listSliceBuilder, createSliceBuilder } from 'store/helper'
import type { ArrivalResource } from 'api/main'
import { arrivalsListRequest, arrivalUpdateRequest, moveToCheckingRequest } from 'store/arrivals'

export const arrivalsReducers = combineReducers({
  list: listSliceBuilder<ArrivalResource>('listArrivals', arrivalsListRequest).reducer,
  update: createSliceBuilder<void>('updateAdvInfo', arrivalUpdateRequest).reducer,
  moveToChecking: createSliceBuilder<void>('moveToChecking', moveToCheckingRequest).reducer,
})

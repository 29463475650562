import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AvailableAreaCollection,
  AdminContainerAvailableService,
} from 'api/main'
import { handleApiCallError } from 'store/helper'

export const availableListRequest = createAsyncThunk<
  AvailableAreaCollection,
  {
    depotId: number,
    perPage?: number,
    page?: number,
    query?: string,
  },
  { rejectValue: string }
>('list/availableListRequestAsync', async ({ depotId, perPage, page, query }, { rejectWithValue }) => {
  try {
    return await AdminContainerAvailableService.adminDepotDepotIdAvailableList(depotId, perPage, page, query)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const moveToAvailableRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'moveToAvailable/moveToAvailableRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerAvailableService.adminAdvanceInfoAdvanceInfoIdAvailablePost(advanceInfoId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

import { Status } from '../../../models'
import { createSlice } from '@reduxjs/toolkit'
import { forgotPasswordRequestAsync } from './asyncActions'
import { RootState } from '../../index'

export interface ForgotPasswordState {
  status: Status
  error: string | null
}

const initialState: ForgotPasswordState = {
  status: Status.IDLE,
  error: null,
}

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(forgotPasswordRequestAsync.pending, state => {
        state.status = Status.PENDING
      })
      .addCase(forgotPasswordRequestAsync.fulfilled, state => {
        state.status = Status.SUCCEEDED
      })
      .addCase(forgotPasswordRequestAsync.rejected, (state, action) => {
        state.status = Status.FAILED
        state.error = action.payload ?? 'Unknown Error'
      })
  },
})

export const selectForgotPassStatus = (state: RootState): ForgotPasswordState['status'] =>
  state.auth.forgotPassword.status
export const selectForgotPassError = (state: RootState): ForgotPasswordState['error'] => state.auth.forgotPassword.error

export default forgotPasswordSlice.reducer

import { colors } from 'constants/colors'
import type { UpdateDamageEstimateRequest, DamagedAreaResource } from 'api/main'
import { DamagedFilterModel } from 'models/forms/DamagedFilterModel'

export enum damagedStatuses {
  waitting_for_estimate = 'damage_waiting_for_estimation',
  waiting_for_approval = 'damage_waiting_for_approval',
  approved = 'damage_approved',
  rejected = 'damage_rejected',
}
export enum damagedActions {
  add_estimation = 'add_estimation',
  move_to_repairing = 'move_to_repairing',
  move_to_washing = 'move_to_washing',
  set_status = 'set_status',
  move_to_available = 'move_to_available',
}

export const mapTextAndColor = (record: DamagedAreaResource): [string, string] => {
  switch (record.status) {
    case damagedStatuses.waitting_for_estimate:
      return ['Waiting for estimation', colors.GRAY2]
    case damagedStatuses.waiting_for_approval:
      return ['Waiting for approval', colors.ORANGE]
    case damagedStatuses.approved:
      return ['Approved', colors.GREEN]
    case damagedStatuses.rejected:
      return ['Rejected', colors.RED]
    default:
      return ['-', colors.GRAY2]
  }
}

export const mapActionText = (record: DamagedAreaResource): string => {
  switch (record.action) {
    case damagedActions.add_estimation:
      return 'Add Estimation'
    case damagedActions.move_to_repairing:
      return 'Move To Repairing Area'
    case damagedActions.move_to_washing:
      return 'Move to Washing Area'
    case damagedActions.set_status:
      return 'Set Status'
    default:
      return '-'
  }
}

export const estmInintialData: UpdateDamageEstimateRequest = {
  number: '',
  damage: '',
  reference: '',
  quantity: '',
  repair: '',
  length: '',
  width: '',
  hours: '',
  company_id: 0,
  component: '',
  location: '',
  paid_by_third_party: false,
  material: '',
  labor_rate: 0,
}

export const filterInitialData: DamagedFilterModel = {
  grade: '',
  stockType: '',
  status: '',
  state: '',
  isoCode: '',
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminUpdateProfileRequest = {
    first_name?: string;
    last_name?: string;
    language?: AdminUpdateProfileRequest.language;
}

export namespace AdminUpdateProfileRequest {

    export enum language {
        EN = 'en',
        FI = 'fi',
    }


}

import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import type { CompanyDepotRequest } from 'api/main'

export const invoiceFields: GeneralFormFieldType<CompanyDepotRequest> = {
  lift_in_fee: {
    label: 'Lift in fee',
    placeholder: 'example',
  },
  lift_out_fee: {
    label: 'Lift out fee',
    placeholder: 'example',
  },
  extra_lift_fee: {
    label: 'Extra lift fee',
    placeholder: 'example',
  },
  storage_fee_per_day: {
    label: 'Storage fee/day',
    placeholder: 'example',
  },
  special_storage_fee_per_day: {
    label: 'Storage Fee Special',
    placeholder: 'example',
  },
  repair_fee_per_hour: {
    label: 'Repair fee/hour',
    placeholder: 'example',
  },
  storage_free_days: {
    label: 'Storage Free days',
    placeholder: 'example',
  },
  customer_number: {
    label: 'Customer number',
    placeholder: 'example',
  },
  vessel_lift_in_fee: {
    label: 'Vessel Lift in fee',
    placeholder: 'example',
  },
  vessel_lift_out_fee: {
    label: 'Vessel Lift out fee',
    placeholder: 'example',
  },
  special_lift_in_fee: {
    label: 'Special Lift in fee',
    placeholder: 'example',
  },
  special_lift_out_fee: {
    placeholder: 'example',
    label: 'Special Lift out fee',
  },
  repair_lift_fee: {
    label: 'Repair Lift fee',
    placeholder: 'example',
  },
  extra_work_per_hour: {
    label: 'Extra work per hour',
    placeholder: 'example',
  },
  extra_work_with_machine_per_hour: {
    label: 'Extra work with machine per hour',
    placeholder: 'example',
  },
}

import { Route, RouteProps, Switch } from 'react-router-dom'
import { VoidFunctionComponent, useEffect, useState } from 'react'
import DashboardPage from 'Components/Dashboard/DashboardPage'
import { Layout } from 'antd'
import Header from 'Components/Shared/Header'
import { Sidebar } from 'Components/Shared'
// import { adminMenu } from 'menus'
import UsersPage from 'Components/Users/UsersPage'
import { PageLayout } from 'Components/UiKit/Layout'
import CompaniesPage from 'Components/Companies/CompaniesPage'
import UserPage from 'Components/Users/User/UserPage'
import ISOCodesPage from 'Components/ISOCodes/ISOCodesPage'
import CreateISOCodePage from 'Components/ISOCodes/CreateISOCode/ISOCodePage'
import CompanyDetailsPage from 'Components/Companies/CompanyDetails/CompanyDetailsPage'
import AddInvoicePage from 'Components/Companies/Invoice/AddInvoice/AddInvoicePage'
import EditUserPage from 'Components/Companies/CompanyUsers/EditUserPage'
import RepairDetailsPage from 'Components/Damageds/RepairDetails/RepairDetailsPage'
import Reports from 'Components/Reports'
import LifeCyclePageFactory from 'Components/Shared/LifeCyclePageFactory/LifeCyclePageFactory'
// import { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { accessRequestAsync, selectAccessData } from 'store/access'
import { MenuItem, Status } from 'models'
import { CodeOutlined, CopyOutlined, DatabaseOutlined, GoldOutlined, UserOutlined } from '@ant-design/icons'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import { selectAboutMeStatus, selectUserNameData } from 'store/auth/selectors'
import { useHistory } from 'react-router-dom'

const mainRoutes: RouteProps[] = [
  {
    exact: true,
    path: '/home',
    children: <DashboardPage />,
  },
  {
    exact: true,
    path: '/users',
    children: <UsersPage />,
  },
  {
    exact: true,
    path: '/users/:userId',
    children: <UserPage />,
  },
  {
    exact: true,
    path: '/companies',
    children: <CompaniesPage />,
  },
  {
    exact: true,
    path: '/companies/:companyId',
    children: <CompanyDetailsPage />,
  },
  {
    exact: true,
    path: '/companies/:companyId/invoices/:depotId',
    children: <AddInvoicePage />,
  },
  {
    exact: true,
    path: '/companies/:companyId/invoices/:depotId/:invoiceId',
    children: <AddInvoicePage />,
  },
  {
    exact: true,
    path: '/companies/:companyId/user/:userId',
    children: <EditUserPage />,
  },
  {
    exact: true,
    path: '/iso-codes',
    children: <ISOCodesPage />,
  },
  {
    exact: true,
    path: '/iso-code',
    children: <CreateISOCodePage />,
  },
  {
    exact: true,
    path: '/iso-code/:isoCodeId',
    children: <CreateISOCodePage />,
  },
  {
    exact: true,
    path: '/repair-details/:depotId/:advInfoId/:companyId/:containerNumber',
    children: <RepairDetailsPage />,
  },
  {
    exact: true,
    path: '/reports',
    children: <Reports />,
  },
]

//Add Container LifeCycle to the route from config
for (let item in containerLifeCyles) {
  const theField = containerLifeCyles[item as keyof typeof containerLifeCyles]
  mainRoutes.push({
    exact: true,
    path: `/${theField}`,
    children: <LifeCyclePageFactory />,
  })
}

export const paths = [mainRoutes.map(route => route.path)].flat().filter(path => path) as string[]

const MainRoutes: VoidFunctionComponent = () => {
  const dispatch = useAppDispatch()
  const accessData = useAppSelector(selectAccessData)
  const name = useAppSelector(selectUserNameData)
  const isUserDataLoading = useAppSelector(selectAboutMeStatus)
  const [accesses, setAccesses] = useState<any>(null)
  const [newMenu, setNewMenu] = useState<any>(null)
  const history = useHistory()

  useEffect(() => {
    dispatch(accessRequestAsync())
  }, [dispatch])

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (accessData) {
      setAccesses(accessData)
    }
    let newArray: any[] = []

    for (const accessIndex in accesses) {
      if (accesses[accessIndex]) {
        newArray = [...newArray, `/${accessIndex.replace('_', '-')}`]
      }
    }

    if (newArray.includes('/all')) {
      newArray = ['/all-containers', ...newArray]
    }

    // Add Container LifeCycle Sub menues from config
    const containerSubMenues = []
    for (let item in containerLifeCyles) {
      const theField = containerLifeCyles[item as keyof typeof containerLifeCyles]

      for (let a = 0; a < newArray?.length; a++) {
        if (`/${theField}` === newArray[a]) {
          containerSubMenues.push({
            title: lifeCyclesConfig[theField].menuTitle || lifeCyclesConfig[theField].title,
            path: `/${theField}`,
            key: theField,
          })
        }
      }
    }

    const adminMenu: MenuItem[] = [
      {
        title: 'Containers',
        key: 'men1',
        icon: <DatabaseOutlined />,
        items: [...containerSubMenues],
      },
      {
        title: 'Users',
        key: 'item1',
        icon: <UserOutlined />,
        path: '/users',
        items: [],
      },
      {
        title: 'Companies',
        key: 'item2',
        icon: <GoldOutlined />,
        path: '/companies',
        items: [],
      },
      {
        title: 'Reports',
        key: 'item4',
        icon: <CopyOutlined />,
        path: '/reports',
        items: [],
      },
      {
        title: 'ISO codes',
        key: 'item6',
        icon: <CodeOutlined />,
        path: '/iso-codes',
        items: [],
      },
    ]

    const newMenuWithPath = adminMenu.filter(item => item.path)
    const newMenuWithoutPath = adminMenu.filter(item => !item.path)

    const newMenu = newMenuWithPath.filter(item => {
      let result
      for (let a = 0; a <= newArray.length; a++) {
        if (item.path === newArray[a]) {
          result = item
        }
      }
      return result
    })

    setNewMenu(newMenuWithoutPath[0].items?.length ? [...newMenuWithoutPath, ...newMenu] : [...newMenu])
  }, [accessData, accesses, history])

  return (
    <Layout>
      <Header name={name ?? "name"} isLoadingn={isUserDataLoading === Status.PENDING} />
      <Layout>
        <Sidebar menuItems={newMenu} />
        <PageLayout>
          <Switch>
            {mainRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Switch>
        </PageLayout>
      </Layout>
    </Layout>
  )
}

export default MainRoutes

import { VoidFunctionComponent } from 'react'
import { Tag } from 'antd'

interface Props {
  simple?: boolean
  data: any[]
  dataKey: string
  color?: string
}

const MultiValueRenderer: VoidFunctionComponent<Props> = ({ data, dataKey, color = 'blue', simple = true }) => {
  return (
    <div>
      {simple
        ? data.map(dt => dt[dataKey]).join(', ')
        : data.map(dt => (
            <Tag key={dt.id} color={color}>
              {dt[dataKey]}
            </Tag>
          ))}
    </div>
  )
}

export default MultiValueRenderer

import { ListState,NormalState } from 'models'
import type { ArrivalResource } from 'api/main'
import { RootState } from '../index'

type ArrivalsListState = ListState<ArrivalResource>
export const selectArrivalsListData = (state: RootState): ArrivalsListState['data'] => state.arrivals.list.data
export const selectArrivalsPaginationData = (state: RootState): ArrivalsListState['paginationData'] =>
  state.arrivals.list.paginationData

export const selectArrivalsListStatus = (state: RootState): ArrivalsListState['status'] => state.arrivals.list.status

export const selectArrivalUpdateStatus= (state: RootState): NormalState<void>['status'] =>
state.arrivals.update.status

export const moveToCheckingStatus= (state: RootState): NormalState<void>['status'] =>
state.arrivals.moveToChecking.status

export const tableSelectors = {
  data: selectArrivalsListData,
  status: selectArrivalsListStatus,
  pagination: selectArrivalsPaginationData,
}

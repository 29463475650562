/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckingAreaCollection } from '../models/CheckingAreaCollection';
import { request as __request } from '../core/request';

export class AdminContainerCheckingService {

    /**
     * Get Checking Area list
     * This end point will return all Checking Area information.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over Checking Area.
     * @returns CheckingAreaCollection Checking area collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdCheckingList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
    ): Promise<CheckingAreaCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/checking`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

    /**
     * From arrival to checking state
     * With this end point you can move a container from arrival state to checking.
     * @param advanceInfoId
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoAdvanceInfoIdCheckingPost(
        advanceInfoId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/advance_info/${advanceInfoId}/checking`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

}
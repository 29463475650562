import {  useEffect } from 'react'
import { PageHeader } from 'antd'
import HeaderExtra from 'Components/Shared/PageHeader/HeaderExtra'
import { useAppDispatch } from 'store/hooks'
import { MappedOption } from 'models'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import { allContainersListRequest } from 'store/allContainers'
import searchedParamUtils from 'utils/searchedParam'
import { useLocation } from 'react-router'

type Props = {
  depot: MappedOption
  fieldName: containerLifeCyles
  page: number
}

const AllContainersHeader = ({ depot, fieldName, page }: Props) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      allContainersListRequest({
        depotId: Number(depot.value),
        perPage: 20,
        page,
        query: searchedParamUtils.getSearchedParam().searched || '',
      })
    )
    return () => {
      searchedParamUtils.removeSearchedParam()
    }
  }, [dispatch, depot.value, page])

  return (
    <>
      <PageHeader
        ghost={false}
        title={lifeCyclesConfig[fieldName].title}
        extra={
          <HeaderExtra
            defaultValue={location.pathname === searchedParamUtils.getSearchedParam().pathname ? searchedParamUtils.getSearchedParam().searched : ''}
            searchPlaceholder={"Search by container number, customer or reference"}
            onSearch={(value: string) => {
              searchedParamUtils.setSearchedParam({ pathname: location.pathname, searched: value })
              dispatch(
                allContainersListRequest({
                  depotId: Number(depot.value),
                  perPage: 20,
                  page: 1,
                  query: value,
                })
              )
            }}
          />
        }
      />
    </>
  )
}

export default AllContainersHeader

import styled from 'styled-components'
import {Text} from 'Components/UiKit/Typography'
import {colors} from 'constants/colors'

interface Props {
  decriptionArr: string[]
  color?: colors
}
const ErrorToast = ({ decriptionArr, color= colors.BLACK }: Props) => {
  return (
    <Ul>
      {decriptionArr.map((desc, index) => (
        <ColorLi key={index} color={color}>
          <Text color={color}>{desc}</Text>
        </ColorLi>
      ))}
    </Ul>
  )
}

export default ErrorToast

const Ul = styled.ul`
  list-style: none;
`

const ColorLi = styled.li`
&::before {
  content: "•";
  color: ${({color})=>color};
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
`
import styled from 'styled-components'

interface Props<T> {
  record: T
  mapper: (record: T) => [string, string]
}

function ColumnWithDots<T>({ record, mapper }: Props<T>) {
  const color = mapper(record)[1]
  return <ColumnWithDotse color={color}>{mapper(record)[0]}</ColumnWithDotse>
}

export default ColumnWithDots

const ColumnWithDotse = styled.p<{ color: string }>`
  margin-bottom: 0;
  &::before {
    content: '';
    color: ${({ color }) => color};
    background-color: ${({ color }) => color};
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    margin-bottom: 2px;
  }
`

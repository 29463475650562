import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerWashedService,
  AdminContainerWashingService,
  WashingAreaCollection,
} from 'api/main'
import { handleApiCallError } from 'store/helper'

export const washingListRequest = createAsyncThunk<
  WashingAreaCollection,
  {
    depotId: number
    perPage?: number
    page?: number
    query?: string
    status?: string
  },
  { rejectValue: string }
>('list/washingListRequestAsync', async ({ depotId, perPage, page, query, status }, { rejectWithValue }) => {
  try {
    return await AdminContainerWashingService.adminDepotDepotIdWashingList(depotId, perPage, page, query, status)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const moveToWashedRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'moveToWashing/moveToWashingRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerWashedService.adminAdvanceInfoAdvanceInfoIdWashedPost(advanceInfoId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

import { VoidFunctionComponent, useState, ChangeEvent } from 'react'
import { Form, Button, Typography, Input, Row, Col, Divider } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { callbackUtils } from 'utils'
import { DepotType, LastAction } from 'Components/Ordering/OrderingTypes'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { dropOffRequest, dropOffValidationRequest, selectDropOffValidationOutStatus } from 'store/ordering'
import { Status } from 'models'

const { Title, Text } = Typography

interface Props {
    selectedDepot: DepotType
    failed: (lastAction: LastAction) => void
    success: () => void
}

interface containerNumbersTypes {
    container: string
}

interface FormTypes {
    company_email: string
    container_numbers: [{ container: string }]
    license_plate_number: string
    transportation_company: string
}

const ChooseAction: VoidFunctionComponent<Props> = ({ selectedDepot, failed, success }) => {
    const dispatch = useAppDispatch()
    const setErrList = useState<string[]>([])[1]
    const [checkContainersValidation, setContainersValidation] = useState<Array<undefined | 'validating' | 'error' | 'success'>>([undefined])
    
    const dropOffValidationOutStatus = useAppSelector<any>(selectDropOffValidationOutStatus)

    const getContainersNumber = (array: containerNumbersTypes[]): string[] => {
        return array.map(({ container }: containerNumbersTypes) => container)
    }

    const onFinish = async (values: FormTypes): Promise<void> => {
        const result = await dispatch(dropOffRequest({
            requestBody: {
                depot_id: selectedDepot.includes('helsinki') ? 1 : 2,
                company_email: values.company_email,
                transportation_company: values.transportation_company,
                license_plate_number: values.license_plate_number,
                container_numbers: getContainersNumber(values.container_numbers)
            }
        }))
    
        if ((result as any)?.error) {
            const errObject = (result as any)?.payload?.errors
            const errArray: string[] = []
            if (errObject) {
                Object.keys(errObject).forEach(key => {
                errObject[key] && errArray.push(errObject[key][0])
                })
            }
            setErrList(errArray)
            failed(LastAction.DROP_OFF)
            setContainersValidation([undefined])
        } else {
            success()
            setContainersValidation([undefined])
        }
    }

    const debouncedSearch = callbackUtils.debounce((value: string, index: number) => handleContainersChange(value, index), 1200)

    async function handleChange(value: any, index: any) {
    debouncedSearch(value, index)
    }

    const handleContainersChange = async (value: string, index: number) => {
        if (value.length < 3) {
            return
        }

        let newContainersValidation = [...checkContainersValidation]
        newContainersValidation[index] = 'validating'
        setContainersValidation(newContainersValidation)

        const result = await dispatch(dropOffValidationRequest({
            requestBody: {
                depot_id: selectedDepot.includes('helsinki') ? 1 : 2,
                container_number: value,
            }
        }))

        if ((result as any)?.error) {
            let newContainersValidation = [...checkContainersValidation]
            newContainersValidation[index] = 'error'
            setContainersValidation(newContainersValidation)
        } else {
            let newContainersValidation = [...checkContainersValidation]
            newContainersValidation[index] = 'success'
            setContainersValidation(newContainersValidation)
        }
    }

    return (
        <>
            <Title level={5} className='mb-6'>Container Drop Off - {selectedDepot}</Title>
            <Form
                name="pickUp"
                layout='vertical'
                onFinish={onFinish}
                autoComplete='off'
            >
            <Row gutter={[24, 8]}>
                <Col span={11} className='ml-2'>
                    <Text strong>Basic Information</Text>
                    <Form.Item
                        className='mt-3'
                        name="license_plate_number"
                        label="License Plate Number"
                        rules={[{ required: true, message: 'License Plate Number is required!' }]}
                    >
                        <Input placeholder='License Plate Number' />
                    </Form.Item>
                    <Form.Item
                        name="transportation_company"
                        label="Transportation Company"
                        rules={[{ required: true, message: 'Transportation Company is required!' }]}
                    >
                        <Input placeholder='Transportation Company' />
                    </Form.Item>
                    <Form.Item
                        name="company_email"
                        label="Company Email"
                        rules={[{ type: 'email', message: 'Company Email is not a valid email!' }]}
                        initialValue={null}
                    >
                        <Input placeholder='Company Email' />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <Divider type='vertical' className='h-100-percent ml-3' />
                </Col>
                <Col span={11} className='ml-3'>
                    <Text strong>Containers</Text>
                    <Row className='mb-3' ></Row>
                    <Form.List
                        name="container_numbers"
                        rules={[
                            {
                            validator: async (_, container_numbers) => {
                                if (!container_numbers || container_numbers.length < 1) {
                                return Promise.reject(new Error('At least 1 container is required!'))
                                }
                            },
                            },
                        ]}
                        initialValue={[{ container: undefined }]}
                        >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                            {fields.map((field, index) => (
                                <Row>
                                    <Col span={21}>
                                        <Form.Item
                                            {...field}
                                            label="Container Number"
                                            name={[field.name, 'container']}
                                            rules={[{ required: true, message: 'Container Number is required!' }]}
                                            hasFeedback={!!checkContainersValidation[index]}
                                            validateStatus={checkContainersValidation[index]}
                                        >
                                            <Input disabled={dropOffValidationOutStatus === Status.PENDING} onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, index)} placeholder='Container Number' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <MinusCircleOutlined hidden={!index} className='dynamic-delete-button' onClick={(): void => remove(field.name)} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Col span={21}>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Container
                                    </Button>
                                </Col>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                </Col>
                </Row>
                <Row justify='end'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Send Request
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    )
}

export default ChooseAction

export enum colors {
  WHITE = 'white',
  PALEVIOLETRED = 'palevioletred',
  NAVY_BLUE = '#000080',
  PRIMARY='#1890ff',
  BLACK = '#000000',
  LINK_WATER = '#D7E4F3',
  RED = '#FF4D4F',
  YELLOW_WARNING = '#FAAD14',
  ERR_NOTIF = '#FFCCC7',
  GRAY = '#f0f2f5',
  GREEN = '#52c41a',
  ORANGE = '#FA8C16',
  GRAY2 = '#D9D9D9'
}

import { useState, useEffect } from 'react'
import { Table } from 'antd'
import { ExpandableConfig } from 'antd/lib/table/interface'
import { ListState } from 'models'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'
import { Status } from 'models'
import Pagination from 'Components/UiKit/Pagination'
import { colors } from 'constants/colors'
import expandIcon from 'Components/UiKit/ExpandIcon'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
const itemsPerPage = 20
interface typeWithID {
  id?: number
}

interface Props<T> {
  selectors: {
    data: (state: RootState) => ListState<T>['data']
    status: (state: RootState) => ListState<T>['status']
    pagination: (state: RootState) => ListState<T>['paginationData']
  }
  children?: React.ReactNode
  expandable?: ExpandableConfig<T>
  configName?: containerLifeCyles
  onPaginate?: (pageNumber: number) => void
  expandedId?: number
}
function CDTable<T extends typeWithID>({ selectors, children, expandable, configName, onPaginate, expandedId }: Props<T>) {
  const [currentPage, setCurrentPage] = useState(1)
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])
  const onTableRowExpand = (expanded: boolean, record: T) => {
    const keys: number[] = []
    if (expanded && record.id) {
      keys.push(record.id) // I have set my record.id as row key. Check the documentation for more details.
    }

    setExpandedRowKeys(keys)
  }
  const tableData = useAppSelector(selectors.data)
  const tableStatus = useAppSelector(selectors.status)
  const tablePagination = useAppSelector(selectors.pagination)

  useEffect(() => {
    setExpandedRowKeys([Number(expandedId)])
  }, [setExpandedRowKeys, expandedId])

  return (
    <>
      <Table
        dataSource={tableData}
        rowKey={rc => rc.id || 0}
        locale={{ emptyText: 'No result' }}
        loading={tableStatus === Status.PENDING}
        scroll={{ x: true }}
        pagination={false}
        expandedRowKeys={expandedRowKeys}
        expandable={{
          ...expandable,
          onExpand: (expanded, record) => {
            onTableRowExpand(expanded, record)
            if (expandable?.onExpand) {
              expandable.onExpand(expanded, record)
            }
          },
          expandIcon: expandable && expandIcon,
        }}
      >
        <>
          {configName &&
            lifeCyclesConfig[configName]?.columns?.map(conf => (
              <Table.Column key={conf.dataIndex} title={conf.title} dataIndex={conf.dataIndex} />
            ))}
          {children}
        </>
      </Table>
      <Pagination
        pageSize={itemsPerPage}
        total={tablePagination?.total}
        current={currentPage}
        onChange={page => {
          setCurrentPage(page)
          if (onPaginate) {
            onPaginate(page)
          }
        }}
        style={{ backgroundColor: colors.GRAY }}
      />
    </>
  )
}

export default CDTable

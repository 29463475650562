import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerCheckedService,
  AdminContainerDamagedService,
  CheckedAreaCollection,
  CheckedAreaUpdateRequestBody,
} from 'api/main'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const checkedListRequest = createAsyncThunk<
  CheckedAreaCollection,
  { depotId: number; list: GeneralListRequest },
  { rejectValue: string }
>('list/checkedsListRequestAsync', async ({ depotId, list: { perPage, page, query } }, { rejectWithValue }) => {
  try {
    return await AdminContainerCheckedService.adminDepotDepotIdCheckedList(depotId, perPage, page, query)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const checkedUpdateRequest = createAsyncThunk<
  void,
  { checkedId: number; requestBody: CheckedAreaUpdateRequestBody },
  { rejectValue: string }
>('update/checkedUpdateRequest', async ({ checkedId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerCheckedService.adminCheckedUpdate(checkedId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const moveToDamagedRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'moveToDamaged/moveToDamagedRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerDamagedService.adminAdvanceInfoAdvanceInfoIdDamagedPost(advanceInfoId)
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)
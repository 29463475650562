import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { CustomerUpdateRequestWithEmail } from 'models'

export const CompanyUserFormFields: GeneralFormFieldType<CustomerUpdateRequestWithEmail> = {
  first_name: {
    label: 'First name',
    placeholder: 'First name',
  },
  last_name: {
    label: 'Last name',
    placeholder: 'Last name',
  },
  email: {
    label: 'Email',
    placeholder: 'test@gmail.com',
    disabled: true
  },
  address: {
    label: 'Address',
    placeholder: 'Address',
  },
  phone_number: {
    label: 'Phone',
    placeholder: 'Phone',
  },
}

import * as yup from 'yup'
import { validators } from '../utils'

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .matches(
      validators.passwordValidator,
      'Password must be at least 8 characters and contain at least one number, one lowercase letter and one uppercase letter'
    ),
  password_confirmation: yup
    .string()
    .required()
    .test('password-confirmation', 'Passwords must match', (value, testCtx) => testCtx.parent.password === value),
})

export default resetPasswordSchema

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminAuthService, AdminProfileResource } from 'api/main'
import { handleApiCallError } from 'store/helper'

export const adminAboutMeRequest = createAsyncThunk<AdminProfileResource, void, { rejectValue: string }>(
  'aboutMe/adminAboutMeRequest',
  async (body, { rejectWithValue }) => {
    try {
      return await AdminAuthService.adminAuthMe()
    } catch (e) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

import { VoidFunctionComponent } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, Form } from 'antd'
import Input from '../../UiKit/Inputs/Input'
import { RegisterFormFields } from './RegisterFormFields'
// import { Text } from '../../UiKit/Typography'
import { yupResolver } from '@hookform/resolvers/yup'
import registerSchema from 'schemas/registerSchema'
import { UserRegistrationRequest } from 'api/main'
import styled from 'styled-components'

type UserRegistrationRequestKeys = keyof UserRegistrationRequest

interface Props {
  onSubmit: SubmitHandler<UserRegistrationRequest>
  isSubmitting?: boolean
}
const RegisterForm: VoidFunctionComponent<Props> = ({ onSubmit, isSubmitting }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserRegistrationRequest>({ resolver: yupResolver(registerSchema) })

  return (
    <Form name="register" onFinish={handleSubmit(onSubmit)} noValidate>
      {Object.entries(RegisterFormFields).map(entry => {
        const value = entry[1]
        const key: UserRegistrationRequestKeys = entry[0] as UserRegistrationRequestKeys
        if (!value.hideInForm) {
          return (
            <span key={key}>
              <Label>{value.label}</Label>
              <Input
                errorClassName="ant-form-item-has-error"
                control={control}
                name={key}
                placeholder={value.placeholder}
                error={errors[key]?.message}
                type={value.type}
              />
            </span>
          )
        } else return null
      })}
      <Form.Item>
        <Button htmlType="submit" type="primary" className="w-100" loading={isSubmitting}>
          Confirm
        </Button>
      </Form.Item>
    </Form>
  )
}

export default RegisterForm

const Label = styled.div`
  margin-bottom: 8px;
`

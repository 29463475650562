import { createAsyncThunk } from '@reduxjs/toolkit'
import type { AdminRoleCollection } from 'api/main'
import { AdminRoleService } from 'api/main'
import { handleApiCallError } from 'store/helper'

export const roleListsRequestAsync = createAsyncThunk<AdminRoleCollection, void, { rejectValue: string }>(
  'list/roleListsRequestAsync',
  async (_, { rejectWithValue }) => {
    try {
      return await AdminRoleService.adminRole()
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue, 'rols list')
    }
  }
)

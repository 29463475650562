import { useState, FunctionComponent, useEffect } from 'react'
import { Table, Button, Space } from 'antd'
import { SubmitHandler } from 'react-hook-form'
import { DeleteOutlined } from '@ant-design/icons'
import { colors } from 'constants/colors'
import { showConfirmModal, MoveToNextStep } from 'store/app'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  advInfoListRequest,
  advInfoDeleteRequest,
  advInfoGetRequest,
  moveToArrivalRequest,
  selectMoveToArrivalStatus,
  tableSelectors,
} from 'store/advInfo'
import AdvInfoEdit from './AdvInfoEdit'
import type { AdvanceInfoResource, FromAdvanceInfoToArrivalRequest } from 'api/main'
import { Status } from 'models'
import { MappedOption } from 'models'
import MoveToArrivalModal from './MoveToArrivalModal'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import CDTable from 'Components/Shared/CDTable'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}

const AdvInfoTab: FunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [moveVisible, setMoveVisible] = useState(false)
  const [containerId, setContainerId] = useState<number>(0)
  const dispatch = useAppDispatch()
  const moveStatus = useAppSelector(selectMoveToArrivalStatus)
  onPaginateChange = (page: number) => {
      dispatch(advInfoListRequest({ depotId: Number(depot.value), list: { perPage: 20, page: page, query: '' } }))
      setCurrentPage(page)
  }
  const onMove: SubmitHandler<FromAdvanceInfoToArrivalRequest> = async values => {
    const result = await dispatch(moveToArrivalRequest({ containerId, requestBody: values }))
    if (!(result as any)?.error) {
      setMoveVisible(false)
      dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.arrivals].title))
      await dispatch(advInfoListRequest({ depotId: Number(depot.value), list: { perPage: 20, page: currentPage, query: '' } }))
    }
  }

  useEffect(() => {
    if (expandedId) {
      dispatch(advInfoGetRequest(expandedId))
    }
  }, [expandedId, dispatch])

  const renderAction = (_value: any, item: AdvanceInfoResource) => (
    <Space>
      <Button
        className='p-0'
        type="link"
        onClick={() => {
          if (item.id) {
            setContainerId(item.id)
          }
          setMoveVisible(true)
        }}
      >
        Move to Arrivals
      </Button>
      <DeleteOutlined
        style={{ color: colors.RED }}
        onClick={async () => {
          dispatch(
            showConfirmModal({
              message: `Are you sure you want to delete Container ${item.container_number}`,
              onYes: async () => {
                if (item.id) {
                  await dispatch(advInfoDeleteRequest(item.id))
                  dispatch(
                    advInfoListRequest({
                      depotId: Number(depot.value),
                      list: { perPage: 20, page: currentPage, query: '' },
                    })
                  )
                }
              },
            })
          )
        }}
      />
    </Space>
  )
  return (
    <>
      <CDTable<AdvanceInfoResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        expandable={{
          expandedRowRender: (record: AdvanceInfoResource) => {
            if (expandedId) {
              return <AdvInfoEdit advInfoId={expandedId} />
            }
            return record.id ? <AdvInfoEdit advInfoId={record.id} /> : null
          },
          expandIconColumnIndex: 6,
          onExpand: (expanded: boolean, record: AdvanceInfoResource) => {
            if (expanded && record.id) {
              dispatch(advInfoGetRequest(record.id))
            }
          },
        }}
        configName={containerLifeCyles.advanceInfo}
        onPaginate={onPaginateChange}
      >
        <Table.Column title="Action" key="action" render={renderAction} />
      </CDTable>
      <MoveToArrivalModal
        onSubmit={onMove}
        visible={moveVisible}
        loading={moveStatus === Status.PENDING}
        onCancel={() => {
          setMoveVisible(false)
        }}
      />
    </>
  )
}

export default AdvInfoTab

import {useState, VoidFunctionComponent} from 'react'
import { Table, Button, Space } from 'antd'
import { tableSelectors, moveToDamagedRequest, checkedListRequest, selectMoveToDamagedStatus } from 'store/checkeds'
import type { CheckedAreaResource, RepairingAreaResource } from 'api/main'
import { MappedOption, Status } from 'models'
import EditChecked from './EditChecked'
import { colors } from 'constants/colors'
import CDTable from 'Components/Shared/CDTable'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { showConfirmModal, MoveToNextStep } from 'store/app'
import lifeCyclesConfig, { containerLifeCyles } from 'Components/Shared/LifeCyclePageFactory/lifeCyclesConfig'
import { gradeTextMap } from 'Components/Shared/LifeCyclePageFactory/helper'
import ColumnWithDots from 'Components/Shared/ColumnWithDot'
import { moveToAvailableRequest } from 'store/availables'

interface Props {
  depot: MappedOption
  onPaginateChange?: (page: number) => void
  expandedId?: number
}

const CheckedsTab: VoidFunctionComponent<Props> = ({ depot, onPaginateChange, expandedId }: Props) => {
  const dispatch = useAppDispatch()
  const moveStatus = useAppSelector(selectMoveToDamagedStatus)
  const [currentPage, setCurrentPage] = useState(1)
    onPaginateChange = (page: number) => {
        dispatch(
            checkedListRequest({
                depotId: Number(depot.value),
                list: { perPage: 20, page: page, query: '' },
            })
        )
        setCurrentPage(page)
    }
  const moveToDemaged = (item: RepairingAreaResource) => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to move Container ${item.container_number} to Damaged Area?`,
        onYes: async () => {
          if (item.id) {
            const result = await dispatch(moveToDamagedRequest(item.id))
            if (!(result as any).error) {
              dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.damagedArea].title))
              dispatch(
                checkedListRequest({
                  depotId: Number(depot.value),
                  list: { perPage: 20, page: currentPage, query: '' },
                })
              )
            }
          }
        },
        confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const moveToAvailable = (item: RepairingAreaResource) => {
    dispatch(
      showConfirmModal({
        message: `Are you sure you want to move Container ${item.container_number} to Available Area?`,
        onYes: async () => {
          if (item.id) {
            const result = await dispatch(moveToAvailableRequest(item.id))
            if (!(result as any).error) {
              // TODO : wher continer should go after repair is done (for animation)
              dispatch(MoveToNextStep(lifeCyclesConfig[containerLifeCyles.availableArea].title))
              dispatch(
                checkedListRequest({
                  depotId: Number(depot.value),
                  list: { perPage: 20, page: currentPage, query: '' },
                })
              )
            }
          }
        },
        // confirmLoading: moveStatus === Status.PENDING,
      })
    )
  }

  const renderAction = (_value: any, item: CheckedAreaResource) => (
    <Space>
      <Button
        className='p-0'
        type="link"
        onClick={() => {
          // eslint-disable-next-line sonarjs/no-small-switch
          switch (item.checked_condition) {
            case 'damaged':
              moveToDemaged(item)
              break
            case 'available':
              moveToAvailable(item)
              break
            default:
              break
          }
        }}
      >
        {mapActionText(item)}
      </Button>
    </Space>
  )
  return (
    <>
      <CDTable<CheckedAreaResource>
        selectors={tableSelectors}
        expandedId={expandedId}
        expandable={{
          expandedRowRender: (record: CheckedAreaResource) => {
            return <EditChecked initialValues={record} depot={depot} currentPage={currentPage} />
          },
          expandIconColumnIndex: 6,
        }}
        configName={containerLifeCyles.checkedArea}
        onPaginate={onPaginateChange}
      >
        <Table.Column
          title="Condition"
          dataIndex="checked_condition"
          render={(text: string, record: CheckedAreaResource) => (
            <ColumnWithDots<CheckedAreaResource> record={record} mapper={mapTextAndColor} />
          )}
        />
        <Table.Column
          title="Grade"
          dataIndex="container_grade"
          render={(text: string, record: CheckedAreaResource) => <p style={{marginBottom:0}}>{gradeTextMap(text)}</p>}
        />
        <Table.Column title="Actions" key="action" render={renderAction} />
      </CDTable>
    </>
  )
}

export default CheckedsTab

const mapTextAndColor = (record: CheckedAreaResource): [string, string] => {
  switch (record.checked_condition) {
    case 'damaged':
      if (record.checked_only_washing) {
        return ['Damaged - Only Washing', colors.YELLOW_WARNING]
      } else {
        return ['Damaged', colors.RED]
      }
    case 'available':
      return ['Available', colors.GREEN]
    default:
      return ['Available', colors.GREEN]
  }
}

const mapActionText = (record: CheckedAreaResource): string => {
  switch (record.checked_condition) {
    case 'available':
      return 'Move to Available Area'
    case 'damaged':
      return 'Move to Demaged Area'
    default:
      return '-'
  }
}

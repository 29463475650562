import { GeneralFormFieldType } from 'models/forms/GeneralFormFields'
import { UserRegistrationRequest } from 'api/main'
import { InputType } from 'models'

export const RegisterFormFields: GeneralFormFieldType<UserRegistrationRequest> = {
  first_name: {
    label: 'First Name',
    placeholder: 'First Name',
  },
  last_name: {
    label: 'Last Name',
    placeholder: 'Last Name',
  },
  phone_number: {
    label: 'Phone number',
    placeholder: 'Phone number',
  },
  address: {
    label: 'Address',
    placeholder: 'Address',
  },
  password: {
    label: 'Password',
    placeholder: 'Password',
    type: InputType.PASSWORD
  },
  token:{
    label: 'Password',
    placeholder: 'Password',
    hideInForm: true,
  },
  date_of_birth:{
    label: 'Password',
    placeholder: 'Password',
    hideInForm: true,
  }

}

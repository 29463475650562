import React, { VoidFunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'
import { Typography } from 'antd'
import DepotsTab from './DepotsTab'
import lifeCyclesConfig, { containerLifeCyles } from './lifeCyclesConfig'
import { MappedOption } from 'models'

const { Title } = Typography

interface useLocationType {
  pathname: string
  containerId: number
  containerNumber: string
  depot: MappedOption
}

const LifeCyclePageFactory: VoidFunctionComponent = () => {
  const { state, pathname } = useLocation<useLocationType>()
  const fieldName: containerLifeCyles = pathname.substring(1) as containerLifeCyles

  return (
    <>
      <DepotsTab
        headerRrender={(depot,page) =>
          React.createElement(lifeCyclesConfig[fieldName].HeaderComponent, {
            depot: state?.depot || depot,
            fieldName,
            page,
            containerNumber: state?.containerNumber || '',
          })
        }
        tabRrender={(depot,onPaginateChange) => {
          const Rendered = lifeCyclesConfig[fieldName].TabComponent
          if (Rendered) {
            return React.createElement(Rendered, {
              depot: state?.depot || depot,
              onPaginateChange,
              expandedId: state?.containerId || 0,
            })
          } else {
            return (
              <Title level={2}>
                Container from {depot.label} in {fieldName} State{' '}
              </Title>
            )
          }
        }}
      />
    </>
  )
}

export default LifeCyclePageFactory

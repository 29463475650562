import * as yup from 'yup'
import { RegisterFormFields } from 'Components/Auth/Register/RegisterFormFields'
import { UserRegistrationRequest } from 'api/main'
import { GeneralSchemaType } from 'models/forms/GeneralSchemaType'
import { validators } from '../utils'

const registerSchema = yup.object().shape<GeneralSchemaType<UserRegistrationRequest>>({
  first_name: yup.string().required(`'${RegisterFormFields.first_name.label}' is a required field`),
  last_name: yup.string().required(`${RegisterFormFields.last_name.label}' is a required field`),
  address: yup.string().required(`'${RegisterFormFields?.address?.label}' is a required field`),
  password: yup
  .string()
  .required()
  .matches(
    validators.passwordValidator,
    'Password must be at least 8 characters and contain at least one number, one lowercase letter and one uppercase letter'
  ),
  token: yup.string(),
  phone_number: yup.string().required(`'${RegisterFormFields?.phone_number?.label}' is a required field`),
})

export default registerSchema

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AdminContainerArrivalService,
  AdminContainerCheckingService,
  ArrivalUpdateRequest,
  ArrivalCollection,
} from 'api/main'
import { GeneralListRequest } from 'models'
import { handleApiCallError } from 'store/helper'

export const arrivalsListRequest = createAsyncThunk<
  ArrivalCollection,
  { depotId: number; list: GeneralListRequest },
  { rejectValue: string }
>('list/arrivalsListRequestAsync', async ({ depotId, list: { perPage, page, query } }, { rejectWithValue }) => {
  try {
    return await AdminContainerArrivalService.adminDepotDepotIdArrivalList(depotId, perPage, page, query)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const arrivalUpdateRequest = createAsyncThunk<
  void,
  { arrivalId: number; requestBody: ArrivalUpdateRequest },
  { rejectValue: string }
>('update/arivalUpdateRequest', async ({ arrivalId, requestBody }, { rejectWithValue }) => {
  try {
    return await AdminContainerArrivalService.adminArrivalUpdate(arrivalId, requestBody)
  } catch (e: any) {
    return handleApiCallError(e, rejectWithValue)
  }
})

export const moveToCheckingRequest = createAsyncThunk<void, number, { rejectValue: string }>(
  'update/arivalUpdateRequest',
  async (advanceInfoId, { rejectWithValue }) => {
    try {
      return await AdminContainerCheckingService.adminAdvanceInfoAdvanceInfoIdCheckingPost(advanceInfoId)
      // return new Promise(()=>{})
    } catch (e: any) {
      return handleApiCallError(e, rejectWithValue)
    }
  }
)

import { CSSProperties, ReactNode, useState, VoidFunctionComponent } from 'react'
import { Button, Card, PageHeader, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import { AdminInviteCustomerRequest } from 'api/main'
import CompanyUsersTable from './CompanyUsersTable'
import CompanyInvoicesTable from './CompanyInvoicesTable'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { Status } from 'models'
import { selectCompanyWithId } from 'store/companies'
import { companyCreateUsersRequestAsync, selectCreateUserStatus } from 'store/companyUsers'
import UserFormModal from 'Components/Companies/CompanyUsers/createUserModal'
import { colors } from 'constants/colors'
const tabList = [
  {
    key: 'users',
    tab: 'Users',
  },
  {
    key: 'invoices',
    tab: 'Invoicing Details',
  },
]

const styles: CSSProperties = {
  padding: 0,
  backgroundColor: colors.GRAY
}

const tabContents: Record<string, ReactNode> = {
  users: <CompanyUsersTable />,
  invoices: <CompanyInvoicesTable />,
}

const CompanyDetailsPage: VoidFunctionComponent = () => {
  const [activeTab, setActiveTab] = useState(tabList[0].key)
  const [createUserModalVisible, setCreateUserModalVisible] = useState(false)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { companyId } = useParams<{ companyId: string }>()
  const selectedCompany = useAppSelector(selectCompanyWithId(Number(companyId)))
  const createUserStatus = useAppSelector(selectCreateUserStatus)
  const onSubmitCreateUser = async (_values: AdminInviteCustomerRequest) => {
    await dispatch(companyCreateUsersRequestAsync({ companyId: Number(companyId), requestBody: _values }))
    setCreateUserModalVisible(false)
  }
  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => {
          history.push('/companies')
        }}
        title={selectedCompany?.name}
        extra={
          <Space className="w-100 justify-content-end">
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setCreateUserModalVisible(true)}>
              Add new user
            </Button>
          </Space>
        }
      />
      <Card bodyStyle={styles} tabList={tabList} activeTabKey={activeTab} onTabChange={key => setActiveTab(key)}>
        {tabContents[activeTab]}
      </Card>
      <UserFormModal
        loading={createUserStatus === Status.PENDING}
        onSubmit={onSubmitCreateUser}
        onCancel={() => {
          setCreateUserModalVisible(false)
        }}
        visible={createUserModalVisible}
      />
    </>
  )
}

export default CompanyDetailsPage

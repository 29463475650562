import { VoidFunctionComponent, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, Form, Row, Col } from 'antd'
import Input from '../../UiKit/Inputs/Input'
import { CompanyUserFormFields } from './CompanyUserFormFields'
import { yupResolver } from '@hookform/resolvers/yup'
import editCompanyUserSchema from 'schemas/editCompanyUserSchema'
import { CustomerUpdateRequest } from 'api/main'
import { CustomerUpdateRequestWithEmail } from 'models'

type CompanyUserKeys = keyof CustomerUpdateRequestWithEmail

interface Props {
  initialValues: CustomerUpdateRequestWithEmail
  onSubmit: SubmitHandler<CustomerUpdateRequest>
  isSubmitting?: boolean
}
const UserForm: VoidFunctionComponent<Props> = ({ onSubmit, isSubmitting, initialValues }) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerUpdateRequestWithEmail>({ resolver: yupResolver(editCompanyUserSchema) })

  useEffect(() => {
    reset(initialValues)
  }, [reset, initialValues])

  return (
    <Form layout="vertical" name="updateUser" onFinish={handleSubmit(onSubmit)} noValidate>
      <Row gutter={16}>
        {Object.entries(CompanyUserFormFields).map(entry => {
          const value = entry[1]
          const key: CompanyUserKeys = entry[0] as CompanyUserKeys
          if (!value.hideInForm) {
            return (
              <Col key={key} span={8}>
                <Input
                  label={value.label}
                  errorClassName="ant-form-item-has-error"
                  control={control}
                  name={key}
                  placeholder={value.placeholder}
                  error={(errors[key] as any)?.message}
                  type={value.type}
                  disabled={value.disabled}
                />
              </Col>
            )
          } else return null
        })}
      </Row>

      <Form.Item className="text-right">
        <Button htmlType="submit" type="primary" loading={isSubmitting}>
          Update
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UserForm

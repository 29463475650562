/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WashingAreaCollection } from '../models/WashingAreaCollection';
import { request as __request } from '../core/request';

export class AdminContainerWashingService {

    /**
     * From damaged/repairing to washing state
     * With this end point you can move a container from damaged or repairing state to washing.
     * @param advanceInfoId
     * @returns void
     * @throws ApiError
     */
    public static async adminAdvanceInfoAdvanceInfoIdWashingPost(
        advanceInfoId: number,
    ): Promise<void> {
        const result = await __request({
            method: 'POST',
            path: `/api/admin/advance_info/${advanceInfoId}/washing`,
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                404: `The entity is not found.`,
            },
        });
        return result.body;
    }

    /**
     * Get Washing Area list
     * This end point will return all Washing Area information.
     * @param depotId
     * @param perPage number of elements per page.
     * @param page Page number.
     * @param query Search query over Repairing Area.
     * @param status Search status over Repairing Area.
     * @returns WashingAreaCollection Washing area collection response
     * @throws ApiError
     */
    public static async adminDepotDepotIdWashingList(
        depotId: number,
        perPage?: number,
        page?: number,
        query?: string,
        status?: string,
    ): Promise<WashingAreaCollection> {
        const result = await __request({
            method: 'GET',
            path: `/api/admin/depot/${depotId}/washing`,
            query: {
                'perPage': perPage,
                'page': page,
                'query': query,
                'status': status,
            },
            errors: {
                401: `Forbidden, this actions is unauthorized.`,
                403: `Unauthorized, the user is unauthenticated.`,
                422: `Unprocessable Entity.`,
            },
        });
        return result.body;
    }

}
import { VoidFunctionComponent } from 'react'
import { Grid, Col, Image, Row, RowProps } from 'antd'
import LogoImage from '../../../assets/images/logo.png'
import { colors } from '../../../constants/colors'
import { Text } from '../../UiKit/Typography'

const { useBreakpoint } = Grid

interface LogoProps {
  isResponsive?: boolean
  color?: colors
}

type Props = Pick<RowProps, 'align'> & LogoProps

const Logo: VoidFunctionComponent<Props> = ({ align = 'middle', isResponsive, color = colors.BLACK }) => {
  const screens = useBreakpoint()

  return (
    <Row align={align} justify={isResponsive ? (screens.lg ? 'start' : 'center') : 'start'}>
      <Col style={{ height: 26 }}>
        <Image preview={false} src={LogoImage} alt="Logo" />
      </Col>
      <Col>
        <Text className="pl-1" color={color} strong>
          CONTAINER-DEPOT
        </Text>
      </Col>
    </Row>
  )
}

export default Logo
